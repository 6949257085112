define("universal-cms/controllers/universal-brand/projects/edit-gallery", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    error: false,
    heading: Ember.computed('isNew', 'model.project.title', function () {
      return this.isNew === true ? 'Add Project Gallery' : `Edit Project Gallery "${this.get('model.gallery.title')}"`;
    }),
    actions: {
      addImage(mediaId) {
        this.set('isAddingImage', true);
        this.universalBrandService.createOne('gallery-media', {
          gallery_id: this.galleryId,
          media_id: mediaId
        }).then(createResponse => {
          this.get('model.gallery.images').pushObject(createResponse.get('image'));
          this.notificationService.show('Image Added', `Image has been added.`);
        }, createError => {
          this.notificationService.show('Error', 'Could not add image.');
        }).finally(() => {
          this.set('isAddingImage', false);
        });
      },

      deleteImage(mediaToDelete) {
        return this.universalBrandService.deleteOne(`delete-gallery-media/${this.galleryId}`, mediaToDelete.get('media_id')).then(() => {
          this.get('model.gallery.images').removeObject(mediaToDelete);
          this.notificationService.show('Image Deleted', 'Image has been deleted.');
        }, () => {
          this.notificationService.show('Error', 'Could not delete image.');
        });
      },

      updateOrder(orderedMedia) {
        let newOrder = [];

        for (let item of orderedMedia) {
          newOrder.push(item.get('media_id'));
        }

        let saveData = {
          items: newOrder
        };
        this.universalBrandService.updateOne(`galleries/${this.galleryId}`, 'update-order', saveData).then(() => {
          this.notificationService.show('Saved', `Order has been saved.`);
        }).catch(() => {
          this.notificationService.show('Error', `Could not save order.`);
        });
      }

    }
  });

  _exports.default = _default;
});