define("universal-cms/controllers/dreamworks/navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    isLoadingLayout: false,
    headerResources: Ember.Object.create({
      header_movies: Ember.Object.create({
        in_theaters: [],
        coming_soon: [],
        home_entertainment: []
      }),
      header_tv_shows: Ember.Object.create({
        now_streaming: [],
        coming_soon: []
      }),
      header_franchises: [],
      header_games: Ember.Object.create({
        games: [],
        mobile_apps: [],
        sites: []
      })
    }),
    actions: {
      async save() {
        try {
          this.set('isSaving', true);
          let saveDataArr = [];
          let saveData = Ember.Object.create({
            key: 'header_resources',
            type: 'json',
            label: 'Header Resources',
            value: this.headerResources,
            category: 'header'
          });
          saveDataArr.pushObject(saveData);
          await this.dreamworksService.put(`api/settings`, saveDataArr);
          this.notificationService.show('Saved', 'Settings has been saved!');
        } catch (error) {
          console.log(error);
          this.notificationService.show('Error', 'Could not save settings.');
        } finally {
          this.set('isSaving', false);
        }
      }

    }
  });

  _exports.default = _default;
});