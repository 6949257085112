define("universal-cms/ember-quill/tests/templates/components/file-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oX3IwKfK",
    "block": "{\"symbols\":[],\"statements\":[[5,[27,[26,2,\"BlockHead\"],[]],[[27,[26,1,\"Expression\"],[]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"    \"],[9,\"input\",true],[13,\"onchange\",[31,58,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[]],\"onFileChange\"],null],null],[12,\"type\",\"file\",null],[10],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"    Uploading...\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"isUploading\",\"unless\"]}",
    "meta": {
      "moduleName": "universal-cms/ember-quill/tests/templates/components/file-input.hbs"
    }
  });

  _exports.default = _default;
});