define("universal-cms/components/manage-published-state", ["exports", "universal-cms/config/environment", "moment", "jquery"], function (_exports, _environment, _moment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['manage-published-state-button'],

    didRender() {
      (0, _jquery.default)('.manage-published-state-popup-open-dropdown-button .dropdown-toggle').dropdown();
    },

    openPopupButtonText: Ember.computed('resource.is_published', function () {
      return this.get('resource.is_published') ? 'Unpublish' : 'Publish';
    }),
    isPopupOpen: false,
    whenToPublish: Ember.computed(function () {
      return this.get('resource.is_published') ? 'future' : 'now';
    }),
    publishNow: Ember.computed('whenToPublish', function () {
      return this.whenToPublish === 'now';
    }),
    publishDateValue: Ember.computed('resource.live_start', function () {
      if (this.get('resource.live_start') !== undefined) {
        let dbLiveStart = _moment.default.tz(this.get('resource.live_start'), _environment.default.databaseTimezone);

        let userLiveStart = dbLiveStart.clone().tz(_moment.default.tz.guess());
        return userLiveStart.format('MM/DD/Y h:mm A');
      } else {
        return null;
      }
    }),
    automaticallyUnpublish: Ember.computed('resource.live_end', function () {
      return this.get('resource.live_end') !== null;
    }),
    unpublishDate: Ember.computed('resource.live_end', function () {
      return this.get('resource.live_end');
    }),
    unpublishDateValue: Ember.computed('resource.live_end', function () {
      if (this.get('resource.live_end') !== undefined) {
        let dbLiveStart = _moment.default.tz(this.get('resource.live_end'), _environment.default.databaseTimezone);

        let userLiveStart = dbLiveStart.clone().tz(_moment.default.tz.guess());
        return userLiveStart.format('MM/DD/Y h:mm A');
      } else {
        return null;
      }
    }),
    isPublishing: false,
    isUnpublishing: false,
    saveButtonText: Ember.computed('resource.is_published', function () {
      return this.get('resource.is_published') ? 'Save' : 'Publish';
    }),
    saveButtonLoadingText: Ember.computed('resource.is_published', function () {
      return this.get('resource.is_published') ? 'Saving...' : 'Publishing...';
    }),

    init() {
      this._super();

      if (this.get('resource.live_start')) {
        this.set('publishDate', _moment.default.tz(this.get('resource.live_start'), _environment.default.databaseTimezone));
      }
    },

    actions: {
      openPopupButtonClick() {
        this.set('isPopupOpen', true);
      },

      closePopup() {
        this.set('isPopupOpen', false); //this.resetPopup();
      },

      publishButtonClick() {
        this.set('isPublishing', true);
        let publishDate;

        let userTimezone = _moment.default.tz.guess();

        let userPublishDate;

        if (this.whenToPublish === 'now') {
          userPublishDate = (0, _moment.default)().tz(userTimezone);
        } else {
          userPublishDate = this.publishDate !== null ? this.publishDate.clone().tz(userTimezone) : (0, _moment.default)().tz(userTimezone);
        }

        publishDate = (0, _moment.default)(userPublishDate).clone().tz(_environment.default.databaseTimezone);
        let unpublishDate = null;

        if (this.automaticallyUnpublish === true) {
          if (this.unpublishDate !== null) {
            let userUnpublishDate = _moment.default.tz(this.unpublishDate, userTimezone);

            unpublishDate = _moment.default.tz(userUnpublishDate, _environment.default.databaseTimezone);
          } else {
            unpublishDate = null;
          }
        }

        this.onPublish({
          resource: this.resource,
          publishDate: publishDate.format('YYYY-MM-DD HH:mm:ss'),
          unpublishDate: unpublishDate !== null ? unpublishDate.format('YYYY-MM-DD HH:mm:ss') : null,
          doneCallback: replacedResource => {
            this.resource.set('live_start', replacedResource.get('live_start'));
            this.resource.set('live_end', replacedResource.get('live_end'));
            this.resource.set('is_published', replacedResource.get('is_published'));
            this.resource.set('is_live', replacedResource.get('is_live'));
            this.set('isPopupOpen', false);
            this.set('isPublishing', false);
          }
        });
      },

      unpublishButtonClick() {
        this.set('isUnpublishing', true);
        this.onUnpublish({
          resource: this.resource,
          doneCallback: replacedResource => {
            this.resource.set('live_start', replacedResource.get('live_start'));
            this.resource.set('live_end', replacedResource.get('live_end'));
            this.resource.set('is_published', replacedResource.get('is_published'));
            this.resource.set('is_live', replacedResource.get('is_live'));
            this.set('isUnpublishing', false);
          }
        });
      },

      updatePublishDate() {
        console.log('updatePublishDate');
      },

      updateUnpublishDate() {
        console.log('updateUnpublishDate');
      }

    },

    keyUp(event) {
      if (event.keyCode === 27) {
        // Esc
        this.set('isPopupOpen', false); //this.resetPopup();
      }
    }

  });

  _exports.default = _default;
});