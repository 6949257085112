define("universal-cms/components/models-table/table-footer", ["exports", "ember-models-table/components/models-table/table-footer"], function (_exports, _tableFooter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _tableFooter.default;
    }
  });
});