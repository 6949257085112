define("universal-cms/helpers/movie-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.movieStatus = movieStatus;

  function movieStatus(params) {
    switch (params[0]) {
      case 'home ent':
        return 'Home Ent';

      case 'current':
        return 'Current';

      case 'future release':
        return 'Future Release';

      case 'future release non listed':
        return 'Future Release - Non-listed';

      case 'archived':
        return 'Archived';
    }

    return params;
  }

  var _default = Ember.Helper.helper(movieStatus);

  _exports.default = _default;
});