define("universal-cms/ember-quill/tests/templates/website/resources/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N4kpan5g",
    "block": "{\"symbols\":[],\"statements\":[[7,\"loader-box\",[],[[],[]],null],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "universal-cms/ember-quill/tests/templates/website/resources/loading.hbs"
    }
  });

  _exports.default = _default;
});