define("universal-cms/ember-quill/tests/templates/components/pxl-asset-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yBFwErFU",
    "block": "{\"symbols\":[],\"statements\":[[5,[27,[26,4,\"BlockHead\"],[]],[[27,[26,0,\"Expression\"],[]]],null,[[\"default\",\"else\"],[{\"statements\":[[5,[27,[26,4,\"BlockHead\"],[]],[[27,[26,3,\"Expression\"],[]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"        \"],[9,\"img\",true],[13,\"src\",[27,[26,1,\"AppendSingleId\"],[]],null],[13,\"style\",[27,[26,2,\"AppendSingleId\"],[]],null],[10],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"        Loading...\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"    \"],[1,0,0,0,[27,[26,0,\"AppendSingleId\"],[]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"loadImageError\",\"imageUrl\",\"imageStyle\",\"isLoadingImageUrl\",\"unless\"]}",
    "meta": {
      "moduleName": "universal-cms/ember-quill/tests/templates/components/pxl-asset-preview.hbs"
    }
  });

  _exports.default = _default;
});