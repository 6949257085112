define("universal-cms/controllers/retailers/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      async updateRetailer(data) {
        try {
          const replacedRetailer = await this.movieService.replaceOne('retailers', data.retailerId, {
            name: data.retailer.name,
            logo: data.retailer.logo,
            logo_alt_text: data.retailer.logoAltText
          });
          this.notificationService.show('Retailer saved', `<strong>${replacedRetailer.name}</strong> was successfully saved.`);
          data.retailer.setProperties({
            name: replacedRetailer.get('name'),
            logo: replacedRetailer.get('logo'),
            logo_alt_text: replacedRetailer.get('logoAltText')
          });
          return replacedRetailer;
        } catch (error) {
          console.log('Save retailer error', error);
          this.notificationService.show('Error', 'Could not save retailer.');
        }
      }

    }
  });

  _exports.default = _default;
});