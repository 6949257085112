define("universal-cms/components/dreamworks/resource-selector-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    routing: Ember.inject.service('-routing'),
    classNames: 'resource-selector-form',
    classNameBindings: ['compact'],
    options: undefined,
    type: undefined,
    languageId: undefined,
    selectedType: null,
    selectedId: null,
    pendingId: null,
    resources: null,
    isLoadingResources: false,
    loadResourcesError: null,
    resource: null,
    isLoadingResource: false,
    loadResourceError: null,
    isEditMode: false,
    compact: false,
    showResourceTypeOptions: undefined,
    chooseText: 'None',
    onlyResourceTypeOptions: false,
    resourceType: Ember.computed('selectedType', function () {
      if (!this.selectedType) {
        return null;
      }

      switch (this.selectedType) {
        case 'franchise':
        case 'franchises':
          return 'franchise';

        case 'movie':
        case 'movies':
          return 'movie';

        case 'tv_show':
        case 'tv_shows':
          return 'tv_show';

        case 'game':
        case 'games':
          return 'game';

        case 'gabbys_website_character':
          return 'gabbys_website_character';

        case 'gabbys_website_event':
          return 'gabbys_website_event';

        case 'gabbys_website_episode':
          return 'gabbys_website_episode';

        case 'gabbys_website_partner':
          return 'gabbys_website_partner';

        case 'page':
        case 'pages':
          return 'page';

        default:
          console.warn(`Unknown selectedType "${this.selectedType}".`);
      }

      return null;
    }),
    endpoint: Ember.computed('selectedType', function () {
      if (!this.selectedType) {
        return null;
      }

      switch (this.selectedType) {
        case 'franchise':
        case 'franchises':
          return 'franchise-data';

        case 'movie':
        case 'movies':
          return 'movie-data';

        case 'tv_show':
        case 'tv_shows':
          return 'tv-show-data';

        case 'game':
        case 'games':
          return 'game-data';

        case 'gabbys_website_character':
        case 'gabbys_website_characters':
          return 'gabbys-website-character-data';

        case 'gabbys_website_event':
        case 'gabbys_website_events':
          return 'gabbys-website-event-data';

        case 'gabbys_website_episode':
        case 'gabbys_website_episodes':
          return 'gabbys-website-episode-data';

        case 'gabbys_website_partner':
        case 'gabbys_website_partners':
          return 'gabbys-website-partner-data';

        case 'page':
        case 'pages':
          return 'page-data';

        default:
          console.warn(`Unknown selectedType "${this.selectedType}".`);
          return null;
      }
    }),
    resourceEditModeOptions: Ember.computed('resources.[]', function () {
      if (!this.resources) {
        return [];
      }

      let formattedResources = this.resources.map(resource => {
        let text = resource.name;

        if (this.selectedType === 'page') {
          text += ` (${resource.slug})`;
        } // text += ` (ID: ${resource.id})`;


        return {
          value: resource.id,
          text
        };
      });
      formattedResources.unshift({
        value: null,
        text: this.chooseText
      });
      return formattedResources;
    }),
    onSelectedTypeChanged: Ember.observer('selectedType', async function () {
      const option = this.options.find(option => option.value === this.selectedType);

      if (!option || option.value === null) {
        return;
      }

      this.set('selectedId', null);
      await this.loadResources();
      await this.loadResource();
    }),
    isLoading: Ember.computed.or('isLoadingResources', 'isLoadingResource'),
    defaultType: 'default',

    async init() {
      this._super(...arguments);

      const resourceTypeOptions = this.dreamworksService.getResourceTypeOptions(this.type || this.defaultType);
      this.set('options', resourceTypeOptions);

      if (typeof this.showResourceTypeOptions === 'undefined') {
        this.set('showResourceTypeOptions', resourceTypeOptions && resourceTypeOptions.length > 1);
      }

      if (!this.selectedType) {
        this.set('selectedType', this.type);
      }

      if (this.type && this.selectedId) {
        this.set('isLoadingResource', true);
      } // Get language from query param ?lang


      const urlParams = new URLSearchParams(window.location.search);
      const langQueryParam = urlParams.get('lang');
      this.set('language_id', langQueryParam || 1);
      await this.loadResources();
      await this.loadResource();
    },

    isGabbysWebsitePage: Ember.computed('routing.currentRouteName', function () {
      const serviceMappings = this.auth.decoded.scopes.mappings;
      const serviceMappingsKeys = Object.keys(serviceMappings);
      const isValidServiceMappingKeys = serviceMappingsKeys.includes('DreamWorks') && serviceMappingsKeys.includes('GabbysWebsite');
      return this.routing.currentRouteName.startsWith('gabbys-website') && isValidServiceMappingKeys;
    }),

    async loadResources() {
      if (!this.resourceType) {
        return;
      }

      this.set('isLoadingResources', true);
      this.set('loadResourcesError', null);
      const queryParams = {};

      if (this.isGabbysWebsitePage) {
        if (this.resourceType === 'page') {
          queryParams.type = 'gabbys_website';
        }

        const queryParamLanguageId = this.get('language_id');
        const languageId = this.get('languageId');

        if (languageId) {
          queryParams.language_id = languageId;
        } else if (queryParamLanguageId) {
          queryParams.language_id = queryParamLanguageId;
        }
      }

      const queryParamsStr = Object.keys(queryParams).map(key => `${key}=${queryParams[key]}`).join('&');

      try {
        const resources = await this.dreamworksService.getMany(this.dreamworksService.resourceTypeToEndpoint(this.resourceType) + `?${queryParamsStr}`);
        this.set('resources', resources);
      } catch (loadResourceError) {
        console.log('loadResourceError', loadResourceError);
        this.set('loadResourcesError', 'Could not load resources.');
      } finally {
        this.set('isLoadingResources', false);
      }
    },

    async loadResource() {
      if (!this.resourceType || !this.selectedId) {
        return;
      }

      this.set('isLoadingResource', true);
      this.set('loadResourceError', null);

      try {
        const resource = await this.dreamworksService.getFirst(this.endpoint, {
          'resource_type': this.resourceType,
          'resource_id': this.selectedId
        });
        const saveData = resource && resource.save_data ? resource.save_data : {};
        this.set('resource', saveData);
      } catch (loadResourceError) {
        console.log('loadResourceError', loadResourceError);
        0;
        this.set('loadResourceError', 'Could not load resource.');
      } finally {
        this.set('isLoadingResource', false);
      }
    },

    actions: {
      toggleResourceMode(enter) {
        if (enter === true) {
          this.set('pendingId', this.selectedId);
          this.set('isEditMode', true);
        } else {
          this.set('isEditMode', false);
        }
      },

      async changeResource(id) {
        this.set('pendingId', id);
      },

      async saveResource() {
        this.set('selectedId', this.pendingId);
        this.set('isEditMode', false);

        if (typeof this.onChange === 'function') {
          this.onChange();
        }

        await this.loadResource();
      },

      async onChangeResource(resourceId) {
        this.set('selectedId', resourceId);

        if (typeof this.onChange === 'function') {
          this.onChange(resourceId);
        }
      },

      setType(type) {
        this.set('selectedType', type);

        if (typeof this.onTypeChanged === 'function') {
          this.onTypeChanged(type);
        }
      }

    }
  });

  _exports.default = _default;
});