define("universal-cms/routes/universal-brand/pages/edit-contact", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      const controller = this.controllerFor(this.routeName);
      controller.set('pageId', params.page_id);
      controller.set('pageVersionId', params.page_version_id);
      const contactId = params.contact_id;
      const isNew = contactId === 'add';
      controller.set('isNew', isNew);
      let rsvp = {};
      rsvp.contact = isNew ? Ember.Object.create({
        isNew: true
      }) : this.universalBrandService.getOne('contacts', contactId).then(contact => {
        return contact;
      }, () => {
        controller.set('error', true);
      });
      return Ember.RSVP.hash(rsvp);
    }

  });

  _exports.default = _default;
});