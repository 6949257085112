define("universal-cms/ember-quill/tests/templates/components/dreamworks/page-component-modals/html-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MIoFJZnk",
    "block": "{\"symbols\":[],\"statements\":[[9,\"h2\",true],[10],[1,1,0,0,\"\\n    HTML Content\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[1,1,0,0,\"\\n\"],[7,\"pxl-form-field\",[],[[\"@label\",\"@value\",\"@required\",\"@rows\",\"@html\"],[\"HTML\",[27,[26,0,\"AppendSingleId\"],[\"data\",\"html\"]],\"true\",\"15\",\"true\"]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"pageComponent\"]}",
    "meta": {
      "moduleName": "universal-cms/ember-quill/tests/templates/components/dreamworks/page-component-modals/html-content.hbs"
    }
  });

  _exports.default = _default;
});