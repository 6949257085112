define("universal-cms/templates/components/dreamworks/page-component-modals/media-grid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "K4NyWCTC",
    "block": "{\"symbols\":[],\"statements\":[[9,\"h2\",true],[10],[1,1,0,0,\"\\n    Media Grid\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[7,\"pxl-form-field\",[],[[\"@label\",\"@value\"],[\"Section Title\",[27,[26,1,\"AppendSingleId\"],[\"data\",\"sectionTitle\"]]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[1,1,0,0,\"\\n\\n\"],[5,[27,[26,5,\"BlockHead\"],[]],[[31,133,6,[27,[26,4,\"CallHead\"],[]],[[27,[26,1,\"Expression\"],[\"data\",\"sectionTitle\"]],\"Games\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"    \"],[7,\"dreamworks/resource-form\",[],[[\"@formOptions\",\"@items\",\"@resourceType\",\"@title\",\"@onItemsUpdated\"],[[27,[26,0,\"AppendSingleId\"],[]],[27,[26,1,\"AppendSingleId\"],[\"data\",\"items\"]],\"Resource\",\"Resources\",[31,348,6,[27,[26,3,\"CallHead\"],[]],[[27,[24,0],[]],[31,356,3,[27,[26,2,\"CallHead\"],[]],[[27,[26,1,\"Expression\"],[\"data\",\"items\"]]],null]],null]]],null],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"    \"],[7,\"dreamworks/resource-form\",[],[[\"@formOptions\",\"@items\",\"@resourceType\",\"@title\",\"@onItemsUpdated\"],[[27,[26,0,\"AppendSingleId\"],[]],[27,[26,1,\"AppendSingleId\"],[\"data\",\"items\"]],\"games\",\"Resources\",[31,562,6,[27,[26,3,\"CallHead\"],[]],[[27,[24,0],[]],[31,570,3,[27,[26,2,\"CallHead\"],[]],[[27,[26,1,\"Expression\"],[\"data\",\"items\"]]],null]],null]]],null],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"resourceFormOptions\",\"pageComponent\",\"mut\",\"action\",\"not-eq\",\"if\"]}",
    "meta": {
      "moduleName": "universal-cms/templates/components/dreamworks/page-component-modals/media-grid.hbs"
    }
  });

  _exports.default = _default;
});