define("universal-cms/controllers/dreamworks/pages/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    resourceType: 'pages',
    text: {
      singular: 'Page',
      plural: 'Pages'
    },

    init() {
      this._super(...arguments);

      this.set('tableColumns', [{
        'title': 'Name',
        'propertyName': 'name'
      }, {
        'title': 'URL',
        'component': 'models-table/dreamworks/page-url'
      }, {
        'title': 'Added',
        'component': 'models-table/dreamworks/date'
      }, {
        'title': 'Manage',
        'component': 'pageActions',
        'editable': false
      }]);
    },

    actions: {
      async deletePage(pageToDelete) {
        if (!confirm(`Are you sure you want to delete page "${pageToDelete.name}"?`)) {
          return;
        }

        try {
          pageToDelete.set('isDeleting', true);
          await this.dreamworksService.deleteOne('pages', pageToDelete.id);
          this.model.resources.removeObject(pageToDelete);
          this.notificationService.show('Page Deleted', `Page <strong>${pageToDelete.name}</strong> has been deleted.`);
        } catch (error) {
          this.notificationService.show('Error', error.message || `Could not delete <strong>${pageToDelete.name}</strong>.`);
        } finally {
          pageToDelete.set('isDeleting', false);
        }
      }

    }
  });

  _exports.default = _default;
});