define("universal-cms/controllers/universal-studios/banners/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    error: false,
    actions: {
      updateBannersOrder(orderedBanners) {
        let newOrder = [];

        for (let banner of orderedBanners) {
          newOrder.push(banner.get('id'));
        }

        let saveData = {
          banners: newOrder
        };
        this.universalStudiosService.updateOne(`banners`, 'update-order', saveData).then(() => {
          this.notificationService.show('Saved', `Banner has been moved.`);
        }).catch(() => {
          this.notificationService.show('Error', `Could not save banners order.`);
        });
      },

      deleteBanner(banner) {
        banner.set('isDeleting', true);
        return this.universalStudiosService.deleteOne('banners', banner.get('id')).then(() => {
          this.notificationService.show('Banner deleted', 'Banner was successfully deleted.');
          this.model.removeObject(banner);
        }, () => {
          banner.set('isDeleting', false);
          this.notificationService.show('Error', 'Could not delete banner.');
        });
      }

    }
  });

  _exports.default = _default;
});