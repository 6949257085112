define("universal-cms/components/super-table-yield/table-foot-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    disableCreate: false,
    isAdding: false,
    isDisabled: Ember.computed.or('disableCreate', 'isCreating')
  });

  _exports.default = _default;
});