define("universal-cms/helpers/nl2br", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.nl2br = nl2br;

  function nl2br(params) {
    let text = params[0];
    return text !== null ? Ember.String.htmlSafe((text + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + '<br>' + '$2')) : '';
  }

  var _default = Ember.Helper.helper(nl2br);

  _exports.default = _default;
});