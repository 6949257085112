define("universal-cms/ember-quill/tests/templates/components/wysiwyg-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WdFQqZXt",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"textarea\",[],[[\"@classNames\",\"@value\"],[\"wysiwyg-textarea form-control\",[27,[26,0,\"AppendSingleId\"],[]]]],null],[1,1,0,0,\"\"],[16,1,null],[1,1,0,0,\"\"]],\"hasEval\":false,\"upvars\":[\"value\"]}",
    "meta": {
      "moduleName": "universal-cms/ember-quill/tests/templates/components/wysiwyg-editor.hbs"
    }
  });

  _exports.default = _default;
});