define("universal-cms/components/dreamworks/page-component-modals/gabbys-website-music-samples-promos", ["exports", "universal-cms/components/dreamworks/page-component-modals/page-component-modal"], function (_exports, _pageComponentModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _pageComponentModal.default.extend({
    classNames: 'gabbys-website-music-samples-promos',

    init() {
      this._super(...arguments); // Initialize samples


      const samplesResourceFormOptions = [];
      samplesResourceFormOptions.pushObject(Ember.Object.create({
        column: 'title',
        type: 'text',
        label: 'Title'
      }));
      samplesResourceFormOptions.pushObject(Ember.Object.create({
        column: 'artist',
        type: 'text',
        label: 'Artist'
      }));
      samplesResourceFormOptions.pushObject(Ember.Object.create({
        column: 'song_sample_id',
        type: 'asset',
        assetType: 'audio',
        label: 'Audio (mp3)'
      }));
      this.set('samplesResourceFormOptions', samplesResourceFormOptions);

      if (!this.get('pageComponent.data.samples')) {
        this.set('pageComponent.data.samples', Ember.A());
      } // Initialize platforms


      const platformsResourceFormOptions = [];
      platformsResourceFormOptions.pushObject(Ember.Object.create({
        column: 'name',
        type: 'text',
        label: 'Name'
      }));
      platformsResourceFormOptions.pushObject(Ember.Object.create({
        column: 'link',
        type: 'text',
        label: 'Link'
      }));
      platformsResourceFormOptions.pushObject(Ember.Object.create({
        column: 'logo_image_id',
        type: 'asset',
        assetType: 'image',
        label: 'Logo'
      }));
      this.set('platformsResourceFormOptions', platformsResourceFormOptions);

      if (!this.get('pageComponent.data.platforms')) {
        this.set('pageComponent.data.platforms', Ember.A());
      }
    }

  });

  _exports.default = _default;
});