define("universal-cms/routes/universal-brand/businesses/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      const controller = this.controllerFor(this.routeName);
      const businessId = params.business_id;
      const isNew = businessId === 'add';
      controller.set('isNew', isNew);
      let rsvp = {};
      rsvp.business = isNew ? Ember.Object.create({
        isNew: true
      }) : this.universalBrandService.getOne('businesses', `${businessId}?relationships[]=desktopImage&relationships[]=mobileImage&relationships[]=thumbnailImage&relationships[]=thumbnailWideImage&relationships[]=thumbnailLandscapeImage&relationships[]=projects`).then(business => {
        return business;
      }, () => {
        controller.set('error', true);
      });
      return Ember.RSVP.hash(rsvp);
    }

  });

  _exports.default = _default;
});