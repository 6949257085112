define("universal-cms/routes/universal-studios/connect/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return this.universalStudiosService.getAll('connects?sort-by=slot').then(connects => {
        return connects;
      }, () => {
        const controller = this.controllerFor(this.routeName);
        controller.set('error', true);
      });
    }

  });

  _exports.default = _default;
});