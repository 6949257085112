define("universal-cms/components/thumbnail-preview-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['thumbnail-preview-image-container'],
    url: null
  });

  _exports.default = _default;
});