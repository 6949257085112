define("universal-cms/ember-quill/tests/templates/components/super-table-yield/table-body-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EofGnJHA",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[16,1,[[31,9,4,[27,[26,3,\"CallHead\"],[]],null,[[\"isEditing\",\"setIsEditing\",\"doneEditing\"],[[27,[26,1,\"Expression\"],[]],[31,69,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[]],\"setIsEditing\"],null],[31,115,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[]],\"doneEditing\"],null]]]]]],[1,1,0,0,\"\\n\\n\"],[5,[27,[26,5,\"BlockHead\"],[]],[[27,[26,4,\"Expression\"],[]]],null,[[\"default\"],[{\"statements\":[[5,[27,[26,2,\"BlockHead\"],[]],[[27,[26,1,\"Expression\"],[]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"        \"],[9,\"span\",false],[23,\"class\",\"edit-button\",null],[3,0,0,[27,[26,0,\"ModifierHead\"],[]],[[27,[24,0],[]],\"setIsEditing\",true],null],[10],[1,1,0,0,\"\\n            \"],[9,\"i\",true],[12,\"class\",\"fa fa-pencil\",null],[12,\"aria-hidden\",\"true\",null],[10],[11],[1,1,0,0,\"\\n        \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"isEditing\",\"unless\",\"hash\",\"editable\",\"if\"]}",
    "meta": {
      "moduleName": "universal-cms/ember-quill/tests/templates/components/super-table-yield/table-body-cell.hbs"
    }
  });

  _exports.default = _default;
});