define("universal-cms/components/super-table-yield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'table',
    orderable: false,
    orderColumn: 'order',
    deletable: false,
    creatable: false,
    draggingItem: null,
    actions: {
      setDraggingItem(item) {
        this.set('draggingItem', item);
      },

      onDragEnter(item) {
        let items = this.items;
        let draggingItem = this.draggingItem;

        if (draggingItem !== item) {
          let draggingOverIndex = items.indexOf(item);
          let draggingItemIndex = items.indexOf(draggingItem);
          items.removeAt(draggingItemIndex);
          items.insertAt(draggingOverIndex, draggingItem);
        }
      },

      onDragEnd(item) {
        if (this.onOrdered) {
          const orderColumn = this.orderColumn;
          let order = 0;

          for (let item of this.items) {
            item.set(orderColumn, order++);
          }

          this.onOrdered(this.items);
        }
      }

    }
  });

  _exports.default = _default;
});