define("universal-cms/helpers/asset-type-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.assetTypeType = assetTypeType;
  _exports.default = void 0;

  function assetTypeType(params) {
    switch (params[0]) {
      case 'image':
        return 'Image';

      case 'video':
        return 'Video';
    }

    return params;
  }

  var _default = Ember.Helper.helper(assetTypeType);

  _exports.default = _default;
});