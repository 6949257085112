define("universal-cms/ember-quill/tests/templates/uwatch/resources", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mzsVgehx",
    "block": "{\"symbols\":[],\"statements\":[[9,\"div\",true],[13,\"class\",[32,[[27,[26,0,\"AppendSingleId\"],[\"options\",\"location\"]]]],null],[10],[1,1,0,0,\"\\n\"],[1,0,0,0,[31,0,0,[27,[26,2,\"CallHead\"],[]],[[31,0,0,[27,[26,1,\"CallHead\"],[]],null,null]],null]],[1,1,0,0,\"\\n\"],[11]],\"hasEval\":false,\"upvars\":[\"model\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "universal-cms/ember-quill/tests/templates/uwatch/resources.hbs"
    }
  });

  _exports.default = _default;
});