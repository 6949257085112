define("universal-cms/controllers/movies/edit/options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    editMovieController: Ember.inject.controller('movies/edit'),
    movie: Ember.computed.reads('editMovieController.model'),
    isLoading: true,
    actions: {
      tabClick(selectedOptionGroup) {
        if (selectedOptionGroup.get('tabSelected') === 'active') {
          return;
        }

        this.optionGroups.forEach(optionGroup => {
          optionGroup.set('tabSelected', '');
        });
        selectedOptionGroup.set('tabSelected', 'active');
      }

    }
  });

  _exports.default = _default;
});