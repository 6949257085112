define("universal-cms/routes/website/preview/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      return Ember.RSVP.hash({
        previewToken: this.serviceResource.getResource('website', 'preview-tokens', params.preview_token_id, 'preview-tokens'),
        movies: this.movieService.getAll('movies')
      });
    }

  });

  _exports.default = _default;
});