define("universal-cms/routes/universal-brand/pages/edit-menu-thumbnail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      const controller = this.controllerFor(this.routeName);
      controller.set('pageId', params.page_id);
      controller.set('pageVersionId', params.page_version_id);
      const menuThumbnailId = params.menu_thumbnail_id;
      const isNew = menuThumbnailId === 'add';
      controller.set('isNew', isNew);
      let rsvp = {};
      rsvp.menuThumbnail = isNew ? Ember.Object.create({
        isNew: true
      }) : this.universalBrandService.getOne('menu-thumbnails', `${menuThumbnailId}?relationships[]=thumbnailImage`).then(menuThumbnail => {
        return menuThumbnail;
      }, () => {
        controller.set('error', true);
      });
      rsvp.pages = this.universalBrandService.getAll('pages');
      return Ember.RSVP.hash(rsvp);
    }

  });

  _exports.default = _default;
});