define("universal-cms/ember-quill/tests/templates/components/dreamworks/page-component-modals/gabbys-website-music-samples-promos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hWRDdume",
    "block": "{\"symbols\":[],\"statements\":[[9,\"h2\",true],[10],[1,1,0,0,\"\\n    Music Samples & Promos\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[9,\"div\",true],[12,\"class\",\"row\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"div\",true],[12,\"class\",\"col-sm-12\",null],[10],[1,1,0,0,\"\\n        \"],[9,\"label\",true],[10],[1,1,0,0,\"Samples\"],[11],[1,1,0,0,\"\\n\\n        \"],[7,\"dreamworks/resource-form\",[],[[\"@formOptions\",\"@items\",\"@resourceType\",\"@onItemsUpdated\"],[[27,[26,0,\"AppendSingleId\"],[]],[27,[26,1,\"AppendSingleId\"],[\"data\",\"samples\"]],\"resource\",[31,347,6,[27,[26,3,\"CallHead\"],[]],[[27,[24,0],[]],[31,355,3,[27,[26,2,\"CallHead\"],[]],[[27,[26,1,\"Expression\"],[\"data\",\"samples\"]]],null]],null]]],null],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[9,\"div\",true],[12,\"class\",\"row\",null],[12,\"style\",\"margin-top:1.5rem;\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"div\",true],[12,\"class\",\"col-sm-12\",null],[10],[1,1,0,0,\"\\n        \"],[9,\"label\",true],[10],[1,1,0,0,\"Platforms\"],[11],[1,1,0,0,\"\\n\\n        \"],[7,\"dreamworks/resource-form\",[],[[\"@formOptions\",\"@items\",\"@resourceType\",\"@onItemsUpdated\"],[[27,[26,4,\"AppendSingleId\"],[]],[27,[26,1,\"AppendSingleId\"],[\"data\",\"platforms\"]],\"resource\",[31,758,6,[27,[26,3,\"CallHead\"],[]],[[27,[24,0],[]],[31,766,3,[27,[26,2,\"CallHead\"],[]],[[27,[26,1,\"Expression\"],[\"data\",\"platforms\"]]],null]],null]]],null],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\"],[11]],\"hasEval\":false,\"upvars\":[\"samplesResourceFormOptions\",\"pageComponent\",\"mut\",\"action\",\"platformsResourceFormOptions\"]}",
    "meta": {
      "moduleName": "universal-cms/ember-quill/tests/templates/components/dreamworks/page-component-modals/gabbys-website-music-samples-promos.hbs"
    }
  });

  _exports.default = _default;
});