define("universal-cms/components/dreamworks/page-component-modals/page-introduction", ["exports", "universal-cms/components/dreamworks/page-component-modals/page-component-modal"], function (_exports, _pageComponentModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _pageComponentModal.default.extend({
    typeOptions: undefined,
    selectedType: undefined,

    init() {
      this._super(...arguments);

      if (!this.pageComponent.data.hero_image) {
        this.pageComponent.data.set('hero_image', {});
      }
    }

  });

  _exports.default = _default;
});