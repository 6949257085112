define("universal-cms/components/super-table-yield/table-body-row/drag-handle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'td',
    classNames: ['collapsing'],

    setCursor(cursor) {
      this.element.style.cursor = `-moz-${cursor}`;
      this.element.style.cursor = `-webkit-${cursor}`;
      this.element.style.cursor = cursor;
    },

    didInsertElement() {
      this.setCursor('grab');
    },

    mouseDown() {
      if (this.onGrab) {
        this.onGrab(true);
      }
    },

    mouseUp() {
      if (this.onGrab) {
        this.onGrab(false);
      }
    }

  });

  _exports.default = _default;
});