define("universal-cms/controllers/universal-studios/features/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    error: false,
    actions: {
      updateFeaturesOrder(orderedFeatures) {
        let newOrder = [];

        for (let movie of orderedFeatures) {
          newOrder.push(movie.get('id'));
        }

        let saveData = {
          features: newOrder
        };
        this.universalStudiosService.updateOne(`features`, 'update-order', saveData).then(() => {
          this.notificationService.show('Saved', `Feature has been moved.`);
        }).catch(() => {
          this.notificationService.show('Error', `Could not save features order.`);
        });
      },

      deleteFeature(feature) {
        feature.set('isDeleting', true);
        return this.universalStudiosService.deleteOne('features', feature.get('id')).then(() => {
          this.notificationService.show('Feature deleted', `<strong>${feature.get('title')}</strong> was successfully deleted.`);
          this.model.removeObject(feature);
        }, () => {
          feature.set('isDeleting', false);
          this.notificationService.show('Error', `Could not delete feature <strong>${feature.get('title')}</strong>.`);
        });
      }

    }
  });

  _exports.default = _default;
});