define("universal-cms/components/critical-acclaim-form", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    mode: 'add',
    retailer: null,
    formData: null,
    isSaving: false,
    saveButtonText: Ember.computed('isSaving', function () {
      if (this.mode === 'edit') {
        return this.isSaving ? 'Saving...' : 'Save';
      } else {
        return this.isSaving ? 'Adding...' : 'Add';
      }
    }),

    init() {
      this._super(...arguments);

      this.set('formData', Ember.Object.create({
        author: this.criticalAcclaim ? this.criticalAcclaim.author : '',
        acclaim: this.criticalAcclaim ? this.criticalAcclaim.acclaim : '',
        logo: this.criticalAcclaim ? this.criticalAcclaim.logo : null
      }));
    },

    actions: {
      async save() {
        if (this.isSaving) {
          return;
        }

        try {
          this.set('isSaving', true);
          const movieId = this.movieId;
          const logo = this.formData.logo;
          const author = this.formData.author;
          const acclaim = this.formData.acclaim;

          if (logo === undefined || author === undefined || acclaim === undefined) {
            return;
          }

          this.movieService.createOne('movies/' + movieId + '/critical-acclaims', {
            movie_id: movieId,
            author: author,
            acclaim: acclaim,
            logo: logo,
            live_start: (0, _moment.default)().format('YYYY-MM-DD HH:mm:ss'),
            live_end: null
          }).then(async () => {
            this.set('formData', Ember.Object.create({
              author: '',
              acclaim: '',
              media_url: null
            })); // movie.critical_acclaims.pushObject(addedCriticalAcclaim);

            this.notificationService.show('Critical Acclaim added', `<strong>${author}</strong> added to movie.`);
            await this.onSaved();
          }, error => {
            this.notificationService.show(`Could not add <strong>${author}</strong> to movie.`);
          }).finally(() => {
            this.set('isSaving', false);
          }); // const savedName = this.formData.name
          // // Clear form
          // if (this.mode === 'add') {
          // } else if (this.mode === 'edit') {
          //     this.set('retailer.name', savedName)
          // }
        } catch (saveRetailerError) {
          console.log('saveRetailerError', saveRetailerError);
        } finally {
          this.set('isSaving', false);
        }
      }

    }
  });

  _exports.default = _default;
});