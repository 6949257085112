define("universal-cms/services/universal-brand", ["exports", "jquery", "universal-cms/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    auth: Ember.inject.service(),

    setUrl(url) {
      this.set('url', url);
    },

    getUrl() {
      let route = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      return this.url + '/api' + (route !== null ? (route.substring(0, 1) !== '/' ? '/' : '') + route : '');
    },

    getOne(resource, id) {
      let url = `${this.getUrl(resource)}/${id}`;
      url += url.indexOf('?') === -1 ? '?' : '&';
      url += `nocache=${Math.floor(Math.random() * 99999)}`;
      return new Ember.RSVP.Promise((resolve, reject) => {
        _jquery.default.ajax({
          method: 'GET',
          url: url,
          beforeSend: xhr => {
            xhr.setRequestHeader('X-Auth', `Bearer ${this.get('auth.jwt')}`);

            if (_environment.default.BRAND_BASIC_AUTH_USERNAME) {
              xhr.setRequestHeader('Authorization', `Basic ${btoa(_environment.default.BRAND_BASIC_AUTH_USERNAME + ':' + _environment.default.BRAND_BASIC_AUTH_PASSWORD)}`);
            }
          }
        }).then(item => {
          resolve(this.emberify(item));
        }, errorResponse => {
          reject(errorResponse);
        });
      });
    },

    getAll(resource) {
      let url = this.getUrl(resource);
      url += url.indexOf('?') === -1 ? '?' : '&';
      url += `nocache=${Math.floor(Math.random() * 99999)}`;
      return new Ember.RSVP.Promise((resolve, reject) => {
        _jquery.default.ajax({
          method: 'GET',
          url: url,
          beforeSend: xhr => {
            xhr.setRequestHeader('X-Auth', `Bearer ${this.get('auth.jwt')}`);

            if (_environment.default.BRAND_BASIC_AUTH_USERNAME) {
              xhr.setRequestHeader('Authorization', `Basic ${btoa(_environment.default.BRAND_BASIC_AUTH_USERNAME + ':' + _environment.default.BRAND_BASIC_AUTH_PASSWORD)}`);
            }
          }
        }).then(items => {
          resolve(this.emberify(items));
        }, errorResponse => {
          reject(errorResponse);
        });
      });
    },

    createOne(resource, data) {
      let withFiles = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      let formData;

      if (withFiles) {
        formData = new FormData();

        for (let key in data) {
          if (typeof data[key] === 'undefined') {
            continue;
          }

          formData.append(key, data[key]);
        }
      } else {
        formData = data;
      }

      return new Ember.RSVP.Promise((resolve, reject) => {
        _jquery.default.ajax({
          method: 'POST',
          url: this.getUrl(resource),
          data: formData,
          contentType: withFiles ? false : 'application/x-www-form-urlencoded; charset=UTF-8',
          processData: !withFiles,
          beforeSend: xhr => {
            xhr.setRequestHeader('X-Auth', `Bearer ${this.get('auth.jwt')}`);

            if (_environment.default.BRAND_BASIC_AUTH_USERNAME) {
              xhr.setRequestHeader('Authorization', `Basic ${btoa(_environment.default.BRAND_BASIC_AUTH_USERNAME + ':' + _environment.default.BRAND_BASIC_AUTH_PASSWORD)}`);
            }
          }
        }).then(response => {
          resolve(this.emberify(response));
        }, errorResponse => {
          if (typeof errorResponse.responseJSON.error === 'string') {
            reject(errorResponse.responseJSON.error);
          } else {
            reject(errorResponse);
          }
        }).catch(error => {
          reject(error);
        });
      });
    },

    updateOne(resource, id, data) {
      let withFiles = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
      let formData;

      if (withFiles) {
        formData = new FormData();

        for (let key in data) {
          if (typeof data[key] === 'undefined') {
            continue;
          }

          formData.append(key, data[key]);
        }

        formData.append('_method', 'PUT');
      } else {
        formData = data;
      }

      return new Ember.RSVP.Promise((resolve, reject) => {
        _jquery.default.ajax({
          method: withFiles ? 'POST' : 'PUT',
          url: `${this.getUrl(resource)}${id !== null ? `/${id}` : ''}`,
          data: formData,
          contentType: withFiles ? false : 'application/x-www-form-urlencoded; charset=UTF-8',
          processData: !withFiles,
          beforeSend: xhr => {
            xhr.setRequestHeader('X-Auth', `Bearer ${this.get('auth.jwt')}`);

            if (_environment.default.BRAND_BASIC_AUTH_USERNAME) {
              xhr.setRequestHeader('Authorization', `Basic ${btoa(_environment.default.BRAND_BASIC_AUTH_USERNAME + ':' + _environment.default.BRAND_BASIC_AUTH_PASSWORD)}`);
            }
          }
        }).then(response => {
          resolve(this.emberify(response));
        }, errorResponse => {
          reject(errorResponse);
        });
      });
    },

    deleteOne(resource, id) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        _jquery.default.ajax({
          method: 'DELETE',
          url: `${this.getUrl(resource)}/${id}`,
          beforeSend: xhr => {
            xhr.setRequestHeader('X-Auth', `Bearer ${this.get('auth.jwt')}`);

            if (_environment.default.BRAND_BASIC_AUTH_USERNAME) {
              xhr.setRequestHeader('Authorization', `Basic ${btoa(_environment.default.BRAND_BASIC_AUTH_USERNAME + ':' + _environment.default.BRAND_BASIC_AUTH_PASSWORD)}`);
            }
          }
        }).then(response => {
          resolve(response);
        }, errorResponse => {
          reject(errorResponse);
        });
      });
    },

    formatErrorResponse(errors) {
      if (typeof errors === 'string') {
        return errors;
      }

      let str = '';

      for (let errorIndex = 0, numErrors = errors.length; errorIndex < numErrors; errorIndex++) {
        str += `- ${errors[errorIndex]}`;

        if (errorIndex < numErrors - 1) {
          str += '<br>';
        }
      }

      return str;
    },

    emberify(object) {
      if (Ember.typeOf(object) === 'array') {
        return Ember.A(object.map(item => {
          return this.emberify(item);
        }));
      } else if (_jquery.default.isPlainObject(object)) {
        for (let key of Object.keys(object)) {
          if (_jquery.default.isPlainObject(object[key])) {
            object[key] = this.emberify(object[key]);
          } else if (Ember.typeOf(object[key]) === 'array') {
            object[key] = this.emberify(object[key]);
          }
        }

        return Ember.Object.create(object);
      } else {
        return object;
      }
    }

  });

  _exports.default = _default;
});