define("universal-cms/components/resource-new-inline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isAdding: false,
    fields: Ember.computed("options", function () {
      let fields = []; // get current fields from model

      for (let field of this.get('options.resource_fields')) {
        if (field.name !== 'position') {
          fields.push(field);
        } else {
          this.set('hasPosition', true);
        }
      }

      return fields;
    }),
    position: Ember.computed('resources', 'hasPosition', function () {
      return this.resources.length;
    }),
    actions: {
      newResource() {
        this.set('isAdding', true); // create object for resource

        let resource = Ember.Object.create(); // get current list of fields

        const fields = this.element.querySelectorAll('input, select, textarea'); // set each field name

        for (let field of fields) {
          resource.set(field.name, field.value);
        } // forward action to resource controller 'resources/list.js'


        this.newResource(resource);
      }

    }
  });

  _exports.default = _default;
});