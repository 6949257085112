define("universal-cms/components/resource-edit-inline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      selectUpdate(value) {
        let resource = this.resource;
        let field = this.get('field.name');

        if (resource !== undefined) {
          resource.set(field, value);
        }
      },

      deleteImage() {
        this.resource.set('id', null);
        this.set('imagePath', null);
      }

    },
    isPosition: Ember.computed('field', function () {
      return this.get('field.type') === 'position';
    }),
    isTextField: Ember.computed('field', function () {
      return this.get('field.type') === 'text';
    }),
    isWysiwyg: Ember.computed('field', function () {
      return this.get('field.type') === 'wysiwyg';
    }),
    isTextarea: Ember.computed('field', function () {
      return this.get('field.type') === 'textarea';
    }),
    isImage: Ember.computed('field', function () {
      return this.get('field.type') === 'image';
    }),
    isDate: Ember.computed('field', function () {
      return this.get('field.type') === 'date';
    }),
    isSelect: Ember.computed('field', function () {
      return this.get('field.type') === 'select';
    }),
    isRelationship: Ember.computed('field', function () {
      return this.get('field.type') === 'relationship';
    }),
    isFile: Ember.computed('field', 'resource', function () {
      if (this.get('resource.path') !== undefined) {
        this.set('imagePath', this.get('resource.path'));
      }

      return this.get('field.type') === 'file';
    }),
    isOther: Ember.computed('isTextField', 'isTextArea', 'isWysiwyg', 'isImage', 'isDate', 'isSelect', function () {
      return !(this.isTextField || this.isWysiwyg || this.isTextArea || this.isImage || this.isDate || this.isRelationship || this.isSelect);
    }),
    value: Ember.computed('resource', 'field', function () {
      let resource = this.resource;

      if (!resource) {
        return '';
      }

      let field = this.get('field.name_map') || this.get('field.name'); // if (field === 'content_') {
      //     field = 'content';
      // }

      let value = resource.get(field);

      if (value === null || value === undefined) {
        return '';
      }

      if (this.isDate) {
        value = new Date(value); // Correct for timezone crazyness: http://stackoverflow.com/questions/7556591/javascript-date-object-always-one-day-off

        value = new Date(value.getUTCFullYear(), value.getUTCMonth(), value.getUTCDate());
      }

      if (this.disallowEdit === true) {
        if (this.isPosition) {
          return this.position;
        } else if (this.isSelect) {
          for (let fieldOption of this.field.options) {
            if (fieldOption.value === value) {
              return fieldOption.text;
            }
          }

          return '-';
        }
      }

      return value;
    }),
    position: Ember.computed('resource.position', function () {
      return this.get('resource.position') + 1;
    }),
    onValueChanged: Ember.observer('value', function () {
      let resource = this.resource;

      if (resource) {
        let fieldName = this.get('field.name');

        if (fieldName === 'content') {
          // Weird "content" bug work-around
          fieldName = 'content_';
        }

        resource.set(fieldName, this.value);
      }
    })
  });

  _exports.default = _default;
});