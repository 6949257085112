define("universal-cms/templates/components/pagination-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R/T9HV9+",
    "block": "{\"symbols\":[\"page\"],\"statements\":[[5,[27,[26,4,\"BlockHead\"],[]],[[31,0,0,[27,[26,3,\"CallHead\"],[]],[[31,0,0,[27,[26,3,\"CallHead\"],[]],[[27,[26,2,\"Expression\"],[]]],null]],null]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"    \"],[9,\"li\",true],[13,\"class\",[27,[24,1],[\"class\"]],null],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,1,\"BlockHead\"],[]],[[27,[24,1],[\"clickable\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"            \"],[9,\"a\",false],[23,\"href\",\"javascript:\",null],[3,0,0,[27,[26,0,\"ModifierHead\"],[]],[[27,[24,0],[]],\"goToPage\",[27,[24,1],[\"number\"]]],null],[10],[1,1,0,0,\"\\n                \"],[1,0,0,0,[27,[24,1],[\"text\"]]],[1,1,0,0,\"\\n            \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"            \"],[9,\"a\",true],[12,\"href\",\"javascript:\",null],[10],[1,1,0,0,\"\\n                \"],[1,0,0,0,[27,[24,1],[\"text\"]]],[1,1,0,0,\"\\n            \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"    \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"if\",\"pages\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "universal-cms/templates/components/pagination-menu.hbs"
    }
  });

  _exports.default = _default;
});