define("universal-cms/services/pxl-media-library", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    component: null,

    registerComponent(component) {
      if (this.component === null) {
        this.set('component', component);
      }
    },

    open() {
      this.component.open();
    },

    chooseMediaItem() {
      let opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return this.component.chooseMediaItem(opts);
    },

    async chooseMediaItemAsync() {
      let opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let events = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      let service = arguments.length > 2 ? arguments[2] : undefined;
      return await this.component.chooseMediaItemAsync(opts, events, service);
    }

  });

  _exports.default = _default;
});