define("universal-cms/controllers/dreamworks/resource-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    routing: Ember.inject.service('-routing'),
    util: Ember.inject.service(),
    queryParams: ['lang', 'tab'],
    lang: null,
    tab: null,
    // selectedTab: computed('tab', function() {
    //     return this.get('tab') || 'general';
    // }),
    isRefreshingModel: false,
    slugPrefix: Ember.computed('resourceType', function () {
      let url;

      if (this.isGabbysWebsitePage) {
        url = `${this.dreamworksService.getGabbysWebsiteUrl()}/`;
      } else {
        url = `${this.dreamworksService.getUrl()}/`;
      } // switch (this.resourceType) {
      //     case 'movies':      url += 'movies/';       break;
      //     case 'tv-shows':    url += 'shows/';        break;
      //     // case 'games':       url += 'games/';        break;
      //     case 'games':       url += 'explore/';        break;
      //     case 'events':      url += 'events/';       break;
      //     case 'leadership':  url += 'about/leadership/';   break;
      //     default: {
      //         console.warn(`Missing slug prefix for resource type: ${this.resourceType}`);
      //         // TODO: Get this from the API instead?
      //     }
      // }


      if (this.model.pageTypeOptions && typeof this.model.pageTypeOptions.slug_prefix !== 'undefined') {
        if (this.model.pageTypeOptions.language && this.model.pageTypeOptions.language.locale_code !== 'en') {
          url += `${this.model.pageTypeOptions.language.locale_code}/`;
        }

        if (this.model.pageTypeOptions.slug_prefix) {
          url += `${this.model.pageTypeOptions.slug_prefix}/`;
        }
      } else {
        console.warn('Missing slug prefix for resource type: ', this.resourceType);
      }

      return url;
    }),
    showLayoutTab: Ember.computed('resourceType', 'model.page.slug', function () {
      // return (this.resourceType !== 'games' && this.resourceType !== 'events' && this.resourceType !== 'leadership' && (!this.model.page || this.model.page.slug !== '/about'));
      return this.resourceType !== 'events' && this.resourceType !== 'leadership' && (!this.model.page || this.model.page.slug !== '/about');
    }),
    showSeoTab: Ember.computed('resourceType', function () {
      return this.resourceType !== 'leadership';
    }),
    hasPosterThumbnail: Ember.computed('resourceType', function () {
      return this.resourceType !== 'pages' && this.resourceType !== 'events' && this.resourceType !== 'leadership' && !this.resourceType.startsWith('gabbys-website');
    }),
    isNameLocked: Ember.computed('model.page', function () {
      return this.model.page && this.model.page.slug === '/';
    }),
    isSlugLocked: Ember.computed('model.{page,resourceSaveData.event_url}', function () {
      return this.model.page && this.model.page.slug === '/' || this.model.resourceSaveData.event_url;
    }),
    eventItemsFields: [{
      column: 'image',
      type: 'asset'
    }, {
      column: 'caption',
      type: 'text'
    }],
    isGabbysWebsitePage: Ember.computed('routing.currentRouteName', function () {
      const serviceMappings = this.auth.decoded.scopes.mappings;
      const serviceMappingsKeys = Object.keys(serviceMappings);
      const isValidServiceMappingKeys = serviceMappingsKeys.includes('DreamWorks') && serviceMappingsKeys.includes('GabbysWebsite');
      return this.routing.currentRouteName.startsWith('gabbys-website') && isValidServiceMappingKeys;
    }),

    init() {
      this._super(...arguments);

      const gabbysWebsiteCatchphrasesResourceFormOptions = [];
      gabbysWebsiteCatchphrasesResourceFormOptions.pushObject(Ember.Object.create({
        column: 'title',
        type: 'text',
        label: 'Title'
      }));
      gabbysWebsiteCatchphrasesResourceFormOptions.pushObject(Ember.Object.create({
        column: 'mp3_file_id',
        type: 'asset',
        assetType: 'audio',
        label: 'Audio (mp3)'
      }));
      this.set('gabbysWebsiteCatchphrasesResourceFormOptions', gabbysWebsiteCatchphrasesResourceFormOptions);
      const gabbysWebsiteEventLocationsResourceFormOptions = [];
      gabbysWebsiteEventLocationsResourceFormOptions.pushObject(Ember.Object.create({
        column: 'date',
        type: 'text',
        label: 'Date'
      }));
      gabbysWebsiteEventLocationsResourceFormOptions.pushObject(Ember.Object.create({
        column: 'time',
        type: 'text',
        label: 'Time'
      }));
      gabbysWebsiteEventLocationsResourceFormOptions.pushObject(Ember.Object.create({
        column: 'city',
        type: 'text',
        label: 'City'
      }));
      gabbysWebsiteEventLocationsResourceFormOptions.pushObject(Ember.Object.create({
        column: 'venue',
        type: 'text',
        rows: 3,
        label: 'Venue'
      }));
      gabbysWebsiteEventLocationsResourceFormOptions.pushObject(Ember.Object.create({
        column: 'rsvp_link',
        type: 'text',
        label: 'RSVP Link'
      }));
      gabbysWebsiteEventLocationsResourceFormOptions.pushObject(Ember.Object.create({
        column: 'rsvp_text',
        type: 'text',
        label: 'RSVP Text'
      }));
      this.set('gabbysWebsiteEventLocationsResourceFormOptions', gabbysWebsiteEventLocationsResourceFormOptions);
    },

    actions: {
      async onAdded(resourceType, addedResource) {
        this.transitionToRoute(`${this.isGabbysWebsitePage ? 'gabbys-website' : 'dreamworks'}.${resourceType}.edit`, addedResource.id);
      },

      async onSaved() {
        this.send('refreshModel');
      },

      async onError(error) {
        this.notificationService.show('Error', error);
      },

      async onRevertedUnpublishedChanges() {
        this.send('refreshModel');
      },

      async onPublishedChanges() {
        this.send('refreshModel');
      },

      async onUnpublished() {
        this.send('refreshModel');
      },

      async onPageComponentsSorted() {},

      onNameInputBlur(name) {
        if (!this.model.pageSaveData.slug) {
          const slug = this.util.slugify(name);
          this.model.pageSaveData.set('slug', slug);
        }
      },

      onLanguageChanged(_ref) {
        let {
          languageLocaleCode
        } = _ref;
        // Set localStorage
        localStorage.setItem('resourceEditLanguageLocaleCode', languageLocaleCode); // Reload page with ?lang query parameter

        const url = new URL(window.location.href);
        url.searchParams.set('lang', languageLocaleCode);
        window.location.href = url.href;
      },

      onTabChanged(tab) {
        this.set('selectedTab', tab);
        const url = new URL(window.location.href);
        url.searchParams.set('tab', tab);
        window.history.pushState({}, '', url.href);
      }

    }
  });

  _exports.default = _default;
});