define("universal-cms/controllers/dreamworks/resource-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    externalBreadcrumbLink: undefined,

    init() {
      this._super(...arguments);

      this.set('externalBreadcrumbLink', this.dreamworksService.getUrl(this.resourceType));
      this.set('tableColumns', this.tableColumns);
    },

    actions: {
      async deleteResource(resourceToDelete) {
        if (!confirm(`Are you sure you want to delete ${this.get('text.singular').toLowerCase()} "${resourceToDelete.name}"?`)) {
          return;
        }

        try {
          resourceToDelete.set('isDeleting', true);
          await this.dreamworksService.deleteOne(this.resourceType, resourceToDelete.id);
          this.model.resources.removeObject(resourceToDelete);
          this.notificationService.show(`${this.get('text.singular')} Deleted`, `${this.get('text.singular')} <strong>${resourceToDelete.name}</strong> has been deleted.`);
        } catch (error) {
          this.notificationService.show('Error', error.message || `Could not delete <strong>${resourceToDelete.name}</strong>.`);
        } finally {
          resourceToDelete.set('isDeleting', false);
        }
      }

    }
  });

  _exports.default = _default;
});