define("universal-cms/controllers/genres/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      updateGenre(genre, data) {
        this.set('isSaving', true);
        this.movieService.replaceOne('genres', genre.id, {
          name: data.get('name')
        }).then(replacedGenre => {
          this.notificationService.show('Genre saved', 'Genre was successfully saved.');
          genre.set('name', replacedGenre.get('name'));
          this.set('isSaving', false);
          return replacedGenre;
        });
      }

    }
  });

  _exports.default = _default;
});