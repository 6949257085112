define("universal-cms/ember-quill/tests/templates/components/dreamworks/page-component-modals/games-carousel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Pd7kwCE0",
    "block": "{\"symbols\":[],\"statements\":[[9,\"h2\",true],[10],[1,1,0,0,\"\\n    Games Carousel\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[7,\"pxl-form-field\",[],[[\"@label\",\"@value\"],[\"Section Title\",[27,[26,0,\"AppendSingleId\"],[\"data\",\"sectionTitle\"]]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[1,1,0,0,\"\\n\\n\"],[7,\"dreamworks/resource-form\",[],[[\"@formOptions\",\"@items\",\"@resourceType\",\"@title\",\"@onItemsUpdated\"],[[27,[26,1,\"AppendSingleId\"],[]],[27,[26,0,\"AppendSingleId\"],[\"data\",\"items\"]],\"games\",\"Resources\",[31,288,6,[27,[26,3,\"CallHead\"],[]],[[27,[24,0],[]],[31,296,3,[27,[26,2,\"CallHead\"],[]],[[27,[26,0,\"Expression\"],[\"data\",\"items\"]]],null]],null]]],null],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"pageComponent\",\"resourceFormOptions\",\"mut\",\"action\"]}",
    "meta": {
      "moduleName": "universal-cms/ember-quill/tests/templates/components/dreamworks/page-component-modals/games-carousel.hbs"
    }
  });

  _exports.default = _default;
});