define("universal-cms/components/pxl-form-date", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    label: undefined,
    required: false,
    value: undefined,
    type: 'date',
    format: Ember.computed('type', function () {
      switch (this.type) {
        case 'date':
          return 'MM/DD/YYYY';

        case 'datetime':
          return 'MM/DD/YYYY h:mm a';

        default:
          return null;
      }
    }),
    saveFormat: Ember.computed('type', function () {
      switch (this.type) {
        case 'date':
          return 'YYYY-MM-DD';

        case 'datetime':
          return 'YYYY-MM-DD HH:mm:ss';

        default:
          return null;
      }
    }),
    pickTime: Ember.computed('type', function () {
      return this.type === 'datetime';
    }),

    init() {
      this._super(...arguments);

      this.set('value', this.value ? (0, _moment.default)(this.value).format(this.format) : null);
    },

    actions: {
      updateValue(e) {
        this.set('value', e.format(this.saveFormat));
      }

    }
  });

  _exports.default = _default;
});