define("universal-cms/routes/application", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    routing: Ember.inject.service('-routing'),
    gabbysWebsite: Ember.inject.service(),
    beforeModel: async function () {
      if (this.get('auth.isAuthed')) {
        const cookieExpirationDate = new Date(this.get('auth.decoded.exp') * 1000);
        const now = new Date();

        if (now >= cookieExpirationDate) {
          localStorage.removeItem('auth');
          window.location = '/';
        }
      } else if (!this.get('auth.isAuthed')) {
        this.set('lastRoute', window.location.pathname);
        this.transitionTo('login');
      }
    },

    activate() {
      if (!this.get('auth.isAuthed')) {
        this.set('lastRoute', window.location.pathname);
        this.transitionTo('login');
      }
    },

    initTooltips() {// $('[data-toggle="tooltip"]').tooltip();
    },

    actions: {
      didTransition() {
        if (this.get('auth.isAuthed')) {
          Ember.run.scheduleOnce('afterRender', this, this.initTooltips);

          if (this.lastRoute) {
            this.transitionTo(this.lastRoute);
            this.set('lastRoute', false);
          }
        } // Necessary for dropdown's in ModelTable's to work


        setTimeout(() => {
          (0, _jquery.default)('.dropdown-toggle').dropdown();
        }, 1000);
      }

    }
  });

  _exports.default = _default;
});