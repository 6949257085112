define("universal-cms/controllers/dreamworks/tv-shows/edit", ["exports", "universal-cms/controllers/dreamworks/resource-edit"], function (_exports, _resourceEdit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resourceEdit.default.extend({
    resourceType: 'tv-shows',
    text: {
      singular: 'TV Show',
      plural: 'TV Shows'
    },

    init() {
      this._super(...arguments);

      this.set('statusOptions', [{
        text: 'Now Streaming',
        value: 'now_streaming'
      }, {
        text: 'Coming Soon',
        value: 'coming_soon'
      }, {
        text: 'Home Entertainment',
        value: 'home_entertainment'
      }]);
      this.set('ratingOptions', ['PG', 'G', 'TV-G', 'TV-Y7', 'TV-Y7-FV']);
    }

  });

  _exports.default = _default;
});