define("universal-cms/templates/components/bs-datetimepicker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5CfL066h",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[5,[27,[26,2,\"BlockHead\"],[]],[[28,[24,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"  \"],[16,1,null],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"  \"],[1,0,0,0,[31,42,5,[27,[26,6,\"CallHead\"],[]],null,[[\"type\",\"class\",\"disabled\",\"name\",\"placeholder\"],[\"text\",\"form-control\",[27,[26,5,\"Expression\"],[]],[27,[26,4,\"Expression\"],[]],[27,[26,3,\"Expression\"],[]]]]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[5,[27,[26,8,\"BlockHead\"],[]],[[27,[26,7,\"Expression\"],[]]],null,[[\"default\"],[{\"statements\":[[9,\"span\",true],[12,\"class\",\"input-group-addon\",null],[10],[1,1,0,0,\"\\n  \"],[9,\"span\",true],[13,\"class\",[32,[[31,221,2,[27,[26,2,\"CallHead\"],[]],[[27,[26,1,\"Expression\"],[\"date\"]],[27,[26,1,\"Expression\"],[\"date\"]],[27,[26,0,\"Expression\"],[]]],null]]],null],[10],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"dateIcon\",\"icons\",\"if\",\"placeholder\",\"textFieldName\",\"disabled\",\"input\",\"noIcon\",\"unless\"]}",
    "meta": {
      "moduleName": "universal-cms/templates/components/bs-datetimepicker.hbs"
    }
  });

  _exports.default = _default;
});