define("universal-cms/templates/components/dreamworks/page-component-modals/media-theater", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cPrPZAbE",
    "block": "{\"symbols\":[],\"statements\":[[9,\"h2\",true],[10],[1,1,0,0,\"\\n    Media Theater\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[7,\"dreamworks/media-theater-editor\",[],[[\"@mediaTheaterComponent\"],[[27,[26,0,\"AppendSingleId\"],[]]]],null],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"pageComponent\"]}",
    "meta": {
      "moduleName": "universal-cms/templates/components/dreamworks/page-component-modals/media-theater.hbs"
    }
  });

  _exports.default = _default;
});