define("universal-cms/controllers/universal-studios/videos/edit", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    error: false,
    heading: Ember.computed('isNew', 'model.video.title', function () {
      return this.isNew === true ? 'Add Video' : this.get('model.video.title');
    }),
    isSaving: false,
    saveButtonText: Ember.computed('isNew', 'isSaving', function () {
      if (this.isNew === true) {
        return this.isSaving === true ? 'Adding...' : 'Add';
      } else {
        return this.isSaving === true ? 'Saving...' : 'Save';
      }
    }),
    actions: {
      save() {
        this.set('isSaving', true);
        const video = this.get('model.video');
        let saveData = video.getProperties('title', 'youtube_id', 'meta', 'desktop_image', 'mobile_image', 'button_text', 'button_link', 'featured');

        if (saveData.button_text === null) {
          saveData.button_text = '';
        }

        if (saveData.button_link === null) {
          saveData.button_link = '';
        }

        if (video.get('featured') === true) {
          saveData.about_title = video.get('about_title');
          saveData.about_super_title = video.get('about_super_title') ? video.get('about_super_title') : '';
          saveData.about_description = video.get('about_description') ? video.get('about_description') : '';
          saveData.about_button1_text = video.get('about_button1_text') ? video.get('about_button1_text') : '';
          saveData.about_button1_link = video.get('about_button1_link') ? video.get('about_button1_link') : '';
          saveData.about_button2_text = video.get('about_button2_text') ? video.get('about_button2_text') : '';
          saveData.about_button2_link = video.get('about_button2_link') ? video.get('about_button2_link') : '';
          saveData.about_meta = video.get('about_meta') ? video.get('about_meta') : '';
          saveData.about_date = (0, _moment.default)(video.get('about_date') ? video.get('about_date') : '').format("YYYY-MM-DD");
          saveData.about_desktop_image = video.get('about_desktop_image');
          saveData.about_mobile_image = video.get('about_mobile_image');
          saveData.about_facebook_link = video.get('about_facebook_link') ? video.get('about_facebook_link') : '';
          saveData.about_instagram_link = video.get('about_instagram_link') ? video.get('about_instagram_link') : '';
          saveData.about_twitter_link = video.get('about_twitter_link') ? video.get('about_twitter_link') : '';
          saveData.about_youtube_link = video.get('about_youtube_link') ? video.get('about_youtube_link') : '';
        }

        if (this.isNew) {
          this.universalStudiosService.createOne('videos', saveData, true).then(createResponse => {
            if (typeof createResponse.errors === 'undefined') {
              this.notificationService.show('Video Added', `<strong>${createResponse.get('title')}</strong> has been added.`);
              this.target.transitionTo(this.routeName, createResponse.get('id'));
            } else {
              this.notificationService.show('Error', this.universalStudiosService.formatErrorResponse(createResponse.errors));
            }
          }, () => {
            this.notificationService.show('Error', 'Could not add video.');
          }).finally(() => {
            this.set('isSaving', false);
          });
        } else {
          this.universalStudiosService.updateOne('videos', video.get('id'), saveData, true).then(updateResponse => {
            if (typeof updateResponse.errors === 'undefined') {
              this.notificationService.show('Video Updated', `<strong>${updateResponse.get('title')}</strong> has been updated.`);
              this.target.transitionTo(this.routeName, updateResponse.get('id'));
            } else {
              this.notificationService.show('Error', this.universalStudiosService.formatErrorResponse(updateResponse.errors));
            }
          }, () => {
            this.notificationService.show('Error', 'Could not update video.');
          }).finally(() => {
            this.set('isSaving', false);
          });
        }
      }

    }
  });

  _exports.default = _default;
});