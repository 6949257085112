define("universal-cms/routes/universal-studios/connect/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      const controller = this.controllerFor(this.routeName);
      const connectId = params.connect_id;
      const isNew = connectId === 'add';
      controller.set('isNew', isNew);
      let rsvp = {};
      rsvp.connect = isNew ? Ember.Object.create({
        isNew: true
      }) : this.universalStudiosService.getOne('connects', connectId).then(connect => {
        if (connect.get('image')) {
          connect.set('image_url', this.auth.getUniversalStudiosUrl(`uploads/${connect.get('image')}`));
        }

        return connect;
      }, () => {
        controller.set('error', true);
      });
      return Ember.RSVP.hash(rsvp);
    }

  });

  _exports.default = _default;
});