define("universal-cms/routes/universal-studios/news/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      const controller = this.controllerFor(this.routeName);
      const newsArticleId = params.news_article_id;
      const isNew = newsArticleId === 'add';
      controller.set('isNew', isNew);
      let rsvp = {};
      rsvp.newsArticle = isNew ? Ember.Object.create({
        isNew: true
      }) : this.universalStudiosService.getOne('news', newsArticleId).then(newsArticle => {
        if (newsArticle.get('desktop_image')) {
          newsArticle.set('desktop_image_url', this.auth.getUniversalStudiosUrl(`uploads/${newsArticle.get('desktop_image')}`));
        }

        if (newsArticle.get('mobile_image')) {
          newsArticle.set('mobile_image_url', this.auth.getUniversalStudiosUrl(`uploads/${newsArticle.get('mobile_image')}`));
        }

        return newsArticle;
      }, () => {
        controller.set('error', true);
      });
      return Ember.RSVP.hash(rsvp);
    }

  });

  _exports.default = _default;
});