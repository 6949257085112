define("universal-cms/ember-quill/tests/templates/components/dreamworks/page-component-modals/media-carousel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VjLvZ4l+",
    "block": "{\"symbols\":[],\"statements\":[[9,\"h2\",true],[10],[1,1,0,0,\"\\n    Media Carousel\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[9,\"div\",true],[12,\"class\",\"row\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"div\",true],[12,\"class\",\"col-sm-12 col-md-8\",null],[10],[1,1,0,0,\"\\n        \"],[7,\"pxl-form-field\",[],[[\"@label\",\"@value\"],[\"Title\",[27,[26,0,\"AppendSingleId\"],[\"data\",\"sectionTitle\"]]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[9,\"div\",true],[12,\"class\",\"row\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"div\",true],[12,\"class\",\"col-sm-12 col-md-8\",null],[10],[1,1,0,0,\"\\n        \"],[9,\"div\",true],[12,\"class\",\"resources-container\",null],[10],[1,1,0,0,\"\\n            \"],[7,\"dreamworks/resource-list\",[],[[\"@pageType\",\"@resourceIds\",\"@selectedResourceTypeOption\",\"@canAdd\",\"@onChanged\",\"@languageId\"],[\"default\",[27,[26,0,\"AppendSingleId\"],[\"data\",\"items\"]],[27,[26,1,\"AppendSingleId\"],[]],true,[31,569,6,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[]],\"onResourcesChanged\"],null],[27,[26,3,\"AppendSingleId\"],[]]]],null],[1,1,0,0,\"\\n        \"],[11],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"pageComponent\",\"selectedResourceTypeOption\",\"action\",\"languageId\"]}",
    "meta": {
      "moduleName": "universal-cms/ember-quill/tests/templates/components/dreamworks/page-component-modals/media-carousel.hbs"
    }
  });

  _exports.default = _default;
});