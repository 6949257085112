define("universal-cms/components/dreamworks/page-component-modal", ["exports", "universal-cms/templates/components/dreamworks/page-component-modal"], function (_exports, _pageComponentModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _pageComponentModal.default,
    util: Ember.inject.service(),
    messageBus: Ember.inject.service('message-bus'),
    isOpen: false,
    pageData: undefined,
    pageComponent: undefined,
    pageComponentComponent: Ember.computed('pageComponent.key', function () {
      if (!this.pageComponent) {
        return;
      }

      return `dreamworks/page-component-modals/${this.pageComponent.key}`;
    }),
    hasChangesBeenMade: false,

    revert() {
      this.messageBus.publish('revert-page-component-modal');
    },

    actions: {
      async save() {
        // Validate form
        // console.log(this.get('onFormValidate'));
        if (typeof this.onSave === 'function') {
          this.onSave(this.get('pageComponent.data'));
        }

        if (typeof this.onHasChangesBeenMadeChanged === 'function') {
          this.onHasChangesBeenMadeChanged(this.hasChangesBeenMade);
        }

        this.send('close');
      },

      cancel() {
        if (this.hasChangesBeenMade && !confirm('Are you sure you want to cancel? (all unsaved changes will be lost)')) {
          return;
        }

        this.revert();

        if (typeof this.onCancel === 'function') {
          this.onCancel();
        }

        this.send('close');
      },

      close() {
        this.set('isOpen', false);
        this.set('hasChangesBeenMade', false);
      },

      onHasChangesBeenMadeChanged(hasChangesBeenMade) {
        this.set('hasChangesBeenMade', hasChangesBeenMade);
      }

    }
  });

  _exports.default = _default;
});