define("universal-cms/routes/movies/edit/assets", ["exports", "universal-cms/config/environment", "moment"], function (_exports, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      let movieId = this.modelFor('movies.edit').id;
      return this.movieService.getAll('asset-types').then(assetTypes => {
        return Ember.RSVP.hash({
          assetTypes: assetTypes,
          assetGroups: this.movieService.getAll('asset-groups'),
          assets: this.movieService.getAll('assets', {
            'movie': movieId
          })
        });
      });
    }

  });

  _exports.default = _default;
});