define("universal-cms/helpers/boolean-formatter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.booleanFormatter = booleanFormatter;
  _exports.default = void 0;

  function booleanFormatter(params) {
    let yesText = typeof params[1] !== 'undefined' ? params[1] : 'Yes';
    let noText = typeof params[2] !== 'undefined' ? params[2] : 'No';
    return params[0] === true ? yesText : noText;
  }

  var _default = Ember.Helper.helper(booleanFormatter);

  _exports.default = _default;
});