define("universal-cms/controllers/movies/edit/critical-acclaims", ["exports", "ember-uploader"], function (_exports, _emberUploader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    editMovieController: Ember.inject.controller('movies/edit'),
    movie: Ember.computed.reads('editMovieController.model'),
    criticalAcclaims: Ember.A(),
    isLoading: true,
    isUploading: false,
    movieId: null,
    fileToUpload: null,
    uploadProgressPercent: 0,
    isAdding: Ember.computed('isAdding', function () {
      return false;
    }),
    isDeleting: Ember.computed('isDeleting', function () {
      return true;
    }),
    tableColumns: null,

    init() {
      this._super(...arguments);

      this.set('tableColumns', [{
        'propertyName': 'author',
        'title': 'Author',
        'component': 'models-table/seo-page-title'
      }, {
        'title': 'Acclaim',
        'propertyName': 'acclaim',
        'component': 'models-table/seo-page-actions'
      }]);
    },

    actions: {
      upload() {
        let fileToUpload = this.fileToUpload;

        if (fileToUpload === null) {
          this.notificationService.show('Choose file', 'Choose file before uploading asset.');
          return;
        }

        let assetType = this.selectedAssetType;

        if (!assetType) {
          this.notificationService.show('Choose type', 'Choose type before uploading asset.');
          return;
        }

        let uploadUrl = this.movieService.getUrl('media');
        let auth = this.auth;

        let uploader = _emberUploader.default.Uploader.extend({
          ajaxSettings: function () {
            var settings = this._super.apply(this, arguments);

            settings.headers = {
              'X-Auth': 'Bearer ' + auth.jwt
            };
            return settings;
          }
        }).create({
          url: uploadUrl
        });

        let oldAjaxSettings = uploader.ajaxSettings.bind(uploader);

        uploader.ajaxSettings = (url, data, method) => {
          let response = oldAjaxSettings(url, data, method);
          response.headers = {
            'X-Auth': 'Bearer ' + this.get('auth.jwt')
          };
          return response;
        };

        this.set('isUploading', true);
        uploader.upload(fileToUpload).then(data => {
          if (data.status === 'fail') {
            this.set('isUploading', false);
            this.notificationService.show('Could not upload asset.', data.data[0]);
            return;
          }

          let url = this.movieService.getUrl('media/' + data.data.media.filename); // Save asset

          this.send('createAsset', {
            assetTypeId: assetType.id,
            value: url
          }); //this.get('notificationService').show('Asset uploaded', 'Asset has been uploaded!');
        }, uploadError => {
          console.log('uploadError');
          this.set('isUploading', false);
          this.notificationService.show('Something went wrong', 'Could not upload asset.');
        });
        uploader.on('progress', function (e) {
          let percent = e.percent;
          this.set('uploadProgressPercent', percent);
        });
      },

      deleteResource(resource) {
        resource.set('isDeleting', true);
        this.movieService.deleteOne('critical-acclaims', resource.id).then(() => {
          this.notificationService.show('Deleted', 'Resource was successfully deleted.');
          resource.set('isDeleting', false);
          this.get('target._routerMicrolib').refresh();
        }, errorResponse => {
          this.notificationService.show('Error', this.util.toErrorString(errorResponse));
          resource.set('isDeleting', false);
        });
      },

      onSavePositions(data) {
        let savePromises = [];
        let position = 0; // Set positions

        let resources = data;

        for (let resource of resources) {
          resource.set('position', position++);
        }

        for (const resource of resources) {
          savePromises.push(new Ember.RSVP.Promise((resolve, reject) => {
            return this.movieService.replaceOne('/critical-acclaims', resource.id, resource).then(result => {
              resolve();
            }, () => {
              reject();
            });
          }));
        }

        return Ember.RSVP.Promise.all(savePromises).then(() => {
          this.get('target._routerMicrolib').refresh();
          this.notificationService.show('Saved', 'Resource positions saved!');
        }, error => {
          this.notificationService.show('Error', 'Could not change resource positions.');
        });
      },

      async onSaved() {
        this.movieService.getAll('movies/' + this.movieId + '/critical-acclaims', {
          sort_by: 'position',
          sort_order: 'asc'
        }).then(items => {
          this.set('criticalAcclaims', items);
        });
      }

    }
  });

  _exports.default = _default;
});