define("universal-cms/routes/logout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    cookies: Ember.inject.service(),

    activate() {
      if (this.get('auth.isAuthed')) {
        localStorage.removeItem('auth');
        window.location = '/';
      } else {
        this.transitionTo('application');
      }
    }

  });

  _exports.default = _default;
});