define("universal-cms/controllers/uwatch/resources/resource", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
    * This function prepares a formatted object for the given resource
    * these formatted resources can then be used  in api calls
    * @params resource type, options
    */
  function formatResource(resource, options) {
    let formattedResource = {};

    for (let option of options.resource_fields) {
      // get specified resource id
      if (resource.get('id')) {
        formattedResource.id = resource.get('id');
      } // check for specific option types


      if (option.type === 'text' || option.type === 'textarea' || option.type === 'wysiwyg') {
        formattedResource[option.name] = resource.get(option.name) ? resource.get(option.name) : null;
      } else if (option.type === 'relationship') {
        formattedResource[option.name] = resource.get(option.name).resource.id;
      } else if (option.type === 'date') {
        // Format date to mysql format
        formattedResource[option.name] = (0, _moment.default)(resource.get(option.name)).format('YYYY-MM-DD HH:mm:ss');
      } else if (option.type === 'select') {
        formattedResource[option.name] = resource.get(option.name);
      }

      if (option.name === 'content') {
        // Weird "content" bug work-around
        formattedResource['content'] = resource.get('content_') ? resource.get('content_') : resource.get('content');
      }
    }

    return formattedResource;
  }

  var _default = Ember.Controller.extend({
    isAdding: false,
    isSaving: false,
    isDeleting: false,
    isLoading: false,
    actions: {
      updateResource(resource) {
        this.set('isSaving', true);
        this.set('isLoading', true); // get options from model

        let options = this.get('model.options'); // resource name from options

        let resourceName = options.location; // format resource from given resource and options

        let formattedResource = formatResource(resource, options); // use resource service to update the resource in the api
        // paramaters specify table, resource name, spefic resource object, resource name

        this.uwatchService.updateResource('uwatch', resourceName, formattedResource, resourceName).then(result => {
          this.set('isSaving', false);
          this.set('isLoading', false); // update successful

          this.notificationService.show('Resource Update', 'Resource updated successfully.'); // refresh to get current update

          this.get('target._routerMicrolib').refresh(); // go back to given resource page

          this.transitionToRoute('uwatch.resources', options.location);
        }, errors => {
          let errorMessage = ''; // show error

          if (errors.length > 0) {
            for (let error of errors) {
              for (let error2 in error) {
                errorMessage += `- ${error[error2]}<br>`;
              }
            }
          } else {
            errorMessage = 'Could not update resource.';
          }

          this.notificationService.show('Resource Update', errorMessage);
          this.set('isSaving', false);
          this.set('isLoading', false);
        });
      },

      newResource(resource) {
        this.set('isAdding', true);
        this.set('isLoading', true); // get model

        let model = this.model; // get options

        let options = this.get('model.options'); // resource name

        let resourceName = options.location; // generate formatted resource

        let formattedResource = formatResource(resource, options); // use resource service to update the resource in the api
        // paramaters specify table, resource name, spefic resource object, resource name

        this.uwatchService.newResource('uwatch', resourceName, formattedResource, resourceName).then(newResource => {
          this.set('isAdding', false);
          this.set('isLoading', false); // resource created

          this.notificationService.show('New Resource', 'Resource was created successfully.'); // update model with new resource

          this.set('model.resource', newResource[0]); // refresh to get current update

          this.get('target._routerMicrolib').refresh(); // go back to given resource page

          this.transitionToRoute('uwatch.resources', model.options.location);
        }, errors => {
          // show error
          let errorMessage = '';

          if (errors.length > 0) {
            for (let error of errors) {
              if (typeof error === 'object') {
                for (let error2 in error) {
                  errorMessage += `- ${error[error2]}<br>`;
                }
              } else {
                errorMessage += `- ${error}<br>`;
              }
            }
          } else {
            errorMessage = 'Could not create new resource.';
          }

          this.notificationService.show('Error', errorMessage);
          this.set('isAdding', false);
          this.set('isLoading', false);
        });
      },

      deleteResource(resource) {
        this.set('isDeleting', true);
        this.set('isLoading', true); // get model

        let model = this.get('model.resources'); // pass the resource options and the resource itself to resource service

        this.uwatchService.deleteResource('uwatch', this.resources, resource).then(() => {
          this.set('isDeleting', false);
          this.set('isLoading', false); // deleted successfully

          this.notificationService.show('Resource deleted', 'Resource was successfully deleted.'); // update model so that the resource is not visible on the resource page

          model.removeObject(resource); // go back to given resource page

          this.transitionToRoute('uwatch.resources', model.resourceName);
        }, error => {
          // show error
          this.notificationService.show('Could not delete resource', 'Could not delete resource.');
          this.set('isDeleting', false);
          this.set('isLoading', false);
        });
      }

    }
  });

  _exports.default = _default;
});