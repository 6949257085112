define("universal-cms/components/dreamworks/page-component-modals/featured-carousel", ["exports", "universal-cms/components/dreamworks/page-component-modals/page-component-modal"], function (_exports, _pageComponentModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _pageComponentModal.default.extend({
    classNames: ['featured-carousel'],

    init() {
      this._super(...arguments);

      const selectedResourceTypeOption = this.dreamworksService.getResourceTypeOptionFromSectionProperty(this.pageComponent.data.sectionProperty);
      this.set('selectedResourceTypeOption', selectedResourceTypeOption);
    },

    actions: {
      onResourcesChanged(resourceTypeOption) {
        this.set('pageComponent.data.sectionProperty', resourceTypeOption.sectionProperty);
      }

    }
  });

  _exports.default = _default;
});