define("universal-cms/routes/website/movie-promos/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    actions: {
      newResource(resource) {
        resource.live_start = null;
        resource.live_end = null;
        this.controller.set('isLoading', true);
        this.serviceResource.newResource('website', 'movie-promos', resource).then(resources => {
          let model = this.modelFor('website.moviePromos');
          this.controller.set('isLoading', false);
          this.notificationService.show('New Resource', 'Resource was created successfully.');
          model.resources.pushObject(resources[0]);
        });
      }

    }
  });

  _exports.default = _default;
});