define("universal-cms/components/dreamworks/page-component-modals/gabbys-website-featured-partners", ["exports", "universal-cms/components/dreamworks/page-component-modals/page-component-modal"], function (_exports, _pageComponentModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _pageComponentModal.default.extend({
    classNames: 'gabbys-website-featured-partners',

    init() {
      this._super(...arguments);

      if (!this.get('pageComponent.data.partner_ids')) {
        this.set('pageComponent.data.partner_ids', Ember.A());
      }

      if (!this.get('pageComponent.data.custom_resource_type')) {
        const selectedResourceTypeOption = this.dreamworksService.getResourceTypeOptionFromSectionProperty('GabbysWebsitePartner');
        this.set('pageComponent.data.custom_resource_type', selectedResourceTypeOption);
      }
    }

  });

  _exports.default = _default;
});