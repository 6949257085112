define("universal-cms/controllers/universal-studios/slider/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    error: false,
    actions: {
      updateSlidersOrder(orderedSliders) {
        let newOrder = [];

        for (let slider of orderedSliders) {
          newOrder.push(slider.get('id'));
        }

        let saveData = {
          sliders: newOrder
        };
        this.universalStudiosService.updateOne(`sliders`, 'update-order', saveData).then(() => {
          this.notificationService.show('Saved', `Slider has been moved.`);
        }).catch(() => {
          this.notificationService.show('Error', `Could not save sliders order.`);
        });
      },

      deleteSlider(slider) {
        slider.set('isDeleting', true);
        return this.universalStudiosService.deleteOne('sliders', slider.get('id')).then(() => {
          this.notificationService.show('Slider deleted', `<strong>${slider.get('title')}</strong> was successfully deleted.`);
          this.model.removeObject(slider);
        }, () => {
          slider.set('isDeleting', false);
          this.notificationService.show('Error', `Could not delete slider <strong>${slider.get('title')}</strong>.`);
        });
      }

    }
  });

  _exports.default = _default;
});