define("universal-cms/components/wysiwyg-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['summernote'],
    height: 400,

    didInsertElement() {
      this._super(...arguments);

      const vm = this;

      if (this.rows) {
        this.set('height', this.rows * 50);
      }

      Ember.run.scheduleOnce('afterRender', this, this.initSummernote);
    },

    initSummernote() {
      const vm = this;
      vm.$('.wysiwyg-textarea').summernote({
        height: this.get('height'),
        toolbar: [['style', ['bold', 'italic', 'underline', 'clear']], ['font', ['strikethrough', 'superscript', 'subscript']], ['fontsize', ['fontsize']], ['color', ['color']], ['para', ['ul', 'ol', 'paragraph']], ['insert', ['link', 'picture', 'video', 'table', 'hr']], ['misc', ['codeview', 'undo', 'redo']]],
        callbacks: {
          onInit: function () {},
          onChange: function (html, $editable) {
            vm.set('value', html);
          }
        }
      });
      vm.$('.wysiwyg-textarea').summernote('code', vm.get('value'));
    }

  });

  _exports.default = _default;
});