define("universal-cms/controllers/universal-studios/banner-headers/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    error: false,
    tableColumns: null,
    tableClasses: Ember.Object.create({
      'globalFilterWrapper': 'pull-right'
    }),

    init() {
      this._super(...arguments);

      this.set('tableColumns', [{
        'propertyName': 'page',
        'title': 'Page'
      }, {
        title: 'Manage',
        component: 'manage',
        editable: false
      }]);
    },

    actions: {
      deleteBannerHeader(bannerHeader) {
        if (!confirm('Are you sure you want to delete banner header?')) {
          return;
        }

        bannerHeader.set('isDeleting', true);
        this.universalStudiosService.deleteOne('banner-headers', bannerHeader.get('id')).then(() => {
          this.notificationService.show('Banner header deleted', 'Banner header was successfully deleted.');
          this.model.removeObject(bannerHeader);
        }, () => {
          bannerHeader.set('isDeleting', false);
          this.notificationService.show('Error', 'Could not delete banner header.');
        });
      }

    }
  });

  _exports.default = _default;
});