define("universal-cms/controllers/genres/list", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    tableColumns: Ember.A([{
      'propertyName': 'name',
      'title': 'Name'
    }, {
      title: 'Manage',
      component: 'manage',
      editable: false
    }]),
    actions: {
      createGenre(data, genreForm) {
        this.set('isSaving', true);
        this.movieService.createOne('genres', {
          name: data.get('name'),
          live_start: (0, _moment.default)().format('YYYY-MM-DD HH:mm:ss'),
          live_end: null
        }).then(createdGenre => {
          this.notificationService.show('Genre added', `<strong>${data.get('name')}</strong> was successfully added.`);
          this.model.pushObject(createdGenre);
          genreForm.set('name', '');
          this.set('isSaving', false);
          return createdGenre;
        });
      },

      deleteGenre(genre) {
        if (!confirm(`Are you sure you want to delete genre "${genre.get('name')}"?`)) {
          return;
        }

        genre.set('isDeleting', true);
        this.movieService.deleteOne('genres', genre.id).then(() => {
          this.notificationService.show('Genre deleted', `<strong>${genre.name}</strong> was successfully deleted.`);
          this.model.removeObject(genre);
        }).catch(() => {
          this.notificationService.show('Error', `Could not delete genre <strong>${genre.name}</strong>.`);
          genre.set('isDeleting', false);
        });
      }

    }
  });

  _exports.default = _default;
});