define("universal-cms/templates/components/dreamworks/page-seo-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wtzjPrN7",
    "block": "{\"symbols\":[],\"statements\":[[9,\"div\",true],[12,\"class\",\"row\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"div\",true],[12,\"class\",\"col-sm-12 col-md-6\",null],[10],[1,1,0,0,\"\\n        \"],[7,\"pxl-form-field\",[],[[\"@id\",\"@label\",\"@value\"],[\"page_title\",\"Page Title\",[27,[26,0,\"AppendSingleId\"],[\"page_title\"]]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[9,\"div\",true],[12,\"class\",\"row\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"div\",true],[12,\"class\",\"col-sm-12 col-md-6\",null],[10],[1,1,0,0,\"\\n        \"],[7,\"pxl-form-field\",[],[[\"@id\",\"@label\",\"@value\"],[\"meta_description\",\"Meta Description\",[27,[26,0,\"AppendSingleId\"],[\"meta_description\"]]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[9,\"div\",true],[12,\"class\",\"row\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"div\",true],[12,\"class\",\"col-sm-12 col-md-6\",null],[10],[1,1,0,0,\"\\n        \"],[7,\"pxl-form-file\",[],[[\"@id\",\"@label\",\"@assetId\",\"@requiredWidth\",\"@requiredHeight\"],[\"share_image\",\"Share Image\",[27,[26,0,\"AppendSingleId\"],[\"share_image\"]],\"1200\",\"630\"]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[9,\"div\",true],[12,\"class\",\"row\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"div\",true],[12,\"class\",\"col-sm-12 col-md-6\",null],[10],[1,1,0,0,\"\\n        \"],[7,\"pxl-youtube-video-form-field\",[],[[\"@label\",\"@value\"],[\"Schema.org YouTube ID\",[27,[26,0,\"AppendSingleId\"],[\"schema_org_youtube_id\"]]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"pageSaveData\"]}",
    "meta": {
      "moduleName": "universal-cms/templates/components/dreamworks/page-seo-form.hbs"
    }
  });

  _exports.default = _default;
});