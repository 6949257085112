define("universal-cms/routes/universal-brand/general/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      const controller = this.controllerFor(this.routeName);
      let rsvp = {};
      rsvp.mainMenuItems = this.universalBrandService.getAll('menu-items?where[type]=main&where[parent_menu_item_id]=null&sort-by=order').then(menuItems => {
        return menuItems;
      }, () => {
        controller.set('mainMenuItemsError', 'Could not load navigation links.');
      });
      rsvp.socialAccounts = this.universalBrandService.getAll('social-accounts?sort-by=order').then(menuItems => {
        return menuItems;
      }, () => {
        controller.set('socialAccountsError', 'Could not load social accounts.');
      });
      rsvp.pageMetaItems = this.universalBrandService.getAll('page-meta-items').then(pageMetaItems => {
        return pageMetaItems;
      }, () => {
        controller.set('socialAccountsError', 'Could not load SEO.');
      });
      rsvp.headerLogo = this.universalBrandService.getOne('settings', 'get?key=header_logo_image_id').then(headerLogo => {
        if (typeof headerLogo.value === 'undefined' || headerLogo.get('value') === null) {
          return null;
        }

        controller.set('headerLogoId', headerLogo.get('value'));
        return headerLogo;
      }, () => {
        controller.set('socialAccountsError', 'Could not load settings.');
      });
      rsvp.footerLogo = this.universalBrandService.getOne('settings', 'get?key=footer_logo_image_id').then(footerLogo => {
        if (typeof footerLogo.value === 'undefined' || footerLogo.get('value') === null) {
          return null;
        }

        controller.set('footerLogoId', footerLogo.get('value'));
        return footerLogo;
      }, () => {
        controller.set('socialAccountsError', 'Could not load settings.');
      });
      rsvp.footerMenuItems = this.universalBrandService.getAll('menu-items/footer').then(menuItems => {
        return menuItems;
      }, () => {
        controller.set('socialAccountsError', 'Could not load left footer menu items.');
      });
      rsvp.copyrightText = this.universalBrandService.getOne('settings', 'get?key=copyright_text').then(copyrightText => {
        return copyrightText;
      }, () => {
        controller.set('socialAccountsError', 'Could not load copyright text.');
      });
      return Ember.RSVP.hash(rsvp);
    }
    /*actions: {
        didTransition() {
            const controller = this.controllerFor(this.routeName);
             Ember.run.scheduleOnce('afterRender', this, function() {
                const headerSocialPlatformsElement = document.getElementById('header-social-platforms');
                 const headerSocialPlatformsSortable = Sortable.create(headerSocialPlatformsElement, {
                    handle: '.drag-handle',
                    draggable: '.header-social-platform',
                    animation: 150
                });
                 controller.set('headerSocialPlatformsSortable', headerSocialPlatformsSortable);
            });
        }
    }*/


  });

  _exports.default = _default;
});