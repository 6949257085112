define("universal-cms/routes/gabbys-website/gabbys-website-episodes/edit", ["exports", "universal-cms/routes/dreamworks/resource-edit"], function (_exports, _resourceEdit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resourceEdit.default.extend({
    templateName: 'dreamworks/resource-edit',
    resourceTypeColumn: 'gabbys-website-episode'
  });

  _exports.default = _default;
});