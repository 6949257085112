define("universal-cms/services/movie", ["exports", "jquery", "universal-cms/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    util: Ember.inject.service(),
    resourceNameMappings: null,

    init() {
      this._super(...arguments);

      this.set('resourceNameMappings', {
        'asset-types': 'asset_types',
        'asset-groups': 'asset_groups',
        'cast-and-crews': 'cast_and_crews',
        'movie-genres': 'movie_genres',
        'movie-roles': 'movie_roles',
        'option-groups': 'option_groups',
        'option-types': 'option_types',
        'movies/(\\d+)/options': 'options',
        'critical-acclaims': 'critical_acclaims'
      });
    },

    mapResourceName(resourceName) {
      if (typeof this.resourceNameMappings[resourceName] !== 'undefined') {
        return this.resourceNameMappings[resourceName];
      } else {
        for (let key in this.resourceNameMappings) {
          if (new RegExp(key).test(resourceName)) {
            return this.resourceNameMappings[key];
          }
        }
      }

      return resourceName;
    },

    getAll(resourceName) {
      let params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      let expand = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      const vm = this;
      return new Ember.RSVP.Promise((resolve, reject) => {
        let url = this.getUrl(resourceName + (expand !== null ? '?expand=' + expand.join() : ''));
        url += url.indexOf('?') === -1 ? '?' : '&';
        url += `nocache=${Math.floor(Math.random() * 99999)}`;
        return _jquery.default.ajax({
          'method': 'GET',
          'url': url,
          'data': params,
          'beforeSend': xhr => this.beforeSendAuth(xhr)
        }).then(response => {
          if (response.status === 'success') {
            let responseResourceName = this.mapResourceName(resourceName);
            let resources = Ember.A(response.data[responseResourceName].map(function (resource) {
              return vm.get('util').emberify(resource);
            }));
            resolve(resources);
          } else if (response.status === 'fail') {
            reject(response.data[0]);
          }
        }, () => {
          reject();
        });
      });
    },

    getOne(resourceName, id) {
      let expand = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      return new Ember.RSVP.Promise((resolve, reject) => {
        let url = this.getUrl(resourceName + '/' + id + (expand !== null ? '?expand=' + expand.join() : ''));
        url += url.indexOf('?') === -1 ? '?' : '&';
        url += `nocache=${Math.floor(Math.random() * 99999)}`;
        return _jquery.default.ajax({
          'method': 'GET',
          'url': url,
          'beforeSend': xhr => this.beforeSendAuth(xhr)
        }).then(response => {
          if (response.status === 'success') {
            let responseResourceName = this.mapResourceName(resourceName);
            let resource = response.data[responseResourceName][0];
            resolve(this.util.emberify(resource));
          } else if (response.status === 'fail') {
            reject(response.data[0]);
          }
        }, () => {
          reject();
        });
      });
    },

    createOne(resourceName, data) {
      let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
        clearAkamaiCache: true
      };
      return new Ember.RSVP.Promise((resolve, reject) => {
        return _jquery.default.ajax({
          'method': 'POST',
          'url': this.getUrl(resourceName),
          'data': JSON.stringify(data),
          'contentType': 'application/json',
          'beforeSend': xhr => this.beforeSendAuth(xhr)
        }).then(response => {
          if (response.status === 'success') {
            let responseResourceName = this.mapResourceName(resourceName);

            if (options.clearAkamaiCache) {
              if (this.websiteUrl) {
                _jquery.default.ajax({
                  'method': 'POST',
                  'url': `${this.websiteUrl}/api/cache/clear?nocache=${Math.floor(Math.random() * 99999)}`,
                  'contentType': 'application/json',
                  'beforeSend': xhr => this.beforeSendAuth(xhr)
                });
              }

              if (this.uWatchUrl) {
                _jquery.default.ajax({
                  'method': 'POST',
                  'url': `${this.uWatchUrl}/api/cache/clear?nocache=${Math.floor(Math.random() * 99999)}`,
                  'contentType': 'application/json',
                  'beforeSend': xhr => this.beforeSendAuth(xhr)
                });
              }
            }

            resolve(Ember.Object.create(response.data[responseResourceName][0]));
          } else if (response.status === 'fail') {
            reject(response.data[0]);
          }
        }).fail(error => {
          reject();
        });
      });
    },

    replaceOne(resourceName, id, data) {
      let method = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'PUT';
      let options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {
        clearAkamaiCache: true
      };
      return new Ember.RSVP.Promise((resolve, reject) => {
        return _jquery.default.ajax({
          'method': method,
          'url': this.getUrl(resourceName + '/' + id),
          'data': JSON.stringify(data),
          'contentType': 'application/json',
          'beforeSend': xhr => this.beforeSendAuth(xhr)
        }).then(response => {
          if (response.status === 'success') {
            let responseResourceName = this.mapResourceName(resourceName);

            if (options.clearAkamaiCache) {
              if (this.websiteUrl) {
                _jquery.default.ajax({
                  'method': 'POST',
                  'url': `${this.websiteUrl}/api/cache/clear?nocache=${Math.floor(Math.random() * 99999)}`,
                  'contentType': 'application/json',
                  'beforeSend': xhr => this.beforeSendAuth(xhr)
                });
              }

              if (this.uWatchUrl) {
                _jquery.default.ajax({
                  'method': 'POST',
                  'url': `${this.uWatchUrl}/api/cache/clear?nocache=${Math.floor(Math.random() * 99999)}`,
                  'contentType': 'application/json',
                  'beforeSend': xhr => this.beforeSendAuth(xhr)
                });
              }
            }

            if (response.data) {
              resolve(Ember.Object.create(response.data[responseResourceName][0]));
            } else {
              resolve(response);
            }
          } else if (response.status === 'fail') {
            reject(response.data[0]);
          }
        }, () => {
          reject();
        });
      });
    },

    deleteOne(resourceName, id) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        return _jquery.default.ajax({
          'method': 'DELETE',
          'url': this.getUrl(resourceName + '/' + id),
          'beforeSend': xhr => this.beforeSendAuth(xhr)
        }).then(() => {
          if (this.websiteUrl) {
            _jquery.default.ajax({
              'method': 'POST',
              'url': `${this.websiteUrl}/api/cache/clear?nocache=${Math.floor(Math.random() * 99999)}`,
              'contentType': 'application/json',
              'beforeSend': xhr => this.beforeSendAuth(xhr)
            });
          }

          if (this.uWatchUrl) {
            _jquery.default.ajax({
              'method': 'POST',
              'url': `${this.uWatchUrl}/api/cache/clear?nocache=${Math.floor(Math.random() * 99999)}`,
              'contentType': 'application/json',
              'beforeSend': xhr => this.beforeSendAuth(xhr)
            });
          }

          resolve();
        }, () => {
          reject();
        });
      });
    },

    setUrl(url) {
      this.set('url', url);
    },

    getUrl() {
      let route = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      return this.url + (route !== null ? (route.substring(0, 1) !== '/' ? '/' : '') + route : '');
    },

    setWebsiteUrl(url) {
      this.set('websiteUrl', url);
    },

    setUWatchUrl(url) {
      this.set('uWatchUrl', url);
    },

    uploadMedia(file) {
      let dir = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      const formData = new FormData();
      formData.append('file', file);
      formData.append('original_size', true);

      if (dir) {
        formData.append('dir', dir);
      }

      return new Ember.RSVP.Promise((resolve, reject) => {
        _jquery.default.ajax({
          method: 'POST',
          url: this.getUrl('media'),
          data: formData,
          contentType: false,
          processData: false,
          beforeSend: xhr => this.beforeSendAuth(xhr)
        }).then(response => {
          resolve(Ember.Object.create(response));
        }, errorResponse => {
          reject(errorResponse);
        });
      });
    },

    beforeSendAuth(xhr) {
      xhr.setRequestHeader('X-Auth', `Bearer ${this.get('auth.jwt')}`);

      if (_environment.default.BASIC_AUTH_USERNAME) {
        xhr.setRequestHeader('Authorization', `Basic ${btoa(_environment.default.BASIC_AUTH_USERNAME + ':' + _environment.default.BASIC_AUTH_PASSWORD)}`);
      }
    }

  });

  _exports.default = _default;
});