define("universal-cms/ember-quill/tests/templates/components/models-table/dreamworks/date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QokDVT3p",
    "block": "{\"symbols\":[],\"statements\":[[1,0,0,0,[31,2,15,[27,[26,1,\"CallHead\"],[]],[[27,[26,0,\"Expression\"],[\"created_at\"]]],[[\"interval\"],[60000]]]],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"record\",\"moment-from-now\"]}",
    "meta": {
      "moduleName": "universal-cms/ember-quill/tests/templates/components/models-table/dreamworks/date.hbs"
    }
  });

  _exports.default = _default;
});