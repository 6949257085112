define("universal-cms/components/models-table/live", ["exports", "universal-cms/config/environment", "moment"], function (_exports, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    liveStartFormatted: Ember.computed('record.{is_live,live_start,live_end}', function () {
      let dbLiveStart = _moment.default.tz(this.get('record.live_start'), _environment.default.databaseTimezone);

      let userLiveStart = dbLiveStart.clone().tz(_moment.default.tz.guess());
      return userLiveStart.format('YYYY-MM-DD HH:mm:ss');
    }),
    wentLiveText: Ember.computed('record.{is_live,live_start,live_end}', function () {
      let dbLiveStart = _moment.default.tz(this.get('record.live_start'), _environment.default.databaseTimezone);

      let userLiveStart = dbLiveStart.clone().tz(_moment.default.tz.guess());

      let now = _moment.default.tz((0, _moment.default)(), _environment.default.databaseTimezone);

      return `Went live ${now.to(userLiveStart)}`;
    }),
    automaticallyUnpublishedText: Ember.computed('record.{is_live,live_start,live_end}', function () {
      let dbLiveEnd = _moment.default.tz(this.get('record.live_end'), _environment.default.databaseTimezone);

      let userLiveEnd = dbLiveEnd.clone().tz(_moment.default.tz.guess());

      let now = _moment.default.tz((0, _moment.default)(), _environment.default.databaseTimezone);

      return `Was unpublished ${now.to(userLiveEnd)}`;
    })
  });

  _exports.default = _default;
});