define("universal-cms/helpers/format-seo-page-title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatSeoPageTitle = formatSeoPageTitle;

  function formatSeoPageTitle(params) {
    switch (params[0]) {
      case 'home_page':
        return 'Home Page';

      case 'about_page':
        return 'About Page';

      case 'movies_page':
        return 'Movies Page';

      case 'news_and_press_page':
        return 'News & Press Page';

      case 'executive_bios_page':
        return 'Executive Bios Page';

      case 'videos_page':
        return 'Videos Page';

      case 'trailers_page':
        return 'Trailers Page';

      case 'popular_videos_page':
        return 'Popular Videos Page';

      case 'featured_videos_page':
        return 'Featured Videos Page';
    }

    return params;
  }

  var _default = Ember.Helper.helper(formatSeoPageTitle);

  _exports.default = _default;
});