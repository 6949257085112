define("universal-cms/controllers/universal-studios/movies/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    error: false,
    actions: {
      updateMoviesOrder(orderedMovies) {
        let newOrder = [];

        for (let movie of orderedMovies) {
          newOrder.push(movie.get('id'));
        }

        let saveData = {
          movies: newOrder
        };
        this.universalStudiosService.updateOne(`movies`, 'update-order', saveData).then(() => {
          this.notificationService.show('Saved', `Movie has been moved.`);
        }).catch(() => {
          this.notificationService.show('Error', `Could not save movies order.`);
        });
      },

      deleteMovie(movie) {
        movie.set('isDeleting', true);
        return this.universalStudiosService.deleteOne('movies', movie.get('id')).then(() => {
          this.notificationService.show('Movie deleted', `<strong>${movie.get('title')}</strong> was successfully deleted.`);
          this.model.removeObject(movie);
        }, () => {
          movie.set('isDeleting', false);
          this.notificationService.show('Error', `Could not delete movie <strong>${movie.get('title')}</strong>.`);
        });
      }

    }
  });

  _exports.default = _default;
});