define("universal-cms/routes/movies/edit/critical-acclaims", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController(controller, model) {
      this._super(controller, model);

      controller.set('movieId', controller.movie.id);
      this.movieService.getAll('movies/' + controller.movie.id + '/critical-acclaims', {
        sort_by: 'position',
        sort_order: 'asc'
      }).then(items => {
        controller.set('criticalAcclaims', items);
      });
    }

  });

  _exports.default = _default;
});