define("universal-cms/templates/components/dreamworks/page-component-modals/media-bio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4Y5fPSMP",
    "block": "{\"symbols\":[],\"statements\":[[9,\"h2\",true],[10],[1,1,0,0,\"\\n    Media Bio\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[9,\"div\",true],[12,\"class\",\"row\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"div\",true],[12,\"class\",\"col-sm-12 col-md-8\",null],[10],[1,1,0,0,\"\\n        \"],[7,\"pxl-form-field\",[],[[\"@label\",\"@value\"],[\"Section Title\",[27,[26,0,\"AppendSingleId\"],[\"data\",\"sectionTitle\"]]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[5,[27,[26,6,\"BlockHead\"],[]],[[31,213,2,[27,[26,5,\"CallHead\"],[]],[[27,[26,4,\"Expression\"],[\"type\"]],\"franchise\"],null]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"    \"],[9,\"div\",true],[12,\"class\",\"row\",null],[10],[1,1,0,0,\"\\n        \"],[9,\"div\",true],[12,\"class\",\"col-sm-12 col-md-8\",null],[10],[1,1,0,0,\"\\n            \"],[7,\"dreamworks/resource-selector-form\",[],[[\"@label\",\"@type\",\"@selectedId\",\"@languageId\",\"@onChange\"],[\"Movie\",\"movie\",[27,[26,0,\"AppendSingleId\"],[\"data\",\"id\"]],[27,[26,1,\"AppendSingleId\"],[]],[31,536,6,[27,[26,3,\"CallHead\"],[]],[[27,[24,0],[]],[31,544,3,[27,[26,2,\"CallHead\"],[]],[[27,[26,0,\"Expression\"],[\"data\",\"id\"]]],null]],null]]],null],[1,1,0,0,\"\\n        \"],[11],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"pageComponent\",\"languageId\",\"mut\",\"action\",\"page\",\"eq\",\"if\"]}",
    "meta": {
      "moduleName": "universal-cms/templates/components/dreamworks/page-component-modals/media-bio.hbs"
    }
  });

  _exports.default = _default;
});