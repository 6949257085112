define("universal-cms/controllers/universal-brand/pages/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    cookies: Ember.inject.service(),
    error: false,

    init() {
      this._super();

      const jwt = JSON.parse(localStorage.getItem('auth'));
      this.set('universalBrandUrl', jwt.decoded.scopes.mappings.UniversalBrand);
    },

    actions: {
      deletePage(page) {
        return new Ember.RSVP.Promise((resolve, reject) => {
          page.set('isDeleting', true);
          this.universalBrandService.deleteOne('pages', page.get('page_id')).then(() => {
            this.notificationService.show('Page deleted', `Page was successfully deleted.`);
            this.model.removeObject(page);
            resolve();
          }, () => {
            page.set('isDeleting', false);
            this.notificationService.show('Error', `Could not delete page.`);
            reject();
          });
        });
      }

    }
  });

  _exports.default = _default;
});