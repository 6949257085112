define("universal-cms/ember-quill/tests/templates/gabbys-website/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0YYnSx3c",
    "block": "{\"symbols\":[],\"statements\":[[5,[27,[26,2,\"BlockHead\"],[]],[[27,[26,1,\"Expression\"],[]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"    \"],[7,\"pxl-auto-form\",[],[[\"@title\",\"@languages\",\"@selectedLanguage\",\"@userInterface\"],[\"Settings\",[27,[26,0,\"AppendSingleId\"],[\"gabbysWebsiteOptions\",\"languages\"]],[27,[26,0,\"AppendSingleId\"],[\"selectedLanguage\"]],[27,[26,0,\"AppendSingleId\"],[\"user_interface\"]]]],null],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"    \"],[9,\"h1\",true],[12,\"class\",\"page-header\",null],[10],[1,1,0,0,\"\\n        Settings\\n    \"],[11],[1,1,0,0,\"\\n\\n    \"],[7,\"loader-box\",[],[[],[]],null],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"isLoadingLayout\",\"unless\"]}",
    "meta": {
      "moduleName": "universal-cms/ember-quill/tests/templates/gabbys-website/settings.hbs"
    }
  });

  _exports.default = _default;
});