define("universal-cms/components/pxl-asset-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'pxl-asset-preview',
    asset: undefined,
    height: null,
    isLoadingImageUrl: false,
    imageUrl: null,
    imageStyle: Ember.computed('height', function () {
      let css = '';

      if (this.height) {
        css += `height: ${this.height}px`;
      }

      return Ember.String.htmlSafe(css);
    }),
    onAssetChanged: Ember.observer('asset', async function () {
      await this.setImageUrl();
    }),

    async init() {
      this._super(...arguments);

      await this.setImageUrl();
    },

    async setImageUrl() {
      this.set('isLoadingImageUrl', true);

      try {
        if (!isNaN(this.asset)) {
          const asset = await this.dreamworksService.getOne('assets', this.asset);
          this.set('imageUrl', asset.url);
        } else {
          this.set('imageUrl', this.asset);
        }
      } catch (error) {
        this.set('loadImageError', `Could not load asset "${this.asset}".`);
      } finally {
        this.set('isLoadingImageUrl', false);
      }
    }

  });

  _exports.default = _default;
});