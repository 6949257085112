define("universal-cms/components/file-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    model: null,
    uploadOnChange: false,
    isUploading: false,
    uploadDir: null,
    uploadedMedia: null,
    actions: {
      async onFileChange(event) {
        const file = event.target.files[0]; // Upload to server

        if (this.uploadOnChange) {
          try {
            this.set('isUploading', true);
            const uploadedMedia = (await this.movieService.uploadMedia(file, this.uploadDir)).data.media;
            this.set('uploadedMedia', uploadedMedia);
            this.model.set(this.field, uploadedMedia.url);
            this.notificationService.show('File Uploaded', `File has been uploaded.`);
          } catch (uploadError) {
            console.log('uploadError', uploadError);
            this.notificationService.show('Error', `Could not upload file.`);
          } finally {
            this.set('isUploading', false);
          }
        } else {
          const reader = new FileReader();

          reader.onload = () => {
            this.model.set(this.field, file);
          };

          reader.readAsDataURL(file);
        }
      }

    }
  });

  _exports.default = _default;
});