define("universal-cms/components/brand/link-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    model: null,
    internalPages: Ember.A(),
    linkColumn: 'link',
    typeColumn: 'link_type',
    selectedType: Ember.computed('typeColumn', function () {
      return this.get(`model.${this.typeColumn}`);
    }),
    actions: {
      setInternalPage(page) {
        this.set(`model.${this.linkColumn}`, page !== null ? `/${page.get('slug')}` : null);
      }

    }
  });

  _exports.default = _default;
});