define("universal-cms/ember-quill/tests/templates/universal-brand/general/footer-link/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T0pw8N13",
    "block": "{\"symbols\":[],\"statements\":[[5,[27,[26,5,\"BlockHead\"],[]],[[27,[26,4,\"Expression\"],[]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"    \"],[9,\"h1\",true],[12,\"class\",\"page-header\",null],[10],[1,1,0,0,\"\\n        \"],[1,0,0,0,[27,[26,0,\"AppendSingleId\"],[]]],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\\n    \"],[7,\"brand/menu-item-form\",[],[[\"@type\",\"@menuItem\",\"@pages\",\"@businesses\",\"@showBackButton\",\"@onAdded\"],[[27,[26,1,\"AppendSingleId\"],[]],[27,[26,2,\"AppendSingleId\"],[\"menuItem\"]],[27,[26,2,\"AppendSingleId\"],[\"pages\"]],[27,[26,2,\"AppendSingleId\"],[\"businesses\"]],true,[31,239,6,[27,[26,3,\"CallHead\"],[]],[[27,[24,0],[]],\"onAdded\"],null]]],null],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"    \"],[9,\"h1\",true],[12,\"class\",\"page-header\",null],[10],[1,1,0,0,\"\\n        Error\\n    \"],[11],[1,1,0,0,\"\\n\\n    \"],[9,\"div\",true],[12,\"class\",\"alert alert-danger alert-table-load-error\",null],[10],[1,1,0,0,\"\\n        Could not load footer link.\\n    \"],[11],[1,1,0,0,\"\\n\\n    \"],[7,\"link-to\",[[23,\"class\",\"btn btn-default\",null]],[[\"@route\"],[\"universalBrand.general.list\"]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"Back\"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"heading\",\"type\",\"model\",\"action\",\"error\",\"unless\"]}",
    "meta": {
      "moduleName": "universal-cms/ember-quill/tests/templates/universal-brand/general/footer-link/edit.hbs"
    }
  });

  _exports.default = _default;
});