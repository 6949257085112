define("universal-cms/controllers/uwatch/resources/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    util: Ember.inject.service(),
    actions: {
      updateResource(resource) {
        let showNotification = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
        let resourceName = this.get('model.resourceName');
        resource.set('isSaving', true);
        return this.uwatchService.updateResource('uwatch', resourceName, resource, resourceName).then(() => {
          if (showNotification === true) {
            this.notificationService.show('Saved', 'Resource was successfully saved.');
            this.get('target._routerMicrolib').refresh();
          }

          resource.set('isSaving', false);
        }, errorResponse => {
          this.notificationService.show('Error', this.util.toErrorString(errorResponse));
          resource.set('isSaving', false);
        });
      },

      newResource(resource) {
        let resourceName = this.get('model.resourceName'); // use resource service to add the resource in the api
        // paramaters specify table, resource name, spefic resource object, resource name

        return this.uwatchService.newResource('uwatch', resourceName, resource, resourceName).then(resources => {
          let model = this.model;
          model.resources.pushObject(resources[0]);
          this.notificationService.show('Created', 'Resource was successfully created.');
          this.get('target._routerMicrolib').refresh();
        }, errorResponse => {
          if (errorResponse.error === 'RELATIONSHIP_ALREADY_EXIST') {
            if (resourceName === 'featured-movies') {
              this.notificationService.show('Error', 'Movie is already featured.');
            } else {
              this.notificationService.show('Error', 'Relationship already exists.');
            }
          } else {
            this.notificationService.show('Error', this.util.toErrorString(errorResponse));
          }
        });
      },

      deleteResource(resource) {
        let resourceName = this.get('model.resourceName');
        resource.set('isDeleting', true);
        this.uwatchService.deleteResource('uwatch', resourceName, resource).then(() => {
          let model = this.model;
          model.resources.removeObject(resource);
          this.notificationService.show('Deleted', 'Resource was successfully deleted.');
          this.get('target._routerMicrolib').refresh();
        }, errorResponse => {
          this.notificationService.show('Error', this.util.toErrorString(errorResponse));
          resource.set('isDeleting', false);
        });
      },

      onSavePositions(data) {
        const resourceName = this.get('model.resourceName');
        let savePromises = [];
        let position = 0; // Set positions

        let resources = data.resources;

        for (let resource of data.resources) {
          resource.set('position', position++);
        }

        for (const resource of resources) {
          savePromises.push(new Ember.RSVP.Promise((resolve, reject) => {
            return this.uwatchService.updateResource('uwatch', resourceName, resource, resourceName).then(result => {
              resolve();
            }, () => {
              reject();
            });
          }));
        }

        return Ember.RSVP.Promise.all(savePromises).then(() => {
          this.get('target._routerMicrolib').refresh();
          this.notificationService.show('Saved', 'Resource positions saved!');
        }, error => {
          this.notificationService.show('Error', 'Could not change resource positions.');
        });
      }

    }
  });

  _exports.default = _default;
});