define("universal-cms/controllers/dashboard/documentation/edit-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    isSaving: false,
    title: Ember.computed('model.title', function () {
      return this.get('model.title');
    }),
    actions: {
      save() {
        let title = this.title;
        let html = this.get('model.html');

        if (title === '') {
          this.notificationService.show('Error', `Specify title of page.`);
          return;
        }

        this.set('isSaving', true);
        let itemIndexInParentModel = this.parentModel.findIndex(item => {
          return item.get('id') === this.get('model.id');
        });
        this.auth.replaceOne('documentation-pages', this.get('model.id'), {
          title: title,
          html: html
        }).then(replacedDocumentationPage => {
          this.set('model', replacedDocumentationPage);

          if (itemIndexInParentModel !== -1) {
            this.parentModel[itemIndexInParentModel].setProperties({
              title: title,
              html: html
            });
          }

          this.set('isSaving', false);
          this.notificationService.show('Saved', `Page <strong>${replacedDocumentationPage.get('title')}</strong> was successfully saved.`);
        }, error => {
          this.set('isSaving', false);
          this.notificationService.show('Error', 'Could not save page.');
        });
      }

    }
  });

  _exports.default = _default;
});