define("universal-cms/components/preview-token-form", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    heading: Ember.computed(function () {
      return this.mode === 'edit' ? 'Edit' : 'Add';
    }),
    isSaving: false,
    saveButtonText: Ember.computed('isSaving', function () {
      return this.isSaving ? this.mode === 'edit' ? 'Saving...' : 'Adding...' : this.mode === 'edit' ? 'Save' : 'Add';
    }),
    time: Ember.computed('previewToken.time', function () {
      return this.mode === 'edit' ? (0, _moment.default)(this.get('previewToken.time').date) : null;
    }),
    allowTimeChange: Ember.computed('previewToken.allowTimeChange', function () {
      return this.mode === 'edit' ? this.get('previewToken.allow_time_change') : false;
    }),
    password: '',
    initialPage: Ember.computed('previewToken.initialPage', function () {
      return this.mode === 'edit' ? this.get('previewToken.initial_page') : '';
    }),
    allowPageNavigation: Ember.computed('previewToken.allowPageNavigation', function () {
      return true; //return (this.get('mode') === 'edit' ? this.get('previewToken.allow_page_navigation') : false);
    }),

    generateKey() {
      const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let text = '';

      for (let i = 0; i < 32; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      }

      return text;
    },

    actions: {
      save() {
        let time = this.time;
        let password = this.password;
        let allowTimeChange = this.allowTimeChange;
        let initialPage = this.initialPage;
        let allowPageNavigation = this.allowPageNavigation;

        if (time === null) {
          this.notificationService.show('Error', 'Choose time.');
          return;
        }

        if (this.mode === 'add' && password === '') {
          this.notificationService.show('Error', 'Enter password.');
          return;
        }

        this.set('isSaving', true);
        const key = this.mode === 'edit' ? this.get('previewToken.key') : this.generateKey();
        let saveData = {
          key: key,
          time: (0, _moment.default)(time).format('YYYY-MM-DD HH:mm:ss'),
          allow_time_change: allowTimeChange ? 1 : 0,
          password: password,
          initial_page: initialPage,
          allow_page_navigation: allowPageNavigation ? 1 : 0
        };

        if (this.mode === 'edit') {
          saveData.id = this.get('previewToken.id');
        }

        this.save(saveData).then(previewToken => {
          if (this.mode === 'add') {
            this.set('allowTimeChange', false);
            this.set('initialPage', '');
            this.set('allowPageNavigation', false);
          }

          this.set('password', '');
          this.set('isSaving', false);
        }, error => {
          this.set('isSaving', false);
        });
      }

    }
  });

  _exports.default = _default;
});