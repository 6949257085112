define("universal-cms/ember-quill/tests/templates/option-groups/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g+izM+c8",
    "block": "{\"symbols\":[],\"statements\":[[9,\"h1\",true],[12,\"class\",\"page-header\",null],[10],[1,1,0,0,\"\\n    Option Groups\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[7,\"models-table\",[],[[\"@data\",\"@columns\",\"@showColumnsDropdown\",\"@useFilteringByColumns\",\"@multipleColumnsSorting\",\"@filteringIgnoreCase\",\"@columnComponents\"],[[27,[26,0,\"AppendSingleId\"],[]],[27,[26,1,\"AppendSingleId\"],[]],false,false,false,true,[31,282,4,[27,[26,4,\"CallHead\"],[]],null,[[\"manage\"],[[31,316,9,[27,[26,3,\"CallHead\"],[]],[\"models-table/option-group-actions\"],[[\"deleteOptionGroup\"],[[31,393,6,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[]],\"deleteOptionGroup\"],null]]]]]]]]],null],[1,1,0,0,\"\\n\\n\"],[9,\"div\",true],[12,\"class\",\"panel panel-inverse\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"div\",true],[12,\"class\",\"panel-heading\",null],[10],[1,1,0,0,\"\\n        \"],[9,\"h4\",true],[12,\"class\",\"panel-title\",null],[10],[1,1,0,0,\"Add\"],[11],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\\n    \"],[9,\"div\",true],[12,\"class\",\"panel-body\",null],[10],[1,1,0,0,\"\\n        \"],[7,\"option-group-form\",[],[[\"@mode\",\"@optionGroup\",\"@createOptionGroup\"],[\"add\",[27,[26,0,\"AppendSingleId\"],[]],[31,671,6,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[]],\"createOptionGroup\"],null]]],null],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"tableColumns\",\"action\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "universal-cms/ember-quill/tests/templates/option-groups/list.hbs"
    }
  });

  _exports.default = _default;
});