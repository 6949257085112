define("universal-cms/controllers/universal-brand/pages/edit-leader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    error: false,
    heading: Ember.computed('isNew', 'model.leader.name', function () {
      return this.isNew === true ? 'Add Leader' : this.get('model.leader.name');
    }),
    isSaving: false,
    saveButtonText: Ember.computed('isNew', 'isSaving', function () {
      if (this.isNew === true) {
        return this.isSaving === true ? 'Adding...' : 'Add';
      } else {
        return this.isSaving === true ? 'Saving...' : 'Save';
      }
    }),
    actions: {
      save() {
        this.set('isSaving', true);
        let saveData = this.get('model.leader').getProperties('thumbnail_image_id', 'first_name', 'last_name', 'title', 'title_line_2');
        saveData.page_version_id = this.pageVersionId;

        if (this.isNew) {
          this.universalBrandService.createOne('leaders', saveData).then(createResponse => {
            if (typeof createResponse.errors === 'undefined') {
              this.notificationService.show('Leader Added', `<strong>${createResponse.get('name')}</strong> has been added.`);
              this.target.transitionTo(this.routeName, createResponse.get('leader_id'));
            } else {
              this.notificationService.show('Error', this.universalBrandService.formatErrorResponse(createResponse.errors));
            }
          }, createError => {
            this.notificationService.show('Error', createError);
          }).finally(() => {
            this.set('isSaving', false);
          });
        } else {
          this.universalBrandService.updateOne('leaders', this.get('model.leader.leader_id'), saveData, true).then(updateResponse => {
            if (typeof updateResponse.errors === 'undefined') {
              this.notificationService.show('Leader Updated', `<strong>${updateResponse.get('name')}</strong> has been updated.`);
              this.set('model.leader', updateResponse);
            } else {
              this.notificationService.show('Error', this.universalBrandService.formatErrorResponse(updateResponse.errors));
            }
          }, updateError => {
            this.notificationService.show('Error', updateError);
          }).finally(() => {
            this.set('isSaving', false);
          });
        }
      }

    }
  });

  _exports.default = _default;
});