define("universal-cms/templates/components/models-table/universal-studios/feature-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fH7cDHyp",
    "block": "{\"symbols\":[],\"statements\":[[7,\"link-to\",[[23,\"class\",\"btn btn-success btn-xs\",null]],[[\"@route\",\"@model\"],[\"universalStudios.features.edit\",[27,[26,0,\"AppendSingleId\"],[\"id\"]]]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"Edit\"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\\n\"],[5,[27,[26,2,\"BlockHead\"],[]],[[27,[26,0,\"Expression\"],[\"isDeleting\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"    \"],[9,\"a\",true],[12,\"href\",\"javascript:\",null],[12,\"class\",\"btn btn-danger btn-xs disabled\",null],[10],[1,1,0,0,\"Deleting...\"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"    \"],[9,\"a\",false],[23,\"href\",\"javascript:\",null],[23,\"class\",\"btn btn-danger btn-xs\",null],[3,0,0,[27,[26,1,\"ModifierHead\"],[]],[[27,[24,0],[]],\"sendAction\",\"deleteFeature\",[27,[26,0,\"Expression\"],[]]],null],[10],[1,1,0,0,\"Delete\"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"record\",\"action\",\"if\"]}",
    "meta": {
      "moduleName": "universal-cms/templates/components/models-table/universal-studios/feature-actions.hbs"
    }
  });

  _exports.default = _default;
});