define("universal-cms/services/gabbys-website", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    options: null,

    setOptions(options) {
      Ember.set(this, 'options', options);
    }

  });

  _exports.default = _default;
});