define("universal-cms/components/pxl-form-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-group pxl-form-field-group'],
    classNameBindings: ['required'],
    label: null,
    placeholder: null,
    value: '',
    prefix: null,
    suffix: null,
    havePrefixOrSuffix: Ember.computed.or('prefix', 'suffix'),
    required: false,
    rows: 1,
    html: false,
    disabled: false,
    multipleRows: Ember.computed('rows', function () {
      return this.rows > 1;
    }),
    wysiwygRows: Ember.computed('rows', function () {
      return this.rows / 2;
    }),
    actions: {
      onChange(e) {
        const newValue = typeof e === 'object' ? e.target.value : e;
        this.set('value', newValue);
      },

      onBlur() {
        if (typeof this.onBlur === 'function') {
          this.onBlur(this.value);
        }
      }

    }
  });

  _exports.default = _default;
});