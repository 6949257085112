define("universal-cms/components/dreamworks/resource-toolbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    messageBus: Ember.inject.service('message-bus'),
    util: Ember.inject.service('util'),
    routing: Ember.inject.service('-routing'),
    isNew: false,
    resourceType: null,
    resource: null,
    resourceData: null,
    resourceSaveData: null,
    pageTypeOptions: null,
    page: null,
    pageData: null,
    pageSaveData: null,
    text: null,
    language: null,
    isSaving: false,
    isRevertingUnpublishedChanges: false,
    isPublishing: false,
    isUnpublishing: false,
    isGabbysWebsitePage: Ember.computed('routing.currentRouteName', function () {
      const serviceMappings = this.auth.decoded.scopes.mappings;
      const serviceMappingsKeys = Object.keys(serviceMappings);
      const isValidServiceMappingKeys = serviceMappingsKeys.includes('DreamWorks') && serviceMappingsKeys.includes('GabbysWebsite');
      return this.routing.currentRouteName.startsWith('gabbys-website') && isValidServiceMappingKeys;
    }),
    previewUrl: Ember.computed('page.preview_url', function () {
      if (this.isGabbysWebsitePage) {
        return this.page.preview_url;
      } else {
        if (this.page.preview_url.startsWith('http://dreamworksdev.films.apps.nbcuni.com')) {
          return this.page.preview_url;
        } else {
          return `${this.page.preview_url}?preview=${this.auth.jwt}`;
        }
      }
    }),
    hasUnpublishedChanges: Ember.computed('pageData', function () {
      return this.pageData !== null && this.pageData.tmp_data !== null;
    }),
    isLive: Ember.computed('hasUnpublishedChanges', 'isNew', function () {
      return !this.hasUnpublishedChanges && !this.isNew && this.page.publish_at;
    }),
    statusText: Ember.computed('isLive', function () {
      return this.isLive ? 'Live' : 'Unpublished Changes';
    }),
    showPreviewButton: Ember.computed('hasUnpublishedChanges', 'page.publish_at', function () {
      return this.hasUnpublishedChanges || !this.page.publish_at;
    }),
    showRevertButton: Ember.computed('hasUnpublishedChanges', 'page.publish_at', function () {
      return this.hasUnpublishedChanges && this.page.publish_at;
    }),
    showPublishButton: Ember.computed('hasUnpublishedChanges', function () {
      // if has unpbulished hcanges, or if it's unpublished
      return this.hasUnpublishedChanges || !this.page.publish_at;
    }),
    isLoading: Ember.computed('isSaving', 'isRevertingUnpublishedChanges', 'isPublishing', function () {
      return this.isSaving || this.isRevertingUnpublishedChanges || this.isPublishing;
    }),
    saveButtonDisabled: Ember.computed('isLoading', function () {
      return this.isLoading;
    }),
    resourceTypeTitle: Ember.computed('resourceType', function () {
      return this.dreamworksService.resourceTypeToTitle(this.resourceType);
    }),
    resourceTypeDataEndpoint: Ember.computed('resourceTypeKey', 'text.singularLowerCase', function () {
      return `${this.resourceTypeKey || this.text.singularLowerCase}-data`;
    }),

    async addPageOrResource() {
      try {
        if (!this.resourceSaveData) {
          throw new Error('Missing "resourceSaveData".');
        }

        const languageId = this.language ? parseInt(this.language) : null;
        delete this.resourceSaveData.isNew;
        const formattedPageSaveData = this.formatPageSaveData(); // Add name and slug to resourceSaveData from pageSaveData

        let addedResource;
        this.resourceSaveData.setProperties({
          name: formattedPageSaveData.name,
          slug: formattedPageSaveData.name.dasherize()
        }); // let resourceUrl = this.resourceType;

        if (this.resourceType === 'pages') {
          // if (this.language) {
          if (this.isGabbysWebsitePage) {
            this.resourceSaveData.set('language_id', parseInt(this.language));
            this.resourceSaveData.set('type', 'gabbys_website');
          }

          addedResource = await this.dreamworksService.createOne(this.resourceType, this.resourceSaveData);
        } else {
          addedResource = await this.dreamworksService.createOne(this.resourceType, {
            name: this.pageSaveData.name,
            slug: this.pageSaveData.slug,
            language_id: languageId
          });
          this.set('resource', addedResource); // Add resource-data row

          await this.dreamworksService.createOne(this.resourceTypeDataEndpoint, {
            resource_id: addedResource.id,
            resource_type: addedResource.page.type,
            language_id: languageId,
            tmp_data: this.resourceSaveData
          });
        }

        await this.updateOrCreatePageData(addedResource);
        this.notificationService.show('Resource Added', `Resource <strong>${addedResource.name}</strong> has been added.`);
        await this.onAdded(this.resourceType, addedResource);
      } catch (addError) {
        this.onError(addError);
      } finally {
        this.set('isSaving', false);
      }
    },

    async updatePageOrResource() {
      try {
        const formattedPageSaveData = this.formatPageSaveData(); // Update resource

        if (this.resource) {
          // Get name and slug from pageSaveData
          this.resourceSaveData.name = formattedPageSaveData.name;
          this.resourceSaveData.slug = formattedPageSaveData.slug || formattedPageSaveData.name.dasherize();
          const updatedResource = await this.dreamworksService.updateOne(this.resourceType, this.resource.id);
          const resourceDataSaveData = {
            tmp_data: this.resourceSaveData
          };

          if (this.resourceData.id) {
            await this.dreamworksService.updateOne(this.resourceTypeDataEndpoint, this.resourceData.id, resourceDataSaveData);
          } else {
            resourceDataSaveData.resource_id = this.resource.id;
            resourceDataSaveData.resource_type = this.resource.page.type;
            await this.dreamworksService.createOne(this.resourceTypeDataEndpoint, resourceDataSaveData);
          }

          this.set('resource.name', updatedResource.name);
        } // Update page


        if (this.pageData.isNew === true) {
          await this.dreamworksService.createOne('page-data', {
            resource_id: this.resource.id,
            // Page id
            language_id: this.language ? parseInt(this.language) : null,
            tmp_data: formattedPageSaveData
          });
        } else {
          await this.dreamworksService.updateOne('page-data', this.pageData.id, {
            tmp_data: formattedPageSaveData
          });
        }

        this.notificationService.show('Saved', `Changes has been saved.`);
        await this.onSaved();
      } finally {
        this.set('isSaving', false);
      }
    },

    async updateOrCreatePageData(resource) {
      const formattedPageSaveData = this.formatPageSaveData();

      if (resource.page) {
        // If resource already has a page, update
        await this.dreamworksService.updateOne('page-data', resource.page.data.id, {
          tmp_data: formattedPageSaveData
        });
      } else {
        // If resource does not have a page, create page data
        await this.dreamworksService.createOne('page-data', {
          resource_id: resource.id,
          // Page id
          language_id: this.language ? parseInt(this.language) : null,
          tmp_data: formattedPageSaveData
        });
      }
    },

    formatPageSaveData() {
      let clonedPageSaveData = this.util.cloneObject(this.pageSaveData);

      if (clonedPageSaveData.slug.charAt(0) !== '/') {
        clonedPageSaveData.set('slug', `/${clonedPageSaveData.slug}`);
      }

      if (!clonedPageSaveData.components) {
        clonedPageSaveData.components = [];
      }

      if (this.resource) {
        if (!this.resource.page) {
          throw new Error('Resource does not have a page.');
        }

        const slugPrefix = this.pageTypeOptions && this.pageTypeOptions.slug_prefix ? this.pageTypeOptions.slug_prefix : '';
        const slug = `/${slugPrefix}${clonedPageSaveData.slug}`;
        clonedPageSaveData.set('slug', slug); // Insert initial components

        if (this.isNew) {
          switch (this.resource.page.type) {
            case 'gabbys_website_character':
              {
                clonedPageSaveData.components.push({
                  key: 'gabbys-website-character-intro',
                  data: []
                });
                clonedPageSaveData.components.push({
                  key: 'gabbys-website-image-gallery',
                  data: {
                    type: 'page_resource',
                    resource_type: 'gabbys_website_character'
                  }
                });
                clonedPageSaveData.components.push({
                  key: 'gabbys-website-media-carousel',
                  data: {
                    title: 'Featured Episodes',
                    cta_text: 'More Episodes',
                    type: 'custom',
                    custom_resource_type: {
                      text: 'Episode',
                      value: 'gabbys_website_episode',
                      apiEndpoint: 'gabbys-website-episodes',
                      sectionProperty: 'GabbysWebsiteEpisode'
                    },
                    custom_resource_ids: []
                  }
                });
                clonedPageSaveData.components.push({
                  key: 'gabbys-website-character-carousel',
                  data: []
                });
                break;
              }

            case 'gabbys_website_event':
              {
                clonedPageSaveData.components.push({
                  key: 'gabbys-website-event-intro',
                  data: []
                });
                clonedPageSaveData.components.push({
                  key: 'gabbys-website-event-dates',
                  data: {
                    title: 'Event Dates'
                  }
                });
                break;
              }
          }
        }
      }

      return clonedPageSaveData;
    },

    actions: {
      async save() {
        this.set('isSaving', true);

        if (this.isNew) {
          await this.addPageOrResource();
        } else {
          await this.updatePageOrResource();
        }
      },

      async revertUnpublishedChanges() {
        if (!confirm('Are you sure you want to revert all unpublished changes?')) {
          return;
        }

        try {
          this.set('isRevertingUnpublishedChanges', true);
          await this.dreamworksService.post(`api/resource-data/${this.pageData.id}/revert`);

          if (this.resourceData.id) {
            await this.dreamworksService.post(`api/resource-data/${this.resourceData.id}/revert`);
          }

          this.notificationService.show('Reverted', 'Unpublished changes has been reverted.');
          await this.onRevertedUnpublishedChanges();
        } catch (error) {
          this.notificationService.show('Error', 'Could not revert unpublished changes.');
        } finally {
          this.set('isRevertingUnpublishedChanges', false);
        }
      },

      async publish() {
        if (!confirm('Are you sure you want to publish changes?')) {
          return;
        }

        try {
          this.set('isPublishing', true); // Save page data

          await this.dreamworksService.updateOne('page-data', this.pageData.id, {
            tmp_data: this.formatPageSaveData()
          });
          await this.dreamworksService.post(`api/resource-data/${this.pageData.id}/publish`); // Save resource data

          if (this.resource) {
            this.resourceSaveData.set('slug', this.pageSaveData.get('slug'));
            await this.dreamworksService.updateOne(this.resourceTypeDataEndpoint, this.resourceData.id, {
              tmp_data: this.resourceSaveData
            });
            await this.dreamworksService.post(`api/resource-data/${this.resourceData.id}/publish`);
          } // Clear Akamai cache


          await this.dreamworksService.clearCloudflareCache();
          this.notificationService.show('Published', 'Changes has been published.');
          await this.onPublishedChanges();
        } catch (error) {
          console.error('error', error);
          this.notificationService.show('Error', 'Could not publish changes.');
        } finally {
          this.set('isPublishing', false);
        }
      },

      revertUnsavedChanges() {
        if (!confirm('Are you sure you want to revert your unsaved changes?')) {
          return;
        }

        this.messageBus.publish('revert');
      },

      async unpublish() {
        if (!confirm('Are you sure you want to unpublish changes?')) {
          return;
        }

        try {
          this.set('isUnpublishing', true);
          await this.dreamworksService.post(`api/resource-data/${this.pageData.id}/unpublish`); // Clear Akamai cache

          await this.dreamworksService.clearCloudflareCache();
          await this.onUnpublished();
          this.notificationService.show('Unpublished', 'Changes has been unpublished.');
        } catch (error) {
          console.error('error', error);
          this.notificationService.show('Error', 'Could not unpublish changes.');
        } finally {
          this.set('isUnpublishing', false);
        }
      }

    }
  });

  _exports.default = _default;
});