define("universal-cms/controllers/movies/edit/genres", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    editMovieController: Ember.inject.controller('movies/edit'),
    movie: Ember.computed.reads('editMovieController.model'),
    addedGenres: Ember.A(),
    hasAddedGenres: false,
    nonAddedGenres: Ember.A(),
    selectedGenre: Ember.computed('selectedGenre', function () {
      return undefined;
    }),
    isLoading: true,
    isAdding: Ember.computed('isAdding', function () {
      return false;
    }),
    isDeleting: Ember.computed('isDeleting', function () {
      return true;
    })
  });

  _exports.default = _default;
});