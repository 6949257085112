define("universal-cms/routes/dreamworks/navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    async model() {
      const controller = this.controllerFor(this.routeName);
      controller.set('isLoadingLayout', true);
      this.is_saving = true;

      try {
        const options = await this.dreamworksService.getOptions('settings');
        const settings = await this.dreamworksService.getMany('settings');
        const headerResourcesSettings = settings.find(setting => setting.key === 'header_resources');

        if (headerResourcesSettings && headerResourcesSettings.value) {
          // Movies
          if (headerResourcesSettings.value.header_movies) {
            if (headerResourcesSettings.value.header_movies.in_theaters) {
              controller.set('headerResources.header_movies.in_theaters', headerResourcesSettings.value.header_movies.in_theaters);
            }

            if (headerResourcesSettings.value.header_movies.coming_soon) {
              controller.set('headerResources.header_movies.coming_soon', headerResourcesSettings.value.header_movies.coming_soon);
            }

            if (headerResourcesSettings.value.header_movies.home_entertainment) {
              controller.set('headerResources.header_movies.home_entertainment', headerResourcesSettings.value.header_movies.home_entertainment);
            }
          } // TV shows


          if (headerResourcesSettings.value.header_tv_shows) {
            if (headerResourcesSettings.value.header_tv_shows.now_streaming) {
              controller.set('headerResources.header_tv_shows.now_streaming', headerResourcesSettings.value.header_tv_shows.now_streaming);
            }

            if (headerResourcesSettings.value.header_tv_shows.coming_soon) {
              controller.set('headerResources.header_tv_shows.coming_soon', headerResourcesSettings.value.header_tv_shows.coming_soon);
            }
          } // Franchises


          if (headerResourcesSettings.value.header_franchises) {
            controller.set('headerResources.header_franchises', headerResourcesSettings.value.header_franchises);
          } // Games


          if (headerResourcesSettings.value.header_games) {
            if (headerResourcesSettings.value.header_games.games) {
              controller.set('headerResources.header_games.games', headerResourcesSettings.value.header_games.games);
            }

            if (headerResourcesSettings.value.header_games.mobile_apps) {
              controller.set('headerResources.header_games.mobile_apps', headerResourcesSettings.value.header_games.mobile_apps);
            }

            if (headerResourcesSettings.value.header_games.sites) {
              controller.set('headerResources.header_games.sites', headerResourcesSettings.value.header_games.sites);
            }
          }
        }

        return Ember.RSVP.hash({
          'user_interface': options.user_interface
        });
      } catch (error) {
        console.log('error', error);
      } finally {
        controller.set('isLoadingLayout', false);
      }
    }

  });

  _exports.default = _default;
});