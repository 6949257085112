define("universal-cms/components/dreamworks/resource-editor", ["exports", "universal-cms/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    auth: Ember.inject.service(),
    router: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),
    isNew: undefined,
    isRefreshingModel: undefined,
    resourceType: undefined,
    resourceOptions: undefined,
    resource: undefined,
    resourceData: undefined,
    resourceSaveData: undefined,
    resourceTypeKey: undefined,
    pageTypeOptions: undefined,
    page: undefined,
    pageData: undefined,
    pageSaveData: undefined,
    text: undefined,
    titleBackup: undefined,
    // Keep title in variable to fix .reload() empty title issue
    title: Ember.computed('isNew', 'text.singular', 'resourceType', 'resource.name', 'page.name', function () {
      const title = this.isNew ? `Add ${this.text.singular}` : this.resourceType === 'pages' ? this.pageData.save_data.name : this.resource.name;

      if (title) {
        this.set('titleBackup', title);
      }

      return title;
    }),
    externalBreadcrumbLink: Ember.computed('page.url', function () {
      return this.page.url;
    }),
    showExternalBreadcrumbLink: Ember.computed('isNew', 'page.publish_at', function () {
      return !this.isNew && this.page.publish_at !== null;
    }),
    language: null,
    selectedLanguage: null,
    selectedLanguageLocaleCode: null,
    selectedLanguageId: null,
    languages: Ember.computed('resourceOptions.languages', function () {
      return this.resourceOptions.languages || [];
    }),
    isGabbysWebsitePage: Ember.computed('routing.currentRouteName', function () {
      const serviceMappings = this.auth.decoded.scopes.mappings;
      const serviceMappingsKeys = Object.keys(serviceMappings);
      const isValidServiceMappingKeys = serviceMappingsKeys.includes('DreamWorks') && serviceMappingsKeys.includes('GabbysWebsite');
      return this.routing.currentRouteName.startsWith('gabbys-website') && isValidServiceMappingKeys;
    }),
    currentLanguageFlagUrl: Ember.computed('selectedLanguageLocaleCode', function () {
      return `${this.authUrl}/images/flags/${this.selectedLanguageLocaleCode}.png`;
    }),

    init() {
      this._super(...arguments);

      this.set('authUrl', _environment.default.authUrl);
      this.set('selectedLanguageLocaleCode', this.language ? this.language.locale_code : this.selectedLanguage ? this.selectedLanguage : null);
      this.set('selectedLanguageId', this.language ? this.language.id : this.selectedLanguage ? this.selectedLanguage.id : null);
    },

    actions: {
      async onAdded(resourceType, addedResource) {
        this.onAdded(resourceType, addedResource);
      },

      async onSaved() {
        this.onSaved();
      },

      async onError(error) {
        this.onError(error);
      },

      async onRevertedUnpublishedChanges() {
        this.onRevertedUnpublishedChanges();
      },

      async onPublishedChanges() {
        this.onPublishedChanges();
      },

      async onUnpublished() {
        this.onUnpublished();
      },

      revertUnsavedChanges() {
        if (typeof this.onRevertUnsavedChanges === 'function') {
          this.onRevertUnsavedChanges();
        }
      },

      goBack() {
        this.router.transitionTo(`${this.isGabbysWebsitePage ? 'gabbys-website' : 'dreamworks'}.${this.resourceType}.list`);
      },

      selectLanguage(event) {
        const selectedLanguageLocaleCode = event.target.value;
        this.set('selectedLanguageLocaleCode', selectedLanguageLocaleCode);
        this.set('selectedLanguageId', this.languages.find(language => language.locale_code === selectedLanguageLocaleCode).id);

        if (this.onLanguageChanged) {
          this.onLanguageChanged({
            languageLocaleCode: selectedLanguageLocaleCode
          });
        }
      }

    }
  });

  _exports.default = _default;
});