define("universal-cms/controllers/option-groups/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      updateOptionGroup(data) {
        return this.movieService.replaceOne('option-groups', data.optionGroup.id, {
          name: data.data.name,
          description: data.data.description
        }).then(replacedOptionGroup => {
          this.notificationService.show('Option group saved', `<strong>${replacedOptionGroup.name}</strong> has been saved.`);
          data.optionGroup.setProperties({
            name: replacedOptionGroup.get('name'),
            description: replacedOptionGroup.get('description')
          });
          return replacedOptionGroup;
        }, error => {
          this.notificationService.show('Error', 'Could not save option group.');
        });
      }

    }
  });

  _exports.default = _default;
});