define("universal-cms/templates/components/object-bin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9bGzPe+L",
    "block": "{\"symbols\":[\"obj\",\"&default\"],\"statements\":[[5,[27,[26,6,\"BlockHead\"],[]],null,[[\"action\"],[[31,35,6,[27,[26,5,\"CallHead\"],[]],[[27,[24,0],[]],\"handleObjectDropped\"],null]]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[9,\"div\",true],[12,\"class\",\"object-bin-title\",null],[10],[1,0,0,0,[27,[26,1,\"AppendSingleId\"],[]]],[11],[1,1,0,0,\"\\n  \"],[9,\"br\",true],[10],[11],[1,1,0,0,\"\\n\"],[5,[27,[26,4,\"BlockHead\"],[]],[[31,0,0,[27,[26,3,\"CallHead\"],[]],[[31,0,0,[27,[26,3,\"CallHead\"],[]],[[27,[26,2,\"Expression\"],[]]],null]],null]],null,[[\"default\"],[{\"statements\":[[5,[27,[26,0,\"BlockHead\"],[]],null,[[\"action\",\"content\"],[\"handleObjectDragged\",[27,[24,1],[]]]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"      \"],[16,2,[[27,[24,1],[]]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"draggable-object\",\"name\",\"model\",\"-track-array\",\"each\",\"action\",\"draggable-object-target\"]}",
    "meta": {
      "moduleName": "universal-cms/templates/components/object-bin.hbs"
    }
  });

  _exports.default = _default;
});