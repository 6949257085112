define("universal-cms/helpers/resource-mapping", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.resourceMapping = resourceMapping;

  function resourceMapping(params, hash) {
    let resource = params[0];
    let field = params[1];
    let mappings = params[2];

    if (mappings && mappings[field]) {
      return mappings[field][resource[field]];
    } else {
      return resource[field];
    }
  }

  var _default = Ember.Helper.helper(resourceMapping);

  _exports.default = _default;
});