define("universal-cms/components/resource-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      newResource(resource) {
        // forward add action to the resouce controller
        this.newResource(resource);
      },

      updateResource(resource) {
        // forward update action to the resouce controller
        this.updateResource(resource);
      },

      deleteResource(resource) {
        if (!confirm('Are you sure you want to delete resource?')) {
          return;
        } // forward delete action to the resouce controller


        this.deleteResource(resource);
      }

    },

    /**
      * formatted fields for options on the model
      */
    fields: Ember.computed('options', function () {
      let resourceFields = this.get('options.resource_fields');
      let fields = [];

      for (const field of resourceFields) {
        if (field.name !== 'position') {
          fields.push(field);
        }
      }

      return fields;
    })
  });

  _exports.default = _default;
});