define("universal-cms/components/dreamworks/page-component-modals/gabbys-website-shop-grid", ["exports", "universal-cms/components/dreamworks/page-component-modals/page-component-modal"], function (_exports, _pageComponentModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _pageComponentModal.default.extend({
    classNames: 'gabbys-website-shop-grid',

    init() {
      this._super(...arguments);

      let resourceFormOptions = [];
      resourceFormOptions.pushObject(Ember.Object.create({
        column: 'retailer',
        type: 'text',
        label: 'Retailer'
      }));
      resourceFormOptions.pushObject(Ember.Object.create({
        column: 'title',
        type: 'text',
        label: 'Title'
      }));
      resourceFormOptions.pushObject(Ember.Object.create({
        column: 'description',
        type: 'text-block',
        label: 'Description'
      }));
      resourceFormOptions.pushObject(Ember.Object.create({
        column: 'key_art_image_id',
        type: 'asset',
        label: 'Image - Required'
      }));
      resourceFormOptions.pushObject(Ember.Object.create({
        column: 'link',
        type: 'text',
        label: 'Link'
      }));
      this.set('resourceFormOptions', resourceFormOptions);

      if (!this.get('pageComponent.data.items')) {
        this.set('pageComponent.data.items', Ember.A());
      }
    }

  });

  _exports.default = _default;
});