define("universal-cms/routes/movies/edit/people", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return Ember.RSVP.hash({
        people: this.movieService.getAll('people', {
          'sort_by': 'name'
        }),
        roles: this.movieService.getAll('roles', {
          'sort_by': 'name'
        })
      });
    },

    actions: {
      addPerson() {
        let movie = this.controller.get('movie');
        let addedPeople = this.controller.get('addedPeople');
        let selectedPerson = this.controller.get('selectedPerson');
        let selectedRole = this.controller.get('selectedRole');

        if (!selectedPerson || !selectedRole) {
          return;
        }

        this.controller.set('isAdding', true); // Make sure movie role relationship exist

        this.movieService.createOne('movie-roles', {
          movie_id: movie.id,
          role_id: selectedRole.id
        }).then(addedMovieRole => {
          movie.movie_roles.pushObject(addedMovieRole);
        }, error => {}).finally(() => {
          // Save to database
          this.movieService.createOne('cast-and-crews', {
            movie: movie.id,
            person: selectedPerson.id,
            role: selectedRole.id,
            live_start: (0, _moment.default)().format('YYYY-MM-DD HH:mm:ss'),
            live_end: null
          }).then(addedCastAndCrew => {
            movie.cast_and_crews.pushObject(addedCastAndCrew);
            this.controller.set('selectedRole', undefined);
            this.notificationService.show('Person added', `<strong>${selectedPerson.name}</strong> added to movie.`);
            this.controller.get('target._routerMicrolib').refresh();
          }, error => {
            if (error === 'RELATIONSHIP_ALREADY_EXIST_ERROR') {
              this.notificationService.show('Person already added', `<strong>${selectedPerson.name}</strong> already added as <strong>${selectedRole.name}</strong>.`);
            } else {
              this.notificationService.show(`Could not add <strong>${selectedPerson.name}</strong>`, error);
            }
          }).finally(() => {
            this.controller.set('isAdding', false);
          });
        });
      },

      deleteCastAndCrew(castAndCrew, roleName) {
        if (!confirm(`Are you sure you want to delete person "${castAndCrew.get('name')}"?`)) {
          return;
        }

        let movie = this.controller.get('movie');
        castAndCrew.set('isDeleting', true); // Save to database

        this.movieService.deleteOne('cast-and-crews', castAndCrew.id).then(() => {
          // Find index to remove
          let castAndCrewIndexToDelete = movie.cast_and_crews.findIndex(castAndCrewRelationship => {
            return castAndCrewRelationship.id === castAndCrew.get('id');
          }); // Remove

          if (castAndCrewIndexToDelete !== -1) {
            movie.cast_and_crews.removeAt(castAndCrewIndexToDelete);
          }

          let roleIndexToDelete = movie.movie_roles.findIndex(movieRoleRelationship => {
            return parseInt(movieRoleRelationship.role, 10) === parseInt(castAndCrew.role, 10);
          }); // Calculate num crew in this role, delete from roles if last

          if (roleIndexToDelete !== -1) {
            let castAndCrewsLeftInRole = movie.cast_and_crews.filter(cast_and_crew => {
              return cast_and_crew.get('role') === parseInt(castAndCrew.get('role'), 10);
            }); // Fix postions

            castAndCrewsLeftInRole.map((cast_and_crew, cast_and_crew_index) => {
              cast_and_crew.set('position', cast_and_crew_index);
              return cast_and_crew;
            });

            if (castAndCrewsLeftInRole.length === 0) {
              movie.movie_roles.removeAt(roleIndexToDelete);
            }
          }

          this.controller.get('target._routerMicrolib').refresh();
          this.notificationService.show('Person deleted', `<strong>${castAndCrew.get('name')}</strong> deleted from movie.`);
        }, error => {
          console.log(error);
        });
      },

      changePosition(castAndCrew, role) {
        console.log(castAndCrew, role); // castAndCrew.set((direction === 'up' ? 'isMovingUp' : 'isMovingDown'), true);
        // let movie = this.controller.get('movie');
        // let addedRolesPeople = this.controller.get('addedRolesPeople')[role];
        // let oldPositionIndex = addedRolesPeople.people.findIndex(addedRolePeople => {
        //     return (addedRolePeople.id === castAndCrew.get('id'));
        // });
        // let nameToMove = addedRolesPeople.people[oldPositionIndex].get('name');
        // let itemObjectIndexToMove = movie.cast_and_crews.findIndex(castAndCrewRelationship => {
        //     return (castAndCrewRelationship.id === castAndCrew.get('id'));
        // });
        // let newPositionIndex = (direction === 'up' ? oldPositionIndex - 1 : oldPositionIndex + 1);
        // let itemObjectToMove = movie.cast_and_crews[itemObjectIndexToMove];
        // let itemObjectToMoveWithFake = addedRolesPeople.people[newPositionIndex];
        // let indexToMoveWith = null;
        // movie.cast_and_crews.forEach((object, index) => {
        //     if (object.get('id') === itemObjectToMoveWithFake.id) {
        //         indexToMoveWith = index;
        //     }
        // });
        // let itemObjectToMoveWith = movie.cast_and_crews[indexToMoveWith];
        // let newPosition = movie.cast_and_crews[indexToMoveWith].get('position');
        // let oldPosition = movie.cast_and_crews[itemObjectIndexToMove].get('position');
        // this.movieService.replaceOne('cast-and-crews', itemObjectToMove.id, {
        //     movie_id: itemObjectToMove.get('movie'),
        //     person_id: itemObjectToMove.get('person'),
        //     role_id: itemObjectToMove.get('role'),
        //     position: newPosition
        // }).then(replacedItemObjectToMove => {
        //     this.movieService.replaceOne('cast-and-crews', itemObjectToMoveWith.id, {
        //         movie_id: itemObjectToMoveWith.get('movie'),
        //         person_id: itemObjectToMoveWith.get('person'),
        //         role_id: itemObjectToMoveWith.get('role'),
        //         position: oldPosition
        //     }).then(replacedItemObjectToMove => {
        //         castAndCrew.set((direction === 'up' ? 'isMovingUp' : 'isMovingDown'), false);
        //         itemObjectToMove.set('position', newPosition);
        //         itemObjectToMoveWith.set('position', oldPosition);
        //         movie.get('cast_and_crews').arrayContentDidChange();
        //         this.controller.get('target._routerMicrolib').refresh();
        //         this.notificationService.show('Person moved', `<strong>${nameToMove}</strong> has been moved.`);
        //     }, (error) => {
        //         this.notificationService.show('Error', `Could not move <strong>${nameToMove}</strong>.`);
        //     });
        // }, (error) => {
        //     this.notificationService.show('Error', `Could not move <strong>${nameToMove}</strong>.`);
        // });
      }

    }
  });

  _exports.default = _default;
});