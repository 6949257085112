define("universal-cms/controllers/dreamworks/retailers/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    error: null,
    isSaving: false,
    heading: Ember.computed('isNew', 'model.retailer.name', function () {
      return this.isNew === true ? 'Add Retailer' : this.get('model.retailer.name');
    }),
    actions: {
      async save() {
        try {
          this.set('isSaving', true);
          let saveData = {
            name: this.model.retailer.name,
            asset_id: this.model.retailer.asset_id,
            movies_anywhere: this.model.retailer.movies_anywhere ? 1 : 0
          };

          if (this.isNew) {
            const createResponse = await this.dreamworksService.createOne('retailers', saveData);
            this.notificationService.show('Added', 'Retailer has been added.');
            this.target.transitionTo('dreamworks.retailers.edit', createResponse.id);
          } else {
            await this.dreamworksService.updateOne('retailers', this.get('model.retailer.id'), saveData);
            this.notificationService.show('Saved', 'Retailer has been saved.');
          }
        } catch (error) {
          console.log('error', error);
          this.notificationService.show('Error', 'Could not save retailer.');
        } finally {
          this.set('isSaving', false);
        }
      }

    }
  });

  _exports.default = _default;
});