define("universal-cms/routes/option-types/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      return Ember.RSVP.hash({
        optionType: this.movieService.getOne('option-types', params.option_type_id),
        optionGroups: this.movieService.getAll('option-groups')
      });
    }

  });

  _exports.default = _default;
});