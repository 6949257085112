define("universal-cms/components/retailer-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    mode: 'add',
    retailer: null,
    formData: null,
    isSaving: false,
    saveButtonText: Ember.computed('isSaving', function () {
      if (this.mode === 'edit') {
        return this.isSaving ? 'Saving...' : 'Save';
      } else {
        return this.isSaving ? 'Adding...' : 'Add';
      }
    }),

    init() {
      this._super(...arguments);

      this.set('formData', Ember.Object.create({
        name: this.retailer ? this.retailer.name : '',
        logo: this.retailer ? this.retailer.logo : null,
        logoAltText: this.retailer ? this.retailer.logo_alt_text : null
      }));
    },

    actions: {
      async save() {
        if (this.isSaving) {
          return;
        }

        const actionName = `${this.mode === 'edit' ? 'update' : 'create'}Retailer`;

        try {
          this.set('isSaving', true);
          this.get(actionName)({
            retailerId: this.retailer ? this.retailer.id : null,
            retailer: this.formData
          });
          const savedName = this.formData.name; // Clear form

          if (this.mode === 'add') {
            this.set('formData', Ember.Object.create({
              name: '',
              logo: null,
              logoAltText: null
            }));
          } else if (this.mode === 'edit') {
            this.set('retailer.name', savedName);
          }
        } catch (saveRetailerError) {
          console.log('saveRetailerError', saveRetailerError);
        } finally {
          this.set('isSaving', false);
        }
      }

    }
  });

  _exports.default = _default;
});