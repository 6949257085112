define("universal-cms/controllers/movies/edit/retailers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    editMovieController: Ember.inject.controller('movies/edit'),
    movie: Ember.computed.reads('editMovieController.model'),
    movieRetailers: Ember.computed.reads('editMovieController.movieRetailers')
  });

  _exports.default = _default;
});