define("universal-cms/routes/universal-studios/news/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return this.universalStudiosService.getAll('news').then(news => {
        return news;
      }, () => {
        const controller = this.controllerFor(this.routeName);
        controller.set('error', true);
      });
    }

  });

  _exports.default = _default;
});