define("universal-cms/components/models-table/universal-studios/video-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      sendAction(action, data) {
        // propogates action to the `deleteVideo` action in /controllers/universal-studios/videos/list.js
        this.sendAction(action, data); // eslint-disable-line ember/closure-actions
      }

    }
  });

  _exports.default = _default;
});