define("universal-cms/templates/components/dreamworks/page-component-modals/gabbys-website-featured-promos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rgA8MNp3",
    "block": "{\"symbols\":[],\"statements\":[[9,\"h2\",true],[10],[1,1,0,0,\"\\n    Featured Promos\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[7,\"dreamworks/resource-form\",[],[[\"@formOptions\",\"@items\",\"@resourceType\",\"@onItemsUpdated\"],[[27,[26,0,\"AppendSingleId\"],[]],[27,[26,1,\"AppendSingleId\"],[\"data\",\"promos\"]],\"resource\",[31,191,6,[27,[26,3,\"CallHead\"],[]],[[27,[24,0],[]],[31,199,3,[27,[26,2,\"CallHead\"],[]],[[27,[26,1,\"Expression\"],[\"data\",\"promos\"]]],null]],null]]],null]],\"hasEval\":false,\"upvars\":[\"resourceFormOptions\",\"pageComponent\",\"mut\",\"action\"]}",
    "meta": {
      "moduleName": "universal-cms/templates/components/dreamworks/page-component-modals/gabbys-website-featured-promos.hbs"
    }
  });

  _exports.default = _default;
});