define("universal-cms/controllers/universal-brand/news/edit", ["exports", "universal-cms/config/environment", "moment", "jquery", "quill"], function (_exports, _environment, _moment, _jquery, _quill) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    pxlMediaLibrary: Ember.inject.service(),
    cookies: Ember.inject.service(),
    error: false,
    heading: Ember.computed('isNew', 'model.article.title', function () {
      return this.isNew === true ? 'Add Article' : this.get('model.article.title');
    }),
    isSaving: false,
    saveButtonText: Ember.computed('isNew', 'isSaving', function () {
      if (this.isNew === true) {
        return this.isSaving === true ? 'Adding...' : 'Add';
      } else {
        return this.isSaving === true ? 'Saving...' : 'Save';
      }
    }),
    formattedArticleDate: Ember.computed('model.article.date', function () {
      const dbTime = _moment.default.tz(this.get('model.article.date'), _environment.default.databaseTimezone);

      return dbTime.clone().tz(_moment.default.tz.guess()).format('M/D/Y h:mm A');
    }),
    escapedBody: Ember.computed('model.article.body', function () {
      return Ember.String.htmlSafe(this.model.article.body);
    }),
    articleTypes: Ember.A([Ember.Object.create({
      title: 'Article',
      value: 'article'
    }), Ember.Object.create({
      title: 'Video',
      value: 'video'
    }), Ember.Object.create({
      title: 'External Link',
      value: 'external_link'
    })]),
    articleUrl: Ember.computed('universalBrandUrl', 'model.article.slug', function () {
      return `${this.universalBrandUrl}/news/${this.get('model.article.slug')}`;
    }),
    bodyQuillEditor: null,

    initQuill() {
      let bodyQuillInterval = setInterval(() => {
        const $bodyQuill = (0, _jquery.default)('#body-quill');

        if ($bodyQuill.length === 1) {
          clearInterval(bodyQuillInterval);
          const editor = new _quill.default('#body-quill', _environment.default.quillOptions);
          editor.getModule('toolbar').addHandler('image', () => {
            this.pxlMediaLibrary.chooseMediaItem({
              selectable: true
            }).then(mediaItem => {
              const range = editor.getSelection();
              editor.insertEmbed(range.index, 'image', mediaItem.url);
            });
          });
          this.set('bodyQuillEditor', editor);
        }
      }, 250);
    },

    articleTypeObserver: Ember.observer('model.article.type', function () {
      if (this.get('model.article.type') === 'article') {
        const vm = this;
        Ember.run.scheduleOnce('afterRender', this, vm.initQuill);
      }
    }),

    init() {
      this._super();

      const jwt = JSON.parse(localStorage.getItem('auth'));
      this.set('universalBrandUrl', jwt.decoded.scopes.mappings.UniversalBrand);
    },

    actions: {
      save() {
        if (this.isSaving === true) {
          return;
        }

        this.set('isSaving', true);
        const article = this.get('model.article');
        let saveData = article.getProperties('type', 'title', 'slug', 'via', 'desktop_image_id', 'mobile_image_id', 'thumbnail_image_id', 'business_id', 'pinned_at');
        saveData.body = this.bodyQuillEditor ? this.bodyQuillEditor.container.firstChild.innerHTML : null;
        saveData.is_featured = article.get('is_featured') ? 1 : 0;
        const publishDate = (0, _moment.default)(this.get('formattedArticleDate')).clone().tz(_environment.default.databaseTimezone);
        saveData.date = publishDate.format('YYYY-MM-DD HH:mm:ss');

        if (article.get('type') === 'video') {
          saveData.youtube_id = article.get('youtube_id');
        } else if (article.get('type') === 'external_link') {
          saveData.external_link = article.get('external_link');
        }

        if (this.isNew) {
          this.universalBrandService.createOne('articles', saveData).then(createResponse => {
            if (typeof createResponse.errors !== 'undefined') {
              this.notificationService.show('Error', this.universalBrandService.formatErrorResponse(createResponse.errors));
            } else if (typeof createResponse.validation_errors !== 'undefined') {
              this.notificationService.show('Validation Error', this.universalBrandService.formatErrorResponse(createResponse.validation_errors));
            } else {
              this.notificationService.show('Article Added', `<strong>${createResponse.get('title')}</strong> has been added.`);
              this.target.transitionTo(this.routeName, createResponse.get('article_id'));
            }
          }, createError => {
            this.notificationService.show('Error', typeof createError.responseJSON.error === 'string' ? createError.responseJSON.error : createError);
          }).finally(() => {
            this.set('isSaving', false);
          });
        } else {
          this.universalBrandService.updateOne('articles', article.get('article_id'), saveData).then(updateResponse => {
            if (typeof updateResponse.errors !== 'undefined') {
              this.notificationService.show('Error', this.universalBrandService.formatErrorResponse(updateResponse.errors));
            } else if (typeof updateResponse.validation_errors !== 'undefined') {
              this.notificationService.show('Validation Error', this.universalBrandService.formatErrorResponse(updateResponse.validation_errors));
            } else {
              this.notificationService.show('Article Updated', `<strong>${updateResponse.get('title')}</strong> has been updated.`);
              this.target.transitionTo(this.routeName, updateResponse.get('article_id'));
            }
          }, updateError => {
            this.notificationService.show('Error', typeof updateError.responseJSON.error === 'string' ? updateError.responseJSON.error : updateError);
          }).finally(() => {
            this.set('isSaving', false);
          });
        }
      },

      updateBody(editor) {
        this.set('model.article.body', editor.root.innerHTML);
      },

      setArticleType(type) {
        this.set('model.article.type', type);
      },

      setBusiness(businessId) {
        this.set('model.article.business_id', businessId);
      },

      setSlug(title) {
        if (!this.get('model.article.slug')) {
          this.set('model.article.slug', title.dasherize());
        }
      },

      deleteDesktopImage() {
        return new Ember.RSVP.Promise(resolve => {
          this.set('model.article.desktop_image_id', null);
          resolve();
        });
      },

      deleteMobileImage() {
        return new Ember.RSVP.Promise(resolve => {
          this.set('model.article.mobile_image_id', null);
          resolve();
        });
      },

      deleteThumbnailImage() {
        return new Ember.RSVP.Promise(resolve => {
          this.set('model.article.thumbnail_image_id', null);
          resolve();
        });
      }

    }
  });

  _exports.default = _default;
});