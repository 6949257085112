define("universal-cms/controllers/universal-brand/projects/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    error: false,
    heading: Ember.computed('isNew', 'model.project.display_title', function () {
      return this.isNew === true ? 'Add Project' : `Edit Project "${this.get('model.project.display_title')}"`;
    }),
    isSaving: false,
    saveButtonText: Ember.computed('isNew', 'isSaving', function () {
      if (this.isNew === true) {
        return this.isSaving === true ? 'Adding...' : 'Add';
      } else {
        return this.isSaving === true ? 'Saving...' : 'Save';
      }
    }),

    /*selectedBusiness: Ember.computed('model.businesses.[]', 'model.project.business_id', function() {
        if (!this.get('model.project.business_id')) {
            return null;
        }
         return this.get('model.businesses').find(business => {
            return this.get('model.project.business_id') === business.get('business_id');
        });
    }),*/
    pageLayouts: Ember.A([{
      value: 'product',
      title: 'Product'
    }, {
      value: 'attraction',
      title: 'Attraction'
    }]),
    newGallery: Ember.Object.create(),
    isAddingGallery: false,
    relatedProjectToAdd: null,
    isAddingCategory: false,
    categoriesDropdownPlaceholderText: Ember.computed('isAddingCategory', function () {
      return this.isAddingCategory === true ? 'Saving...' : 'Add Category';
    }),
    nonSelectedProjects: Ember.computed('model.{projects.[],related_projects.[]}', function () {
      const allProjects = this.get('model.projects');
      const selectedProjects = this.get('model.project.related_projects');

      if (typeof selectedProjects === 'undefined') {
        return Ember.A();
      }

      let nonSelectedProjects = Ember.A();

      for (let project of allProjects) {
        let selectedProjectIndex = selectedProjects.findIndex(selectedProject => {
          return project.get('project_id') === selectedProject.get('related_project_id');
        });

        if (selectedProjectIndex === -1) {
          nonSelectedProjects.pushObject(project);
        }
      }

      return nonSelectedProjects;
    }),
    nonAddedCategories: Ember.computed('model.{categories.[],project.categories.[]}', function () {
      const projectCategories = this.get('model.project.categories');
      let nonAddedCategories = Ember.A(); // Get categories from model.categories that's not in model.project.categories

      if (typeof projectCategories !== 'object') {
        return nonAddedCategories;
      }

      for (let category of this.get('model.categories')) {
        // If category is in model.project.categories don't add
        let categoryFound = false;

        for (let projectCategory of projectCategories) {
          if (projectCategory.get('category_id') === category.get('category_id')) {
            categoryFound = true;
            break;
          }
        }

        if (categoryFound === false) {
          nonAddedCategories.pushObject(category);
        }
      }

      return nonAddedCategories;
    }),

    saveGeneralTab() {
      return new Ember.RSVP.Promise((resolve, reject) => {
        let saveData = {
          business_id: this.get('model.project.business_id'),
          title: this.get('model.project.title'),
          slug: this.get('model.project.slug'),
          external_link: this.get('model.project.external_link'),
          type: this.get('model.project.type'),
          description: this.get('model.project.description'),
          body: this.get('model.project.body')
        };

        if (this.isNew) {
          this.universalBrandService.createOne('projects', saveData).then(saveResponse => {
            if (typeof saveResponse.errors !== 'undefined') {
              this.notificationService.show('Error', this.universalBrandService.formatErrorResponse(saveResponse.errors));
            } else if (typeof saveResponse.validation_errors !== 'undefined') {
              this.notificationService.show('Validation Error', this.universalBrandService.formatErrorResponse(saveResponse.validation_errors));
            } else {
              this.notificationService.show('Success', `<strong>${saveData.title}</strong> has been created.`);
            }

            resolve(saveResponse);
          }).catch(e => {
            reject();
          });
        } else {
          this.universalBrandService.updateOne('projects', this.projectId, saveData).then(saveResponse => {
            if (typeof saveResponse.errors !== 'undefined') {
              this.notificationService.show('Error', this.universalBrandService.formatErrorResponse(saveResponse.errors));
            } else if (typeof saveResponse.validation_errors !== 'undefined') {
              this.notificationService.show('Validation Error', this.universalBrandService.formatErrorResponse(saveResponse.validation_errors));
            } else {
              this.notificationService.show('Success', `<strong>${saveData.title}</strong> has been saved.`);
            }

            resolve();
          }).catch(e => {
            reject();
          });
        }
      });
    },

    saveBannerTab() {
      if (this.isNew) {
        return new Ember.RSVP.Promise(resolve => {
          return resolve();
        });
      } else {
        return this.universalBrandService.updateOne('projects', this.projectId, {
          desktop_image_id: this.get('model.project.desktop_image_id'),
          mobile_image_id: this.get('model.project.mobile_image_id'),
          thumbnail_image_id: this.get('model.project.thumbnail_image_id')
        });
      }
    },

    saveOtherProjectsTab() {
      return new Ember.RSVP.Promise((resolve, reject) => {
        resolve();
      });
    },

    actions: {
      save() {
        this.set('isSaving', true);
        Ember.RSVP.Promise.all([this.saveGeneralTab(), this.saveBannerTab(), this.saveOtherProjectsTab()]).then(saveResponse => {
          if (this.isNew) {
            this.target.transitionTo('universalBrand.projects.edit', saveResponse[0].get('project_id'));
          }
        }).catch(e => {
          this.notificationService.show('Error', 'Could not ' + (this.isNew ? 'add' : 'save') + ' project.');
        }).finally(() => {
          this.set('isSaving', false);
        });
      },

      createGallery() {
        this.set('isAddingGallery', true);
        const newGallery = this.newGallery;
        let saveData = newGallery.getProperties('title', 'description');
        saveData.project_id = this.projectId;
        return this.universalBrandService.createOne('galleries', saveData).then(createResponse => {
          this.get('model.project.galleries').pushObject(createResponse);
          newGallery.setProperties({
            'title': '',
            'description': ''
          });
          this.notificationService.show('Gallery Added', `<strong>${createResponse.title}</strong> has been added.`);
        }, () => {
          this.notificationService.show('Error', 'Could not add gallery.');
        }).finally(() => {
          this.set('isAddingGallery', false);
        });
      },

      deleteGallery(galleryToDelete) {
        const galleryTitleToDelete = galleryToDelete.get('title');
        return this.universalBrandService.deleteOne('galleries', galleryToDelete.get('gallery_id')).then(() => {
          this.get('model.project.galleries').removeObject(galleryToDelete);
          this.notificationService.show('Gallery Deleted', `<strong>${galleryTitleToDelete}</strong> has been deleted.`);
        }, () => {
          this.notificationService.show('Error', 'Could not delete gallery.');
        });
      },

      updateGallery(gallery, column, value) {
        if (value === gallery.get(column)) {
          return;
        }

        gallery.set(column, value);
        let saveData = {};
        saveData[column] = value;
        return this.universalBrandService.updateOne('galleries', gallery.get('gallery_id'), saveData).then(() => {
          this.notificationService.show('Saved', `Gallery has been saved.`);
        }, () => {
          this.notificationService.show('Error', `Could not save gallery.`);
        });
      },

      updateGalleriesOrder(orderedGalleries) {
        let newOrder = [];

        for (let item of orderedGalleries) {
          newOrder.push(item.get('gallery_id'));
        }

        let saveData = {
          items: newOrder
        };
        this.universalBrandService.updateOne(`projects/${this.projectId}`, 'update-galleries-order', saveData).then(() => {
          this.notificationService.show('Saved', `Order has been saved.`);
        }).catch(() => {
          this.notificationService.show('Error', `Could not save order.`);
        });
      },

      addRelatedProject() {
        const relatedProjectToAdd = this.relatedProjectToAdd;

        if (!relatedProjectToAdd) {
          this.notificationService.show('Error', `Choose a project.`);
          return;
        }

        this.set('isAddingRelatedProject', true);
        this.universalBrandService.createOne(`related-projects`, {
          project_id: this.projectId,
          related_project_id: relatedProjectToAdd.get('project_id')
        }).then(addedRelatedProject => {
          this.get('model.project.related_projects').pushObject(addedRelatedProject);
          this.notificationService.show('Related Project Added', `Related project has been added.`);
        }, () => {
          this.notificationService.show('Error', `Could not add <strong>${relatedProjectToAdd.get('display_title')}</strong> as a related project.`);
        }).finally(() => {
          this.set('isAddingRelatedProject', false);
        });
      },

      updateRelatedProjectsOrder(orderedRelatedProjects) {
        let newOrder = [];

        for (let item of orderedRelatedProjects) {
          newOrder.push(item.get('related_project_id'));
        }

        let saveData = {
          items: newOrder
        };
        this.universalBrandService.updateOne(`related-projects/${this.projectId}`, 'update-order', saveData).then(() => {
          this.notificationService.show('Saved', `Order has been saved.`);
        }).catch(() => {
          this.notificationService.show('Error', `Could not save order.`);
        });
      },

      deleteRelatedProject(relatedProjectToDelete) {
        const relatedProjectTitleToDelete = relatedProjectToDelete.get('project.display_title');
        return this.universalBrandService.deleteOne('related-projects', relatedProjectToDelete.get('id')).then(() => {
          this.get('model.project.related_projects').removeObject(relatedProjectToDelete);
          this.notificationService.show('Related Project Deleted', `Related project has been deleted.`);
        }, () => {
          this.notificationService.show('Error', 'Could not delete related project.');
        });
      },

      setPageLayout(pageLayout) {
        this.set('model.project.type', pageLayout);
      },

      updateDescription(editor) {
        this.set('model.project.description', editor.root.innerHTML);
      },

      updateBody(editor) {
        this.set('model.project.body', editor.root.innerHTML);
      },

      onCreateCategory(title) {
        this.set('isAddingCategory', true);
        this.universalBrandService.createOne('categories', {
          title: title
        }).then(createdCategory => {
          this.send('onAddCategory', createdCategory);
        }).catch(() => {
          this.notificationService.show('Error', 'Could not add category.');
        }).finally(() => {
          this.set('isAddingCategory', false);
        });
      },

      onAddCategory(category) {
        this.set('isAddingCategory', true);
        this.universalBrandService.createOne(`projects/${this.get('model.project.project_id')}/categories`, {
          category_id: category.get('category_id')
        }).then(() => {
          this.get('model.project.categories').pushObject(category);
          this.notificationService.show('Addded', 'Category added.');
        }).catch(() => {
          this.notificationService.show('Error', 'Could not add category.');
        }).finally(() => {
          this.set('isAddingCategory', false);
        });
      },

      detachProjectCategory(category) {
        return new Ember.RSVP.Promise((resolve, reject) => {
          this.universalBrandService.deleteOne(`projects/${this.get('model.project.project_id')}/categories`, category.get('category_id')).then(() => {
            this.get('model.project.categories').removeObject(category);
            this.notificationService.show('Addded', 'Category deleted.');
            resolve();
          }).catch(() => {
            this.notificationService.show('Error', 'Could not delete category.');
            reject();
          });
        });
      }

    }
  });

  _exports.default = _default;
});