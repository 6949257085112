define("universal-cms/components/sidebar-menu", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['sidebar', 'application-sidebar'],

    didInsertElement() {
      Ember.run.scheduleOnce('afterRender', this, this.initSlimScroll);
    },

    initSlimScroll() {
      (0, _jquery.default)('.application-sidebar').slimScroll({
        height: '100%',
        width: (0, _jquery.default)('.application-sidebar').width(),
        alwaysVisible: true
      });
    }

  });

  _exports.default = _default;
});