define("universal-cms/controllers/universal-brand/businesses/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    error: false,
    heading: Ember.computed('isNew', 'model.business.title', function () {
      return this.isNew === true ? 'Add Business' : this.get('model.business.title');
    }),
    isSaving: false,
    saveButtonText: Ember.computed('isNew', 'isSaving', function () {
      if (this.isNew === true) {
        return this.isSaving === true ? 'Adding...' : 'Add';
      } else {
        return this.isSaving === true ? 'Saving...' : 'Save';
      }
    }),
    newBannerPhotoDesktop: Ember.Object.create(),
    newBannerPhotoMobile: Ember.Object.create(),
    actions: {
      save() {
        if (this.isSaving === true) {
          return;
        }

        this.set('isSaving', true);
        const business = this.get('model.business');
        let saveData = business.getProperties('title', 'slug', 'body', 'description', 'hex_code', 'desktop_image_id', 'mobile_image_id', 'thumbnail_image_id', 'thumbnail_wide_image_id', 'thumbnail_landscape_image_id', 'is_enabled');

        if (!saveData.hex_code) {
          saveData.hex_code = '#000';
        }

        console.log('wooooooot');
        saveData.is_enabled = saveData.is_enabled ? 1 : 0;

        if (this.isNew) {
          this.universalBrandService.createOne('businesses', saveData).then(createResponse => {
            if (typeof createResponse.errors !== 'undefined') {
              this.notificationService.show('Error', this.universalBrandService.formatErrorResponse(createResponse.errors));
            } else if (typeof createResponse.validation_errors !== 'undefined') {
              this.notificationService.show('Validation Error', this.universalBrandService.formatErrorResponse(createResponse.validation_errors));
            } else {
              this.notificationService.show('Business Added', `<strong>${createResponse.get('title')}</strong> has been added.`);
              this.target.transitionTo(this.routeName, createResponse.get('business_id'));
            }
          }, createError => {
            this.notificationService.show('Error', typeof createError.responseJSON.error === 'string' ? createError.responseJSON.error : createError);
          }).finally(() => {
            this.set('isSaving', false);
          });
        } else {
          this.universalBrandService.updateOne('businesses', business.get('business_id'), saveData).then(updateResponse => {
            if (typeof updateResponse.errors !== 'undefined') {
              this.notificationService.show('Error', this.universalBrandService.formatErrorResponse(updateResponse.errors));
            } else if (typeof updateResponse.validation_errors !== 'undefined') {
              this.notificationService.show('Validation Error', this.universalBrandService.formatErrorResponse(updateResponse.validation_errors));
            } else {
              this.notificationService.show('Business Updated', `<strong>${updateResponse.get('title')}</strong> has been updated.`);
              this.target.transitionTo(this.routeName, updateResponse.get('business_id'));
            }
          }, updateError => {
            this.notificationService.show('Error', typeof updateError.responseJSON.error === 'string' ? updateError.responseJSON.error : updateError);
          }).finally(() => {
            this.set('isSaving', false);
          });
        }
      },

      updateBody(editor) {
        this.set('model.business.body', editor.root.innerHTML);
      },

      updateDescription(editor) {
        this.set('model.business.description', editor.root.innerHTML);
      },

      deleteProject(project) {
        return new Ember.RSVP.Promise((resolve, reject) => {
          project.set('isDeleting', true);
          this.universalBrandService.deleteOne('projects', project.get('project_id')).then(() => {
            this.notificationService.show('Project deleted', `Project was successfully deleted.`);
            this.get('model.business.projects').removeObject(project);
            resolve();
          }, () => {
            project.set('isDeleting', false);
            this.notificationService.show('Error', `Could not delete project.`);
            reject();
          });
        });
      },

      updateProjectsOrder(orderedProjects) {
        let newOrder = [];

        for (let item of orderedProjects) {
          newOrder.push(item.get('project_id'));
        }

        this.universalBrandService.updateOne(`businesses/${this.get('model.business.business_id')}/projects`, 'update-order', {
          items: newOrder
        }).then(() => {
          this.notificationService.show('Saved', `Order has been saved.`);
        }).catch(() => {
          this.notificationService.show('Error', `Could not save order.`);
        });
      }

    }
  });

  _exports.default = _default;
});