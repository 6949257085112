define("universal-cms/routes/movies/edit", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    isSaving: false,

    model(params) {
      return this.movieService.getOne('movies', params.movie_id, ['cast_and_crews', 'movie_genres', 'movie_roles', 'options', 'critical_claims']);
    }

  });

  _exports.default = _default;
});