define("universal-cms/routes/dreamworks/leadership/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return Ember.RSVP.hash({
        leaders: this.dreamworksService.getMany('leadership/ordered')
      });
    }

  });

  _exports.default = _default;
});