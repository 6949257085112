define("universal-cms/components/asset-group-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isEditing: Ember.computed('mode', function () {
      return this.mode === 'edit';
    }),
    name: Ember.computed('assetGroup.name', function () {
      return this.get('assetGroup.name');
    }),
    description: Ember.computed('assetGroup.description', function () {
      return this.get('assetGroup.description');
    }),
    saveButtonText: Ember.computed('isSaving', function () {
      if (this.mode === 'edit') {
        return this.isSaving ? 'Saving...' : 'Save';
      } else {
        return this.isSaving ? 'Adding...' : 'Add';
      }
    }),
    actions: {
      save(assetGroup) {
        let name = this.name;

        if (!name) {
          return;
        }

        this.set('isSaving', true);
        let actionName = (this.mode === 'edit' ? 'update' : 'create') + 'AssetGroup';
        this.get(actionName)({
          assetGroup: this.assetGroup,
          data: {
            name: name,
            description: this.description ? this.description : null
          }
        }).then(assetGroup => {
          if (this.mode === 'add' && assetGroup) {
            this.setProperties({
              'name': '',
              'description': ''
            });
          }

          this.set('isSaving', false);
        });
      }

    }
  });

  _exports.default = _default;
});