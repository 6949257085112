define("universal-cms/initializers/inject-env", ["exports", "universal-cms/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize(application) {
    application.register('env:main', _environment.default, {
      singleton: true,
      instantiate: false
    });
    application.inject('controller', 'env', 'env:main');
  }

  var _default = {
    name: 'inject-env',
    initialize
  };
  _exports.default = _default;
});