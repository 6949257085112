define("universal-cms/components/super-table-yield/table-body-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'td',
    editable: false,
    isEditing: false,

    //attributeBindings: ['style'],
    //style: Ember.String.htmlSafe('vertical-align:top;'),
    doubleClick() {
      if (this.editable) {
        this.send('setIsEditing', true);
      }
    },

    actions: {
      setIsEditing(isEditing) {
        this.set('isEditing', isEditing);
      },

      doneEditing() {
        this.set('isEditing', false);
      }

    }
  });

  _exports.default = _default;
});