define("universal-cms/components/dreamworks/networks-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    util: Ember.inject.service(),
    classNames: 'dreamworks-networks-editor',
    // Available Streaming Networks
    availableNetworks: ['Netflix Light', 'Netflix Dark', 'Amazon', 'Hulu Light', 'Hulu Dark', 'Peacock Light', 'Peacock Dark'],
    // OLD DATA STUFF
    oldNetwork: null,
    oldStreamingLink: '',
    // Chosen Networks
    networks: null,
    pageResourceType: null,
    // New Network To Add
    networkToAdd: null,
    // Link to Add
    linkToAdd: '',
    // If No Network is Chosen, Disable The Add Button (TODO: NEEDS FIXING)
    addNetworkButtonDisabled: Ember.computed('networkToAdd', function () {
      return !this.networkToAdd;
    }),
    // Networks that have not been added yet (TODO: NEEDS FIXING)
    nonAddedNetworks: Ember.computed('networks', 'availableNetworks', function () {
      if (!this.networks) {
        return this.availableNetworks;
      }

      return this.availableNetworks.filter(network => this.networks.findIndex(selectedNetwork => selectedNetwork === network) === -1);
    }),

    init() {
      this._super(...arguments); // If There are no chosen Networks, set the networks to an array


      if (!this.networks) {
        this.set('networks', []);

        if (this.oldNetwork) {
          if (this.oldNetwork == 'Netflix') {
            this.networks.pushObject(Ember.Object.create({
              name: `${this.oldNetwork} Dark`,
              link: this.oldStreamingLink
            }));
          } else if (this.oldNetwork == 'Amazon') {
            this.networks.pushObject(Ember.Object.create({
              name: this.oldNetwork,
              link: this.oldStreamingLink
            }));
          }
        }
      }
    },

    _onChange() {
      if (typeof this.onChange === 'function') {
        this.onChange(this.networks);

        if (this.oldNetwork) {
          this.onNetworkClear(null);
        }

        if (this.oldStreamingLink) {
          this.onStreamingLinkClear(null);
        }
      }
    },

    actions: {
      addNetwork() {
        // If there is no networkToAdd
        if (!this.networkToAdd) {
          return;
        }

        if (!this.networks) {
          this.set('networks', []);
        }

        if (!this.linkToAdd) {
          this.set('linkToAdd', '');
        }

        this.networks.pushObject(Ember.Object.create({
          name: this.networkToAdd,
          link: this.linkToAdd
        })); // RESET THE FIELDS

        this.set('networkToAdd', null);
        this.set('linkToAdd', null);

        this._onChange();
      },

      removeNetwork(networkToRemove) {
        this.networks.removeObject(networkToRemove);

        this._onChange();
      }

    }
  });

  _exports.default = _default;
});