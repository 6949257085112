define("universal-cms/components/pxl-form-gallery", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    util: Ember.inject.service(),
    classNames: 'pxl-form-gallery',
    label: undefined,
    required: false,
    assetIds: undefined,
    type: null,
    assets: null,
    isLoadingAssets: false,
    singularText: 'asset',
    pluralText: 'assets',
    formattedAssets: Ember.computed('assets.[]', function () {
      return this.assets.map(asset => {
        asset.set('formattedSize', this.util.formatBytes(asset.size));
        return asset;
      });
    }),
    pluralTextLowerCase: Ember.computed('pluralText', function () {
      return this.pluralText.toLowerCase();
    }),

    async init() {
      this._super(...arguments);

      await this.loadAssets();
    },

    async loadAssets() {
      this.set('isLoadingAssets', true);
      let assets = [];

      if (typeof this.assetIds === 'object') {
        for (let assetId of this.assetIds) {
          if (typeof assetId === 'number' || typeof assetId === 'string') {
            const asset = await this.dreamworksService.getOne('assets', assetId);
            assets.pushObject(asset);
          } else if (assetId.alt) {
            const asset = await this.dreamworksService.getOne('assets', assetId.assetId);
            asset.set('alt', assetId.alt);
            assets.pushObject(asset);
          }
        }
      }

      this.set('assets', assets);
      this.set('isLoadingAssets', false);

      if (this.element && this.element.querySelectorAll('.assets')) {
        Ember.run.scheduleOnce('afterRender', this, this.initSortable);
      }
    },

    initSortable() {
      const sortable = Sortable.create(this.element.querySelectorAll('.assets')[0], {
        handle: '.drag-handle',
        animation: 150,
        onSort: event => {
          const {
            oldIndex,
            newIndex
          } = event;

          if (oldIndex === newIndex) {
            return false;
          }

          const sortableArray = sortable.toArray();
          let sortedArray = [];

          for (let i = 0; i < sortableArray.length; i++) {
            const currentItem = this.assetIds.find(assetId => {
              if (typeof assetId === 'number' || typeof assetId === 'string') {
                return assetId === sortableArray[i] || assetId === parseInt(sortableArray[i]);
              } else {
                return assetId.assetId === parseInt(sortableArray[i]) || assetId.assetId === sortableArray[i];
              }
            });
            sortedArray.push(currentItem);
          }

          this.set('assetIds', sortedArray); // this.set('assetIds', sortable.toArray());
          // this.onChange();
        }
      });
    },

    actions: {
      async remove(assetToDelete) {
        if (!confirm(`Are you sure you want to remove asset?`)) {
          return;
        } // const assetIds = this.assetIds.filter(assetId => parseInt(assetId, 10) !== assetToDelete.id);


        const objectToRemove = this.assetIds.find(assetId => {
          if (typeof assetId === 'number' || typeof assetId === 'string') {
            return parseInt(assetId) === assetToDelete.id || assetId === assetToDelete.id;
          } else {
            return assetId.assetId === assetToDelete.id;
          }
        });
        this.assetIds.removeObject(objectToRemove); // this.set('assetIds', assetIds);

        this.assets.removeObject(assetToDelete);
      },

      async removeAllAssets() {
        if (!confirm(`Are you sure you want to remove all assets?`)) {
          return;
        }

        this.set('assetIds', []);
        this.set('assets', []);
      },

      async onAltInput(assetToUpdate) {
        const convertedAssetIds = this.assetIds.map(asset => {
          if (typeof asset === 'object') {
            return parseInt(asset.assetId);
          } else {
            return parseInt(asset);
          }
        });
        const assetIndex = convertedAssetIds.indexOf(assetToUpdate.id);

        if (assetIndex !== -1) {
          this.assetIds[assetIndex] = Ember.Object.create({
            assetId: assetToUpdate.id,
            alt: assetToUpdate.alt
          });
        }

        console.log('Asset Ids:', this.assetIds);
        console.log('Assets:', this.assetIds); // this.assetIds.replace(assetIndex, 1, [EmberObject.create({
        //     assetId: assetToUpdate.id,
        //     alt: assetToUpdate.alt
        // })]);
      },

      async addGalleryPhoto(assetToAdd) {
        if (typeof this.assetIds === 'undefined') {
          this.set('assetIds', []);
        }

        this.assetIds.pushObject(assetToAdd.id);
        await this.loadAssets();

        if (typeof this.onChange === 'function') {
          this.onChange();
        } // this.notificationService.show('Gallery photo added', 'Gallery photo has been added.');

      }

    }
  });

  _exports.default = _default;
});