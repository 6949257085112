define("universal-cms/ember-quill/tests/templates/roles/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "atbuAOL6",
    "block": "{\"symbols\":[],\"statements\":[[9,\"h1\",true],[12,\"class\",\"page-header\",null],[10],[1,1,0,0,\"\\n    Roles\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[7,\"models-table\",[],[[\"@data\",\"@columns\",\"@showColumnsDropdown\",\"@useFilteringByColumns\",\"@multipleColumnsSorting\",\"@filteringIgnoreCase\",\"@columnComponents\"],[[27,[26,0,\"AppendSingleId\"],[]],[27,[26,1,\"AppendSingleId\"],[]],false,false,false,true,[31,274,4,[27,[26,4,\"CallHead\"],[]],null,[[\"manage\"],[[31,308,9,[27,[26,3,\"CallHead\"],[]],[\"models-table/role-actions\"],[[\"deleteRole\"],[[31,370,6,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[]],\"deleteRole\"],null]]]]]]]]],null],[1,1,0,0,\"\\n\\n\"],[9,\"div\",true],[12,\"class\",\"panel panel-inverse\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"div\",true],[12,\"class\",\"panel-heading\",null],[10],[1,1,0,0,\"\\n        \"],[9,\"h4\",true],[12,\"class\",\"panel-title\",null],[10],[1,1,0,0,\"Add\"],[11],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\\n    \"],[9,\"div\",true],[12,\"class\",\"panel-body\",null],[10],[1,1,0,0,\"\\n        \"],[7,\"role-form\",[],[[\"@mode\",\"@role\",\"@createRole\"],[\"add\",[27,[26,0,\"AppendSingleId\"],[]],[31,620,6,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[]],\"createRole\"],null]]],null],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"tableColumns\",\"action\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "universal-cms/ember-quill/tests/templates/roles/list.hbs"
    }
  });

  _exports.default = _default;
});