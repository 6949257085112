define("universal-cms/controllers/universal-studios/news/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    error: false,
    tableColumns: null,
    tableClasses: Ember.Object.create({
      'globalFilterWrapper': 'pull-right'
    }),

    init() {
      this._super(...arguments);

      this.set('tableColumns', [{
        'propertyName': 'title',
        'title': 'Title'
      }, {
        'propertyName': 'link',
        'title': 'Link'
      }, {
        'propertyName': 'meta',
        'title': 'Meta'
      }, {
        'propertyName': 'date',
        'title': 'Date'
      }, {
        'propertyName': 'featured',
        'title': 'Featured'
      }, {
        title: 'Manage',
        component: 'manage',
        editable: false
      }]);
    },

    actions: {
      deleteNewsArticle(newsArticle) {
        if (!confirm(`Are you sure you want to delete news article "${newsArticle.get('title')}"?`)) {
          return;
        }

        newsArticle.set('isDeleting', true);
        this.universalStudiosService.deleteOne('news', newsArticle.get('id')).then(() => {
          this.notificationService.show('News article deleted', `<strong>${newsArticle.get('title')}</strong> was successfully deleted.`);
          this.model.removeObject(newsArticle);
        }, () => {
          newsArticle.set('isDeleting', false);
          this.notificationService.show('Error', `Could not delete news article <strong>${newsArticle.get('title')}</strong>.`);
        });
      }

    }
  });

  _exports.default = _default;
});