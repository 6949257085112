define("universal-cms/components/dreamworks/resource-offer-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    util: Ember.inject.service(),
    classNames: 'resource-offer-editor',
    isLoadingRetailers: false,
    loadRetailersError: null,
    retailers: null,
    pageResourceType: null,
    retailerToAdd: null,
    offerRetailers: null,
    cleanedOfferRetailers: Ember.computed('offerRetailers.[]', function () {
      if (!this.offerRetailers || this.offerRetailers.length < 1) {
        return null;
      } else {
        return this.offerRetailers.map(retailer => {
          const retailerObject = this.retailers.find(_retailer => _retailer.id === retailer.vendor);
          retailer.set('name', retailerObject.name);
          return retailer;
        });
      }
    }),
    addRetailerButtonDisabled: Ember.computed('retailerToAdd', 'offerRetailers', function () {
      return !this.retailerToAdd || this.offerRetailers.length >= 4;
    }),
    nonAddedRetailers: Ember.computed('retailers.[]', 'offerRetailers.[]', function () {
      if (!this.cleanedOfferRetailers) {
        return this.retailers;
      }

      return this.retailers.filter(retailer => this.cleanedOfferRetailers.findIndex(selectedSeasonRetailer => selectedSeasonRetailer.vendor === retailer.id) === -1);
    }),

    async init() {
      this._super(...arguments);

      await this.send('loadRetailers');

      if (typeof this.resourceRetailers === 'undefined' || this.resourceRetailers === null) {
        this.set('resourceRetailers', []);
      }

      this.set('offerRetailers', this.resourceRetailers);
    },

    actions: {
      async loadRetailers() {
        try {
          this.set('isLoadingRetailers', true);
          this.set('loadRetailersError', null);
          const retailers = await this.dreamworksService.getMany('retailers');
          this.set('retailers', retailers);
        } catch (error) {
          console.log('error', error);
          this.set('loadRetailersError', 'Could not load retailers.');
        } finally {
          this.set('isLoadingRetailers', false);
        }
      },

      addRetailer() {
        if (!this.retailerToAdd || this.offerRetailers.length >= 4) {
          return;
        }

        this.offerRetailers.pushObject(Ember.Object.create({
          vendor: this.retailerToAdd.id,
          link: this.retailerToAdd.link,
          offer_image: this.retailerToAdd.offer_image,
          heading: this.retailerToAdd.heading,
          description: this.retailerToAdd.description
        }));
        this.set('retailerToAdd', null);
      },

      deleteRetailer(retailerToRemove) {
        this.offerRetailers.removeObject(retailerToRemove);
      }

    }
  });

  _exports.default = _default;
});