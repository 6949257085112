define("universal-cms/services/auth", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    isAuthed: false,

    login(jwt, jwtEncoded) {
      this.set('isAuthed', true);
      this.set('jwt', jwtEncoded);
      this.set('decoded', jwt.decoded);
      this.set('user', jwt.decoded.sub);
      this.set('userLanguages', jwt.userLanguages);
      const localStorageLocaleCode = localStorage.getItem('language_locale_code');
      let languageLocaleCode;

      if (localStorageLocaleCode) {
        const isUserLanguage = jwt.userLanguages.some(userLanguage => {
          return userLanguage.language.locale_code === localStorageLocaleCode;
        });

        if (isUserLanguage) {
          languageLocaleCode = localStorageLocaleCode;
        }
      }

      if (!languageLocaleCode) {
        if (jwt.languageLocaleCode) {
          languageLocaleCode = jwt.languageLocaleCode;
        }
      }

      this.set('languageLocaleCode', languageLocaleCode);
    },

    setUrl(url) {
      this.set('url', url);
    },

    getUrl() {
      let route = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      return this.url + '/api' + (route !== null ? (route.substring(0, 1) !== '/' ? '/' : '') + route : '');
    },

    getOptions() {
      return new Ember.RSVP.Promise((resolve, reject) => {
        return _jquery.default.ajax({
          'method': 'OPTIONS',
          'url': this.getUrl(),
          'beforeSend': xhr => {
            xhr.setRequestHeader('X-Auth', 'Bearer ' + this.jwt);
          }
        }).then(response => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject();
          }
        });
      });
    },

    getAll(resourceName) {
      let params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      let expand = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      return new Ember.RSVP.Promise((resolve, reject) => {
        let url = this.getUrl(resourceName + (expand !== null ? '?expand=' + expand.join() : ''));
        url += url.indexOf('?') === -1 ? '?' : '&';
        url += `nocache=${Math.floor(Math.random() * 99999)}`;
        return _jquery.default.ajax({
          'method': 'GET',
          'url': url,
          'data': params,
          'beforeSend': xhr => {
            xhr.setRequestHeader('X-Auth', 'Bearer ' + this.get('jwt'));
          }
        }).then(response => {
          if (response.status === 'success') {
            let responseResourceName = resourceName.replace(/-/g, '_');
            let resources = Ember.A(response.data[responseResourceName].map(resource => {
              // Objectify expand fields
              if (expand !== null) {
                expand.forEach(expandKey => {
                  if (typeof resource === 'object') {
                    resource[expandKey] = Ember.Object.create(resource[expandKey]);
                  } else {
                    resource[expandKey].forEach((expandObject, expandObjectIndex) => {
                      resource[expandKey][expandObjectIndex] = Ember.Object.create(resource[expandKey][expandObjectIndex]);
                    });
                  }
                });
              }

              return Ember.Object.create(resource);
            }));
            resolve(resources);
          } else if (response.status === 'fail') {
            reject(response.data[0]);
          }
        });
      });
    },

    getOne(resourceName, id) {
      let expand = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      return new Ember.RSVP.Promise((resolve, reject) => {
        let url = this.getUrl(resourceName + '/' + id + (expand !== null ? '?expand=' + expand.join() : ''));
        url += url.indexOf('?') === -1 ? '?' : '&';
        url += `nocache=${Math.floor(Math.random() * 99999)}`;
        return _jquery.default.ajax({
          'method': 'GET',
          'url': url,
          'beforeSend': xhr => {
            xhr.setRequestHeader('X-Auth', 'Bearer ' + this.jwt);
          }
        }).then(response => {
          if (response.status === 'success') {
            let responseResourceName = resourceName.replace(/-/g, '_');
            let resource = response.data[responseResourceName][0]; // Objectify expand fields

            if (expand !== null) {
              expand.forEach(expandKey => {
                if (typeof resource === 'object') {
                  resource[expandKey] = Ember.Object.create(resource[expandKey]);
                } else {
                  resource[expandKey].forEach((expandObject, expandObjectIndex) => {
                    resource[expandKey][expandObjectIndex] = Ember.Object.create(resource[expandKey][expandObjectIndex]);
                  });
                }
              });
            }

            resolve(Ember.Object.create(resource));
          } else if (response.status === 'fail') {
            reject(response.data[0]);
          }
        });
      });
    },

    createOne(resourceName, data) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        return _jquery.default.ajax({
          'method': 'POST',
          'url': this.getUrl(resourceName),
          'data': JSON.stringify(data),
          'contentType': 'application/json',
          'beforeSend': xhr => {
            xhr.setRequestHeader('X-Auth', 'Bearer ' + this.jwt);
          }
        }).then(response => {
          if (response.status === 'success') {
            let responseResourceName = resourceName.replace(/-/g, '_');
            resolve(Ember.Object.create(response.data[responseResourceName][0]));
          } else if (response.status === 'fail') {
            reject(response.data[0]);
          }
        });
      });
    },

    replaceOne(resourceName, id, data) {
      let method = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'PUT';
      return new Ember.RSVP.Promise((resolve, reject) => {
        return _jquery.default.ajax({
          'method': method,
          'url': this.getUrl(resourceName + '/' + id),
          'data': JSON.stringify(data),
          'contentType': 'application/json',
          'beforeSend': xhr => {
            xhr.setRequestHeader('X-Auth', 'Bearer ' + this.jwt);
          }
        }).then(response => {
          if (response.status === 'success') {
            let responseResourceName = resourceName.replace(/-/g, '_');
            resolve(Ember.Object.create(response.data[responseResourceName][0]));
          } else if (response.status === 'fail') {
            reject(response.data[0]);
          }
        });
      });
    },

    deleteOne(resourceName, id) {
      return _jquery.default.ajax({
        'method': 'DELETE',
        'url': this.getUrl(resourceName + '/' + id),
        'beforeSend': xhr => {
          xhr.setRequestHeader('X-Auth', 'Bearer ' + this.jwt);
        }
      });
    },

    getUniversalPicturesWebsiteUrl() {
      let path = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      return this.get('decoded.aud')[2] + (path ? `/${path}` : '');
    },

    getUniversalStudiosUrl() {
      let path = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      return this.get('decoded.aud')[3] + (path ? `/${path}` : '');
    },

    getUWatchWebsiteUrl() {
      let path = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      return this.get('decoded').scopes.mappings.UWatch + (path ? `/${path}` : '');
    },

    getUWatchPreviewWebsiteUrl() {
      let path = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      return this.get('decoded').scopes.mappings.UWatchPreview + (path ? `/${path}` : '');
    }

  });

  _exports.default = _default;
});