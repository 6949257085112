define("universal-cms/ember-quill/tests/templates/components/super-table-yield/table-foot-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BHRdEQEC",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[5,[27,[26,4,\"BlockHead\"],[]],[[27,[26,5,\"Expression\"],[]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"    \"],[9,\"th\",true],[10],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"],[16,1,null],[1,1,0,0,\"\\n\\n\"],[5,[27,[26,4,\"BlockHead\"],[]],[[27,[26,6,\"Expression\"],[]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"    \"],[9,\"th\",true],[10],[1,1,0,0,\"\\n        \"],[9,\"button\",true],[13,\"disabled\",[27,[26,0,\"AppendSingleId\"],[]],null],[12,\"class\",\"btn btn-xs btn-primary\",null],[13,\"onclick\",[31,160,6,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[]],[27,[26,1,\"Expression\"],[]]],null],null],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,4,\"BlockHead\"],[]],[[27,[26,3,\"Expression\"],[]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"                Adding...\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"                Add\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"        \"],[11],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"],[5,[27,[26,4,\"BlockHead\"],[]],[[27,[26,7,\"Expression\"],[]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"    \"],[9,\"th\",true],[10],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"isDisabled\",\"onCreate\",\"action\",\"isCreating\",\"if\",\"orderable\",\"creatable\",\"deletable\"]}",
    "meta": {
      "moduleName": "universal-cms/ember-quill/tests/templates/components/super-table-yield/table-foot-row.hbs"
    }
  });

  _exports.default = _default;
});