define("universal-cms/components/dreamworks/page-component-modals/movie-list", ["exports", "universal-cms/components/dreamworks/page-component-modals/page-component-modal"], function (_exports, _pageComponentModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _pageComponentModal.default.extend({
    movies: null,
    isLoadingMovies: false,

    async init() {
      this._super(...arguments);

      await this.loadMovies();
    },

    async loadMovies() {
      try {
        this.set('isLoadingMovies', true);
        let movies = [];
        movies.pushObject({
          name: 'Movie 1'
        });
        movies.pushObject({
          name: 'Movie 2'
        });
        this.set('movies', movies);
      } catch (error) {
        console.log('error', error);
      } finally {
        this.set('isLoadingMovies', false);
      }
    }

  });

  _exports.default = _default;
});