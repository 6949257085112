define("universal-cms/components/pxl-asset-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    pxlMediaLibrary: Ember.inject.service(),
    tagName: 'button',
    type: 'image',
    attributeBindings: ['disabled'],
    isDisabled: false,
    disabled: Ember.computed('isOpening', 'isDisabled', function () {
      return this.isOpening || this.isDisabled;
    }),
    buttonText: Ember.computed('isOpening', 'isOpen', function () {
      if (this.isOpening) {
        return 'Opening...';
      } else if (this.isOpen) {
        return this.originalText;
      } else {
        return this.originalText;
      }
    }),
    originalText: null,
    isOpening: false,
    isOpen: false,
    requiredWidth: -1,
    requiredHeight: -1,

    init() {
      this._super(...arguments);

      this.set('originalText', this.text);
    },

    async click() {
      if (!this.onChoose || typeof this.onChoose !== 'function') {
        console.warn('No action specified for "onChoose".');
        return;
      } // Open file picker


      this.set('isOpening', true);

      try {
        const choosenMediaItem = await this.pxlMediaLibrary.chooseMediaItemAsync({
          type: this.type || 'image',
          selectable: true,
          requiredWidth: this.requiredWidth,
          requiredHeight: this.requiredHeight
        }, {
          onOpened: () => {
            this.setProperties({
              'isOpening': false,
              'isOpen': true
            });
          },
          onClosed: () => {
            this.set('isOpen', false);
          }
        }, 'dreamworks');

        if (this.onChoose && typeof this.onChoose === 'function') {
          this.onChoose(choosenMediaItem);
        }
      } catch (chooseMediaError) {
        if (chooseMediaError === 'close') {
          return;
        }

        console.log('chooseMediaError', chooseMediaError);
      }
    }

  });

  _exports.default = _default;
});