define("universal-cms/controllers/universal-brand/general/footer-link/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['type'],
    error: false,
    heading: Ember.computed('isNew', 'model.menuItem.title', function () {
      if (this.isNew === true) {
        let heading = 'Add ';

        if (this.type === 'footer_left') {
          heading += 'Left';
        } else if (this.type === 'footer_center') {
          heading += 'Center';
        } else if (this.type === 'footer_right') {
          heading += 'Right';
        }

        heading += ' Footer Link';
        return heading;
      } else {
        return this.get('model.menuItem.title');
      }
    }),
    showSubLinks: Ember.computed('isNew', 'model.menuItem.parent_menu_item_id', function () {
      return this.isNew === false && this.get('model.menuItem.parent_menu_item_id') === null;
    }),
    actions: {
      onAdded(addedMenuItem) {
        this.transitionToRoute('universalBrand.general.list');
      }

    }
  });

  _exports.default = _default;
});