define("universal-cms/routes/website/movie-promos/edit/spot-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      let parentModel = this.modelFor('website.movie-promos.edit');
      return Ember.RSVP.hash({
        spot: Ember.ArrayProxy.create(this.serviceResource.getResource('website', "movie-promos/" + parentModel.resource.id + "/spots", params.spot_id, "movie_promo_spots")),
        spot_options: this.serviceResource.getResources('website', 'movie-promos/' + parentModel.resource.id + '/spots/' + params.spot_id + '/options', null, 'movie_promo_spot_options'),
        spot_types: Ember.ArrayProxy.create(this.serviceResource.getResources('webiste', "spot-types")),
        promo: parentModel.resource
      });
    },

    afterModel(model) {
      let spotType = model.spot.promo_spot_type_id;
      return this.serviceResource.getResources('website', 'spot-types/' + spotType + '/options', null, 'spot_type_options').then(response => {
        model.spot_type_options = response;
      });
    }

  });

  _exports.default = _default;
});