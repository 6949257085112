define("universal-cms/routes/website/home-page-promos/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      let parentModel = this.modelFor('website.home-page-promos');
      this.set('resources', parentModel.options.location);
      return Ember.RSVP.hash({
        options: parentModel.options,
        resource: Ember.ArrayProxy.create(this.serviceResource.getResource('website', 'home-page-promos', params.movie_promo_id)),
        spots: Ember.ArrayProxy.create(this.serviceResource.getResources('website', `home-page-promos/${params.movie_promo_id}/spots`, null, 'home_page_promo_spots')),
        spot_types: Ember.ArrayProxy.create(this.serviceResource.getResources('webiste', 'spot-types'))
      });
    },

    actions: {
      newResource(resource, parentController) {
        parentController.set('isLoading', true);
        this.serviceResource.newResource('website', `home-page-promos/${resource.promo_id}/spots`, resource, 'home_page_promo_spots').then(resources => {
          let model = this.modelFor('website.homePagePromos.edit');
          parentController.set('isLoading', false);
          this.notificationService.show('New Resource', 'Resource was created successfully.');
          model.spots.pushObject(resources[0]);
        });
      }

    }
  });

  _exports.default = _default;
});