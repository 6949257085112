define("universal-cms/controllers/asset-groups/list", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    tableColumns: null,

    init() {
      this._super(...arguments);

      this.set('tableColumns', [{
        'propertyName': 'name',
        'title': 'Name'
      }, {
        'propertyName': 'description',
        'title': 'Description'
      }, {
        title: 'Manage',
        component: 'manage',
        editable: false
      }]);
    },

    actions: {
      createAssetGroup(data) {
        return this.movieService.createOne('asset-groups', {
          name: data.data.name,
          description: data.data.description,
          live_start: (0, _moment.default)().format('YYYY-MM-DD HH:mm:ss'),
          live_end: null
        }).then(createdAssetGroup => {
          this.notificationService.show('Asset group added', `<strong>${createdAssetGroup.name}</strong> was successfully added.`);
          this.model.pushObject(createdAssetGroup);
          return createdAssetGroup;
        }, error => {
          this.notificationService.show('Error', 'Could not add asset group.');
        });
      },

      deleteAssetGroup(assetGroup) {
        if (!confirm(`Are you sure you want to delete asset group "${assetGroup.get('name')}"?`)) {
          return;
        }

        assetGroup.set('isDeleting', true);
        this.movieService.deleteOne('asset-groups', assetGroup.id).then(() => {
          this.notificationService.show('Asset group deleted', `<strong>${assetGroup.name}</strong> was successfully deleted.`);
          this.model.removeObject(assetGroup);
        }).catch(() => {
          this.notificationService.show('Error', `Could not delete asset group <strong>${assetGroup.name}</strong>.`);
          assetGroup.set('isDeleting', false);
        });
      }

    }
  });

  _exports.default = _default;
});