define("universal-cms/routes/movies/edit/retailers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return Ember.RSVP.hash({
        retailers: this.movieService.getAll('retailers')
      });
    }

  });

  _exports.default = _default;
});