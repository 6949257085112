define("universal-cms/ember-quill/tests/templates/dashboard/documentation/edit-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L2h6lKP8",
    "block": "{\"symbols\":[],\"statements\":[[9,\"h1\",true],[10],[1,1,0,0,\"Edit page \\\"\"],[1,0,0,0,[27,[26,1,\"Expression\"],[\"title\"]]],[1,1,0,0,\"\\\"\"],[11],[1,1,0,0,\"\\n\\n\"],[9,\"div\",true],[12,\"class\",\"row\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"div\",true],[12,\"class\",\"col-sm-12 col-md-8\",null],[10],[1,1,0,0,\"\\n        \"],[9,\"div\",true],[12,\"class\",\"form-group\",null],[10],[1,1,0,0,\"\\n            \"],[9,\"label\",true],[10],[1,1,0,0,\"Title\"],[11],[1,1,0,0,\"\\n\\n            \"],[7,\"input\",[[23,\"class\",\"form-control\",null]],[[\"@type\",\"@value\"],[\"text\",[27,[26,2,\"AppendSingleId\"],[]]]],null],[1,1,0,0,\"\\n        \"],[11],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[7,\"wysiwyg-editor\",[],[[\"@name\",\"@value\",\"@height\"],[\"html\",[27,[26,1,\"AppendSingleId\"],[\"html\"]],300]],null],[1,1,0,0,\"\\n\\n\"],[5,[27,[26,4,\"BlockHead\"],[]],[[27,[26,3,\"Expression\"],[]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"    \"],[9,\"button\",false],[23,\"class\",\"btn btn-primary\",null],[3,0,0,[27,[26,0,\"ModifierHead\"],[]],[[27,[24,0],[]],\"save\"],null],[10],[1,1,0,0,\"Save\"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"    \"],[9,\"button\",true],[12,\"class\",\"btn btn-primary\",null],[12,\"disabled\",\"\",null],[10],[1,1,0,0,\"Saving...\"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"model\",\"title\",\"isSaving\",\"unless\"]}",
    "meta": {
      "moduleName": "universal-cms/ember-quill/tests/templates/dashboard/documentation/edit-page.hbs"
    }
  });

  _exports.default = _default;
});