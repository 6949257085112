define("universal-cms/controllers/universal-brand/general/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    newSocialAccount: Ember.Object.create(),
    isAddingSocialMediaAccount: false,
    isSavingHeaderTab: false,
    headerLogoId: null,
    headerSocialPlatformsSortable: null,
    actions: {
      deleteMainMenuItem(mainMenuItemToDelete) {
        const mainMenuItemTitleToDelete = mainMenuItemToDelete.get('title');
        return this.universalBrandService.deleteOne('menu-items', mainMenuItemToDelete.get('menu_item_id')).then(() => {
          this.get('model.mainMenuItems').removeObject(mainMenuItemToDelete);
          this.notificationService.show('Navigation Link Deleted', `<strong>${mainMenuItemTitleToDelete}</strong> has been deleted.`);
        }, () => {
          this.notificationService.show('Error', 'Could not delete navigation link.');
        });
      },

      createSocialMediaAccount() {
        this.set('isAddingSocialMediaAccount', true);
        const newSocialAccount = this.newSocialAccount;
        let saveData = newSocialAccount.getProperties('icon', 'title', 'external_link');
        saveData.show_in_header = newSocialAccount.get('show_in_header') ? 1 : 0;
        saveData.show_in_footer = newSocialAccount.get('show_in_footer') ? 1 : 0;
        return this.universalBrandService.createOne('social-accounts', saveData).then(createResponse => {
          this.get('model.socialAccounts').pushObject(createResponse);
          newSocialAccount.setProperties({
            'icon': '',
            'title': '',
            'external_link': ''
          });
          this.notificationService.show('Social Account Added', `<strong>${createResponse.title}</strong> has been added.`);
        }, () => {
          this.notificationService.show('Error', 'Could not add social account.');
        }).finally(() => {
          this.set('isAddingSocialMediaAccount', false);
        });
      },

      deleteSocialAccount(socialAccountToDelete) {
        const socialAccountTitleToDelete = socialAccountToDelete.get('title');
        return this.universalBrandService.deleteOne('social-accounts', socialAccountToDelete.get('social_account_id')).then(() => {
          this.get('model.socialAccounts').removeObject(socialAccountToDelete);
          this.notificationService.show('Social Account Deleted', `<strong>${socialAccountTitleToDelete}</strong> has been deleted.`);
        }, () => {
          this.notificationService.show('Error', 'Could not delete social account.');
        });
      },

      updateSocialAccount(socialAccount, column, value) {
        if (column === 'show_in_header' || column === 'show_in_footer') {
          value = value.target.checked === true ? 1 : 0;
        }

        if (value === socialAccount.get(column)) {
          return;
        }

        socialAccount.set(column, value);
        let saveData = {};
        saveData[column] = value;
        return this.universalBrandService.updateOne('social-accounts', socialAccount.get('social_account_id'), saveData).then(() => {
          this.notificationService.show('Saved', `Social account has been saved.`);
        }, () => {
          this.notificationService.show('Error', `Could not save social account.`);
        });
      },

      saveHeaderTab() {
        this.set('isSavingHeaderTab', true);
        return this.universalBrandService.createOne('settings/save', {
          'header_logo_image_id': this.model.headerLogoId
        }).then(() => {
          this.notificationService.show('Saved', `Settings has been saved.`);
        }, () => {
          this.notificationService.show('Error', `Could not save settings.`);
        }).finally(() => {
          this.set('isSavingHeaderTab', false);
        });
      },

      saveFooterTab() {
        this.set('isSavingFooterTab', true);
        return this.universalBrandService.createOne('settings/save', {
          'footer_logo_image_id': this.footerLogoId,
          'copyright_text': this.get('model.copyrightText.value')
        }).then(() => {
          this.notificationService.show('Saved', `Footer settings has been saved.`);
        }, () => {
          this.notificationService.show('Error', `Could not save footer settings.`);
        }).finally(() => {
          this.set('isSavingFooterTab', false);
        });
      },

      onDeleteFooterMenuItem(footerMenuItemToDelete) {
        return new Ember.RSVP.Promise((resolve, reject) => {
          if (!confirm(`Are you sure you want to delete menu item "${footerMenuItemToDelete.get('title')}"?`)) {
            reject();
            return;
          }

          return this.universalBrandService.deleteOne('menu-items', footerMenuItemToDelete.get('menu_item_id')).then(() => {
            this.notificationService.show('Deleted', `Footer menu item <strong>${footerMenuItemToDelete.get('title')}</strong> deleted.`);
            resolve();
          }, () => {
            this.notificationService.show('Error', `Could not delete footer menu item.`);
            reject();
          });
        });
      },

      updateSocialAccountsOrder(orderedSocialAccounts) {
        let newOrder = [];

        for (let item of orderedSocialAccounts) {
          newOrder.push(item.get('social_account_id'));
        }

        this.universalBrandService.updateOne('social-accounts', 'update-order', {
          items: newOrder
        }).then(() => {
          this.notificationService.show('Saved', `Social accounts order has been saved.`);
        }).catch(() => {
          this.notificationService.show('Error', `Could not save social accounts order.`);
        });
      },

      updateNavigationLinksOrder(orderedNavigationLinks) {
        let newOrder = [];

        for (let item of orderedNavigationLinks) {
          newOrder.push(item.get('menu_item_id'));
        }

        this.universalBrandService.updateOne('menu-items', 'update-order', {
          items: newOrder
        }).then(() => {
          this.notificationService.show('Saved', `Navigation links order has been saved.`);
        }).catch(() => {
          this.notificationService.show('Error', `Could not save navigation links order.`);
        });
      },

      setHeaderLogo(mediaItemId, mediaItem) {
        this.set('model.headerLogoId', mediaItemId);
        this.set('model.headerLogo', mediaItem);
      }

    }
  });

  _exports.default = _default;
});