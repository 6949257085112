define("universal-cms/controllers/universal-brand/categories/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    error: false,
    actions: {
      deleteCategory(category) {
        return new Ember.RSVP.Promise((resolve, reject) => {
          category.set('isDeleting', true);
          this.universalBrandService.deleteOne('categories', category.get('category_id')).then(() => {
            this.notificationService.show('Category deleted', `Category was successfully deleted.`);
            this.model.removeObject(category);
            resolve();
          }, () => {
            category.set('isDeleting', false);
            this.notificationService.show('Error', `Could not delete category.`);
            reject();
          });
        });
      },

      updateOrder(orderedCategories) {
        let newOrder = [];

        for (let category of orderedCategories) {
          newOrder.push(category.get('category_id'));
        }

        this.universalBrandService.updateOne('categories', 'update-order', {
          categories: newOrder
        }).then(() => {
          this.notificationService.show('Saved', `Categories order has been saved.`);
        }).catch(() => {
          this.notificationService.show('Error', `Could not save categories order.`);
        });
      }

    }
  });

  _exports.default = _default;
});