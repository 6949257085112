define("universal-cms/components/dreamworks/media-theater-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'media-theater-editor',
    mediaTheaterComponent: undefined,
    isMediaTheaterItemModalOpen: false,
    editingMediaTheaterItem: null,
    isLoadingResources: false,
    resources: null,
    items: Ember.computed('mediaTheaterComponent.data.items.{[],@each.id}', 'resources.[]', function () {
      const numItems = this.mediaTheaterComponent.data.items.length;
      return this.mediaTheaterComponent.data.items.map((item, itemIndex) => {
        let resourceTypeTitle;
        let slideProperty;

        if (item.slideProperty) {
          slideProperty = item.slideProperty;
          resourceTypeTitle = slideProperty;
        }

        let title;
        let assetId;

        if (slideProperty) {
          let resourceType;

          switch (slideProperty) {
            case 'Movie':
              resourceType = 'movies';
              resourceTypeTitle = 'Movie';
              break;

            case 'TvShow':
              resourceType = 'tv-shows';
              resourceTypeTitle = 'TV Show';
              break;

            case 'Game':
              resourceType = 'games';
              resourceTypeTitle = 'Game';
              break;

            case 'Franchise':
              resourceType = 'franchises';
              resourceTypeTitle = 'Franchise';
              break;
          }

          let resourceData = this.resources[resourceType].find(resourceItem => resourceItem.resource_id === item.id);
          title = resourceData.data.name;

          if (resourceData.data.hero_image && resourceData.data.hero_image.landscape) {
            assetId = resourceData.data.hero_image.landscape;
          }
        } else {
          title = item.heading;
          assetId = item.hero_image.landscape;
          resourceTypeTitle = 'Custom';
        }

        if (!item.index) {
          item.set('index', numItems);
        }

        item.set('title', title);
        item.set('assetId', assetId);
        item.set('resourceTypeTitle', resourceTypeTitle);
        return item;
      });
    }),

    async init() {
      this._super(...arguments);

      await this.loadResources(true);
    },

    async loadResources(isFirst) {
      let resourceTypes = ['movie', 'tv-show', 'game', 'franchise'];
      let resources = {};

      try {
        this.set('isLoadingResources', true);

        for (let resourceType of resourceTypes) {
          resources[`${resourceType}s`] = await this.dreamworksService.getMany(`${resourceType}-data`, {
            resource_type: resourceType.replace('-', '_')
          });
        }

        Ember.run.scheduleOnce('afterRender', this, this.initSortable, isFirst);
      } catch (e) {
        console.log(e);
      } finally {
        this.set('resources', resources);
        this.set('isLoadingResources', false);
      }
    },

    initSortable(isFirst) {
      const layoutItemsSortable = Sortable.create(document.getElementById('layout-items'), {
        handle: '.drag-handle',
        animation: 150,
        onSort: event => {
          const {
            oldIndex,
            newIndex
          } = event;

          if (oldIndex === newIndex) {
            return false;
          }

          const items = layoutItemsSortable.toArray().map(itemIndex => this.items.find(item => {
            return item.index === parseInt(itemIndex, 10);
          }));
          this.set('mediaTheaterComponent.data.items', items);
        }
      });

      if (isFirst) {
        this.set('originalResourcesOrder', layoutItemsSortable.toArray());
        this.set('originalMediaTheaterComponentDataItems', this.get('mediaTheaterComponent.data.items').map((item, itemIndex) => {
          item.set('index', itemIndex + 1);
          return item;
        }));
      }

      this.set('layoutItemsSortable', layoutItemsSortable);
    },

    actions: {
      editItem(item) {
        this.set('editingMediaTheaterItem', item);
        this.set('isMediaTheaterItemModalOpen', true);
      },

      removeItem(itemToRemove) {
        if (!confirm(`Are you sure you want to remove "${itemToRemove.title}"?`)) {
          return;
        }

        this.get('mediaTheaterComponent.data.items').removeObject(itemToRemove);
      },

      openNewItemModal() {
        this.set('editingMediaTheaterItem', Ember.Object.create({
          isNew: true
        }));
        this.set('isMediaTheaterItemModalOpen', true);
      },

      onSaveItem() {
        if (this.editingMediaTheaterItem.isNew) {
          this.get('mediaTheaterComponent.data.items').pushObject(this.editingMediaTheaterItem);
        } // this.layoutItemsSortable.save();
        // console.log('layoutItemsSortable.toArray()', this.layoutItemsSortable.toArray());

      }

    }
  });

  _exports.default = _default;
});