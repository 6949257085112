define("universal-cms/routes/dreamworks/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    async model() {
      const controller = this.controllerFor(this.routeName);
      controller.set('isLoadingLayout', true);
      this.is_saving = true;

      try {
        const options = await this.dreamworksService.getOptions('settings?language=en');
        return Ember.RSVP.hash({
          'user_interface': options.user_interface
        });
      } catch (error) {
        console.log('error', error);
      } finally {
        controller.set('isLoadingLayout', false);
      }
    }

  });

  _exports.default = _default;
});