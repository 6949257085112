define("universal-cms/controllers/people/list", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    tableColumns: null,

    init() {
      this._super(...arguments);

      this.set('tableColumns', [{
        'propertyName': 'name',
        'title': 'Name'
      }, {
        title: 'Manage',
        component: 'manage',
        editable: false
      }]);
    },

    actions: {
      createPerson(data) {
        this.set('isSaving', true);
        return this.movieService.createOne('people', {
          name: data.get('name'),
          live_start: (0, _moment.default)().format('YYYY-MM-DD HH:mm:ss'),
          live_end: null
        }).then(createdPerson => {
          this.notificationService.show('Person added', `<strong>${createdPerson.name}</strong> was successfully added.`);
          this.model.pushObject(createdPerson);
          this.set('isSaving', false);
          return createdPerson;
        });
      },

      deletePerson(person) {
        if (!confirm(`Are you sure you want to delete person "${person.get('name')}"?`)) {
          return;
        }

        person.set('isDeleting', true);
        this.movieService.deleteOne('people', person.id).then(() => {
          this.notificationService.show('Person deleted', `<strong>${person.name}</strong>  was successfully deleted.`);
          this.model.removeObject(person);
        });
      }

    }
  });

  _exports.default = _default;
});