define("universal-cms/ember-quill/tests/templates/components/dreamworks/page-component-modals/media-catalog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oLhnWMIq",
    "block": "{\"symbols\":[],\"statements\":[[9,\"h2\",true],[10],[1,1,0,0,\"\\n    Media Catalog\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[9,\"div\",true],[12,\"class\",\"row\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"div\",true],[12,\"class\",\"col-sm-12 col-md-8\",null],[10],[1,1,0,0,\"\\n        \"],[7,\"pxl-form-field\",[],[[\"@label\",\"@value\"],[\"Title\",[27,[26,0,\"AppendSingleId\"],[\"data\",\"sectionTitle\"]]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[9,\"div\",true],[12,\"class\",\"row\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"div\",true],[12,\"class\",\"col-sm-12 col-md-8\",null],[10],[1,1,0,0,\"\\n        \"],[7,\"pxl-form-field\",[],[[\"@label\",\"@value\"],[\"Section Property (i.e. Game, Movie, TvShow)\",[27,[26,0,\"AppendSingleId\"],[\"data\",\"sectionProperty\"]]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\"],[11]],\"hasEval\":false,\"upvars\":[\"pageComponent\"]}",
    "meta": {
      "moduleName": "universal-cms/ember-quill/tests/templates/components/dreamworks/page-component-modals/media-catalog.hbs"
    }
  });

  _exports.default = _default;
});