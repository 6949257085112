define("universal-cms/routes/option-groups/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return this.movieService.getAll('option-groups');
    },

    hasOptionGroups: Ember.computed('model', function () {
      return this.model.length > 0;
    })
  });

  _exports.default = _default;
});