define("universal-cms/components/dreamworks/page-component-modals/gabbys-website-video-teaser", ["exports", "universal-cms/components/dreamworks/page-component-modals/page-component-modal"], function (_exports, _pageComponentModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _pageComponentModal.default.extend({
    classNames: 'gabbys-website-video-teaser',

    init() {
      this._super(...arguments);

      if (!this.get('pageComponent.data.type')) {
        this.set('pageComponent.data.type', 'custom');
      }
    }

  });

  _exports.default = _default;
});