define("universal-cms/components/pxl-form-file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'pxl-form-file',
    classNameBindings: ['requiredDimensionsText:has-required-dimensions-text'],
    type: 'image',
    label: undefined,
    required: false,
    assetId: undefined,
    asset: null,
    requiredWidth: null,
    requiredHeight: null,
    isLoadingAsset: false,
    loadError: false,
    requiredDimensionsText: Ember.computed('requiredWidth', 'requiredHeight', function () {
      if (this.requiredWidth && this.requiredHeight) {
        return `${this.requiredWidth} x ${this.requiredHeight}px`;
      } else if (this.requiredWidth) {
        return `${this.requiredWidth}px wide`;
      } else if (this.requiredHeight) {
        return `${this.requiredWidth}px tall`;
      } else {
        return null;
      }
    }),

    async init() {
      this._super(...arguments);

      if (this.assetId) {
        await this.loadAsset();
      }
    },

    async loadAsset() {
      if (!this.assetId) {
        return;
      }

      this.set('isLoadingAsset', true);
      this.set('loadError', false);

      try {
        const asset = await this.dreamworksService.getOne('assets', this.assetId);
        this.set('asset', asset);

        if (this.type === 'image') {
          // Load image
          let imgElement = document.createElement('img');

          imgElement.onload = () => {
            this.set('isLoadingAsset', false);
          };

          imgElement.onerror = () => {
            this.set('loadError', true);
            this.set('isLoadingAsset', false);
          };

          imgElement.src = asset.url;
        } else {
          this.set('isLoadingAsset', false);
        }
      } catch (error) {
        console.log(error);
        this.set('loadError', true);
        this.set('isLoadingAsset', false);
      }
    },

    actions: {
      async onChooseAsset(assetId, path) {
        this.set('assetId', assetId);

        if (assetId) {
          await this.loadAsset();
        }

        if (this.onAssetChange) {
          this.onAssetChange({
            target: {
              value: {
                type: 'asset',
                asset_id: assetId,
                path
              }
            }
          });
        }
      },

      async onDeleteAsset() {
        this.set('assetId', null);

        if (this.onAssetDelete) {
          this.onAssetDelete({
            target: {
              value: undefined
            }
          });
        }
      }

    }
  });

  _exports.default = _default;
});