define("universal-cms/routes/universal-studios/movies/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      const controller = this.controllerFor(this.routeName);
      const movieId = params.movie_id;
      const isNew = movieId === 'add';
      controller.set('isNew', isNew);
      let rsvp = {};
      rsvp.movie = isNew ? Ember.Object.create({
        isNew: true
      }) : this.universalStudiosService.getOne('movies', movieId).then(movie => {
        if (movie.get('desktop_image')) {
          movie.set('desktop_image_url', this.auth.getUniversalStudiosUrl(`uploads/${movie.get('desktop_image')}`));
        }

        if (movie.get('mobile_image')) {
          movie.set('mobile_image_url', this.auth.getUniversalStudiosUrl(`uploads/${movie.get('mobile_image')}`));
        }

        return movie;
      }, () => {
        controller.set('error', true);
      });
      return Ember.RSVP.hash(rsvp);
    }

  });

  _exports.default = _default;
});