define("universal-cms/components/dreamworks/page-component-modals/social-callout", ["exports", "universal-cms/components/dreamworks/page-component-modals/page-component-modal"], function (_exports, _pageComponentModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _pageComponentModal.default.extend({
    classNames: 'dreamworks-social-callout',
    resourceFormOptions: undefined,

    init() {
      this._super(...arguments);

      if (!this.pageComponent.data.image || !this.pageComponent.data.image.social_image && !this.pageComponent.data.image.landscape || isArray(this.pageComponent.data.image) && this.pageComponent.data.image.length === 0) {
        this.pageComponent.data.set('image', {});
      }
    },

    actions: {
      onResourceChanged() {
        console.log('onResourceChanged', this.pageComponent.data.id, this.pageComponent.data.type);
      }

    }
  });

  _exports.default = _default;
});