define("universal-cms/ember-quill/tests/templates/website/preview/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+xcX3lam",
    "block": "{\"symbols\":[],\"statements\":[[7,\"preview-token-form\",[],[[\"@mode\",\"@previewToken\",\"@pages\",\"@save\"],[\"edit\",[27,[26,0,\"AppendSingleId\"],[\"previewToken\"]],[27,[26,1,\"AppendSingleId\"],[]],[31,93,6,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[]],\"onSave\"],null]]],null],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"pages\",\"action\"]}",
    "meta": {
      "moduleName": "universal-cms/ember-quill/tests/templates/website/preview/edit.hbs"
    }
  });

  _exports.default = _default;
});