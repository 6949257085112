define("universal-cms/controllers/dashboard/documentation/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    isAdding: false,
    title: '',
    actions: {
      addPage() {
        let title = this.title;

        if (title === '') {
          this.notificationService.show('Error', `Specify title of page.`);
          return;
        }

        this.set('isAdding', true);
        this.auth.createOne('documentation-pages', {
          title: title,
          html: null
        }).then(createdDocumentationPage => {
          this.model.pushObject(createdDocumentationPage);
          this.set('title', '');
          this.set('isAdding', false);
          this.notificationService.show('Added', `Page <strong>${title}</strong> was successfully added.`);
          this.transitionToRoute('dashboard.documentation.editPage', createdDocumentationPage.get('id'));
        }, error => {
          this.notificationService.show('Error', 'Could not create page.');
        });
      }

    }
  });

  _exports.default = _default;
});