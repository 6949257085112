define("universal-cms/components/service-resource-list-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isUpdating: false,
    isDeleting: false,
    actions: {
      saveResource() {
        const resource = this.resource;
        this.set('isUpdating', true);
        this.updateResource(resource).finally(() => {
          this.set('isUpdating', false);
        });
      },

      deleteResource() {
        const resource = this.resource;
        this.set('isDeleting', true);
        this.deleteResource(resource);
      }

    }
  });

  _exports.default = _default;
});