define("universal-cms/components/loader-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    size: 'medium',
    isSmall: Ember.computed('size', function () {
      return this.size === 'small';
    }),
    isMedium: Ember.computed('size', function () {
      return this.size === 'medium';
    }),
    isLarge: Ember.computed('large', function () {
      return this.size === 'large';
    }),
    withText: true,
    noTopMargin: false,
    centered: true,
    containerClass: Ember.computed('size', 'noTopMargin', 'centered', function () {
      return 'loader-box-container clearfix ' + this.size + (this.noTopMargin === true ? ' no-top-margin' : '') + (this.withText === true ? ' with-text' : '') + (this.centered === true ? ' centered' : '');
    })
  });

  _exports.default = _default;
});