define("universal-cms/components/dreamworks/page-component-modals/page-component-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    hasChangesBeenMade: false,
    hasChangesBeenMadeObject: {},

    init() {
      this._super(...arguments);

      this.set('languageId', this.get('pageData.language_id'));
    }

  });

  _exports.default = _default;
});