define("universal-cms/controllers/dashboard/documentation/view-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    isDeleting: false,
    escapedHtml: Ember.computed('model.html', function () {
      return Ember.String.htmlSafe(this.model.html);
    }),
    actions: {
      delete(documentationPage) {
        if (!confirm(`Are you sure you want to delete page "${documentationPage.get('title')}"?`)) {
          return;
        }

        let itemIndexInParentModel = this.parentModel.findIndex(item => {
          return item.get('id') === documentationPage.get('id');
        });
        this.set('isDeleting', true);
        this.auth.deleteOne('documentation-pages', documentationPage.get('id')).then(() => {
          if (itemIndexInParentModel !== -1) {
            this.parentModel.removeAt(itemIndexInParentModel);
          }

          this.set('isDeleting', false);
          this.notificationService.show('Error', `Page <strong>${documentationPage.get('title')}</strong> was deleted successfully.`);
          this.transitionToRoute('dashboard.documentation');
        }, error => {
          this.set('isDeleting', false);
          this.notificationService.show('Error', `Could not delete page <strong>${documentationPage.get('title')}</strong>.`);
        });
      }

    }
  });

  _exports.default = _default;
});