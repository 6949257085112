define("universal-cms/controllers/universal-studios/banners/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    error: false,
    heading: Ember.computed('isNew', function () {
      return this.isNew === true ? 'Add Banner' : 'Edit Banner';
    }),
    pages: null,
    isSaving: false,
    saveButtonText: Ember.computed('isNew', 'isSaving', function () {
      if (this.isNew === true) {
        return this.isSaving === true ? 'Adding...' : 'Add';
      } else {
        return this.isSaving === true ? 'Saving...' : 'Save';
      }
    }),

    init() {
      this._super(...arguments);

      this.set('pages', [{
        value: 'home',
        text: 'Home'
      }, {
        value: 'videos',
        text: 'Videos'
      }, {
        value: 'news',
        text: 'News'
      }, {
        value: 'side',
        text: 'Side'
      }]);
    },

    actions: {
      save() {
        this.set('isSaving', true);
        const banner = this.get('model.banner');
        let saveData = banner.getProperties('link', 'page', 'desktop_image', 'mobile_image');

        if (this.isNew) {
          this.universalStudiosService.createOne('banners', saveData, true).then(createResponse => {
            if (typeof createResponse.errors === 'undefined') {
              this.notificationService.show('Banner Added', 'Banner has been added.');
              this.target.transitionTo(this.routeName, createResponse.get('id'));
            } else {
              this.notificationService.show('Error', this.universalStudiosService.formatErrorResponse(createResponse.errors));
            }
          }, () => {
            this.notificationService.show('Error', 'Could not add banner.');
          }).finally(() => {
            this.set('isSaving', false);
          });
        } else {
          this.universalStudiosService.updateOne('banners', banner.get('id'), saveData, true).then(updateResponse => {
            if (typeof updateResponse.errors === 'undefined') {
              this.notificationService.show('Banner Updated', 'Banner has been updated.');
              this.target.transitionTo(this.routeName, updateResponse.get('id'));
            } else {
              this.notificationService.show('Error', this.universalStudiosService.formatErrorResponse(updateResponse.errors));
            }
          }, () => {
            this.notificationService.show('Error', 'Could not update banner.');
          }).finally(() => {
            this.set('isSaving', false);
          });
        }
      }

    }
  });

  _exports.default = _default;
});