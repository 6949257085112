define("universal-cms/components/movie-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    statuses: null,
    isLoading: true,
    isEditing: Ember.computed('mode', function () {
      return this.mode === 'edit';
    }),
    title: Ember.computed('movie.title', function () {
      return this.get('movie.title');
    }),
    slug: Ember.computed('movie.slug', function () {
      return this.get('movie.slug');
    }),
    selectedStatus: Ember.computed('movie.status', function () {
      return this.get('movie.status');
    }),
    saveText: Ember.computed(function () {
      return this.mode === 'add' ? 'Add' : 'Save';
    }),
    savingText: Ember.computed(function () {
      return (this.mode === 'add' ? 'Adding' : 'Saving') + '...';
    }),
    backButtonText: Ember.computed(function () {
      return this.mode === 'add' ? 'Cancel' : 'Back';
    }),
    isArchived: Ember.computed('selectedStatus', function () {
      return this.selectedStatus === 'archived';
    }),
    archiveUrl: Ember.computed('movie.archive_url', function () {
      return this.get('movie.archive_url');
    }),

    init() {
      this._super(...arguments);

      this.set('statuses', [{
        value: 'future release',
        text: 'Future Release'
      }, {
        value: 'future release non listed',
        text: 'Future Release - Non-listed'
      }, {
        value: 'current',
        text: 'Current'
      }, {
        value: 'archived',
        text: 'Archived'
      }, {
        value: 'home ent',
        text: 'Home Ent'
      }]);
    },

    actions: {
      save(movie) {
        let retailersSaveValue = this.movieRetailers ? JSON.stringify(this.movieRetailers) : null; // Make sure any old incorrect DB values are being saved correctly

        if (retailersSaveValue === 'Array') {
          retailersSaveValue = null;
        }

        let data = Ember.Object.create({
          title: this.title,
          slug: this.slug,
          status: this.selectedStatus,
          archiveUrl: this.archiveUrl,
          theatricalRelease: this.get('movie.theatrical_release'),
          uwatchRelease: this.get('movie.uwatch_release') ? this.get('movie.uwatch_release') : null,
          showInUniversalPictures: this.get('movie.show_in_universal_pictures'),
          optionGroups: this.movieOptionGroups,
          retailers: retailersSaveValue
        });
        this.updateMovie(movie, data);
      },

      tabClick(route) {
        this.linkTo(route);
      },

      updateTheatricalDate(value) {
        console.log(value);
      }

    }
  });

  _exports.default = _default;
});