define("universal-cms/components/bs-datetimepicker", ["exports", "ember-bootstrap-datetimepicker/components/bs-datetimepicker"], function (_exports, _bsDatetimepicker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _bsDatetimepicker.default;
    }
  });
});