define("universal-cms/controllers/roles/list", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    tableColumns: null,

    init() {
      this._super(...arguments);

      this.set('tableColumns', [{
        'propertyName': 'name',
        'title': 'Name (singular)'
      }, {
        'propertyName': 'name_plural',
        'title': 'Name (plural)'
      }, {
        title: 'Manage',
        component: 'manage',
        editable: false
      }]);
    },

    actions: {
      createRole(data) {
        return this.movieService.createOne('roles', {
          name: data.data.name,
          name_plural: data.data.name_plural,
          live_start: (0, _moment.default)().format('YYYY-MM-DD HH:mm:ss'),
          live_end: null
        }).then(createdRole => {
          this.model.pushObject(createdRole);
          this.notificationService.show('Role added', `<strong>${createdRole.name}</strong> was successfully added.`);
          return createdRole;
        }, error => {
          this.notificationService.show('Error', 'Could not add role.');
        });
      },

      deleteRole(role) {
        if (!confirm(`Are you sure you want to delete role "${role.get('name')}"?`)) {
          return;
        }

        role.set('isDeleting', true);
        this.movieService.deleteOne('roles', role.get('id')).then(() => {
          this.notificationService.show('Role deleted', `<strong>${role.name}</strong> was successfully deleted.`);
          this.model.removeObject(role);
        });
      }

    }
  });

  _exports.default = _default;
});