define("universal-cms/helpers/is-checked", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isChecked = isChecked;

  function isChecked(_ref) {
    let [key, map] = _ref;
    return map[key] || false;
  }

  var _default = Ember.Helper.helper(isChecked);

  _exports.default = _default;
});