define("universal-cms/ember-quill/tests/templates/components/bread-crumb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1kBXSBEM",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[5,[27,[26,3,\"BlockHead\"],[]],[[31,0,0,[27,[26,2,\"CallHead\"],[]],[[31,0,0,[27,[26,2,\"CallHead\"],[]],[[27,[26,1,\"Expression\"],[]]],null]],null]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"    \"],[9,\"li\",true],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,0,\"BlockHead\"],[]],[[27,[24,1],[\"route\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"            \"],[7,\"link-to\",[],[[\"@route\"],[[27,[24,1],[\"route\"]]]],[[\"default\"],[{\"statements\":[[1,0,0,0,[27,[24,1],[\"text\"]]]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"            \"],[1,0,0,0,[27,[24,1],[\"text\"]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"    \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"items\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "universal-cms/ember-quill/tests/templates/components/bread-crumb.hbs"
    }
  });

  _exports.default = _default;
});