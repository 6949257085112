define("universal-cms/components/brand/general-seo-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'form',
    isSaving: false,
    saveButtonText: Ember.computed('isSaving', function () {
      return this.isSaving === true ? 'Saving...' : 'Save';
    }),
    pageMetaItems: Ember.A(),
    formattedPageMetaItems: Ember.computed('pageMetaItems.[]', function () {
      let formattedPageMetaItems = Ember.Object.create();

      for (let pageMetaItem of this.pageMetaItems) {
        const name = pageMetaItem.get('name');

        if (name === 'facebook_image' || name === 'twitter_image' || name === 'google_plus_image') {
          formattedPageMetaItems.set(name, pageMetaItem);
        } else {
          formattedPageMetaItems.set(name, pageMetaItem.get('content'));
        }
      }

      return formattedPageMetaItems;
    }),
    actions: {
      save() {
        if (this.isSaving === true) {
          return;
        }

        this.set('isSaving', true);
        let saveData = {};
        let pageMetaItems = this.formattedPageMetaItems;
        saveData = pageMetaItems.getProperties('page_title', 'meta_description', 'meta_keywords', 'share_title', 'facebook_description', 'facebook_image_id', 'twitter_description', 'twitter_image_id', 'google_plus_description', 'google_plus_image_id');
        this.universalBrandService.updateOne('page-meta-items', 'save', {
          page_meta_items: saveData
        }).then(() => {
          this.notificationService.show('Saved', 'SEO has been saved.');
        }, e => {
          console.error(e);
          this.notificationService.show('Error', 'Could not save SEO.');
        }).finally(() => {
          this.set('isSaving', false);
        });
      }

    }
  });

  _exports.default = _default;
});