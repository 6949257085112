define("universal-cms/helpers/title-case", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.titleCase = titleCase;

  function titleCase(params) {
    let text = params[0];
    return _lodash.default.startCase(text);
  }

  var _default = Ember.Helper.helper(titleCase);

  _exports.default = _default;
});