define("universal-cms/controllers/movies/list", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    tableColumns: null,
    movieStatuses: null,
    newMovie: null,
    createMovieButtonText: 'Add',
    createMovieButtonLoadingText: 'Adding...',
    isCreatingMovie: false,

    init() {
      this._super(...arguments);

      this.set('tableColumns', [{
        'propertyName': 'title',
        'title': 'Title'
      }, {
        'propertyName': 'theatrical_release',
        'title': 'Theatrical Release Date'
      }, {
        'title': 'Status',
        'component': 'models-table/movie-status'
      }, {
        'title': 'Status',
        'component': 'models-table/live'
      }, {
        title: 'Manage',
        component: 'movieActions',
        editable: false
      }]);
      this.set('movieStatuses', [{
        value: 'future release',
        text: 'Future Release'
      }, {
        value: 'future release non listed',
        text: 'Future Release - Non-listed'
      }, {
        value: 'current',
        text: 'Current'
      }, {
        value: 'archived',
        text: 'Archived'
      }, {
        value: 'home ent',
        text: 'Home Ent'
      }]);
      this.set('newMovie', {
        title: null,
        status: null,
        theatricalRelease: null,
        uwatchRelease: null,
        showInUniversalPictures: true
      });
    },

    actions: {
      createMovie(data) {
        if (data.title === null || data.status === null || data.theatricalRelease === null) {
          return;
        }

        this.set('isCreatingMovie', true);
        this.movieService.createOne('movies', {
          'title': data.title,
          'slug': data.title.parameterize(),
          'status': data.status,
          'theatrical_release': (0, _moment.default)(data.theatricalRelease).format('YYYY-MM-DD'),
          'uwatch_release': data.uwatchRelease ? (0, _moment.default)(data.uwatchRelease).format('YYYY-MM-DD') : null,
          'show_in_universal_pictures': data.showInUniversalPictures,
          'live_start': null,
          'live_end': null
        }).then(createdMovie => {
          this.notificationService.show('Movie added', `<strong>${createdMovie.title}</strong> was successfully added.`);
          this.model.pushObject(createdMovie);
          this.set('isCreatingMovie', false);
          this.set('newMovie', {
            title: null,
            status: null //theatricalRelease: null

          });
          this.transitionToRoute('movies.edit', createdMovie);
          return createdMovie;
        });
      },

      deleteMovie(movie) {
        if (!confirm(`Are you sure you want to delete movie "${movie.title}"?`)) {
          return;
        }

        movie.set('isDeleting', true);
        this.movieService.deleteOne('movies', movie.id).then(() => {
          this.notificationService.show('Movie deleted', `<strong>${movie.title}</strong> was successfully deleted.`);
          this.model.removeObject(movie);
        });
      },

      onPublish(data) {
        return this.movieService.replaceOne('movies', data.resource.id, {
          title: data.resource.title,
          slug: data.resource.slug,
          status: data.resource.status,
          theatrical_release: data.resource.theatrical_release,
          uwatch_release: data.resource.uwatch_release,
          show_in_universal_pictures: data.resource.showInUniversalPictures,
          live_start: data.publishDate,
          live_end: data.unpublishDate
        }).then(replacedMovie => {
          data.doneCallback(replacedMovie);
          this.notificationService.show('Published', `<strong>${replacedMovie.title}</strong> was published.`);
          return replacedMovie;
        });
      },

      onUnpublish(data) {
        return this.movieService.replaceOne('movies', data.resource.id, {
          title: data.resource.title,
          slug: data.resource.slug,
          status: data.resource.status,
          theatrical_release: data.resource.theatrical_release,
          uwatch_release: data.resource.uwatch_release,
          show_in_universal_pictures: data.resource.showInUniversalPictures,
          live_start: null,
          live_end: null
        }).then(replacedMovie => {
          data.doneCallback(replacedMovie);
          this.notificationService.show('Unublished', `<strong>${replacedMovie.title}</strong> was unpublished.`);
          return replacedMovie;
        });
      }

    }
  });

  _exports.default = _default;
});