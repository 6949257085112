define("universal-cms/components/universal-brand-page-layout-manager", ["exports", "universal-cms/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['universal-brand-page-layout-manager'],
    page: null,
    versions: Ember.A(),
    selectedVersion: null,
    isCreatingVersion: false,
    isRenamingVersion: false,
    isChangingVersion: false,
    isRefreshingVersions: false,
    isLoadingObserver: Ember.observer('isCreatingVersion', 'isRenamingVersion', 'isDeletingVersion', 'isChangingVersion', 'isRefreshingVersions', 'isSettingNewLiveDate', function () {
      this.set('isLoading', this.isChangingVersion || this.isRenamingVersion || this.isCreatingVersion || this.isDeletingVersion || this.isRefreshingVersions || this.isSettingNewLiveDate);
    }),
    loadingText: Ember.computed('isCreatingVersion', 'isRenamingVersion', 'isChangingVersion', 'isDeletingVersion', 'isRefreshingVersions', function () {
      if (this.isChangingVersion) {
        return 'Loading version...';
      } else if (this.isRenamingVersion) {
        return 'Renaming version...';
      } else if (this.isDeletingVersion) {
        return 'Deleting version...';
      } else if (this.isCreatingVersion) {
        return 'Creating version...';
      } else if (this.isRefreshingVersions) {
        return 'Refreshing versions...';
      } else if (this.isSettingNewLiveDate) {
        return 'Updating live date...';
      } else {
        return 'Loading...';
      }
    }),
    loadError: null,
    formattedVersions: Ember.computed('versions.[]', 'selectedVersion', function () {
      const selectedVersion = this.selectedVersion;
      return this.versions.map(version => {
        const isCurrentVersion = selectedVersion !== null && version.get('page_version_id') === selectedVersion.get('page_version_id');
        version.setProperties({
          'isCurrentVersion': isCurrentVersion,
          'itemClass': 'item clearfix' + (isCurrentVersion ? ' current-version' : '')
        });
        return version;
      });
    }),

    init() {
      this._super();

      this.set('isLoading', true);
      this.refreshVersions().then(() => {
        const versions = this.versions;

        if (versions.length > 0) {
          const initialVersion = this.versions[0];
          this.send('onChangeVersion', initialVersion.get('page_version_id'));
        }
      });
    },

    refreshVersions() {
      return new Ember.RSVP.Promise((resolve, reject) => {
        this.set('isRefreshingVersions', true);
        this.set('loadError', null);
        this.universalBrandService.getAll(`pages/${this.get('page.page_id')}/versions?relationships[]=desktopBannerImage&relationships[]=mobileBannerImage`).then(versions => {
          if (this.selectedVersion !== null) {
            const formattedSelectedVersion = versions.find(version => {
              return version.get('page_version_id') === this.selectedVersion.get('page_version_id');
            });

            if (formattedSelectedVersion) {
              this.set('selectedVersion', formattedSelectedVersion);
            }
          }

          this.set('versions', versions);
          resolve();
        }, () => {
          this.set('loadError', 'Could not load versions.');
          reject();
        }).finally(() => {
          this.set('isRefreshingVersions', false);
        });
      });
    },

    actions: {
      onNewVersion(name) {
        let pageVersionIdToDuplicate = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
        this.set('isCreatingVersion', true);
        this.universalBrandService.createOne(`pages/${this.get('page.page_id')}/versions`, {
          page_id: this.get('page.page_id'),
          name: name,
          duplicate: pageVersionIdToDuplicate
        }).then(createdPageVersion => {
          this.notificationService.show('Version Created', `Version <strong>${createdPageVersion.get('title')}</strong> has been created.`);
          this.refreshVersions().then(() => {
            const newVersion = this.versions.find(version => {
              return version.get('page_version_id') === createdPageVersion.get('page_version_id');
            });

            if (newVersion) {
              this.set('selectedVersion', newVersion);
            }
          });
        }, () => {
          this.notificationService.show('Error', 'Could not create version.');
        }).finally(() => {
          this.set('isCreatingVersion', false);
        });
      },

      onRenameVersion(newName) {
        this.set('isRenamingVersion', true);
        this.universalBrandService.updateOne(`pages/${this.get('page.page_id')}/versions/${this.get('selectedVersion.page_version_id')}`, 'rename', {
          name: newName
        }).then(renamedVersion => {
          this.notificationService.show('Version Renamed', 'Version has been renamed.');
          this.set('selectedVersion.title', renamedVersion.get('title'));
          this.refreshVersions();
        }, () => {
          this.notificationService.show('Error', 'Could not rename version.');
        }).finally(() => {
          this.set('isRenamingVersion', false);
        });
      },

      onDeleteVersion() {
        this.set('isDeletingVersion', true);
        const versionTitle = this.get('selectedVersion.title');
        this.universalBrandService.deleteOne('page-versions', this.get('selectedVersion.page_version_id')).then(() => {
          this.set('selectedVersion', null);
          this.notificationService.show('Version Deleted', `Version <strong>${versionTitle}</strong> has been deleted.`);
          this.refreshVersions();
        }, () => {
          this.notificationService.show('Error', `Could not delete version <strong>${versionTitle}</strong>.`);
        }).finally(() => {
          this.set('isDeletingVersion', false);
        });
      },

      onChangeVersion(pageVersionId) {
        this.set('isChangingVersion', true);
        this.set('loadError', null);
        this.universalBrandService.getOne(`pages/${this.get('page.page_id')}/versions`, pageVersionId).then(pageVersion => {
          let formattedPageVersion = this.versions.find(version => {
            return version.get('page_version_id') === pageVersion.get('page_version_id');
          });
          this.onChangedVersion(formattedPageVersion).finally(() => {
            this.set('selectedVersion', formattedPageVersion);
            this.set('isChangingVersion', false);
          });
        }, () => {
          this.set('loadError', 'Could not change version.');
          this.set('isChangingVersion', false);
        });
      },

      onSetLiveDate(newLiveDate) {
        this.set('isSettingNewLiveDate', true);
        this.universalBrandService.updateOne(`pages/${this.get('page.page_id')}/versions/${this.get('selectedVersion.page_version_id')}`, 'set-live-date', {
          time: newLiveDate ? newLiveDate.clone().tz(_environment.default.databaseTimezone).format('YYYY-MM-DD HH:mm:ss') : null
        }).then(updatedVersion => {
          this.notificationService.show('Live Date Updated', 'Live date has been updated.');

          if (this.selectedVersion !== null) {
            this.set('selectedVersion.live_start', updatedVersion.get('live_start'));
          }

          this.refreshVersions();
        }, () => {
          this.notificationService.show('Error', 'Could not update live date.');
        }).finally(() => {
          this.set('isSettingNewLiveDate', false);
        });
      }

    }
  });

  _exports.default = _default;
});