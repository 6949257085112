define("universal-cms/routes/asset-types/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      return this.movieService.getAll('asset-types', null, ['group']).then(assetTypes => {
        let assetTypeToEdit = null;
        params.asset_type_id = parseInt(params.asset_type_id, 10);

        for (let assetTypeIndex = 0, numAssetTypes = assetTypes.length; assetTypeIndex < numAssetTypes; assetTypeIndex++) {
          if (assetTypes[assetTypeIndex].id === params.asset_type_id) {
            assetTypeToEdit = assetTypes[assetTypeIndex];
            break;
          }
        }

        return Ember.RSVP.hash({
          assetType: assetTypeToEdit,
          assetGroups: this.movieService.getAll('asset-groups')
        });
      });
    }

  });

  _exports.default = _default;
});