define("universal-cms/components/universal-brand-page-layout-manager-toolbar", ["exports", "jquery", "universal-cms/config/environment", "moment"], function (_exports, _jquery, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['universal-brand-page-layout-manager-toolbar'],
    page: null,
    versions: Ember.A(),
    selectedVersion: null,
    liveDateInput: null,
    formattedLiveDate: Ember.computed('selectedVersion.live_start', function () {
      if (this.selectedVersion === null) {
        return null;
      }

      const dbTime = _moment.default.tz(this.get('selectedVersion.live_start'), _environment.default.databaseTimezone);

      return dbTime.clone().tz(_moment.default.tz.guess()).format('M/D/Y h:mm A');
    }),

    init() {
      this._super();

      if (this.selectedVersion !== null) {
        const dbLiveStart = _moment.default.tz(this.get('selectedVersion.live_start'), _environment.default.databaseTimezone);

        this.set('liveDateInput', dbLiveStart.clone().tz(_moment.default.tz.guess()));
      }
    },

    didInsertElement() {
      const vm = this;

      vm._super();

      const $version_dropdown = (0, _jquery.default)('.version-dropdown');
      $version_dropdown.on('click', () => {
        $version_dropdown.toggleClass('open');
      });
      (0, _jquery.default)(document).on('mouseup', e => {
        if (!$version_dropdown.is(e.target) && $version_dropdown.hasClass('open')) {
          $version_dropdown.removeClass('open');
        }
      });
      $version_dropdown.find('.item').on('click', function (e) {
        const $item = (0, _jquery.default)(this);
        const action = $item.data('action');

        if (typeof action === 'undefined') {
          return;
        }

        if (action === 'rename-version') {
          const newName = prompt('Name:', vm.get('selectedVersion.title'));

          if (newName === null) {
            return;
          }

          vm.get('onRenameVersion')(newName);
        } else if (action === 'new-version') {
          const name = prompt('Name (optional):');
          vm.get('onNewVersion')(name);
        } else if (action === 'duplicate-version') {
          if (vm.get('selectedVersion') === null) {
            return;
          }

          const name = prompt('Name:', vm.get('selectedVersion.title'));
          vm.get('onNewVersion')(name, vm.get('selectedVersion.page_version_id'));
        } else if (action === 'delete-version') {
          if (!confirm(`Are you sure you want to delete version "${vm.get('selectedVersion.title')}"?`)) {
            return;
          }

          vm.get('onDeleteVersion')();
        } else if (action.substring(0, 14) === 'change-version') {
          let [actionType, pageVersionId] = action.split('|');

          if (parseInt(pageVersionId, 10) === parseInt(vm.get('selectedVersion.page_version_id'), 10)) {
            return;
          }

          vm.get('onChangeVersion')(pageVersionId);
        } // Close dropdown


        $version_dropdown.removeClass('open');
        e.stopPropagation();
      });
    },

    actions: {
      setLiveDate() {
        this.onSetLiveDate(this.liveDateInput);
      }

    }
  });

  _exports.default = _default;
});