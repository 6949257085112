define("universal-cms/controllers/option-groups/list", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    tableColumns: null,

    init() {
      this._super(...arguments);

      this.set('tableColumns', [{
        'propertyName': 'name',
        'title': 'Name'
      }, {
        'propertyName': 'description',
        'title': 'Description'
      }, {
        title: 'Manage',
        component: 'manage',
        editable: false
      }]);
    },

    actions: {
      createOptionGroup(data) {
        return this.movieService.createOne('option-groups', {
          name: data.data.name,
          description: data.data.description,
          live_start: (0, _moment.default)().format('YYYY-MM-DD HH:mm:ss'),
          live_end: null
        }).then(createdOptionGroup => {
          this.notificationService.show('Option group added', `<strong>${createdOptionGroup.name}</strong> was successfully added.`);
          this.model.pushObject(createdOptionGroup);
          return createdOptionGroup;
        }, error => {
          this.notificationService.show('Error', 'Could not add option group.');
        });
      },

      deleteOptionGroup(optionGroup) {
        if (!confirm(`Are you sure you want to delete option group "${optionGroup.get('name')}"?`)) {
          return;
        }

        optionGroup.set('isDeleting', true);
        this.movieService.deleteOne('option-groups', optionGroup.id).then(() => {
          this.notificationService.show('Option group deleted', `<strong>${optionGroup.name}</strong> was successfully deleted.`);
          this.model.removeObject(optionGroup);
        });
      }

    }
  });

  _exports.default = _default;
});