define("universal-cms/ember-quill/tests/templates/website/resources/resource", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SribWm6j",
    "block": "{\"symbols\":[],\"statements\":[[7,\"resource-edit\",[],[[\"@resource\",\"@options\",\"@newResource\",\"@updateResource\",\"@deleteResource\",\"@isAdding\",\"@isSaving\",\"@isDeleting\",\"@isLoading\",\"@service\"],[[27,[26,0,\"AppendSingleId\"],[\"resource\"]],[27,[26,0,\"AppendSingleId\"],[\"options\"]],[31,85,6,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[]],\"newResource\"],null],[31,126,6,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[]],\"updateResource\"],null],[31,170,6,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[]],\"deleteResource\"],null],[27,[26,2,\"AppendSingleId\"],[]],[27,[26,3,\"AppendSingleId\"],[]],[27,[26,4,\"AppendSingleId\"],[]],[27,[26,5,\"AppendSingleId\"],[]],[27,[26,0,\"AppendSingleId\"],[\"service\"]]]],null],[1,1,0,0,\"\\n\"],[1,0,0,0,[31,0,0,[27,[26,7,\"CallHead\"],[]],[[31,0,0,[27,[26,6,\"CallHead\"],[]],null,null]],null]],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"action\",\"isAdding\",\"isSaving\",\"isDeleting\",\"isLoading\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "universal-cms/ember-quill/tests/templates/website/resources/resource.hbs"
    }
  });

  _exports.default = _default;
});