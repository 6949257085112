define("universal-cms/components/pxl-media-library-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    pxlMediaLibrary: Ember.inject.service(),
    type: 'image',
    expectedTypes: null,
    isOpen: false,
    selectable: false,
    deletable: false,
    mediaItemId: null,
    mediaItem: null,
    requiredWidth: -1,
    requiredHeight: -1,
    isLoading: false,
    onMediaItemIdChanged: Ember.observer('mediaItemId', function () {
      if (!this.mediaItemId) {
        this.set('mediaItem', null);
      }
    }),
    selectButtonText: Ember.computed('isOpen', 'isOpening', function () {
      if (this.isOpening) {
        return 'Opening...';
      } else if (this.isOpen) {
        return 'Open';
      } else {
        return 'Select';
      }
    }),
    selectButtonDisabled: Ember.computed('isOpen', 'isOpening', function () {
      return this.isOpen || this.isOpening;
    }),
    preview: true,
    service: 'brand',

    init() {
      this._super(...arguments);

      this.set('expectedTypes', []);
    },

    actions: {
      async chooseMediaItem() {
        if (this.service === 'dreamworks') {
          // Open file picker
          this.set('isOpening', true);

          try {
            const choosenMediaItem = await this.pxlMediaLibrary.chooseMediaItemAsync({
              type: this.type || 'image',
              expectedTypes: this.expectedTypes,
              selectable: this.selectable,
              requiredWidth: this.requiredWidth,
              requiredHeight: this.requiredHeight
            }, {
              onOpened: () => {
                this.setProperties({
                  'isOpening': false,
                  'isOpen': true
                });
              },
              onClosed: () => {
                this.set('isOpen', false);
              }
            }, 'dreamworks');

            if (this.onChoose && typeof this.onChoose === 'function') {
              this.onChoose(choosenMediaItem.id, choosenMediaItem.path);
            }
          } catch (chooseMediaError) {
            if (chooseMediaError === 'close') {
              return;
            }

            console.log('chooseMediaError', chooseMediaError);
          }
        } else {
          this.set('isOpen', true);
          return this.pxlMediaLibrary.chooseMediaItem({
            expectedTypes: this.expectedTypes,
            selectable: this.selectable
          }).then(mediaItem => {
            this.set('mediaItem', mediaItem);

            if (typeof this.onChoose === 'function') {
              if (this.service === 'brand') {
                this.set('mediaItemId', mediaItem.get('media_id'));
                this.onChoose(mediaItem.get('media_id'), mediaItem, mediaItem.path);
              } else if (this.service === 'dreamworks') {
                this.set('mediaItemId', mediaItem.get('id'));
                this.onChoose(mediaItem.get('id'), mediaItem.id, mediaItem.path);
              }
            }
          }, failMessage => {
            console.log('failMessage', failMessage);

            if (failMessage !== 'close' && typeof this.onChoose === 'function') {
              this.onChoose(failMessage);
            }
          }).finally(() => {
            this.set('isOpen', false);
          });
        }
      },

      deleteMediaItem() {
        if (!confirm('Are you sure you want to delete media?')) {
          return;
        }

        if (typeof this.onDelete === 'function') {
          this.onDelete(null).then(() => {
            this.set('mediaItem', null);
          });
        } else {
          this.set('mediaItem', null);
        }
      }

    }
  });

  _exports.default = _default;
});