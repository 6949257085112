define("universal-cms/components/role-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isSaving: false,
    isEditing: Ember.computed('mode', function () {
      return this.mode === 'edit';
    }),
    saveButtonText: Ember.computed('isSaving', function () {
      if (this.mode === 'edit') {
        return this.isSaving ? 'Saving...' : 'Save';
      } else {
        return this.isSaving ? 'Adding...' : 'Add';
      }
    }),
    nameSingular: Ember.computed('role.name', function () {
      return this.get('role.name');
    }),
    namePlural: Ember.computed('role.name_plural', function () {
      return this.get('role.name_plural');
    }),
    actions: {
      save() {
        let nameSingular = this.nameSingular;

        if (nameSingular === '') {
          return;
        }

        this.set('isSaving', true);
        let actionName = (this.mode === 'edit' ? 'update' : 'create') + 'Role';
        this.get(actionName)({
          role: this.role,
          data: {
            name: nameSingular,
            name_plural: this.namePlural
          }
        }).then(role => {
          if (this.mode === 'add') {
            this.set('nameSingular', '');
            this.set('namePlural', '');
          }

          this.set('isSaving', false);
        });
      }

    }
  });

  _exports.default = _default;
});