define("universal-cms/controllers/dreamworks/movies/edit", ["exports", "universal-cms/controllers/dreamworks/resource-edit"], function (_exports, _resourceEdit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resourceEdit.default.extend({
    resourceType: 'movies',
    text: undefined,
    statusOptions: undefined,
    ratingOptions: undefined,

    init() {
      this._super(...arguments);

      this.set('statusOptions', [{
        text: 'In Theaters',
        value: 'in_theaters'
      }, {
        text: 'Coming Soon',
        value: 'coming_soon'
      }, {
        text: 'Home Entertainment',
        value: 'home_entertainment'
      }]);
      this.set('ratingOptions', ['PG', 'G', 'TV-G', 'TV-Y7', 'TV-Y7-FV']);
      this.set('text', {
        singular: 'Movie',
        plural: 'Movies'
      });
    }

  });

  _exports.default = _default;
});