define("universal-cms/routes/gabbys-website/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    gabbysWebsiteService: Ember.inject.service('gabbysWebsite'),

    async model(params, transition) {
      let lang;
      const preferredLanguageLocaleCode = localStorage.getItem('language_locale_code');

      if (this.auth.languageLocaleCode) {
        lang = this.auth.languageLocaleCode;
      } else if (preferredLanguageLocaleCode) {
        lang = preferredLanguageLocaleCode;
      } else {
        lang = transition.to.queryParams.lang;
      }

      if (!lang) {
        lang = 'en';
      }

      const controller = this.controllerFor(this.routeName);
      controller.set('isLoadingLayout', true);
      this.is_saving = true;

      try {
        const options = await this.dreamworksService.getOptions(`settings?site=gabbys-website${lang ? `&language=${lang}` : ''}`);
        const gabbysWebsiteOptions = await this.dreamworksService.getOptions(`gabbys-website${lang ? `?language=${lang}` : ''}`);
        const selectedLanguage = gabbysWebsiteOptions.languages ? gabbysWebsiteOptions.languages.find(language => language.locale_code === lang) : null;
        return Ember.RSVP.hash({
          'user_interface': options.user_interface,
          gabbysWebsiteOptions,
          selectedLanguage
        });
      } catch (error) {
        console.log('error', error);
      } finally {
        controller.set('isLoadingLayout', false);
      }
    }

  });

  _exports.default = _default;
});