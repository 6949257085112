define("universal-cms/components/gabbys-website/language-selector", ["exports", "universal-cms/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    auth: Ember.inject.service(),
    languages: [],
    selectedLanguage: null,
    selectLanguageDisabled: Ember.computed('auth.languageLocaleCode', function () {
      return false; // this.auth.languageLocaleCode !== null;
    }),
    availableLanguages: Ember.computed('this.auth.userLanguages', 'languages', function () {
      if (!this.auth.userLanguages || this.auth.userLanguages.length === 0) {
        return this.languages;
      }

      const availableLanguages = this.languages.filter(language => {
        return this.auth.userLanguages.some(userLanguage => {
          return language.locale_code === userLanguage.language.locale_code;
        });
      });
      return availableLanguages;
    }),
    currentLanguageFlagUrl: Ember.computed('model.selectedLanguage', function () {
      const selectedLanguage = this.selectedLanguage && this.selectedLanguage.locale_code ? this.selectedLanguage.locale_code : 'en';
      return `${this.authUrl}/images/flags/${selectedLanguage}.png`;
    }),

    init() {
      this._super(...arguments);

      this.set('authUrl', _environment.default.authUrl);
    },

    actions: {
      selectLanguage(event) {
        const languageLocaleCode = event.target.value; // Set language locale code in localStorage

        localStorage.setItem('language_locale_code', languageLocaleCode); // Reload page with ?lang query parameter

        const url = new URL(window.location.href);
        url.searchParams.set('lang', languageLocaleCode);
        window.location.href = url.href;
      }

    }
  });

  _exports.default = _default;
});