define("universal-cms/components/user-group-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isSaving: false,
    isEditing: Ember.computed('mode', function () {
      return this.mode === 'edit';
    }),
    saveButtonText: Ember.computed('isSaving', function () {
      if (this.mode === 'edit') {
        return this.isSaving ? 'Saving...' : 'Save';
      } else {
        return this.isSaving ? 'Adding...' : 'Add';
      }
    }),
    name: Ember.computed('group.name', function () {
      return this.get('group.name') !== undefined ? this.get('group.name') : '';
    }),
    groupServices: Ember.computed('services', function () {
      // eslint-disable-line ember/require-return-from-computed
      let services = this.services;
      let promises = [];
      services.forEach(service => {
        promises.push(new Ember.RSVP.Promise((resolve, reject) => {
          this.auth.getAll('scopes', {
            service: service.get('id'),
            group: this.get('group.id')
          }).then(scopes => {
            let scope = scopes[0];
            service.set('canCreate', scope !== undefined ? scope.create : false);
            service.set('canRead', scope !== undefined ? scope.read : false);
            service.set('canUpdate', scope !== undefined ? scope.update : false);
            service.set('canDelete', scope !== undefined ? scope.delete : false);
            resolve();
          });
        }));
      });
      Ember.RSVP.Promise.all(promises).then(result => {
        this.set('groupServices', services);
      });
    }),
    actions: {
      save() {
        let name = this.name;

        if (name === '') {
          this.notificationService.show('Error', 'Enter name.');
          return;
        }

        this.set('isSaving', true);
        let actionName = (this.mode === 'edit' ? 'update' : 'create') + 'Group';
        this.get(actionName)({
          group: this.group,
          data: {
            name: name,
            services: this.groupServices
          }
        }).then(group => {
          if (this.mode === 'add') {
            this.set('name', '');
          }

          this.set('isSaving', false);
        });
      }

    }
  });

  _exports.default = _default;
});