define("universal-cms/components/dreamworks/page-component-modals/featured-diptych", ["exports", "universal-cms/components/dreamworks/page-component-modals/page-component-modal"], function (_exports, _pageComponentModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _pageComponentModal.default.extend({
    classNames: 'dreamworks-featured-diptych',
    resourceFormOptions: undefined,

    init() {
      this._super(...arguments);

      let resourceFormOptions = [];

      if (this.page.type === 'tv_show' || this.page.type === 'movie') {
        this.pageComponent.data.sectionProperty = this.dreamworksService.resourceTypeToSectionProperty(this.page.type);
      }

      if (this.pageComponent.data.sectionTitle === 'Purchase') {
        resourceFormOptions.pushObject(Ember.Object.create({
          column: 'heading',
          type: 'text',
          label: 'Heading'
        }));
        resourceFormOptions.pushObject(Ember.Object.create({
          column: 'subhead',
          type: 'text',
          label: 'Sub Heading'
        }));
        resourceFormOptions.pushObject(Ember.Object.create({
          column: 'he_art',
          type: 'asset',
          label: 'Home Entertainment Art'
        }));
        resourceFormOptions.pushObject(Ember.Object.create({
          column: 'key_art',
          type: 'asset',
          label: 'Key Art'
        }));
        resourceFormOptions.pushObject(Ember.Object.create({
          column: 'category',
          type: 'select',
          options: [{
            value: null,
            text: 'None'
          }, {
            value: 'digital',
            text: 'Digital'
          }, {
            value: 'physical',
            text: 'Physical'
          }],
          label: 'Category'
        }));
      } else if (this.pageComponent.data.sectionTitle === 'Watch Now') {
        resourceFormOptions.pushObject(Ember.Object.create({
          column: 'heading',
          type: 'text',
          label: 'Heading'
        }));
        resourceFormOptions.pushObject(Ember.Object.create({
          column: 'subhead',
          type: 'text',
          label: 'Sub Heading'
        }));
        resourceFormOptions.pushObject(Ember.Object.create({
          column: 'he_art',
          type: 'asset',
          label: 'Home Entertainment Art'
        }));
        resourceFormOptions.pushObject(Ember.Object.create({
          column: 'cta_url',
          type: 'text',
          label: 'Link'
        }));
      } else {
        resourceFormOptions.pushObject(Ember.Object.create({
          column: 'id',
          type: 'resource',
          resourceType: this.dreamworksService.getResourceTypeOption(this.dreamworksService.convertResourceType(this.resourceType)),
          label: 'Resource'
        }));
      }

      resourceFormOptions.pushObject(Ember.Object.create({
        column: 'ctaText',
        type: 'text',
        label: 'CTA Text'
      }));
      this.set('resourceFormOptions', resourceFormOptions);
    }

  });

  _exports.default = _default;
});