define("universal-cms/templates/components/super-table-yield/table-body-row/drag-handle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aTHEPWex",
    "block": "{\"symbols\":[],\"statements\":[[9,\"i\",true],[12,\"class\",\"fa fa-bars\",null],[10],[11]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "universal-cms/templates/components/super-table-yield/table-body-row/drag-handle.hbs"
    }
  });

  _exports.default = _default;
});