define("universal-cms/templates/components/models-table/asset-type-allow-many", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Gf5bCnrM",
    "block": "{\"symbols\":[],\"statements\":[[1,0,0,0,[31,2,17,[27,[26,1,\"CallHead\"],[]],[[27,[26,0,\"Expression\"],[\"allow_many\"]]],null]],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"record\",\"boolean-formatter\"]}",
    "meta": {
      "moduleName": "universal-cms/templates/components/models-table/asset-type-allow-many.hbs"
    }
  });

  _exports.default = _default;
});