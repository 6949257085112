define("universal-cms/controllers/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    applicationConteroller: Ember.inject.controller('application'),
    username: '',
    password: '',
    isSigningIn: false
  });

  _exports.default = _default;
});