define("universal-cms/routes/website/resources/resource", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      // get model from parent
      let parentModel = this.modelFor('website.resources'); // set resources in model to requested location

      this.set('resources', parentModel.options.location); // return model as formatted object

      return Ember.RSVP.hash({
        options: parentModel.options,
        resource: params.resource === 'new' ? null : Ember.ArrayProxy.create(this.serviceResource.getResource('website', this.resources, params.resource)),
        service: parentModel.service
      });
    },

    afterModel(model, transition) {
      // if resource doesn't exist create a new ArrayProxy object
      if (model.resource === null) {
        model.resource = Ember.ArrayProxy.create(Ember.Object.create());
      }

      let relationshipHash = {}; // get resource fields from model

      let resourceFields = model.options.resource_fields; // define function to push new object to the model
      // this allows the resource page to update visually

      const update_model = (res, rej) => {
        res(Ember.Object.create());
      }; // create hash obejct for each field


      for (const field of resourceFields) {
        if (field.type === 'relationship') {
          // create hash object for api requests with the model
          relationshipHash[field.name] = Ember.RSVP.hash({
            // set name from model if it exists or create with resource service
            resource: model.resource[field.name] ? this.serviceResource.getResource('website', field.location, model.resource[field.name]) : new Ember.RSVP.Promise(update_model),
            options: this.serviceResource.getOptions('website', field.location)
          });
        }
      }

      return Ember.RSVP.hash(relationshipHash).then(relationships => {
        // for each field, set resource name based on relationships for model
        for (const field of resourceFields) {
          if (field.type === 'relationship') {
            model.resource[field.name] = relationships[field.name];
          }
        }
      });
    }

  });

  _exports.default = _default;
});