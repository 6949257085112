define("universal-cms/templates/components/dreamworks/page-component-modals/gabbys-website-episodes-grid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UGrDNawt",
    "block": "{\"symbols\":[],\"statements\":[[9,\"h2\",true],[10],[1,1,0,0,\"\\n    Episodes Grid\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[9,\"div\",true],[12,\"class\",\"row\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"div\",true],[12,\"class\",\"col-sm-12 col-md-6\",null],[10],[1,1,0,0,\"\\n        \"],[7,\"pxl-form-field\",[],[[\"@label\",\"@value\",\"@required\"],[\"Section Title\",[27,[26,0,\"AppendSingleId\"],[\"data\",\"title\"]],\"true\"]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[7,\"pxl-form-file\",[],[[\"@label\",\"@assetId\"],[\"Burst Promo Logo (optional)\",[27,[26,0,\"AppendSingleId\"],[\"data\",\"burst_promo_logo_image_id\"]]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[1,1,0,0,\"\\n\\n\"],[7,\"pxl-form-field\",[],[[\"@label\",\"@value\"],[\"Burst Promo Text (optional)\",[27,[26,0,\"AppendSingleId\"],[\"data\",\"burst_promo_text\"]]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[1,1,0,0,\"\\n\\n\"],[7,\"pxl-form-field\",[],[[\"@label\",\"@value\"],[\"Burst Promo Link (optional)\",[27,[26,0,\"AppendSingleId\"],[\"data\",\"burst_promo_link\"]]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"pageComponent\"]}",
    "meta": {
      "moduleName": "universal-cms/templates/components/dreamworks/page-component-modals/gabbys-website-episodes-grid.hbs"
    }
  });

  _exports.default = _default;
});