define("universal-cms/components/dreamworks/resource-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'resource-list',
    classNameBindings: ['isLoadingResources:loading'],
    resourceListId: null,
    canAdd: false,
    isAddMode: false,
    languageId: undefined,
    resourceIds: [],
    resources: undefined,
    resourceTypeOptions: undefined,
    selectedResourceTypeOption: undefined,
    resourceIdToAdd: undefined,
    showResourceTypesRadioOptions: false,
    hideResourceTypesRadioOptions: false,
    isLoadingResources: false,
    loadError: null,
    selectedResourceToAddId: null,
    sectionPropertyMode: false,
    onResourcesChanged: Ember.observer('resources.[]', function () {
      let resourceIds;

      if (this.sectionPropertyMode) {
        resourceIds = this.resources.map(resource => {
          return {
            resource: resource.id,
            sectionProperty: this.dreamworksService.resourceTypeToSectionProperty(resource.data.resource_type)
          };
        });
      } else {
        resourceIds = this.resources.map(resource => resource.id);
      }

      if (typeof this.onChanged === 'function') {
        this.onChanged(this.selectedResourceTypeOption);
      }
    }),
    addResourceButtonDisabled: Ember.computed('resourceIdToAdd', 'isLoadingResources', function () {
      if (this.maxItems) {
        return this.resourceIdToAdd === null || this.isLoadingResources || this.resourceIds.length === this.maxItems;
      } else {
        return this.resourceIdToAdd === null || this.isLoadingResources;
      }
    }),
    sortingGroup: Ember.computed('resourceListId', function () {
      return this.resourceListId ? `sorting-scope-${this.resourceListId}` : null;
    }),

    async init() {
      this._super(...arguments);

      await this.initResourceTypeOptions();

      if (this.resourceIds) {
        await this.send('loadResources');
      }
    },

    async initResourceTypeOptions() {
      let resourceTypeOptions = this.dreamworksService.getResourceTypeOptions(this.pageType);

      if (resourceTypeOptions && resourceTypeOptions.length > 0) {
        if (this.selectedResourceTypeOption) {
          if (this.resourceIds) {
            await this.send('loadResources');
          }
        } else {
          this.set('selectedResourceTypeOption', resourceTypeOptions[0]);
        }

        if (resourceTypeOptions.length > 1) {
          this.set('showResourceTypesRadioOptions', true);
        }
      }

      this.set('resourceTypeOptions', resourceTypeOptions);
    },

    actions: {
      async loadResources() {
        if (!this.resourceIds || this.resourceIds.length === 0) {
          return;
        }

        try {
          this.set('isLoadingResources', true);
          this.set('loadError', null);
          let idParam;

          if (this.sectionPropertyMode) {
            idParam = this.resourceIds.map(resourceIdObject => {
              if (resourceIdObject.resource) {
                return resourceIdObject.resource;
              } else {
                return resourceIdObject;
              }
            });
          } else {
            idParam = this.resourceIds;
          }

          let resources = await this.dreamworksService.getMany(this.dreamworksService.resourceTypeToEndpoint(this.selectedResourceTypeOption.value), {
            id: idParam
          }); // Find preview image

          resources = resources.map(resource => {
            let previewImage;

            if (resource.data && resource.data.data) {
              if (resource.data.data.about_image && (resource.data.data.about_image.portrait || resource.data.data.about_image.landscape)) {
                previewImage = resource.data.data.about_image.portrait || resource.data.data.about_image.landscape;
              } else if (resource.data.data.key_art) {
                previewImage = resource.data.data.key_art;
              } else if (resource.data.data.poster) {
                previewImage = resource.data.data.poster;
              }

              resource.set('previewImage', previewImage);
            }

            return resource;
          });
          this.set('resources', resources);
        } catch (error) {
          console.log(error);
          this.set('loadError', 'Could not load resources.');
        } finally {
          this.set('isLoadingResources', false);
        }
      },

      async addResource() {
        if (!this.resourceIdToAdd) {
          return;
        }

        if (!this.resourceIds) {
          this.resourceIds = [];
        }

        let dataToData;

        if (this.sectionPropertyMode) {
          dataToData = {
            resource: this.resourceIdToAdd,
            sectionProperty: this.dreamworksService.resourceTypeToSectionProperty(this.selectedResourceTypeOption.value)
          };
        } else {
          dataToData = this.resourceIdToAdd;
        }

        this.resourceIds.pushObject(dataToData);
        this.set('selectedResourceToAddId', null);
        await this.send('loadResources');
      },

      removeResource(resourceToRemove) {
        if (!confirm(`Are you sure you want to remove "${resourceToRemove.name}"?`)) {
          return;
        }

        this.resources.removeObject(resourceToRemove);
        let resourceIds = [];

        if (this.sectionPropertyMode) {
          resourceIds = this.resourceIds.filter(resourceIdObject => resourceIdObject.resource != resourceToRemove.id);
        } else {
          resourceIds = this.resourceIds.filter(resourceId => resourceId != resourceToRemove.id);
        }

        this.set('resourceIds', resourceIds);
      },

      changeSelectedResourceTypeOption(type) {
        let resourceTypeOptions = this.dreamworksService.getResourceTypeOptions(this.pageType);
        const selectedResourceTypeOption = resourceTypeOptions.find(resourceTypeOption => type == resourceTypeOption.value);
        this.set('selectedResourceTypeOption', selectedResourceTypeOption);
        this.setProperties({
          'resourceIds': [],
          'resources': []
        });
      },

      enterAddMode() {
        this.set('isAddMode', true);
      },

      exitAddMode() {
        this.set('isAddMode', false);
      },

      onReordered() {
        let reorderedResourceIds = [];

        if (this.sectionPropertyMode) {
          reorderedResourceIds = this.resources.map(resource => {
            return {
              resource: resource.id,
              sectionProperty: this.dreamworksService.resourceTypeToSectionProperty(resource.data.resource_type)
            };
          });
        } else {
          reorderedResourceIds = this.resources.map(resource => resource.id);
        }

        this.set('resourceIds', reorderedResourceIds);
      }

    }
  });

  _exports.default = _default;
});