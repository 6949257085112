define("universal-cms/routes/universal-brand/general/footer-link/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      const controller = this.controllerFor(this.routeName);
      const menuItemId = params.menu_item_id;
      const isNew = menuItemId === 'add';
      controller.set('isNew', isNew);
      let rsvp = {};
      rsvp.menuItem = isNew ? Ember.Object.create({
        isNew: true
      }) : this.universalBrandService.getOne('menu-items', menuItemId).then(menuItem => {
        return menuItem;
      }, () => {
        controller.set('error', 'Could not load footer link.');
      });
      rsvp.pages = this.universalBrandService.getAll('pages').then(pages => {
        return pages;
      }, () => {
        controller.set('error', 'Could not load pages');
      });
      rsvp.businesses = this.universalBrandService.getAll('businesses').then(businesses => {
        return businesses;
      }, () => {
        controller.set('error', 'Could not load businesses');
      });
      return Ember.RSVP.hash(rsvp);
    }

  });

  _exports.default = _default;
});