define("universal-cms/routes/dreamworks/resource-items/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    async model(params) {
      const resourceItemType = params.resource_item_type;
      return await this.dreamworksService.getMany(resourceItemType);
    }

  });

  _exports.default = _default;
});