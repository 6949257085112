define("universal-cms/routes/dreamworks/retailers/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    async model(params) {
      const controller = this.controllerFor(this.routeName);
      const retailerId = params.id;
      const isNew = retailerId === 'add';
      controller.set('isNew', isNew);
      let retailer;

      if (isNew) {
        retailer = Ember.Object.create({
          isNew: true
        });
      } else {
        try {
          retailer = await this.dreamworksService.getOne('retailers', retailerId);
        } catch (loadRetailerError) {
          controller.set('error', true);
        }
      }

      return Ember.RSVP.hash({
        'retailer': retailer
      });
    }

  });

  _exports.default = _default;
});