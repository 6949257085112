define("universal-cms/components/brand/footer-menu-editor", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['footer-menu-editor', 'clearfix'],
    menuItems: null,
    leftMenuItems: Ember.computed.filterBy('menuItems', 'type', 'footer_left'),
    centerMenuItems: Ember.computed.filterBy('menuItems', 'type', 'footer_center'),
    rightMenuItems: Ember.computed.filterBy('menuItems', 'type', 'footer_right'),
    isSaving: false,

    init() {
      this._super(...arguments);

      Ember.run.scheduleOnce('afterRender', this, this.initSortable);
    },

    initSortable() {
      const vm = this;

      for (let side of ['left', 'center', 'right']) {
        const containerElement = document.getElementById(`${side}-footer-menu-items`);
        let put;

        if (side === 'left') {
          put = ['center', 'right'];
        } else if (side === 'center') {
          put = ['left', 'right'];
        } else if (side === 'right') {
          put = ['left', 'center'];
        }

        const sortable = Sortable.create(containerElement, {
          group: {
            name: side,
            pull: true,
            put: put
          },
          handle: '.drag-handle',
          draggable: '.footer-menu-item',
          animation: 150,
          onUpdate: function (evt) {
            vm.set('isSaving', true); // Get all items

            let savePromises = [];
            (0, _jquery.default)(`#${side}-footer-menu-items`).find('.footer-menu-item').each((order, element) => {
              const menuItemId = element.getAttribute('data-id');
              savePromises.push(new Ember.RSVP.Promise((resolve, reject) => {
                vm.get('universalBrandService').updateOne('menu-items', menuItemId, {
                  order: order
                }).then(() => {
                  resolve();
                }).catch(() => {
                  reject();
                });
              }));
            });
            Ember.RSVP.Promise.all(savePromises).then(() => {
              vm.get('notificationService').show('Saved', `Footer menu items has been saved.`);
            }).catch(() => {
              vm.get('notificationService').show('Error', `Could not save footer menu items.`);
            }).finally(() => {
              vm.set('isSaving', false);
            });
          },
          onAdd: function (evt) {
            vm.set('isSaving', true);
            const menuItemId = evt.item.getAttribute('data-id');
            vm.get('universalBrandService').updateOne('menu-items', menuItemId, {
              type: `footer_${side}`
            }).then(() => {
              vm.get('notificationService').show('Saved', `Footer menu items has been saved.`);
            }).catch(() => {
              vm.get('notificationService').show('Error', `Could not save footer menu items.`);
            }).finally(() => {
              vm.set('isSaving', false);
            });
          }
        });
        vm.set(`${side}Sortable`, sortable);
      }
    },

    actions: {
      deleteMenuItem(menuItemToDelete) {
        if (menuItemToDelete.get('isDeleting')) {
          return;
        }

        menuItemToDelete.set('isDeleting', true);

        if (typeof this.onDelete === 'function') {
          this.onDelete(menuItemToDelete).then(() => {
            this.menuItems.removeObject(menuItemToDelete);
          });
        }
      }

    }
  });

  _exports.default = _default;
});