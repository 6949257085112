define("universal-cms/controllers/option-types/list", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    tableColumns: null,
    isCreating: false,

    init() {
      this._super(...arguments);

      this.set('tableColumns', [{
        'propertyName': 'name',
        'title': 'Name'
      }, {
        'propertyName': 'groupName',
        'title': 'Group'
      }, {
        'propertyName': 'type',
        'title': 'Type',
        'component': 'models-table/option-type-type'
      }, {
        title: 'Manage',
        component: 'manage',
        editable: false
      }]);
    },

    actions: {
      createOptionType(data) {
        return this.movieService.createOne('option-types', {
          name: data.data.name,
          helper_text: data.data.helper_text,
          type: data.data.type,
          option_group_id: data.data.optionGroupId,
          live_start: (0, _moment.default)().format('YYYY-MM-DD HH:mm:ss'),
          live_end: null
        }).then(createdOptionType => {
          this.notificationService.show('Option type added', `<strong>${createdOptionType.name}</strong> type was successfully added.`); // Format

          let optionGroups = this.get('model.optionGroups');

          for (let optionGroupIndex = 0, numOptionGroups = optionGroups.length; optionGroupIndex < numOptionGroups; optionGroupIndex++) {
            if (optionGroups[optionGroupIndex].id === createdOptionType.group) {
              createdOptionType.set('groupName', optionGroups[optionGroupIndex].name);
              break;
            }
          }

          this.get('model.optionTypes').pushObject(createdOptionType);
          return createdOptionType;
        }, error => {
          this.notificationService.show('Error', 'Could not add option type.');
        });
      },

      deleteOptionType(optionType) {
        if (!confirm(`Are you sure you want to delete option type "${optionType.get('name')}"?`)) {
          return;
        }

        optionType.set('isDeleting', true);
        this.movieService.deleteOne('option-types', optionType.id).then(() => {
          this.notificationService.show('Option type deleted', `<strong>${optionType.name}</strong> was successfully deleted.`);
          this.get('model.optionTypes').removeObject(optionType);
        });
      }

    }
  });

  _exports.default = _default;
});