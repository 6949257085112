define("universal-cms/controllers/universal-brand/projects/list", ["exports", "moment", "jquery"], function (_exports, _moment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    error: false,
    actions: {
      deleteProject(project) {
        return new Ember.RSVP.Promise((resolve, reject) => {
          project.set('isDeleting', true);
          this.universalBrandService.deleteOne('projects', project.get('project_id')).then(() => {
            this.notificationService.show('Project deleted', `Project was successfully deleted.`);
            this.model.removeObject(project);
            resolve();
          }, () => {
            project.set('isDeleting', false);
            this.notificationService.show('Error', `Could not delete project.`);
            reject();
          });
        });
      },

      async toggleArchive(project) {
        const archive = !project.archived;

        try {
          project.set('isArchiving', true);
          const updatedProject = await this.universalBrandService.updateOne('projects', project.project_id, {
            'archived': archive ? (0, _moment.default)().format('YYYY-MM-DD HH:mm:ss') : 'NULL'
          });
          project.set('archived', updatedProject.archived);
          this.notificationService.show('Project updated', `Project ${archive ? 'archived' : 'republished'}.`);
        } catch (error) {
          this.notificationService.show('Error', `Could not ${archive ? 'archive' : 'republish'} project.`);
        } finally {
          project.set('isArchiving', false);
        }
      }

    }
  });

  _exports.default = _default;
});