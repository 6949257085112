define("universal-cms/components/dreamworks/page-component-modals/standard-diptych-carousel", ["exports", "universal-cms/components/dreamworks/page-component-modals/page-component-modal"], function (_exports, _pageComponentModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _pageComponentModal.default.extend({
    classNames: 'dreamworks-standard-diptych-carousel',
    resourceFormOptions: undefined,

    init() {
      this._super(...arguments);

      let resourceFormOptions = [];
      resourceFormOptions.pushObject(Ember.Object.create({
        column: 'heading',
        type: 'text',
        label: 'Heading'
      }));
      resourceFormOptions.pushObject(Ember.Object.create({
        column: 'description',
        type: 'text-block',
        label: 'Description'
      }));
      resourceFormOptions.pushObject(Ember.Object.create({
        column: 'custom_cta',
        type: 'text',
        label: 'Custom CTA (optional)'
      }));
      resourceFormOptions.pushObject(Ember.Object.create({
        column: 'external_link',
        type: 'text',
        label: 'Link (optional)'
      }));
      resourceFormOptions.pushObject(Ember.Object.create({
        column: 'image_portrait',
        type: 'asset',
        label: 'Image (Desktop - 1440x1500px) - Required'
      }));
      resourceFormOptions.pushObject(Ember.Object.create({
        column: 'image_landscape',
        type: 'asset',
        label: 'Image (Mobile - 1350x700px) - Required'
      }));
      this.set('resourceFormOptions', resourceFormOptions);

      if (!this.pageComponent.data.sectionProperty) {
        this.pageComponent.data.set('sectionProperty', this.dreamworksService.resourceTypeToSectionProperty(this.page.type));
      }
    }

  });

  _exports.default = _default;
});