define("universal-cms/routes/website/resources/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController(controller, model) {
      let isSortable = false;

      if (['featured-movies', 'featured-videos', 'playlists', 'executives', 'classics', 'custom-pages'].includes(model.resourceName)) {
        isSortable = true;
      }

      controller.set('isSortable', isSortable);
      let disallowEdit = false;

      if (['featured-movies'].includes(model.resourceName)) {
        disallowEdit = true;
      }

      controller.set('disallowEdit', disallowEdit);

      this._super(controller, model);
    }

  });

  _exports.default = _default;
});