define("universal-cms/templates/components/dreamworks/page-component-modals/gabbys-website-character-carousel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2tmPkkfh",
    "block": "{\"symbols\":[],\"statements\":[[9,\"h2\",true],[10],[1,1,0,0,\"\\n    Character Carousel\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[9,\"div\",true],[12,\"class\",\"row\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"div\",true],[12,\"class\",\"col-sm-12 col-md-6\",null],[10],[1,1,0,0,\"\\n        \"],[9,\"label\",true],[10],[1,1,0,0,\"Highlight Character\"],[11],[1,1,0,0,\"\\n\\n        \"],[7,\"dreamworks/resource-selector-form\",[],[[\"@defaultType\",\"@selectedType\",\"@selectedId\",\"@label\",\"@languageId\",\"@onChange\"],[\"gabbys_website_character\",[27,[26,0,\"AppendSingleId\"],[\"data\",\"resource_type\"]],[27,[26,0,\"AppendSingleId\"],[\"data\",\"highlight_character_id\"]],\"Resource\",[27,[26,1,\"AppendSingleId\"],[]],[31,454,6,[27,[26,3,\"CallHead\"],[]],[[27,[24,0],[]],[31,462,3,[27,[26,2,\"CallHead\"],[]],[[27,[26,0,\"Expression\"],[\"data\",\"highlight_character_id\"]]],null]],null]]],null],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[9,\"div\",true],[12,\"class\",\"row\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"div\",true],[12,\"class\",\"col-sm-12 col-md-6\",null],[10],[1,1,0,0,\"\\n        \"],[9,\"label\",true],[10],[1,1,0,0,\"CTA Page Link\"],[11],[1,1,0,0,\"\\n\\n        \"],[7,\"dreamworks/resource-selector-form\",[],[[\"@defaultType\",\"@selectedType\",\"@selectedId\",\"@label\",\"@languageId\",\"@onChange\"],[\"page\",\"page\",[27,[26,0,\"AppendSingleId\"],[\"data\",\"cta_link_id\"]],\"Resource\",[27,[26,1,\"AppendSingleId\"],[]],[31,893,6,[27,[26,3,\"CallHead\"],[]],[[27,[24,0],[]],[31,901,3,[27,[26,2,\"CallHead\"],[]],[[27,[26,0,\"Expression\"],[\"data\",\"cta_link_id\"]]],null]],null]]],null],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\"],[11]],\"hasEval\":false,\"upvars\":[\"pageComponent\",\"languageId\",\"mut\",\"action\"]}",
    "meta": {
      "moduleName": "universal-cms/templates/components/dreamworks/page-component-modals/gabbys-website-character-carousel.hbs"
    }
  });

  _exports.default = _default;
});