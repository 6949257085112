define("universal-cms/controllers/universal-brand/news/list", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    error: false,
    actions: {
      deleteArticle(article) {
        return new Ember.RSVP.Promise((resolve, reject) => {
          article.set('isDeleting', true);
          this.universalBrandService.deleteOne('articles', article.get('article_id')).then(() => {
            this.notificationService.show('Article deleted', `Article was successfully deleted.`);
            this.model.removeObject(article);
            resolve();
          }, () => {
            article.set('isDeleting', false);
            this.notificationService.show('Error', `Could not delete article.`);
            reject();
          });
        });
      },

      async toggleArchive(article) {
        const archive = !article.archived;

        try {
          article.set('isArchiving', true);
          const updateArticle = await this.universalBrandService.updateOne('articles', article.article_id, {
            'archived': archive ? (0, _moment.default)().format('YYYY-MM-DD HH:mm:ss') : 'NULL'
          });
          article.set('archived', updateArticle.archived);
          this.notificationService.show('Article updated', `Article ${archive ? 'archived' : 'republished'}.`);
        } catch (error) {
          this.notificationService.show('Error', `Could not ${archive ? 'archive' : 'republish'} article.`);
        } finally {
          article.set('isArchiving', false);
        }
      }

    }
  });

  _exports.default = _default;
});