define("universal-cms/templates/universal-studios/news/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TSLfF9Hl",
    "block": "{\"symbols\":[],\"statements\":[[9,\"h1\",true],[12,\"class\",\"page-header\",null],[10],[1,1,0,0,\"\\n    News\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[7,\"link-to\",[[23,\"class\",\"btn btn-xs btn-primary\",null]],[[\"@route\",\"@model\"],[\"universalStudios.news.edit\",\"add\"]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"Add News Article\"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\\n\"],[5,[27,[26,7,\"BlockHead\"],[]],[[27,[26,6,\"Expression\"],[]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"    \"],[7,\"models-table\",[],[[\"@data\",\"@columns\",\"@showColumnsDropdown\",\"@useFilteringByColumns\",\"@multipleColumnsSorting\",\"@filteringIgnoreCase\",\"@customClasses\",\"@columnComponents\"],[[27,[26,0,\"AppendSingleId\"],[]],[27,[26,1,\"AppendSingleId\"],[]],false,false,false,true,[27,[26,2,\"AppendSingleId\"],[]],[31,478,4,[27,[26,5,\"CallHead\"],[]],null,[[\"manage\"],[[31,520,9,[27,[26,4,\"CallHead\"],[]],[\"models-table/universal-studios/news-article-actions\"],[[\"deleteNewsArticle\"],[[31,619,6,[27,[26,3,\"CallHead\"],[]],[[27,[24,0],[]],\"deleteNewsArticle\"],null]]]]]]]]],null],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"    \"],[9,\"div\",true],[12,\"class\",\"alert alert-danger alert-table-load-error\",null],[10],[1,1,0,0,\"\\n        Could not load news.\\n    \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"tableColumns\",\"tableClasses\",\"action\",\"component\",\"hash\",\"error\",\"unless\"]}",
    "meta": {
      "moduleName": "universal-cms/templates/universal-studios/news/list.hbs"
    }
  });

  _exports.default = _default;
});