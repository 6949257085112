define("universal-cms/controllers/movies/edit", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    util: Ember.inject.service(),
    applicationController: Ember.inject.controller('application'),
    editMovieOptionsController: Ember.inject.controller('movies/edit/options'),
    movieOptionGroups: Ember.computed.reads('editMovieOptionsController.optionGroups'),
    editMovieRetailersController: Ember.inject.controller('movies/edit/retailers'),
    movieRetailersControllerMovieRetailers: Ember.computed.reads('editMovieRetailersController.movieRetailers'),
    movieRetailers: Ember.computed('model.retailers', function () {
      let movieRetailers = this.get('model.retailers');

      if (movieRetailers && movieRetailers !== 'Array') {
        let parsedMovieObject = this.util.emberify(JSON.parse(movieRetailers));

        if (!parsedMovieObject.digital) {
          parsedMovieObject.digital = [];
        }

        if (!parsedMovieObject.physical) {
          parsedMovieObject.physical = [];
        }

        if (!parsedMovieObject.ondemand) {
          parsedMovieObject.ondemand = [];
        }

        if (!parsedMovieObject.preorder) {
          parsedMovieObject.preorder = [];
        }

        if (!parsedMovieObject.premium) {
          parsedMovieObject.premium = [];
        }

        if (!parsedMovieObject.premiumEs) {
          parsedMovieObject.premiumEs = [];
        }

        if (!parsedMovieObject.custom_copy) {
          parsedMovieObject.custom_copy = Ember.Object.create({
            custom_title: null,
            custom_subtitle: null,
            custom_ondemand_copy: null,
            custom_digital_copy: null,
            custom_physical_copy: null,
            custom_preorder_copy: null
          });
        }

        if (!parsedMovieObject.release_dates) {
          parsedMovieObject.release_dates = Ember.Object.create({
            ondemand_release_date: null,
            digital_release_date: null,
            physical_release_date: null
          });
        }

        movieRetailers = Ember.Object.create(parsedMovieObject);
      } else {
        movieRetailers = Ember.Object.create({
          digital: [],
          physical: [],
          ondemand: [],
          preorder: [],
          premium: [],
          premiumEs: [],
          custom_copy: Ember.Object.create({
            custom_title: null,
            custom_subtitle: null,
            custom_ondemand_copy: null,
            custom_digital_copy: null,
            custom_physical_copy: null,
            custom_preorder_copy: null
          }),
          release_dates: Ember.Object.create({
            ondemand_release_date: null,
            digital_release_date: null,
            physical_release_date: null
          })
        });
      }

      return movieRetailers;
    }),
    isGeneralTab: Ember.computed('applicationController.currentRouteName', function () {
      let routeName = this.get('applicationController.currentRouteName');
      return routeName === 'movies.edit.index';
    }),
    showSaveButton: Ember.computed('applicationController.currentRouteName', function () {
      let routeName = this.get('applicationController.currentRouteName');
      return ['movies.edit.index', 'movies.edit.options', 'movies.edit.retailers'].includes(routeName);
    }),
    movieUrl: Ember.computed('model.slug', function () {
      return this.auth.getUniversalPicturesWebsiteUrl(`movies/${this.get('model.slug')}`);
    }),
    uwatchUrl: Ember.computed('model.slug', function () {
      return this.auth.getUWatchWebsiteUrl(`${this.get('model.slug')}`);
    }),
    uwatchPreviewUrl: Ember.computed('model.slug', function () {
      return this.auth.getUWatchPreviewWebsiteUrl(`${this.get('model.slug')}?movie-id=${this.get('model.id')}`);
    }),
    uwatchHomepagePreviewUrl: Ember.computed('model.id', function () {
      return this.auth.getUWatchPreviewWebsiteUrl(`?movie-id=${this.get('model.id')}`);
    }),
    tabs: Ember.computed('applicationController.currentRouteName', function () {
      let routeName = this.get('applicationController.currentRouteName');
      return [{
        title: 'General',
        route: 'edit',
        isActive: routeName === 'movies.edit.index'
      }, {
        title: 'Genres',
        route: 'genres',
        isActive: routeName === 'movies.edit.genres'
      }, {
        title: 'People',
        route: 'people',
        isActive: routeName === 'movies.edit.people'
      }, {
        title: 'Options',
        route: 'options',
        isActive: routeName === 'movies.edit.options'
      }, {
        title: 'Retailers',
        route: 'retailers',
        isActive: routeName === 'movies.edit.retailers'
      }, {
        title: 'Assets',
        route: 'assets',
        isActive: routeName === 'movies.edit.assets'
      }, {
        title: 'Critical Acclaims',
        route: 'critical-acclaims',
        isActive: routeName === 'movies.edit.critical-acclaims'
      }];
    }),
    actions: {
      updateMovie(movie, data) {
        let title = data.get('title').trim();
        let slug = data.get('slug').trim();
        let status = data.get('status');
        let theatricalRelease = data.get('theatricalRelease');
        let uwatchRelease = data.get('uwatchRelease');
        let showInUniversalPictures = data.get('showInUniversalPictures');
        let archiveUrl = data.get('archiveUrl') !== '' ? data.get('archiveUrl') : null;

        if (title === '' || slug === '' || status === null || theatricalRelease === null) {
          return;
        }

        this.set('isSaving', true);
        let movieSavePromises = []; // Options tab

        if (data.get('optionGroups') !== undefined) {
          data.get('optionGroups').forEach(movieOptionGroup => {
            movieOptionGroup.optionTypes.forEach(optionType => {
              if (optionType.value !== undefined && optionType.value !== '') {
                movieSavePromises.push(new Ember.RSVP.Promise((resolve, reject) => {
                  this.movieService.createOne('movies/' + movie.id + '/options', {
                    option_type_id: optionType.id,
                    movie_id: movie.id,
                    value: optionType.value,
                    live_start: (0, _moment.default)().format('YYYY-MM-DD HH:mm:ss'),
                    live_end: null
                  }, {
                    clearAkamaiCache: false
                  }).then(createdOption => {
                    optionType.set('optionId', createdOption.id);
                    resolve(createdOption);
                  }, error => {
                    if (error === 'RELATIONSHIP_ALREADY_EXIST_ERROR') {
                      this.movieService.replaceOne('movies/' + movie.id + '/options', optionType.optionId, {
                        option_type_id: optionType.id,
                        movie_id: movie.id,
                        value: optionType.value
                      }, 'PUT', {
                        clearAkamaiCache: false
                      }).then(replacedOption => {
                        resolve(replacedOption);
                      }, error => {
                        reject(error);
                      });
                    }
                  });
                }));
              } else if (optionType.optionId !== undefined) {
                // Delete
                movieSavePromises.push(new Ember.RSVP.Promise((resolve, reject) => {
                  this.movieService.deleteOne(`movies/${movie.id}/options`, optionType.optionId).then(result => {
                    resolve();
                  }, error => {
                    reject(error);
                  });
                }));
              }
            });
          });
        }

        let previousStatus = movie.status; // General tab

        movieSavePromises.push(new Ember.RSVP.Promise((resolve, reject) => {
          this.movieService.replaceOne('movies', movie.id, {
            title: title,
            slug: slug,
            status: status,
            archive_url: archiveUrl,
            theatrical_release: theatricalRelease !== null && typeof theatricalRelease === 'object' ? theatricalRelease.format('YYYY-MM-DD') : theatricalRelease,
            uwatch_release: uwatchRelease !== null && typeof uwatchRelease === 'object' ? uwatchRelease.format('YYYY-MM-DD') : uwatchRelease,
            show_in_universal_pictures: showInUniversalPictures,
            retailers: data.retailers || null
          }).then(replacedMovie => {
            movie.set('title', replacedMovie.get('title'));
            movie.set('slug', replacedMovie.get('slug'));
            movie.set('status', replacedMovie.get('status'));
            movie.set('archive_url', replacedMovie.get('archive_url'));
            movie.set('theatrical_release', replacedMovie.get('theatrical_release'));
            movie.set('uwatch_release', replacedMovie.get('uwatch_release'));
            movie.set('show_in_universal_pictures', replacedMovie.get('show_in_universal_pictures')); // console.log('replaced movie', replacedMovie.get('retailers'))
            // If status is changed to archived, delete from featured movies

            if (previousStatus !== 'archived' && status === 'archived') {
              this.serviceResource.getResources('website', 'featured-movies', {
                movie_id: movie.id
              }, 'featured-movies').then(movieServiceResources => {
                if (movieServiceResources.length > 0) {
                  this.serviceResource.deleteResource('website', 'featured-movies', movieServiceResources[0]).finally(() => {
                    resolve(replacedMovie);
                  });
                } else {
                  resolve(replacedMovie);
                }
              }, error => {
                resolve(replacedMovie);
              });
            } else {
              resolve(replacedMovie);
            }
          }).catch(error => {
            reject(error);
          });
        }));
        Ember.RSVP.Promise.all(movieSavePromises).then(result => {
          this.set('isSaving', false);
          this.notificationService.show('Movie saved', 'Movie was successfully saved.');
        }).catch(error => {
          this.set('isSaving', false);
          this.notificationService.show('Error', error.status && error.status[0] && typeof error.status[0] === 'string' ? error.status[0] : 'Could not save movie.');
        });
      },

      linkTo(route) {
        if (route !== 'edit') {
          this.transitionToRoute(`/movies/${this.get('model.id')}/${route}`);
        } else {
          this.transitionToRoute('movies.edit', this.model);
        }
      }

    }
  });

  _exports.default = _default;
});