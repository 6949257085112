define("universal-cms/routes/universal-brand/projects/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return this.universalBrandService.getAll('projects?relationships[]=business&sort-by=order').then(projects => {
        return projects;
      }, () => {
        const controller = this.controllerFor(this.routeName);
        controller.set('error', true);
      });
    }

  });

  _exports.default = _default;
});