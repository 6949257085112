define("universal-cms/components/dreamworks/page-component-modals/character-bios", ["exports", "universal-cms/components/dreamworks/page-component-modals/page-component-modal"], function (_exports, _pageComponentModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _pageComponentModal.default.extend({
    classNames: 'dreamworks-character-bios',
    resourceFormOptions: undefined,

    init() {
      this._super(...arguments);

      let resourceFormOptions = [];
      resourceFormOptions.pushObject(Ember.Object.create({
        column: 'name',
        type: 'text',
        label: 'Actor Name (optional)'
      }));
      resourceFormOptions.pushObject(Ember.Object.create({
        column: 'actorThumbnail',
        type: 'asset',
        label: 'Actor Image (optional)'
      }));
      resourceFormOptions.pushObject(Ember.Object.create({
        column: 'note',
        type: 'text',
        label: 'Character Name'
      }));
      resourceFormOptions.pushObject(Ember.Object.create({
        column: 'thumbnail',
        type: 'asset',
        label: 'Character Image'
      }));
      resourceFormOptions.pushObject(Ember.Object.create({
        column: 'description',
        type: 'text-block',
        label: 'Description'
      }));
      this.set('resourceFormOptions', resourceFormOptions);
    }

  });

  _exports.default = _default;
});