define("universal-cms/controllers/universal-brand/pages/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    error: false,
    heading: Ember.computed('isNew', 'model.page.title', function () {
      return this.isNew === true ? 'Add Page' : this.get('model.page.title');
    }),
    types: null,
    isSaving: false,
    saveButtonText: Ember.computed('isNew', 'isSaving', function () {
      if (this.isNew === true) {
        return this.isSaving === true ? 'Adding...' : 'Add';
      } else {
        return this.isSaving === true ? 'Saving...' : 'Save';
      }
    }),
    disabledSlugField: Ember.computed('model.page.type', function () {
      if (this.get('model.page.type') === 'home') {
        return true;
      }

      return false;
    }),
    showBannerTab: Ember.computed('model.page.type', function () {
      const pageType = this.get('model.page.type');
      return pageType === 'about' || pageType === 'news' || pageType === 'leadership' || pageType === 'contact' || pageType === 'custom' || pageType === 'worlds' || pageType === 'products' || pageType === 'games' || pageType === 'live';
    }),
    showMissionStatementTab: Ember.computed('model.page.type', function () {
      const pageType = this.get('model.page.type');
      return pageType === 'about' || pageType === 'worlds' || pageType === 'products' || pageType === 'games' || pageType === 'live';
    }),
    selectedVersion: null,
    isSavingHeader: false,
    isSavingBanner: false,
    isSavingMissionStatement: false,
    missionStatementData: Ember.Object.create({
      'title': '',
      'description': ''
    }),
    isSavingCustomPageContent: false,
    pageTypes: null,
    selectedWorldsCharacterCategory: null,
    filteredWorldsCharacters: Ember.computed('selectedVersion.worlds_characters.[]', 'selectedWorldsCharacterCategory', function () {
      return this.selectedVersion.worlds_characters.filter(worldsCharacter => {
        return worldsCharacter.category_id === this.selectedWorldsCharacterCategory.id;
      });
    }),
    isUpdatingGamesOrder: false,

    init() {
      this._super(...arguments);

      this.set('types', [{
        value: 'universal_pictures',
        text: 'Universal Pictures'
      }, {
        value: 'universal_home_entertainment',
        text: 'Universal Home Entertainment'
      }]);
      this.set('pageTypes', ['about', 'business', 'contact', 'custom', 'home', 'leadership', 'locations', 'news', 'worlds']);
    },

    actions: {
      save() {
        this.set('isSaving', true);
        const page = this.get('model.page');
        let saveData = page.getProperties('title', 'slug', 'type', 'is_enabled');
        saveData.is_enabled = page.get('is_enabled') ? 1 : 0;

        if (this.isNew) {
          this.universalBrandService.createOne('pages', saveData).then(createResponse => {
            if (typeof createResponse.errors !== 'undefined') {
              this.notificationService.show('Error', this.universalBrandService.formatErrorResponse(createResponse.errors));
            } else if (typeof createResponse.validation_errors !== 'undefined') {
              this.notificationService.show('Validation Error', this.universalBrandService.formatErrorResponse(createResponse.validation_errors));
            } else {
              this.notificationService.show('Page Added', `<strong>${createResponse.get('title')}</strong> has been added.`);
              this.target.transitionTo(this.routeName, createResponse.get('page_id'));
            }
          }, () => {
            this.notificationService.show('Unknown Error', 'Could not add page.');
          }).finally(() => {
            this.set('isSaving', false);
          });
        } else {
          this.universalBrandService.updateOne('pages', page.get('page_id'), saveData).then(updateResponse => {
            if (typeof updateResponse.errors !== 'undefined') {
              this.notificationService.show('Error', this.universalBrandService.formatErrorResponse(updateResponse.errors));
            } else if (typeof updateResponse.validation_errors !== 'undefined') {
              this.notificationService.show('Validation Error', this.universalBrandService.formatErrorResponse(updateResponse.validation_errors));
            } else {
              this.notificationService.show('Page Updated', `<strong>${updateResponse.get('title')}</strong> has been updated.`);
              this.target.transitionTo(this.routeName, updateResponse.get('page_id'));
            }
          }, () => {
            this.notificationService.show('Unknown Error', 'Could not update page.');
          }).finally(() => {
            this.set('isSaving', false);
          });
        }
      },

      onChangedVersion(pageVersion) {
        return new Ember.RSVP.Promise((resolve, reject) => {
          if (typeof pageVersion.get('data.mission_statement') === 'object') {
            this.set('missionStatementData', pageVersion.get('data.mission_statement'));
          }

          if (!this.selectedWorldsCharacterCategory && pageVersion.page.type === 'worlds') {
            this.set('selectedWorldsCharacterCategory', pageVersion.worlds_characters_categories[0]);
          }

          if (pageVersion.get('page.type') === 'home') {
            if (!pageVersion.get('data')) {
              pageVersion.set('data', Ember.Object.create({}));
            }
          }

          resolve();
        });
      },

      updateBody(editor) {
        this.set('model.page.body', editor.root.innerHTML);
      },

      deleteFeaturedBanner(featuredBanner) {
        return this.universalBrandService.deleteOne('carousel-feature-items', featuredBanner.get('carousel_feature_item_id')).then(() => {
          this.notificationService.show('Featured Banner Deleted', `Featured banner was successfully deleted.`);
          this.get('selectedVersion.carousel_feature_items').removeObject(featuredBanner);
        }).catch(() => {
          this.notificationService.show('Error', `Could not delete featured banner.`);
        });
      },

      updateFeaturedBannerOrder(orderedCarouselFeatureItems) {
        let newOrder = [];

        for (let item of orderedCarouselFeatureItems) {
          newOrder.push(item.get('carousel_feature_item_id'));
        }

        let saveData = {
          page_version_id: this.get('selectedVersion.page_version_id'),
          items: newOrder
        };
        this.universalBrandService.updateOne(`pages/${this.get('model.page.page_id')}/versions/${this.get('selectedVersion.page_version_id')}`, 'update-carousel-feature-items-order', saveData).then(() => {
          this.notificationService.show('Saved', `Featured banner order has been saved.`);
        }).catch(() => {
          this.notificationService.show('Error', `Could not save featured banner order.`);
        });
      },

      updateNavigationBlockOrder(orderedMenuThumbnails) {
        let newOrder = [];

        for (let item of orderedMenuThumbnails) {
          newOrder.push(item.get('menu_thumbnail_id'));
        }

        let saveData = {
          page_version_id: this.get('selectedVersion.page_version_id'),
          items: newOrder
        };
        this.universalBrandService.updateOne(`pages/${this.get('model.page.page_id')}/versions/${this.get('selectedVersion.page_version_id')}`, 'update-menu-thumbnails-order', saveData).then(() => {
          this.notificationService.show('Saved', `Navigation block order has been saved.`);
        }).catch(() => {
          this.notificationService.show('Error', `Could not save navigation block order.`);
        });
      },

      updateLeadershipOrder(orderedLeaders) {
        let newOrder = [];

        for (let item of orderedLeaders) {
          newOrder.push(item.get('leader_id'));
        }

        let saveData = {
          page_version_id: this.get('selectedVersion.page_version_id'),
          items: newOrder
        };
        this.universalBrandService.updateOne(`pages/${this.get('model.page.page_id')}/versions/${this.get('selectedVersion.page_version_id')}`, 'update-leadership-order', saveData).then(() => {
          this.notificationService.show('Saved', `Leadership order has been saved.`);
        }).catch(() => {
          this.notificationService.show('Error', `Could not save leadership order.`);
        });
      },

      saveHeader() {
        this.set('isSavingHeader', true);
        this.universalBrandService.updateOne('page-versions', this.get('selectedVersion.page_version_id'), {
          header: this.get('selectedVersion.header')
        }).then(() => {
          this.notificationService.show('Saved', `Title has been saved.`);
        }).catch(() => {
          this.notificationService.show('Error', `Could not save title.`);
        }).finally(() => {
          this.set('isSavingHeader', false);
        });
      },

      saveBanner() {
        this.set('isSavingBanner', true);
        let saveData = this.selectedVersion.getProperties('desktop_banner_image_id', 'mobile_banner_image_id');
        this.universalBrandService.updateOne('page-versions', this.get('selectedVersion.page_version_id'), saveData).then(() => {
          this.notificationService.show('Saved', `Banners has been saved.`);
        }).catch(() => {
          this.notificationService.show('Error', `Could not save banners.`);
        }).finally(() => {
          this.set('isSavingBanner', false);
        });
      },

      saveVideo() {
        const saveData = this.selectedVersion.getProperties('data');
        const formattedSaveData = JSON.parse(JSON.stringify(saveData));
        this.universalBrandService.updateOne('page-versions', this.get('selectedVersion.page_version_id'), formattedSaveData).then(() => {
          this.notificationService.show('Saved', `Video has been saved.`);
        }).catch(error => {
          console.error(error);
          this.notificationService.show('Error', `Could not save video.`);
        }).finally(() => {
          this.set('isSavingVideo', false);
        });
      },

      saveCustomPageContent() {
        this.set('isSavingCustomPageContent', true);
        this.universalBrandService.updateOne('page-versions', this.get('selectedVersion.page_version_id'), {
          data: {
            html: this.get('selectedVersion.html')
          }
        }).then(() => {
          this.notificationService.show('Saved', `Content has been saved.`);
        }).catch(() => {
          this.notificationService.show('Error', `Could not save content.`);
        }).finally(() => {
          this.set('isSavingCustomPageContent', false);
        });
      },

      updateMissionStatementDescription(editor) {
        this.set('missionStatementData.description', editor.root.innerHTML);
      },

      updateMissionStatementTitle(title) {
        this.set('missionStatementData.title', title);
      },

      saveMissionStatement() {
        this.set('isSavingMissionStatement', true);
        this.universalBrandService.updateOne('page-versions', this.get('selectedVersion.page_version_id'), {
          data: {
            mission_statement: {
              title: this.get('missionStatementData.title'),
              description: this.get('missionStatementData.description')
            }
          }
        }).then(() => {
          this.notificationService.show('Saved', `Mission statement has been saved.`);
        }).catch(e => {
          this.notificationService.show('Error', `Could not save mission statement.`);
        }).finally(() => {
          this.set('isSavingMissionStatement', false);
        });
      },

      deleteLeader(leaderToDelete) {
        return this.universalBrandService.deleteOne('leaders', leaderToDelete.get('leader_id')).then(() => {
          this.notificationService.show('Leader Deleted', `Leader was successfully deleted.`);
          this.get('selectedVersion.leaders').removeObject(leaderToDelete);
        }).catch(() => {
          this.notificationService.show('Error', `Could not delete leader.`);
        });
      },

      deleteContact(contactToDelete) {
        return this.universalBrandService.deleteOne('contacts', contactToDelete.get('contact_id')).then(() => {
          this.notificationService.show('Contact Deleted', `Contact was successfully deleted.`);
          this.get('selectedVersion.contacts').removeObject(contactToDelete);
        }).catch(() => {
          this.notificationService.show('Error', `Could not delete contact.`);
        });
      },

      deleteLocation(locationToDelete) {
        return this.universalBrandService.deleteOne('locations', locationToDelete.get('location_id')).then(() => {
          this.notificationService.show('Location Deleted', `Location was successfully deleted.`);
          this.get('selectedVersion.locations').removeObject(locationToDelete);
        }).catch(() => {
          this.notificationService.show('Error', `Could not delete location.`);
        });
      },

      deleteNavigationBlock(navigationBlockToDelete) {
        return this.universalBrandService.deleteOne('menu-thumbnails', navigationBlockToDelete.get('menu_thumbnail_id')).then(() => {
          this.notificationService.show('Navigation Block Deleted', `Navigation block was successfully deleted.`);
          this.get('selectedVersion.menu_thumbnails').removeObject(navigationBlockToDelete);
        }).catch(() => {
          this.notificationService.show('Error', `Could not delete navigation block.`);
        });
      },

      updateCustomPageContent(editor) {
        this.set('selectedVersion.html', editor.root.innerHTML);
      },

      deleteWorldsCharactersItem(itemToDelete) {
        return this.universalBrandService.deleteOne('worlds-and-characters', itemToDelete.get('worlds_characters_id')).then(() => {
          this.notificationService.show('World & Characters Deleted', `World & Characters item was successfully deleted.`);
          this.get('selectedVersion.worlds_characters').removeObject(itemToDelete);
        }).catch(() => {
          this.notificationService.show('Error', `Could not delete World & Characters item.`);
        });
      },

      updateWorldsCharactersItemsOrder(orderedItems) {
        let newOrder = [];

        for (let item of orderedItems) {
          newOrder.push(item.get('worlds_characters_id'));
        }

        let saveData = {
          page_version_id: this.get('selectedVersion.page_version_id'),
          items: newOrder
        };
        this.universalBrandService.updateOne(`pages/${this.get('model.page.page_id')}/versions/${this.get('selectedVersion.page_version_id')}`, 'update-worlds-and-characters-order', saveData).then(() => {
          this.notificationService.show('Saved', `Worlds & Characters items order has been saved.`);
        }).catch(() => {
          this.notificationService.show('Error', `Could not save Worlds & Characters items order.`);
        });
      },

      updateWorldsCharacter(worldsCharacter, column, value) {
        if (value === worldsCharacter.get(column)) {
          return;
        }

        worldsCharacter.set(column, value);
        let saveData = {
          page_version_id: this.get('selectedVersion.page_version_id')
        };
        saveData[column] = value;
        return this.universalBrandService.updateOne('worlds-and-characters', worldsCharacter.get('worlds_characters_id'), saveData).then(() => {
          this.notificationService.show('Saved', `Worlds & Characters has been saved.`);
        }, () => {
          this.notificationService.show('Error', `Could not save Worlds & Characters.`);
        });
      },

      async updateGameProjectsOrder(orderedProjects) {
        this.set('isUpdatingGamesOrder', true);
        let newOrder = [];

        for (let item of orderedProjects) {
          newOrder.push(item.get('project_id'));
        }

        try {
          await this.universalBrandService.updateOne('games-and-digital-platforms', 'update-projects-order', {
            business_id: this.get('model.gamesAndDigitalBusiness.business_id'),
            projects: newOrder
          });
          this.notificationService.show('Saved', `Games order has been saved.`);
        } catch (error) {
          console.log(error);
          this.notificationService.show('Error', `Could not save games order.`);
        } finally {
          this.set('isUpdatingGamesOrder', false);
        } // this.universalBrandService.updateOne(`pages/${this.get('model.page.page_id')}/versions/${this.get('selectedVersion.page_version_id')}`, 'update-game-projets-order', saveData).then(() => {
        //     this.notificationService.show('Saved', `Games order has been saved.`);
        // }).catch(() => {
        //     this.notificationService.show('Error', `Could not save games order.`);
        // });

      }

    }
  });

  _exports.default = _default;
});