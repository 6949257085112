define("universal-cms/components/dreamworks/page-component-modals/standard-diptych", ["exports", "universal-cms/components/dreamworks/page-component-modals/page-component-modal", "lodash"], function (_exports, _pageComponentModal, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _pageComponentModal.default.extend({
    classNames: 'dreamworks-standard-diptych',

    init() {
      this._super(...arguments);

      if (!this.pageComponent.data.image || !this.pageComponent.data.image.portrait && !this.pageComponent.data.image.landscape || (0, _lodash.isArray)(this.pageComponent.data.image) && this.pageComponent.data.image.length === 0) {
        this.pageComponent.data.set('image', {});
      }
    }

  });

  _exports.default = _default;
});