define("universal-cms/components/super-table-yield/table-body-cell/input-text-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'input',
    classNames: ['form-control', 'input-sm', 'edit-input'],
    attributeBindings: ['autofocus', 'value'],
    autofocus: true,

    didInsertElement() {
      Ember.run.scheduleOnce('afterRender', this, this.focusElement);
    },

    focusElement() {
      this.element.focus();
    },

    focusOut() {
      if (this.doneEditing) {
        this.doneEditing();
      }

      if (this.valueUpdated) {
        this.valueUpdated(this.element.value);
      }
    },

    keyDown(_ref) {
      let {
        key
      } = _ref;

      if (key === 'Enter' || key === 'Escape') {
        if (this.doneEditing) {
          this.doneEditing();
        }

        if (this.valueUpdated) {
          this.valueUpdated(this.element.value);
        }
      }
    }

  });

  _exports.default = _default;
});