define("universal-cms/templates/retailers/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "M8cZv/kK",
    "block": "{\"symbols\":[],\"statements\":[[9,\"h1\",true],[12,\"class\",\"page-header\",null],[10],[1,1,0,0,\"\\n    \"],[1,0,0,0,[27,[26,0,\"Expression\"],[\"name\"]]],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[7,\"retailer-form\",[],[[\"@mode\",\"@retailer\",\"@updateRetailer\"],[\"edit\",[27,[26,0,\"AppendSingleId\"],[]],[31,128,6,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[]],\"updateRetailer\"],null]]],null],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"action\"]}",
    "meta": {
      "moduleName": "universal-cms/templates/retailers/edit.hbs"
    }
  });

  _exports.default = _default;
});