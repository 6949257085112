define("universal-cms/routes/uwatch/resources", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    util: Ember.inject.service(),

    model(params) {
      const resourceName = params.resources;
      this.set('resources', resourceName);
      let parentModel = this.modelFor('uwatch');
      return Ember.RSVP.hash({
        resourceName: resourceName,
        options: this.uwatchService.getOptions('uwatch', params.resources),
        resources: Ember.ArrayProxy.create(this.uwatchService.getResources('uwatch', params.resources)),
        service: parentModel
      });
    }

  });

  _exports.default = _default;
});