define("universal-cms/components/pagination-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'ul',
    classNames: ['pagination'],
    items: Ember.A(),
    numPerPage: 10,
    currentPage: 1,
    numAdjacents: 2,
    pageOffset: Ember.computed('numPerPage', 'currentPage', function () {
      let offset = (this.currentPage - 1) * this.numPerPage;
      return offset;
    }),
    numPages: Ember.computed('numTotalItems', 'numPerPage', function () {
      return Math.ceil(this.numTotalItems / this.numPerPage);
    }),
    pages: Ember.computed('numPages', 'currentPage', function () {
      const numPages = this.numPages;
      const currentPage = this.currentPage;
      const numAdjacents = this.numAdjacents;
      let pages = [];

      if (numPages > 1) {
        pages.push({
          number: currentPage - 1,
          text: 'Previous'
        });

        if (numPages < 7 + numAdjacents * 2) {
          for (let pageNumber = 1; pageNumber <= numPages; pageNumber++) {
            pages.push({
              number: pageNumber,
              text: pageNumber
            });
          }
        } else if (numPages > 5 + numAdjacents * 2) {
          if (currentPage < 1 + numAdjacents * 2) {
            for (let pageNumber = 1; pageNumber < 4 + numAdjacents * 2; pageNumber++) {
              pages.push({
                number: pageNumber,
                text: pageNumber
              });
            }
          } else if (numPages - numAdjacents * 2 > currentPage && currentPage > numAdjacents * 2) {
            pages.push({
              number: 1,
              text: '1'
            });
            pages.push({
              number: 2,
              text: '2'
            });
            pages.push({
              number: null,
              text: '...'
            });

            for (let pageNumber = currentPage - numAdjacents; pageNumber <= currentPage + numAdjacents; pageNumber++) {
              pages.push({
                number: pageNumber,
                text: pageNumber
              });
            }

            pages.push({
              number: null,
              text: '...'
            });
            pages.push({
              number: numPages - 1,
              text: numPages - 1
            });
            pages.push({
              number: numPages,
              text: numPages
            });
          } else {
            pages.push({
              number: 1,
              text: '1'
            });
            pages.push({
              number: 2,
              text: '2'
            });
            pages.push({
              number: null,
              text: '...'
            });

            for (let pageNumber = numPages - (2 + numAdjacents * 2); pageNumber <= numPages; pageNumber++) {
              pages.push({
                number: pageNumber,
                text: pageNumber
              });
            }
          }
        }

        pages.push({
          number: currentPage + 1,
          text: 'Next'
        });
      } else {
        pages.push({
          number: 1,
          text: '1'
        });
      }

      for (let page of pages) {
        let classStr = '';

        if (page.number === null || page.number === 0 || page.number === numPages + 1) {
          classStr = 'disabled item';
          page.clickable = false;
        } else if (page.number !== null && page.number === currentPage) {
          classStr += 'item active';
          page.clickable = false;
        } else {
          classStr = 'item';
          page.clickable = true;
        }

        page.class = classStr;
      }

      return pages;
    }),
    actions: {
      goToPage(pageNumber) {
        this.onPageChanged(pageNumber).then(() => {
          this.set('currentPage', pageNumber);
        });
      }

    }
  });

  _exports.default = _default;
});