define("universal-cms/components/preview-token-delete-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'input',
    attributeBindings: ['type', 'checked'],
    type: 'checkbox',
    checked: Ember.computed('previewToken.isChecked', function () {
      return this.get('previewToken.isChecked');
    }),

    change(e) {
      this.onChange(e.target.checked);
    }

  });

  _exports.default = _default;
});