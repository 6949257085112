define("universal-cms/controllers/universal-brand/general/navigation-links/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    error: false,
    heading: Ember.computed('isNew', 'model.mainMenuItem.title', function () {
      return this.isNew === true ? 'Add Navigation Link' : this.get('model.mainMenuItem.title');
    }),
    showSubLinks: Ember.computed('isNew', 'model.mainMenuItem.parent_menu_item_id', function () {
      return this.isNew === false && this.get('model.mainMenuItem.parent_menu_item_id') === null;
    }),
    actions: {
      onAdded(addedMenuItem) {
        this.target.transitionTo(this.routeName, addedMenuItem.get('menu_item_id'));
      },

      onAddedSubLink(addedMenuItem) {
        this.get('model.mainMenuItem.sub_links').pushObject(addedMenuItem);
      },

      deleteSubLink(menuItemToDelete) {
        const menuItemTitleToDelete = menuItemToDelete.get('title');
        return this.universalBrandService.deleteOne('menu-items', menuItemToDelete.get('menu_item_id')).then(() => {
          this.get('model.mainMenuItem.sub_links').removeObject(menuItemToDelete);
          this.notificationService.show('Sub Link Deleted', `<strong>${menuItemTitleToDelete}</strong> has been deleted.`);
        }, () => {
          this.notificationService.show('Error', 'Could not delete sub link.');
        });
      }

    }
  });

  _exports.default = _default;
});