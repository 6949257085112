define("universal-cms/controllers/universal-studios/slider/edit", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    error: false,
    heading: Ember.computed('isNew', 'model.slider.title', function () {
      return this.isNew === true ? 'Add Slider Item' : this.get('model.slider.title');
    }),
    isSaving: false,
    saveButtonText: Ember.computed('isNew', 'isSaving', function () {
      if (this.isNew === true) {
        return this.isSaving === true ? 'Adding...' : 'Add';
      } else {
        return this.isSaving === true ? 'Saving...' : 'Save';
      }
    }),
    businessUnits: Ember.A([{
      value: 'up',
      text: 'UP'
    }, {
      value: 'uphe',
      text: 'UPHE'
    }, {
      value: 'uor',
      text: 'UOR'
    }, {
      value: 'ups',
      text: 'UPS'
    }]),
    actions: {
      save() {
        this.set('isSaving', true);
        const slider = this.get('model.slider');
        let saveData = slider.getProperties('title', 'meta', 'desktop_image', 'mobile_image', 'featured', 'business_unit');
        saveData.button1_text = slider.get('button1_text') ? slider.get('button1_text') : '';
        saveData.button1_link = slider.get('button1_link') ? slider.get('button1_link') : '';
        saveData.button2_text = slider.get('button2_text') ? slider.get('button2_text') : '';
        saveData.button2_link = slider.get('button2_link') ? slider.get('button2_link') : '';
        saveData.in_theaters_date = slider.get('in_theaters_date') ? (0, _moment.default)(slider.get('in_theaters_date')).format('MM/DD/YYYY') : '';
        saveData.business_unit = slider.get('business_unit') ? slider.get('business_unit') : null;
        saveData.show_desktop_image_gradient = slider.get('show_desktop_image_gradient') ? 1 : 0;
        saveData.show_mobile_image_gradient = slider.get('show_mobile_image_gradient') ? 1 : 0;

        for (let column in saveData) {
          if (saveData[column] === null) {
            saveData[column] = '';
          }
        }

        if (this.isNew) {
          this.universalStudiosService.createOne('sliders', saveData, true).then(createResponse => {
            if (typeof createResponse.errors === 'undefined') {
              this.notificationService.show('Slider Added', `<strong>${createResponse.get('title')}</strong> has been added.`);
              this.target.transitionTo(this.routeName, createResponse.get('id'));
            } else {
              this.notificationService.show('Error', this.universalStudiosService.formatErrorResponse(createResponse.errors));
            }
          }, () => {
            this.notificationService.show('Error', 'Could not add slider.');
          }).finally(() => {
            this.set('isSaving', false);
          });
        } else {
          this.universalStudiosService.updateOne('sliders', slider.get('id'), saveData, true).then(updateResponse => {
            if (typeof updateResponse.errors === 'undefined') {
              this.notificationService.show('Slider Updated', `<strong>${updateResponse.get('title')}</strong> has been updated.`);
              this.target.transitionTo(this.routeName, updateResponse.get('id'));
            } else {
              this.notificationService.show('Error', this.universalStudiosService.formatErrorResponse(updateResponse.errors));
            }
          }, () => {
            this.notificationService.show('Error', 'Could not update slider.');
          }).finally(() => {
            this.set('isSaving', false);
          });
        }
      }

    }
  });

  _exports.default = _default;
});