define("universal-cms/components/brand/menu-item-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'form',
    isNew: undefined,
    pages: Ember.A(),
    business: Ember.A(),
    menuItem: undefined,
    parentMenuItem: undefined,
    showBackButton: false,
    isExternal: Ember.computed('menuItem.external_link', function () {
      return this.get('menuItem.external_link') !== null;
    }),
    selectedPage: Ember.computed('menuItem.page_id', function () {
      if (this.mode === 'add') {
        return null;
      }

      const pageId = this.get('menuItem.page_id');
      return this.pages.find(page => {
        return page.get('page_id') === pageId;
      });
    }),
    selectedBusiness: Ember.computed('menuItem.business_id', function () {
      if (this.mode === 'add') {
        return null;
      }

      const businessId = this.get('menuItem.business_id');
      return this.businesses.find(business => {
        return business.get('business_id') === businessId;
      });
    }),
    isSaving: false,
    saveButtonText: Ember.computed('isNew', 'isSaving', function () {
      if (this.isNew === true) {
        return this.isSaving === true ? 'Adding...' : 'Add';
      } else {
        return this.isSaving === true ? 'Saving...' : 'Save';
      }
    }),

    init() {
      this._super();

      if (typeof this.menuItem === 'object' && this.get('menuItem.isNew') !== true) {
        this.set('isNew', false);
      } else {
        this.set('menuItem', Ember.Object.create());
        this.set('isNew', true);
      }
    },

    actions: {
      save() {
        this.set('isSaving', true);
        const menuItem = this.menuItem;
        let saveData = menuItem.getProperties('title', 'link_type');

        if (menuItem.get('link_type') === 'internal') {
          saveData.page_id = this.get('selectedPage.page_id');
          saveData.external_link = null;
          saveData.business_id = null;
        } else if (menuItem.get('link_type') === 'external') {
          saveData.external_link = menuItem.get('external_link');
          saveData.page_id = null;
          saveData.business_id = null;
        } else if (menuItem.get('link_type') === 'business') {
          saveData.business_id = this.get('selectedBusiness.business_id');
          saveData.page_id = null;
          saveData.external_link = null;
        }

        if (this.parentMenuItem) {
          saveData.parent_menu_item_id = this.get('parentMenuItem.menu_item_id');
        }

        saveData.type = this.type;

        if (this.isNew) {
          this.universalBrandService.createOne('menu-items', saveData).then(createResponse => {
            if (typeof createResponse.errors === 'undefined') {
              this.notificationService.show('Navigation Link Added', `<strong>${createResponse.get('title')}</strong> has been added.`);

              if (typeof this.onAdded === 'function') {
                this.onAdded(createResponse);
              }

              this.set('menuItem', Ember.Object.create());
            } else {
              this.notificationService.show('Error', this.universalStudiosService.formatErrorResponse(createResponse.errors));
            }
          }, createError => {
            this.notificationService.show('Error', typeof createError.error !== 'undefined' ? createError.error : 'Could not add navigation link.');
          }).finally(() => {
            this.set('isSaving', false);
          });
        } else {
          this.universalBrandService.updateOne('menu-items', menuItem.get('menu_item_id'), saveData).then(updateResponse => {
            if (typeof updateResponse.errors === 'undefined') {
              this.notificationService.show('Navigation Link Updated', `<strong>${updateResponse.get('title')}</strong> has been updated.`);
            } else {
              this.notificationService.show('Error', this.universalStudiosService.formatErrorResponse(updateResponse.errors));
            }
          }, () => {
            this.notificationService.show('Error', 'Could not update navigation link.');
          }).finally(() => {
            this.set('isSaving', false);
          });
        }
      },

      setSelectedBusiness(business) {
        this.set('menuItem.business_id', business.get('business_id'));
      }

    }
  });

  _exports.default = _default;
});