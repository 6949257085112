define("universal-cms/routes/website/movie-promos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    actions: {
      updateResource(resource) {
        this.serviceResource.updateResource('website', this.resources, resource);
      },

      deleteResource(resource) {
        if (!confirm('Are you sure you want to delete movie promo?')) {
          return;
        }

        resource.set('isDeleting', true);
        this.serviceResource.deleteResource('website', "movie-promos", resource).then(() => {
          let model = this.modelFor('website.moviePromos');
          model.resources.removeObject(resource);
          resource.set('isDeleting', false);
          this.notificationService.show('Deleted', 'Movie promo deleted.');
        });
      }

    },

    model(params) {
      this.set('resources', 'movie-promos');
      let parentModel = this.modelFor('website');
      return Ember.RSVP.hash({
        options: this.serviceResource.getOptions('website', 'movie-promos'),
        resources: Ember.ArrayProxy.create(this.serviceResource.getResources('website', 'movie-promos')),
        service: parentModel
      });
    }

  });

  _exports.default = _default;
});