define("universal-cms/controllers/dreamworks/tv-shows/list", ["exports", "universal-cms/controllers/dreamworks/resource-list"], function (_exports, _resourceList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resourceList.default.extend({
    resourceType: 'tv-shows',
    text: {
      singular: 'TV Show',
      plural: 'TV Shows'
    },

    init() {
      this._super(...arguments);

      this.set('text', {
        singular: 'TV Show',
        plural: 'TV Shows'
      });
      this.set('tableColumns', [{
        'title': 'Name',
        'propertyName': 'name'
      }, {
        'title': 'Added',
        'component': 'models-table/dreamworks/date'
      }, {
        'title': 'Manage',
        'component': 'resourceActions',
        'editable': false
      }]);
    }

  });

  _exports.default = _default;
});