define("universal-cms/components/dreamworks/page-component-modals/combined-media", ["exports", "universal-cms/components/dreamworks/page-component-modals/page-component-modal"], function (_exports, _pageComponentModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _pageComponentModal.default.extend({
    classNames: 'dreamworks-combined-media',
    resourceFormOptions: undefined,

    init() {
      this._super(...arguments);

      let resourceFormOptions = []; // const selectedResourceTypeOption = this.dreamworksService.getResourceTypeOptionFromSectionProperty(this.pageComponent.data.sectionProperty);
      // this.set('selectedResourceTypeOption', selectedResourceTypeOption);

      resourceFormOptions.pushObject(Ember.Object.create({
        column: 'id',
        type: 'resource',
        resourceType: this.dreamworksService.getResourceTypeOption(this.dreamworksService.convertResourceType(this.resourceType)),
        label: 'Resource'
      }));
      this.set('resourceFormOptions', resourceFormOptions);

      if (!this.pageComponent.data.sectionProperty) {
        this.pageComponent.data.set('sectionProperty', 'Franchise');
      }

      if (!this.pageComponent.data.movie) {
        this.pageComponent.data.set('movie', Ember.Object.create({
          'sectionTitle': 'Movies',
          'sectionProperty': 'Movie',
          'items': []
        }));
      }

      if (!this.pageComponent.data.tv_show) {
        this.pageComponent.data.set('tv_show', Ember.Object.create({
          'sectionTitle': 'TV Shows',
          'sectionProperty': 'TvShow',
          'items': []
        }));
      }
    },

    actions: {
      onResourcesChanged(resourceTypeOption) {
        this.set('pageComponent.data.sectionProperty', resourceTypeOption.sectionProperty);
      }

    }
  });

  _exports.default = _default;
});