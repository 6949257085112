define("universal-cms/templates/components/draggable-object-target", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wwlITdWh",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[5,[27,[26,2,\"BlockHead\"],[]],[[27,[26,1,\"Expression\"],[]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"  \"],[9,\"a\",false],[23,\"href\",\"#\",null],[3,0,0,[27,[26,0,\"ModifierHead\"],[]],[[27,[24,0],[]],\"acceptForDrop\"],null],[10],[1,1,0,0,\"\\n    \"],[16,1,null],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"  \"],[16,1,null],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"enableClicking\",\"if\"]}",
    "meta": {
      "moduleName": "universal-cms/templates/components/draggable-object-target.hbs"
    }
  });

  _exports.default = _default;
});