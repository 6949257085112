define("universal-cms/ember-quill/tests/templates/components/dreamworks/page-component-modals/social-hub", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZbIWRSzL",
    "block": "{\"symbols\":[],\"statements\":[[9,\"h2\",true],[10],[1,1,0,0,\"\\n    Social Hub\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[7,\"pxl-form-file\",[],[[\"@label\",\"@assetId\"],[\"Background Image (optional)\",[27,[26,0,\"AppendSingleId\"],[\"data\",\"socialBackground\"]]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"pageComponent\"]}",
    "meta": {
      "moduleName": "universal-cms/ember-quill/tests/templates/components/dreamworks/page-component-modals/social-hub.hbs"
    }
  });

  _exports.default = _default;
});