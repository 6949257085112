define("universal-cms/controllers/universal-studios/connect/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    error: false,
    actions: {
      updateConnectsOrder(orderedConnects) {
        let newOrder = [];

        for (let connect of orderedConnects) {
          newOrder.push(connect.get('id'));
        }

        let saveData = {
          connects: newOrder
        };
        this.universalStudiosService.updateOne(`connects`, 'update-order', saveData).then(() => {
          this.notificationService.show('Saved', `Connect has been moved.`);
        }).catch(() => {
          this.notificationService.show('Error', `Could not save connects order.`);
        });
      },

      deleteConnect(connect) {
        connect.set('isDeleting', true);
        return this.universalStudiosService.deleteOne('connects', connect.get('id')).then(() => {
          this.notificationService.show('Connect deleted', `<strong>${connect.get('title')}</strong> was successfully deleted.`);
          this.model.removeObject(connect);
        }, () => {
          connect.set('isDeleting', false);
          this.notificationService.show('Error', `Could not delete connect <strong>${connect.get('title')}</strong>.`);
        });
      }

    }
  });

  _exports.default = _default;
});