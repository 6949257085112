define("universal-cms/components/dreamworks/page-component-modals/gabbys-website-broadcasters-promo", ["exports", "universal-cms/components/dreamworks/page-component-modals/page-component-modal"], function (_exports, _pageComponentModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _pageComponentModal.default.extend({
    classNames: 'gabbys-website-broadcasters-promo',

    init() {
      this._super(...arguments);

      const broadcastersResourceFormOptions = [];
      broadcastersResourceFormOptions.pushObject(Ember.Object.create({
        column: 'name',
        type: 'text',
        label: 'Name'
      }));
      broadcastersResourceFormOptions.pushObject(Ember.Object.create({
        column: 'link',
        type: 'text',
        label: 'Link'
      }));
      broadcastersResourceFormOptions.pushObject(Ember.Object.create({
        column: 'logo_lockup_image_id',
        type: 'asset',
        assetType: 'image',
        label: 'Logo Lockup'
      }));
      this.set('broadcastersResourceFormOptions', broadcastersResourceFormOptions);
    }

  });

  _exports.default = _default;
});