define("universal-cms/routes/dashboard/users/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    async model(params) {
      const authOptions = await this.auth.getOptions();
      const user = await this.auth.getOne('users', params.user_id, ['languages']);
      let languages = [];

      if (user.languages) {
        languages = Object.keys(user.languages).map(languageIndex => {
          const userLanguage = user.languages[languageIndex];
          return userLanguage;
        });
      }

      user.languages = languages;
      return Ember.RSVP.hash({
        user,
        users: this.modelFor('dashboard/users').users,
        groups: this.auth.getAll('groups'),
        services: this.auth.getAll('services'),
        authOptions // scopes: this.auth.getAll('scopes', { user: params.user_id }, [ 'service' ]),

      });
    }

  });

  _exports.default = _default;
});