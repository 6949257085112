define("universal-cms/ember-quill/tests/templates/movies/edit/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3guZYnB1",
    "block": "{\"symbols\":[],\"statements\":[[7,\"loader-box\",[],[[\"@size\",\"@withText\",\"@noTopMargin\"],[\"medium\",false,true]],null],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "universal-cms/ember-quill/tests/templates/movies/edit/loading.hbs"
    }
  });

  _exports.default = _default;
});