define("universal-cms/services/util", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    emberify(object) {
      if (Ember.typeOf(object) === 'array') {
        return Ember.A(object.map(item => {
          return this.emberify(item);
        }));
      } else if (_jquery.default.isPlainObject(object)) {
        for (let key of Object.keys(object)) {
          if (_jquery.default.isPlainObject(object[key])) {
            object[key] = this.emberify(object[key]);
          } else if (Ember.typeOf(object[key]) === 'array') {
            object[key] = this.emberify(object[key]);
          }
        }

        return Ember.Object.create(object);
      } else {
        return object;
      }
    },

    cloneObject(object) {
      return this.emberify(JSON.parse(JSON.stringify(object)));
    },

    moveArrayItems(array, fromIndex, toIndex) {
      const element = array[fromIndex];
      array.splice(fromIndex, 1);
      array.splice(toIndex, 0, element);
      return array;
    },

    toErrorString(data) {
      if (typeof data === 'object') {
        const array = Object.values(data);

        if (array.length === 0) {
          return 'Unknown error.';
        } else if (array.length === 1) {
          return array[0];
        } else {
          let str = '';
          let num = array.length;
          let arrayIndex = 0;

          for (let arrayItem of array) {
            str += `- ${arrayItem}`;

            if (arrayIndex < num - 1) {
              str += '<br>';
            }

            arrayIndex++;
          }

          return str;
        }
      } else {
        return data;
      }
    },

    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    formatBytes(a, b) {
      if (0 == a) return "0 Bytes";
      var c = 1024,
          d = b || 2,
          e = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
          f = Math.floor(Math.log(a) / Math.log(c));
      return parseFloat((a / Math.pow(c, f)).toFixed(d)) + " " + e[f];
    },

    slugify(str) {
      return str.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
    }

  });

  _exports.default = _default;
});