define("universal-cms/components/super-table-yield/table-body-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    attributeBindings: ['draggable'],
    classNameBindings: ['draggable:active'],
    orderable: false,
    handleClicked: false,
    draggable: Ember.computed('handleClicked', 'orderable', function () {
      return this.handleClicked && this.orderable;
    }),

    dragEnter(dragEvent) {
      if (this.onDragEnter) {
        this.onDragEnter(this.item);
      }
    },

    dragEnd() {
      if (this.draggable && this.onDragEnd) {
        this.set('handleClicked', false);
        this.onDragEnd(this.item);
      }
    },

    dragStart() {
      if (this.draggable && this.setDraggingItem) {
        this.setDraggingItem(this.item);
      }
    },

    actions: {
      onHandleGrab(isGrabbing) {
        this.set('handleClicked', isGrabbing);
      },

      onDeleteClick() {
        if (typeof this.onDelete === 'function') {
          return this.onDelete(this.item);
        }
      }

    }
  });

  _exports.default = _default;
});