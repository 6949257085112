define("universal-cms/components/dreamworks/page-component-modals/games-carousel", ["exports", "universal-cms/components/dreamworks/page-component-modals/page-component-modal"], function (_exports, _pageComponentModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _pageComponentModal.default.extend({
    classNames: 'dreamworks-games-carousel',
    resourceFormOptions: undefined,
    title: null,

    init() {
      this._super(...arguments);

      let resourceFormOptions = [];
      resourceFormOptions.pushObject(Ember.Object.create({
        column: 'id',
        type: 'games',
        resourceType: 'games',
        label: 'Resource'
      }));
      this.set('resourceFormOptions', resourceFormOptions);
    }

  });

  _exports.default = _default;
});