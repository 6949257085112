define("universal-cms/components/dreamworks/page-component-modals/gabbys-website-signup-newsletter", ["exports", "universal-cms/components/dreamworks/page-component-modals/page-component-modal"], function (_exports, _pageComponentModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _pageComponentModal.default.extend({
    classNames: 'gabbys-website-signup-newsletter',

    init() {
      this._super(...arguments);
    }

  });

  _exports.default = _default;
});