define("universal-cms/controllers/movies/edit/people", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    editMovieController: Ember.inject.controller('movies/edit'),
    movie: Ember.computed.reads('editMovieController.model'),
    addedPeople: Ember.A(),
    addedRolesPeople: Ember.computed('model.{roles,people,cast_and_crews.[],movie_roles.[}', function () {
      let addedPeople = this.get('movie.cast_and_crews');
      let addedRolesPeople = Ember.Object.create();
      let movie_roles = this.get('movie.movie_roles');
      movie_roles = movie_roles.sortBy('position'); // Just set the roles first so we get the position straight

      let numRoles = this.get('model.roles').length;
      let lastAddedRolePersonKey = null;
      movie_roles.forEach(movieRole => {
        for (let roleIndex = 0; roleIndex < numRoles; roleIndex++) {
          let addedRole = this.get('model.roles')[roleIndex];

          if (parseInt(addedRole.get('id'), 10) === parseInt(movieRole.get('role'), 10) && typeof addedRolesPeople[addedRole.get('name')] === 'undefined') {
            addedRolesPeople[addedRole.get('name')] = Ember.Object.create({
              id: addedRole.get('id'),
              movieRoleId: movieRole.get('id'),
              name: null,
              people: Ember.A(),
              haveMultiplePeople: false,
              position: null,
              isFirst: false,
              isLast: false,
              isMovingUp: false,
              isMovingDown: false
            });
            lastAddedRolePersonKey = addedRole.get('name');
            break;
          }
        }
      });

      if (lastAddedRolePersonKey !== null) {
        addedRolesPeople[lastAddedRolePersonKey].isLast = true;
      }

      let roles = [];
      this.get('model.people').forEach(person => {
        addedPeople.forEach(addedPerson => {
          addedPerson = Ember.Object.create(addedPerson);

          if (addedPerson.get('person') === person.get('id')) {
            this.addedPeople.pushObject(person);
            this.get('model.roles').forEach(addedRole => {
              if (addedRole.get('id') === addedPerson.get('role')) {
                if (typeof addedRolesPeople[addedRole.name] === 'undefined') {
                  addedRolesPeople[addedRole.name] = Ember.Object.create({
                    id: addedRole.get('id'),
                    movieRoleId: null,
                    people: Ember.A(),
                    haveMultiplePeople: false,
                    position: null,
                    isFirst: false,
                    isLast: false,
                    isMovingUp: false,
                    isMovingDown: false
                  });
                }

                if (typeof addedRolesPeople[addedRole.name] !== 'undefined'
                /* && addedRolesPeople[addedRole.name].name === null*/
                ) {
                  addedRolesPeople[addedRole.name].name = {
                    singular: addedRole.name,
                    plural: addedRole.name_plural ? addedRole.name_plural : addedRole.name
                  };
                }

                person.set('castAndCrewId', addedPerson.id);
                addedPerson.set('name', person.name);
                addedRolesPeople[addedRole.name].people.pushObject(addedPerson);

                if (!roles.includes(addedRole.name)) {
                  roles.push(addedRole.name);
                }
              }
            });
          }
        });
      }); // Sort cast and crew by position

      roles.forEach(role => {
        addedRolesPeople[role].people = addedRolesPeople[role].people.sortBy('position');
        addedRolesPeople[role].people[0].set('isFirst', true);
        addedRolesPeople[role].people[addedRolesPeople[role].people.length - 1].set('isLast', true);
        addedRolesPeople[role].haveMultiplePeople = addedRolesPeople[role].people.length > 1;
      });
      Object.keys(addedRolesPeople).forEach((roleName, index) => {
        addedRolesPeople[roleName].isFirst = index === 0;
        addedRolesPeople[roleName].position = index;
        addedRolesPeople[roleName].isLast = index === Object.keys(addedRolesPeople).length - 1;
      });
      return addedRolesPeople;
    }),
    selectedPerson: Ember.computed('selectedPerson', function () {
      return undefined;
    }),
    selectedRole: Ember.computed('selectedRole', function () {
      return undefined;
    }),
    isAdding: Ember.computed('isAdding', function () {
      return false;
    }),
    actions: {
      personCreated(name) {
        this.movieService.getAll('people', {
          name: name
        }).then(people => {
          if (people.length > 0) {
            this.notificationService.show('Error', `A person with name <strong>${name}</strong> already exists.`);
            return;
          }

          this.movieService.createOne('people', {
            name: name,
            live_start: (0, _moment.default)().format('YYYY-MM-DD HH:mm:ss'),
            live_end: null
          }).then(createdPerson => {
            this.get('model.people').pushObject(createdPerson);
            this.set('selectedPerson', createdPerson);
            this.notificationService.show('Person added', `<strong>${createdPerson.name}</strong> was added.`);
          }, error => {
            this.notificationService.show('Error', 'Could not add person.');
          });
        }, error => {
          this.notificationService.show('Error', 'Could not add person.');
        });
      },

      moveRole(direction, roleToMove) {
        // console.log('roleToMove', roleToMove);
        const movieId = this.movie.id;
        let roleToMoveWithPosition = direction === 'up' ? roleToMove.position - 1 : roleToMove.position + 1; // let roleToMoveWith = null;

        if (direction === 'up') {
          roleToMove.set('isMovingUp', true);
        } else {
          roleToMove.set('isMovingDown', true);
        } // console.log('this.addedRolesPeople', this.addedRolesPeople);
        // get the one with roleToMove.position + 1


        const addedRolesPeopleKeys = Object.keys(this.addedRolesPeople); // Find out index of roleToMove, then take the next one

        const roleToMoveIndex = addedRolesPeopleKeys.findIndex((roleKey, roleIndex) => {
          return roleKey === roleToMove.name.singular;
        });
        const moveWithIndex = direction === 'up' ? roleToMoveIndex - 1 : roleToMoveIndex + 1;
        const roleToMoveWithKey = addedRolesPeopleKeys.find((roleKey, roleIndex) => {
          return moveWithIndex === roleIndex;
        });
        const roleToMoveWith = this.addedRolesPeople[roleToMoveWithKey];

        if (roleToMove.movieRoleId) {
          this.movieService.replaceOne('movie-roles', roleToMove.movieRoleId, {
            movie_id: movieId,
            role_id: roleToMove.id,
            position: roleToMoveWithPosition
          }).then(movedMovieRole => {
            this.movieService.replaceOne('movie-roles', roleToMoveWith.movieRoleId, {
              movie_id: movieId,
              role_id: roleToMoveWith.id,
              position: roleToMove.position
            }).then(movedWithMovieRole => {
              if (direction === 'up') {
                roleToMove.set('isMovingUp', false);
              } else {
                roleToMove.set('isMovingDown', false);
              }

              this.get('target._routerMicrolib').refresh();
              this.notificationService.show('Role moved', 'Role was successfully moved.');
            }, error => {
              this.notificationService.show('Error', 'Could not move role.');
            });
          }, error => {
            this.notificationService.show('Error', 'Could not move role.');
          });
        } else {
          if (direction === 'up') {
            roleToMove.set('isMovingUp', false);
          } else {
            roleToMove.set('isMovingDown', false);
          }

          this.notificationService.show('Error', 'Could not move role.');
        }
      },

      positionsChanged(castAndCrew) {
        let savePromises = [];
        castAndCrew.forEach((cast, index) => {
          savePromises.push(new Ember.RSVP.Promise((resolve, reject) => {
            return this.movieService.replaceOne('cast-and-crews', cast.get('id'), {
              movie_id: cast.get('movie'),
              person_id: cast.get('person'),
              role_id: cast.get('role'),
              position: index
            }).then(() => {
              resolve();
            }, () => {
              reject();
            });
          }));
        });
        return Ember.RSVP.Promise.all(savePromises).then(() => {
          this.notificationService.show('Saved', 'Resource positions saved!');
        }, error => {
          this.notificationService.show('Error', 'Could not change resource positions.');
        });
      }

    }
  });

  _exports.default = _default;
});