define("universal-cms/routes/website/preview/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return Ember.RSVP.hash({
        previewTokens: this.serviceResource.getResources('website', 'preview-tokens', null, 'preview-tokens'),
        movies: this.movieService.getAll('movies')
      });
    },

    newlyCreatedLinkUrl: Ember.computed(function () {
      return this.get('controller.newlyCreatedLinkUrl');
    }),
    actions: {
      onCopiedLinkSuccess() {
        this.notificationService.show('Copy Link', 'Preview token link copied to clipboard.');
      },

      onCopiedLinkError() {
        this.notificationService.show('Copy Link', 'Could not copy preview token link to clipboard.');
      },

      delete(previewToken) {
        if (!confirm('Are you sure you want to delete preview token?')) {
          return;
        }

        previewToken.set('isDeleting', true);
        this.serviceResource.deleteResource('website', 'preview-tokens', previewToken).then(() => {
          previewToken.set('isDeleting', false);
          this.controller.get('model.previewTokens').removeObject(previewToken);
          this.notificationService.show('Deleted', 'Preview link was successfully deleted.');
        }, error => {
          this.notificationService.show('Error', 'Could not delete preview link.');
        });
      }

    }
  });

  _exports.default = _default;
});