define("universal-cms/ember-quill/tests/templates/components/models-table/movie-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0NnKP8Cv",
    "block": "{\"symbols\":[],\"statements\":[[1,0,0,0,[31,2,12,[27,[26,1,\"CallHead\"],[]],[[27,[26,0,\"Expression\"],[\"status\"]]],null]],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"record\",\"movie-status\"]}",
    "meta": {
      "moduleName": "universal-cms/ember-quill/tests/templates/components/models-table/movie-status.hbs"
    }
  });

  _exports.default = _default;
});