define("universal-cms/routes/universal-brand/pages/edit-worlds-characters-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      const controller = this.controllerFor(this.routeName);
      controller.set('pageId', params.page_id);
      controller.set('pageVersionId', params.page_version_id);
      const worldsCharactersId = params.worlds_characters_id;
      const isNew = worldsCharactersId === 'add';
      controller.set('isNew', isNew);
      let rsvp = {};
      rsvp.worldsCharactersItem = isNew ? Ember.Object.create({
        isNew: true
      }) : this.universalBrandService.getOne('worlds-and-characters', worldsCharactersId).then(worldsCharactersItem => {
        return worldsCharactersItem;
      }, () => {
        controller.set('error', true);
      });
      rsvp.categories = this.universalBrandService.getAll('worlds-and-characters/categories', worldsCharactersId).then(categories => {
        return categories;
      });
      return Ember.RSVP.hash(rsvp);
    }

  });

  _exports.default = _default;
});