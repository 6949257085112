define("universal-cms/components/asset-type-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    assetType: Ember.Object.create(),
    isEditing: Ember.computed('mode', function () {
      return this.mode === 'edit';
    }),
    assetGroupId: Ember.computed('assetType.asset_group_id', function () {
      return this.get('assetType.asset_group_id');
    }),
    name: Ember.computed('assetType.name', function () {
      return this.get('assetType.name');
    }),
    description: Ember.computed('assetType.description', function () {
      return this.get('assetType.description');
    }),
    allowMany: Ember.computed('assetType.allow_many', function () {
      return this.get('assetType.allow_many');
    }),
    saveButtonText: Ember.computed('isSaving', function () {
      if (this.mode === 'edit') {
        return this.isSaving ? 'Saving...' : 'Save';
      } else {
        return this.isSaving ? 'Adding...' : 'Add';
      }
    }),
    types: Ember.computed(function () {
      return [{
        value: 'image',
        text: 'Image'
      }, {
        value: 'video',
        text: 'Video'
      }];
    }),
    selectedType: Ember.computed('assetType.type', function () {
      let selectedType;
      let types = this.types;

      for (var i = 0, numTypes = types.length; i < numTypes; i++) {
        if (this.get('assetType.type') === types[i].value) {
          selectedType = types[i];
          break;
        }
      }

      return selectedType;
    }),
    selectedAssetGroup: Ember.computed('assetType.group', function () {
      let selectedAssetGroup;
      this.assetGroups.forEach(assetGroup => {
        if (this.get('assetType.group.id') === assetGroup.id) {
          selectedAssetGroup = assetGroup;
        }
      });
      return selectedAssetGroup;
    }),
    actions: {
      changeGroup(selectedGroup) {
        if (selectedGroup === null) {
          return;
        }

        this.set('assetType.group', selectedGroup);
      },

      changeType(selectedType) {
        if (selectedType === null) {
          return;
        }

        let type = this.types.find(type => {
          return type.value === selectedType.value;
        });
        this.set('assetType.type', type.value);
      },

      save(assetType) {
        if (!this.name) {
          return;
        } else if (!this.selectedType) {
          return;
        }

        this.set('isSaving', true);
        let actionName = (this.mode === 'edit' ? 'update' : 'create') + 'AssetType';
        this.get(actionName)({
          assetType: this.assetType,
          data: {
            assetGroupId: this.get('selectedAssetGroup.id'),
            name: this.name,
            description: this.description ? this.description : null,
            allowMany: this.allowMany ? 1 : 0,
            type: this.selectedType.value
          }
        }).then(assetType => {
          if (this.mode === 'add' && assetType) {
            this.setProperties({
              'name': '',
              'description': '',
              'allowMany': false
            });
            this.assetType.setProperties({
              'asset_group_id': null,
              'name': '',
              'description': '',
              'type': null,
              'group': null
            });
          }

          this.set('isSaving', false);
        });
      }

    }
  });

  _exports.default = _default;
});