define("universal-cms/components/resource-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      resourcePosition(direction) {
        let currentResourceIndex = this.resources.indexOf(this.resource);
        let newResourceIndex = currentResourceIndex;

        if (direction === "up") {
          newResourceIndex--;
        } else {
          newResourceIndex++;
        }

        let otherResource = this.resources.objectAt(newResourceIndex); // Update array of resources

        this.resources.set("" + newResourceIndex, this.resource);
        this.resources.set("" + currentResourceIndex, otherResource); // Update resource positions

        this.resource.set("position", newResourceIndex);
        otherResource.set("position", currentResourceIndex);
        this.resources.arrayContentDidChange(); // pass update to the controller

        this.updateResource(this.resource, false);
        this.updateResource(otherResource);
      },

      deleteResource() {
        // propogate data to the delete function in the controller
        this.deleteResource(this.resource);
      },

      saveResource() {
        // propogate data to the save function in the controller
        this.updateResource(this.resource);
      }

    },
    hasPosition: Ember.computed('resource', function () {
      return this.get('resource.position') !== undefined;
    }),
    isLast: Ember.computed('resource', 'resources.[]', function () {
      return this.resources.indexOf(this.resource) === this.resources.length - 1;
    }),
    isFirst: Ember.computed('resource', 'resources.[]', function () {
      return this.resources.indexOf(this.resource) === 0;
    })
  });

  _exports.default = _default;
});