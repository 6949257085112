define("universal-cms/components/file-upload", ["exports", "ember-uploader", "universal-cms/config/environment", "jquery"], function (_exports, _emberUploader, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberUploader.default.FileField.extend({
    url: '',
    showNotification: true,
    classNameBindings: ['isLoading'],
    filesDidChange: function (files) {
      if (this.onBeforeUpload) {
        this.onBeforeUpload();
      }

      let uploadUrl = this.serviceResource.getUrl('api/media');
      let auth = this.auth;

      let uploader = _emberUploader.default.Uploader.extend({
        ajaxSettings: function () {
          var settings = this._super.apply(this, arguments);

          settings.headers = {
            'X-Auth': `Bearer ${auth.jwt}`
          };
          return settings;
        }
      }).create({
        url: uploadUrl,
        paramName: 'media'
      });

      if (!Ember.isEmpty(files)) {
        this.set('isLoading', true);
        let uploadPromise = uploader.upload(files[0]);
        uploadPromise.then(data => {
          this.set('isLoading', false);

          if (this.showNotification === true) {
            this.notificationService.show('Image Uploaded', this.onUploadedText ? this.onUploadedText : 'Image uploaded successfully.');
          }

          let uploadedMedia = Ember.Object.create(data.data.media[0]);

          if (this.replaceResource === true) {
            this.set('resource', uploadedMedia);
          }

          if (this.onUpload) {
            this.onUpload(this.resource, uploadedMedia);
          }

          (0, _jquery.default)(`#${this.elementId}`).val('');
        }, function (error) {
          this.set('isLoading', false);
          this.notificationService.show('Image Uploaded', 'Image Upload Failed.');
        });
      }
    }
  });

  _exports.default = _default;
});