define("universal-cms/routes/uwatch/about", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    async model() {
      const about = await this.uwatchService.getAbout();
      return about;
    }

  });

  _exports.default = _default;
});