define("universal-cms/routes/gabbys-website/home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    gabbysWebsiteService: Ember.inject.service('gabbysWebsite'),

    async model(params, transition) {
      const gabbysWebsiteServiceOptions = this.get('gabbysWebsiteService.options');
      return gabbysWebsiteServiceOptions;
    },

    setupController(controller, model) {
      this._super(controller, model);

      controller.set('gabbysWebsiteServiceOptions', model);
    }

  });

  _exports.default = _default;
});