define("universal-cms/routes/dashboard/documentation/view-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      return this.auth.getOne('documentation-pages', params.documentation_page_id);
    },

    setupController: function (controller, model) {
      controller.set('parentModel', this.modelFor('dashboard.documentation'));

      this._super(controller, model);
    }
  });

  _exports.default = _default;
});