define("universal-cms/ember-quill/tests/templates/dreamworks/events/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kDC//axE",
    "block": "{\"symbols\":[],\"statements\":[[9,\"h1\",true],[12,\"class\",\"page-header less-bottom-margin\",null],[10],[1,1,0,0,\"\\n    \"],[1,0,0,0,[27,[26,0,\"Expression\"],[\"plural\"]]],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[9,\"a\",true],[13,\"href\",[27,[26,1,\"AppendSingleId\"],[]],null],[12,\"class\",\"external-breadcrumb-link\",null],[12,\"target\",\"_blank\",null],[10],[1,1,0,0,\"\\n    \"],[1,0,0,0,[27,[26,1,\"AppendSingleId\"],[]]],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[9,\"p\",true],[10],[1,1,0,0,\"\\n    \"],[7,\"link-to\",[[23,\"class\",\"btn btn-xs btn-primary\",null]],[[\"@route\",\"@model\"],[\"dreamworks.events.edit\",\"add\"]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"Add Event\"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[7,\"models-table\",[],[[\"@data\",\"@columns\",\"@showColumnsDropdown\",\"@useFilteringByColumns\",\"@multipleColumnsSorting\",\"@filteringIgnoreCase\",\"@columnComponents\"],[[27,[26,2,\"AppendSingleId\"],[\"resources\"]],[27,[26,3,\"AppendSingleId\"],[]],false,false,false,true,[31,558,4,[27,[26,6,\"CallHead\"],[]],null,[[\"manage\"],[[31,592,9,[27,[26,5,\"CallHead\"],[]],[\"models-table/dreamworks/resource-actions\"],[[\"deleteResource\"],[[31,673,6,[27,[26,4,\"CallHead\"],[]],[[27,[24,0],[]],\"deleteResource\"],null]]]]]]]]],null],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"text\",\"externalBreadcrumbLink\",\"model\",\"tableColumns\",\"action\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "universal-cms/ember-quill/tests/templates/dreamworks/events/list.hbs"
    }
  });

  _exports.default = _default;
});