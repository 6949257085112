define("universal-cms/ember-quill/tests/templates/components/super-table-yield/table-body-cell/input-media", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QGz0vbJr",
    "block": "{\"symbols\":[],\"statements\":[[7,\"pxl-media-library-item\",[],[[\"@selectable\",\"@mediaItem\",\"@onChoose\"],[true,[27,[26,0,\"AppendSingleId\"],[]],[31,75,6,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[]],\"updateValue\"],null]]],null],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"value\",\"action\"]}",
    "meta": {
      "moduleName": "universal-cms/ember-quill/tests/templates/components/super-table-yield/table-body-cell/input-media.hbs"
    }
  });

  _exports.default = _default;
});