define("universal-cms/components/pxl-media-library", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    pxlMediaLibrary: Ember.inject.service(),
    util: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),
    isOpening: false,
    isOpen: false,
    isLoadingMedia: false,
    mediaItems: Ember.A(),
    numMediaItemsPerPage: 7,
    numTotalMediaItems: null,
    requiredWidth: -1,
    requiredHeight: -1,
    requiredWidthUploadWarning: null,
    requiredHeightUploadWarning: null,
    ignoreRequiredDimensions: false,
    isUploading: false,
    newMediaItem: Ember.Object.create({
      name: '',
      file: null
    }),
    previewImage: null,
    service: 'brand',
    class: Ember.computed('isOpen', function () {
      return `pxl-media-library${this.isOpen === true ? ' open' : ''}`;
    }),
    formattedMediaItems: Ember.computed('mediaItems.[]', function () {
      let mediaItems = this.mediaItems;

      switch (this.type) {
        case 'image':
          {
            mediaItems = mediaItems.filter(mediaItem => {
              return mediaItem.original_filename.endsWith('.jpg') || mediaItem.original_filename.endsWith('.jpeg') || mediaItem.original_filename.endsWith('.png') || mediaItem.original_filename.endsWith('.gif');
            });
            break;
          }

        case 'audio':
          {
            mediaItems = mediaItems.filter(mediaItem => {
              return mediaItem.original_filename.endsWith('.mp3');
            });
            break;
          }

        default:
          {
            if (this.type) {
              console.warn('No implementation for type: ', this.type);
            }
          }
      }

      return mediaItems;
    }),
    isUploadButtonDisabled: Ember.computed('isUploading', 'newMediaItem.{name,file}', 'requiredWidthUploadWarning', 'requiredHeightUploadWarning', 'ignoreRequiredDimensions', function () {
      return this.isUploading === true || this.get('newMediaItem.file') === null || this.ignoreRequiredDimensions === false && (this.requiredWidthUploadWarning || this.requiredHeightUploadWarning);
    }),
    searchQuery: '',
    onSearchQueryChange: Ember.observer('searchQuery', function () {
      setTimeout(() => {
        this.getMedia(this.currentPage);
      }, 150);
    }),
    currentPage: 1,
    isGabbysWebsitePage: Ember.computed('routing.currentRouteName', function () {
      const serviceMappings = this.auth.decoded.scopes.mappings;
      const serviceMappingsKeys = Object.keys(serviceMappings);
      const isValidServiceMappingKeys = serviceMappingsKeys.includes('DreamWorks') && serviceMappingsKeys.includes('GabbysWebsite');
      return this.routing.currentRouteName.startsWith('gabbys-website') && isValidServiceMappingKeys;
    }),
    tabTitle: Ember.computed('type', function () {
      switch (this.type) {
        case 'image':
          return 'Gallery';

        case 'audio':
          return 'Audio Library';

        default:
          return 'Media Library';
      }
    }),

    init() {
      this._super(...arguments);

      this.pxlMediaLibrary.registerComponent(this);
    },

    chooseMediaItem() {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      if (options.expectedTypes) {
        this.set('expectedTypes', options.expectedTypes);
      }

      this.set('selectable', options.selectable === true);
      return new Ember.RSVP.Promise((resolve, reject) => {
        this.set('choosePromiseResolve', resolve);
        this.set('choosePromiseReject', reject);
        this.set('isChoosing', true);
        this.open();
      }).finally(() => {
        this.close();
      });
    },

    async chooseMediaItemAsync() {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let events = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      let service = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'brand';

      if (service) {
        this.set('service', service);
      }

      if (options.expectedTypes) {
        this.set('expectedTypes', options.expectedTypes);
      }

      if (options.type) {
        this.set('type', options.type);
      }

      this.set('selectable', options.selectable === true);
      this.set('requiredWidth', options.requiredWidth ? parseInt(options.requiredWidth) : -1);
      this.set('requiredHeight', options.requiredHeight ? parseInt(options.requiredHeight) : -1);
      return new Ember.RSVP.Promise(async (resolve, reject) => {
        this.set('choosePromiseResolve', resolve);
        this.set('choosePromiseReject', reject);
        this.set('isChoosing', true); // $('.pxl-media-library .nav.nav-tabs:not(:first)').removeClass('active');
        // $('.pxl-media-library .nav.nav-tabs li:not(:first)').removeClass('active');
        // $('.pxl-media-library .nav.nav-tabs li:first').addClass('active');
        // $('#pxl-media-library-tab-upload').removeClass('active');

        await this.open();

        if (events.onOpened && typeof events.onOpened === 'function') {
          events.onOpened();
        }
      }).finally(() => {
        this.close();

        if (events.onClosed && typeof events.onClosed === 'function') {
          events.onClosed();
        }
      });
    },

    async getMedia() {
      let pageNumber = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
      this.set('isLoadingMedia', true);
      this.set('searchTerm', null);
      let mediaItemsResult;

      try {
        if (this.service === 'brand') {
          mediaItemsResult = await this.universalBrandService.getAll(`media-library?sort-by=created_at&sort-order=desc&limit=${this.numMediaItemsPerPage}&page=${pageNumber}&search[name]=${this.searchQuery}`);
          this.set('currentPage', mediaItemsResult.get('current_page'));
          this.set('numTotalMediaItems', mediaItemsResult.get('num_total'));
        } else if (this.service === 'dreamworks') {
          let queryParams = {
            limit: this.numMediaItemsPerPage,
            page: pageNumber,
            'search[name]': this.searchQuery,
            'search[path]': this.searchQuery
          };

          if (this.requiredWidth && this.requiredWidth > 0) {
            queryParams['meta[width]'] = this.requiredWidth;
          }

          if (this.requiredHeight && this.requiredHeight > 0) {
            queryParams['meta[height]'] = this.requiredHeight;
          }

          if (this.isGabbysWebsitePage) {
            queryParams.tag = 'gabbys_website';
          }

          if (this.type) {
            queryParams.type = this.type;
          }

          mediaItemsResult = await this.dreamworksService.getMany('assets', queryParams);
          this.set('currentPage', mediaItemsResult.get('meta.current_page'));
          this.set('numTotalMediaItems', mediaItemsResult.get('meta.total'));
        }

        this.set('mediaItems', mediaItemsResult.get('data'));
      } catch (getMediaItemsError) {
        this.notificationService.show('Error', 'Could not get assets.');
      } finally {
        this.set('isLoadingMedia', false);
      }
    },

    async open() {
      this.set('isOpening', true);
      await this.getMedia();
      this.set('isOpening', false);
      this.set('isOpen', true);
    },

    close() {
      (0, _jquery.default)('.pxl-media-library .nav.nav-tabs:not(:first)').removeClass('active');
      (0, _jquery.default)('.pxl-media-library .nav.nav-tabs li:not(:first)').removeClass('active');
      (0, _jquery.default)('.pxl-media-library .nav.nav-tabs li:first').addClass('active');
      (0, _jquery.default)('#pxl-media-library-tab-upload').removeClass('active');
      this.send('close');
    },

    actions: {
      close() {
        this.set('isOpen', false);
        this.choosePromiseReject('close');
      },

      selectMediaItem(mediaItem) {
        this.choosePromiseResolve(mediaItem);
      },

      chooseFile(_ref) {
        let {
          target
        } = _ref;
        this.setProperties({
          'requiredWidthUploadWarning': null,
          'requiredHeightUploadWarning': null
        });

        if (target.files && target.files[0]) {
          let file = target.files[0];

          if (file.type.match(/image/)) {
            let fileReader = new FileReader();

            fileReader.onload = e => {
              let image = new Image();
              image.src = e.target.result;

              image.onload = () => {
                if (this.requiredWidth !== -1 && image.width !== this.requiredWidth) {
                  this.set('requiredWidthUploadWarning', true);
                }

                if (this.requiredHeight !== -1 && image.height !== this.requiredHeight) {
                  this.set('requiredHeightUploadWarning', true);
                }

                this.set('previewImage', {
                  data: e.target.result,
                  width: image.width,
                  height: image.height
                });
              };
            };

            fileReader.readAsDataURL(file);
          }

          this.set('newMediaItem.file', file);
        } else {
          this.set('newMediaItem', null);
          this.set('previewImage', null);
        }
      },

      async upload() {
        const newMediaItem = this.newMediaItem;
        this.set('isUploading', true);
        const postData = {
          name: newMediaItem.get('name'),
          file: newMediaItem.get('file')
        };

        if (this.isGabbysWebsitePage) {
          postData.tag = 'gabbys_website';
        }

        if (this.service === 'dreamworks') {
          try {
            const uploadResponse = await this.dreamworksService.uploadFile('assets/upload', postData);

            if (typeof uploadResponse.error === 'string') {
              throw new Error(uploadResponse.error);
            }

            this.set('newMediaItem', Ember.Object.create({
              name: '',
              file: null
            }));
            this.set('previewImage', null);
            this.notificationService.show('Media Uploaded', 'Media has been successfully uploaded.');
            this.choosePromiseResolve(uploadResponse);
          } catch (uploadError) {
            console.log(uploadError);
            this.notificationService.show('Error', uploadError.message || 'Could not upload media.');
          } finally {
            this.set('isUploading', false);
          }
        } else {
          this.universalBrandService.createOne(`media-library/upload`, postData, true).then(addedMediaItem => {
            if (addedMediaItem.error) {
              this.notificationService.show('Error', addedMediaItem.error);
            } else {
              this.mediaItems.unshiftObject(addedMediaItem);
              this.set('newMediaItem', Ember.Object.create({
                name: '',
                file: null
              }));
              this.set('previewImage', null);
              this.notificationService.show('Media Uploaded', 'Media has been successfully uploaded.');
              this.choosePromiseResolve(addedMediaItem);
            }
          }, () => {
            this.notificationService.show('Error', 'Could not upload media.');
          }).finally(() => {
            this.set('isUploading', false);
          });
        }
      },

      deleteMediaItem(mediaItem) {
        if (!confirm('Are you sure you want to delete media?')) {
          return;
        }

        mediaItem.set('isDeleting', true);
        this.universalBrandService.deleteOne(`media-library`, mediaItem.get('media_id')).then(() => {
          this.mediaItems.removeObject(mediaItem);
          this.notificationService.show('Media Deleted', 'Media has been successfully deleted.');
        }).catch(() => {
          this.notificationService.show('Error', 'Could not delete media.');
        }).finally(() => {
          mediaItem.set('isDeleting', true);
        });
      },

      onPageChanged(pageNumber) {
        this.set('currentPage', pageNumber);
        return new Ember.RSVP.Promise(resolve => {
          this.getMedia(pageNumber).then(() => {
            resolve();
          });
        });
      }

    }
  });

  _exports.default = _default;
});