define("universal-cms/routes/universal-brand/pages/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    async model(params) {
      const controller = this.controllerFor(this.routeName);
      const pageId = params.page_id;
      const isNew = pageId === 'add';
      controller.set('isNew', isNew);
      let rsvp = {};

      try {
        rsvp.page = isNew ? Ember.Object.create({
          isNew: true
        }) : await this.universalBrandService.getOne('pages', pageId);

        if (rsvp.page.type === 'games') {
          const gamesAndDigitalBusiness = (await this.universalBrandService.getAll('businesses')).find(business => business.slug === 'games-and-digital-platforms');
          const gameProjects = await this.universalBrandService.getAll(`projects?business_id=${gamesAndDigitalBusiness.business_id}&sort-by=order&non-archived=1`);
          rsvp.gamesAndDigitalBusiness = gamesAndDigitalBusiness;
          rsvp.gameProjects = gameProjects;
        } // Reset


        controller.set('selectedVersion', null);
        return Ember.RSVP.hash(rsvp);
      } catch (error) {
        console.log(error);
        controller.set('error', true);
      }
    }

  });

  _exports.default = _default;
});