define("universal-cms/controllers/website/home-page-promos/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    isAdding: false,
    isSaving: false,
    isDeleting: false,
    isLoading: false,
    shouldEditInline: false,
    newResource: null,

    init() {
      this._super(...arguments);

      this.set('newResource', {
        position: null
      });
    },

    actions: {
      selectUpdate(e) {
        let {
          name,
          value
        } = e.target;
        this.newResource[name] = value;
      },

      createResource() {
        if (this.newResource.position === null) {
          this.notificationService.show('Error', 'Choose position.');
          return;
        }

        this.send('newResource', this.newResource);
      },

      onPublish(data) {
        data.resource.set('live_start', data.publishDate);
        data.resource.set('live_end', data.unpublishDate);
        return this.serviceResource.updateResource('website', 'home-page-promos', data.resource, 'home_page_promos').then(homePagePromo => {
          data.doneCallback(homePagePromo);
          this.notificationService.show('Published', 'Home page promo was published.');
          return homePagePromo;
        });
      },

      onUnpublish(data) {
        data.resource.set('live_start', null);
        data.resource.set('live_end', null);
        return this.serviceResource.updateResource('website', 'home-page-promos', data.resource, 'home_page_promos').then(homePagePromo => {
          data.doneCallback(homePagePromo);
          this.notificationService.show('Unublished', 'Home page promo was unpublished.');
          return homePagePromo;
        });
      }

    }
  });

  _exports.default = _default;
});