define("universal-cms/ember-quill/tests/templates/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bjMNbrmj",
    "block": "{\"symbols\":[],\"statements\":[[9,\"div\",true],[12,\"class\",\"error-container\",null],[10],[1,1,0,0,\"\\n    \"],[1,0,0,0,[31,36,2,[27,[26,1,\"CallHead\"],[]],[[27,[26,0,\"Expression\"],[]],[27,[26,0,\"Expression\"],[]],\"Something went wrong\"],null]],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"if\"]}",
    "meta": {
      "moduleName": "universal-cms/ember-quill/tests/templates/error.hbs"
    }
  });

  _exports.default = _default;
});