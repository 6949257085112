define("universal-cms/templates/components/dreamworks/asset-img", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wZqk9cTA",
    "block": "{\"symbols\":[],\"statements\":[[5,[27,[26,3,\"BlockHead\"],[]],[[27,[26,4,\"Expression\"],[]]],null,[[\"default\",\"else\"],[{\"statements\":[[5,[27,[26,3,\"BlockHead\"],[]],[[27,[26,2,\"Expression\"],[]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"        \"],[9,\"img\",true],[13,\"src\",[27,[26,0,\"AppendSingleId\"],[]],null],[13,\"height\",[27,[26,1,\"AppendSingleId\"],[]],null],[10],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"    Loading...\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"src\",\"height\",\"loadError\",\"unless\",\"isLoading\"]}",
    "meta": {
      "moduleName": "universal-cms/templates/components/dreamworks/asset-img.hbs"
    }
  });

  _exports.default = _default;
});