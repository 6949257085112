define("universal-cms/components/genre-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isEditing: Ember.computed('mode', function () {
      return this.mode === 'edit';
    }),
    name: Ember.computed('genre.name', function () {
      return this.get('genre.name');
    }),
    saveText: Ember.computed(function () {
      return this.mode === 'add' ? 'Add' : 'Save';
    }),
    savingText: Ember.computed(function () {
      return (this.mode === 'add' ? 'Adding' : 'Saving') + '...';
    }),
    actions: {
      save(genre) {
        let name = this.name;

        if (name === undefined) {
          return;
        }

        let data = Ember.Object.create({
          'name': name
        });

        if (this.mode === 'add') {
          this.createGenre(data, this);
        } else {
          this.updateGenre(genre, data);
        }
      }

    }
  });

  _exports.default = _default;
});