define("universal-cms/templates/people/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rtL5p9DF",
    "block": "{\"symbols\":[],\"statements\":[[9,\"h1\",true],[12,\"class\",\"page-header\",null],[10],[1,1,0,0,\"\\n    \"],[1,0,0,0,[27,[26,0,\"Expression\"],[\"name\"]]],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[7,\"person-form\",[],[[\"@mode\",\"@person\",\"@updatePerson\",\"@isSaving\"],[\"edit\",[27,[26,0,\"AppendSingleId\"],[]],[31,110,6,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[]],\"updatePerson\"],null],[27,[26,2,\"AppendSingleId\"],[]]]],null],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"action\",\"isSaving\"]}",
    "meta": {
      "moduleName": "universal-cms/templates/people/edit.hbs"
    }
  });

  _exports.default = _default;
});