define("universal-cms/controllers/roles/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      updateRole(data) {
        return this.movieService.replaceOne('roles', data.role.id, {
          name: data.data.name,
          name_plural: data.data.name_plural
        }).then(replacedRole => {
          this.notificationService.show('Role saved', `<strong>${replacedRole.name}</strong> was successfully saved.`);
          data.role.setProperties({
            name: replacedRole.get('name'),
            name_plural: replacedRole.get('name_plural')
          });
          return replacedRole;
        }, error => {
          this.notificationService.show('Error', 'Could not save role.');
        });
      }

    }
  });

  _exports.default = _default;
});