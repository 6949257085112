define("universal-cms/routes/dreamworks/movies/edit", ["exports", "universal-cms/routes/dreamworks/resource-edit"], function (_exports, _resourceEdit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resourceEdit.default.extend({
    templateName: 'dreamworks/resource-edit',

    afterModel(model, transition) {
      this._super(...arguments); // console.log('model.resourceSaveData.hashtag', model.resourceSaveData.hashtag);
      // if (!model.resourceSaveData.hashtag) {
      //     model.resourceSaveData.set('hashtag', []);
      // } else {
      //     console.log('hashtag exist');
      // }

    }

  });

  _exports.default = _default;
});