define("universal-cms/components/pxl-auto-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    routing: Ember.inject.service('-routing'),
    userInterface: undefined,
    isSaving: false,
    title: null,
    languages: [],
    selectedLanguage: null,
    tabs: Ember.computed('userInterface.tabs.[]', function () {
      return this.userInterface.tabs.map(tab => {
        if (tab.form_fields) {
          tab.form_fields = tab.form_fields.map(formField => {
            return formField;
          });
        }

        return tab;
      });
    }),

    init() {
      this._super(...arguments);

      this.userInterface.tabs.map((tab, tabIndex) => {
        tab.set('isActive', tabIndex === 0);
        return tab;
      });
    },

    isGabbysWebsitePage: Ember.computed('routing.currentRouteName', function () {
      const serviceMappings = this.auth.decoded.scopes.mappings;
      const serviceMappingsKeys = Object.keys(serviceMappings);
      const isValidServiceMappingKeys = serviceMappingsKeys.includes('DreamWorks') && serviceMappingsKeys.includes('GabbysWebsite');
      return this.routing.currentRouteName.startsWith('gabbys-website') && isValidServiceMappingKeys;
    }),
    actions: {
      onChangedLanguage(_ref) {
        let {
          language
        } = _ref;
        // Reload page with ?lang query parameter
        const url = new URL(window.location.href);
        url.searchParams.set('lang', language.locale_code);
        window.location.href = url.href;
      },

      async save() {
        try {
          this.set('isSaving', true);
          let saveData = [];

          for (let tab of this.userInterface.tabs) {
            for (let formField of tab.form_fields) {
              saveData.push({
                key: formField.column,
                value: formField.value,
                category: tab.category,
                label: formField.label,
                type: formField.type
              });
            }
          }

          const queryParams = {};

          if (this.isGabbysWebsitePage) {
            queryParams.site = 'gabbys-website';
          }

          if (this.selectedLanguage) {
            queryParams.lang = this.selectedLanguage.locale_code;
          } else {
            queryParams.lang = 'en';
          }

          const queryParamsStr = Object.keys(queryParams).map(key => `${key}=${queryParams[key]}`).join('&');
          await this.dreamworksService.put(`api/settings?${queryParamsStr}`, saveData);
          this.notificationService.show('Saved', 'Settings has been saved!');
        } catch (error) {
          console.log(error);
          this.notificationService.show('Error', 'Could not save settings.');
        } finally {
          this.set('isSaving', false);
        }
      }

    }
  });

  _exports.default = _default;
});