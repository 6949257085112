define("universal-cms/ember-quill/tests/templates/components/resource-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SOAwZ1KZ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[16,1,null],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "universal-cms/ember-quill/tests/templates/components/resource-table.hbs"
    }
  });

  _exports.default = _default;
});