define("universal-cms/components/dreamworks/page-component-modals/gabbys-website-videos-grid", ["exports", "universal-cms/components/dreamworks/page-component-modals/page-component-modal"], function (_exports, _pageComponentModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _pageComponentModal.default.extend({
    classNames: 'gabbys-website-videos-grid',

    init() {
      this._super(...arguments);

      const playlistsResourceFormOptions = [];
      playlistsResourceFormOptions.pushObject(Ember.Object.create({
        column: 'title',
        type: 'text',
        label: 'Title'
      }));
      playlistsResourceFormOptions.pushObject(Ember.Object.create({
        column: 'youtube_id',
        type: 'text',
        label: 'YouTube Playlist ID'
      }));
      this.set('playlistsResourceFormOptions', playlistsResourceFormOptions);
    }

  });

  _exports.default = _default;
});