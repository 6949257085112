define("universal-cms/components/user-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    user: Ember.Object.create(),
    isSaving: false,
    toggledLanguageIds: [],
    isEditing: Ember.computed('mode', function () {
      return this.mode === 'edit';
    }),
    saveButtonText: Ember.computed('isSaving', function () {
      if (this.mode === 'edit') {
        return this.isSaving ? 'Saving...' : 'Save';
      } else {
        return this.isSaving ? 'Adding...' : 'Add';
      }
    }),
    username: Ember.computed('user.username', function () {
      return this.get('user.username');
    }),
    password: Ember.computed('user.password', function () {
      return this.isEditing ? '' : this.get('user.password');
    }),
    selectedGroup: Ember.computed('user.group', function () {
      return this.groups.find(group => {
        return group.get('id') === this.get('user.group');
      });
    }),
    selectedLanguages: Ember.computed('user.languages', function () {
      return this.languages.filter(language => {
        return this.user.languages.some(userLanguage => {
          return userLanguage.locale_code === language.locale_code;
        });
      });
    }),
    selectedLanguagesMap: Ember.computed('toggledLanguageIds.[]', function () {
      let toggledLanguageIds = this.get('toggledLanguageIds') || [];
      let map = {};
      this.languages.forEach(language => {
        map[language.id] = toggledLanguageIds.includes(language.id);
      });
      return map;
    }),
    groups: [],
    languages: [],
    group: null,
    language_locale_code: null,

    init() {
      this._super(...arguments);

      this.set('group', this.get('user.group'));
      this.set('language_locale_code', this.get('user.language_locale_code')); // Set the toggledLanguageIds

      this.set('toggledLanguageIds', this.get('user.languages') ? this.get('user.languages').map(language => language.language_id) : []);
    },

    isLanguageSelected(languageId) {
      return this.get('toggledLanguageIds').includes(languageId);
    },

    actions: {
      save() {
        let username = this.username;
        let password = this.password; // let group = this.selectedGroup;

        let errors = [];

        if (username === undefined || username === '') {
          errors.push('Enter username');
        }

        if (this.mode === 'add' && (password === undefined || password === '')) {
          errors.push('Enter password');
        }

        if (this.group === undefined || this.group === null) {
          errors.push('Select group');
        }

        if (errors.length > 0) {
          this.notificationService.show('Error', errors.join('<br>'));
          return;
        }

        this.set('isSaving', true);
        let actionName = (this.mode === 'edit' ? 'update' : 'create') + 'User';
        let changePassword = true;

        if (this.mode === 'edit') {
          if (password === '') {
            password = this.get('user.password');
            changePassword = false;
          }
        }

        this.get(actionName)({
          user: this.user,
          data: {
            username: this.username,
            password: password,
            changePassword: changePassword,
            group_id: this.group,
            language_ids: this.toggledLanguageIds
          }
        }).then(savedUser => {
          if (this.mode === 'add') {
            this.set('username', '');
            this.set('group', ''); // this.set('language_locale_code', '');

            this.set('user', Ember.Object.create());
          }

          this.set('password', '');
          this.set('isSaving', false);

          if (typeof this.onSaved === 'function') {
            this.onSaved(savedUser);
          }
        }).catch(error => {
          console.error(error);
          this.set('isSaving', false);
        });
      },

      selectGroup(selectedGroupId) {
        this.set('group', selectedGroupId);
        this.set('user.group', selectedGroupId);
      },

      // selectLanguage(selectedLanguageLocaleCode) {
      //     this.set('language_locale_code', selectedLanguageLocaleCode);
      //     this.set('user.language_locale_code', selectedLanguageLocaleCode);
      // },
      toggleLanguage(languageId) {
        let toggledLanguageIds = this.get('toggledLanguageIds');

        if (toggledLanguageIds.includes(languageId)) {
          toggledLanguageIds.removeObject(languageId);
        } else {
          toggledLanguageIds.pushObject(languageId);
        }
      }

    }
  });

  _exports.default = _default;
});