define("universal-cms/routes/uwatch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function () {
      return {
        'url': this.uwatchService.getUrl()
      };
    }
  });

  _exports.default = _default;
});