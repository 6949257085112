define("universal-cms/controllers/gabbys-website/clear-cache", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    selectedOption: 'individualUrls',
    isSubmitting: false,
    urls: '',
    urlsExampleText: Ember.computed(function () {
      return Ember.String.htmlSafe('/events<br>/characters/gabby');
    }),
    submitButtonText: Ember.computed('isSubmitting', function () {
      return this.isSubmitting ? 'Submitting...' : 'Submit';
    }),
    actions: {
      async submit() {
        if (this.isSubmitting) {
          return;
        }

        if (this.selectedOption === 'individualUrls') {
          if (!this.urls) {
            // Focus the textarea input field
            (0, _jquery.default)('textarea').focus();
            return;
          }
        } else if (this.selectedOption === 'allUrls') {
          if (!confirm('Are you sure you want to clear the cache for all URLs?')) {
            return;
          }
        }

        try {
          this.set('isSubmitting', true); // Split the URLs by line breaks

          const urls = this.selectedOption === 'individualUrls' ? this.urls.split('\n').map(url => url.trim()).filter(url => url.length > 0) : null;
          const clearCacheApiResponse = await this.dreamworksService.post('api/cache/clear', {
            type: this.selectedOption,
            urls,
            site: 'gabbys-website'
          });

          if (!clearCacheApiResponse || !clearCacheApiResponse.data) {
            throw new Error('Could not parse API response');
          } else if (clearCacheApiResponse.data.error) {
            throw new Error(clearCacheApiResponse.data.error);
          }

          if (this.selectedOption === 'individualUrls') {
            const clearedUrls = clearCacheApiResponse.data.urls || [];
            this.notificationService.show('Success', 'Cache is now being cleared for the following URLs:<br>' + clearedUrls.join('<br>'));
          } else if (this.selectedOption === 'allUrls') {
            this.notificationService.show('Success', 'Cache is now being cleared for all URLs');
          }
        } catch (error) {
          console.error(error);
          this.notificationService.show('Error', error.message);
        } finally {
          this.set('isSubmitting', false);
        }
      }

    }
  });

  _exports.default = _default;
});