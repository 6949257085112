define("universal-cms/routes/universal-brand/projects/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      const controller = this.controllerFor(this.routeName);
      const projectId = params.project_id;
      controller.set('projectId', projectId);
      const isNew = projectId === 'add';
      controller.set('isNew', isNew);
      let rsvp = {};
      rsvp.project = isNew ? Ember.Object.create({
        isNew: true
      }) : this.universalBrandService.getOne('projects', `${projectId}?relationships[]=desktopImage&relationships[]=mobileImage&relationships[]=thumbnailImage&relationships[]=galleries&relationships[]=categories`).then(project => {
        return project;
      }, () => {
        controller.set('error', true);
      });
      rsvp.businesses = this.universalBrandService.getAll('businesses').then(businesses => {
        return businesses;
      }, () => {
        controller.set('error', 'Could not load businesses');
      });
      rsvp.projects = this.universalBrandService.getAll('projects').then(projects => {
        return projects;
      }, () => {
        controller.set('error', 'Could not load projects');
      });
      rsvp.categories = this.universalBrandService.getAll('categories').then(categories => {
        return categories;
      }, () => {
        controller.set('error', 'Could not load categories');
      });
      return Ember.RSVP.hash(rsvp);
    }

  });

  _exports.default = _default;
});