define("universal-cms/components/gabbys-website/_language-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isSelectingLanguage: false,
    selectLanguageError: null,
    selectedLanguageId: null,
    languages: [],
    isLoading: Ember.computed('isSelectingLanguage', function () {
      return this.get('isSelectingLanguage') === true;
    }),
    loadError: Ember.computed('selectLanguageError', function () {
      if (this.get('selectLanguageError') !== null) {
        return this.get('selectLanguageError');
      } else {
        return null;
      }
    }),
    formattedLanguages: Ember.computed('languages.[]', function () {
      return this.get('languages') || [];
    }),
    selectLanguageDisabled: Ember.computed('auth.languageLocaleCode', function () {
      return this.auth.languageLocaleCode !== null;
    }),

    didInsertElement() {
      this._super(...arguments); // Read ?lang query param


      const url = new URL(window.location.href);
      const queryLangValue = url.searchParams.get('lang');
      const languages = this.get('formattedLanguages');
      const defaultLanguage = languages.find(language => language.id === 1);
      let selectedLanguageId = null;

      if (this.auth.languageLocaleCode) {
        const language = languages.find(language => language.locale_code === this.auth.languageLocaleCode);

        if (!language) {
          console.warn('Could not find language with locale_code: ', this.auth.languageLocaleCode);
          return;
        }

        selectedLanguageId = language.id;
      } else if (queryLangValue) {
        const language = languages.find(language => language.locale_code === queryLangValue);

        if (!language) {
          console.warn('Could not find language with locale_code: ', queryLangValue);
          return;
        }

        selectedLanguageId = language.id;
      } else if (defaultLanguage) {
        selectedLanguageId = defaultLanguage.id;
      } else if (languages.length > 0) {
        selectedLanguageId = languages[0].id;
      }

      this.set('selectedLanguageId', selectedLanguageId);
    },

    selectLanguage: Ember._action(async function (event) {
      const selectedLanguageId = parseInt(event.target.value);
      const selectedLanguage = this.get('formattedLanguages').find(language => language.id === selectedLanguageId);

      if (!selectedLanguage) {
        alert(`Could not find language with ID: ${selectedLanguageId}`);
        return;
      }

      if (typeof this.onChangedLanguage === 'function') {
        this.onChangedLanguage({
          language: selectedLanguage
        });
      }
    })
  });

  _exports.default = _default;
});