define("universal-cms/helpers/array-index-prop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.arrayIndexProp = arrayIndexProp;
  _exports.default = void 0;

  function arrayIndexProp(params) {
    let array = params[0];
    let index = params[1];
    let prop = params[2];

    if (!prop) {
      return array[index];
    } else {
      return array[index][prop];
    }
  }

  var _default = Ember.Helper.helper(arrayIndexProp);

  _exports.default = _default;
});