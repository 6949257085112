define("universal-cms/controllers/website/preview/list", ["exports", "jquery", "moment"], function (_exports, _jquery, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    newlyCreatedLinkUrl: null,
    checkAllPreviewTokensChecked: undefined,
    watchCheckAllPreviewTokensChecked: Ember.observer('checkAllPreviewTokensChecked', function () {
      const isChecked = this.checkAllPreviewTokensChecked;

      for (let previewToken of this.previewTokens) {
        previewToken.set('isChecked', isChecked);
      }
    }),
    noSelectedPreviewTokens: Ember.computed('checkAllPreviewTokensChecked', 'previewTokens', function () {
      let haveCheckedPreviewToken = false;

      for (let previewToken of this.previewTokens) {
        if (previewToken.get('isChecked') === true) {
          haveCheckedPreviewToken = true;
          break;
        }
      }

      return !haveCheckedPreviewToken;
    }),
    actions: {
      onCreate(data) {
        return this.serviceResource.newResource('website', 'preview-tokens', data, 'preview-tokens').then(createdPreviewToken => {
          this.set('newlyCreatedLinkUrl', this.serviceResource.getUrl(`preview/${createdPreviewToken[0].get('key')}`));
          (0, _jquery.default)('#created-preview-link-modal').modal('show');
          this.get('model.previewTokens').pushObject(createdPreviewToken[0]);
          return createdPreviewToken[0];
        }, error => {
          this.notificationService.show('Error', 'Could not add preview token.');
        });
      },

      deleteSelectedPreviewTokens() {
        if (!confirm('Are you sure you want to delete selected preview tokens?')) {
          return;
        }

        let deletePromises = [];

        for (const previewToken of this.previewTokens) {
          if (previewToken.get('isChecked')) {
            deletePromises.push(new Ember.RSVP.Promise((resolve, reject) => {
              this.serviceResource.deleteResource('website', 'preview-tokens', previewToken).then(() => {
                previewToken.set('isDeleting', true);
                this.get('model.previewTokens').removeObject(previewToken);
                resolve();
              }, error => {
                reject();
              });
            }));
          }
        }

        this.set('isDeletingPreviewTokens', true);
        Ember.RSVP.Promise.all(deletePromises).then(result => {
          const numDeletedPreviewTokens = result.length;
          this.set('isDeletingPreviewTokens', false);
          this.notificationService.show('Deleted', 'Preview link' + (numDeletedPreviewTokens !== 1 ? 's were' : ' was') + ' successfully deleted.'); // Unselect "Delete All" checkbox if all preview tokens were deleted

          if (this.previewTokens.length === 0) {
            this.set('noSelectedPreviewTokens', true);
          }
        });
      },

      previewTokenDeleteChecked(clickedPreviewToken, isChecked) {
        clickedPreviewToken.set('isChecked', isChecked); // If all preview tokens are unchecked, also uncheck the check all checkbox

        let isAllUnchecked = true;

        for (let previewToken of this.previewTokens) {
          if (previewToken.get('isChecked') === true) {
            isAllUnchecked = false;
            break;
          }
        }

        if (isAllUnchecked === true) {
          this.set('checkAllPreviewTokensChecked', false);
        }

        this.previewTokens.arrayContentDidChange();
      }

    },
    previewTokens: Ember.computed('model.previewTokens.[]', function () {
      let previewTokens = this.get('model.previewTokens');
      previewTokens.map(previewToken => {
        let time = (0, _moment.default)(previewToken.time.date);
        previewToken.set('timeDate', time.format('YYYY-MM-DD'));
        previewToken.set('timeTime', time.format('HH:mm'));
        previewToken.set('url', this.serviceResource.getUrl(`preview/${previewToken.get('key')}`));
        let initialPage = null;

        for (let pageIndex = 0; pageIndex < this.pages.length; pageIndex++) {
          let page = this.pages[pageIndex];

          if (typeof page.subPages !== 'undefined') {
            let subPage = page.subPages.find(subPage => {
              return subPage.path === previewToken.initial_page;
            });

            if (subPage !== null) {
              initialPage = subPage;
              break;
            }
          } else {
            if (page.path === previewToken.initial_page) {
              initialPage = page;
              break;
            }
          }
        }

        previewToken.set('formattedInitialPage', initialPage ? initialPage.title : '-');
      });
      return previewTokens;
    }),
    pages: Ember.computed(function () {
      let pages = [{
        title: 'Home Page',
        path: ''
      }];
      let moviesPage = {
        title: 'Movies',
        subPages: []
      };
      this.get('model.movies').forEach(movie => {
        moviesPage.subPages.push({
          title: movie.get('title'),
          path: `movies/${movie.get('slug')}`
        });
      });
      pages.push(moviesPage);
      pages.push({
        title: 'About',
        path: 'about'
      });
      return pages;
    })
  });

  _exports.default = _default;
});