define("universal-cms/routes/movies/edit/genres", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return this.movieService.getAll('genres');
    },

    setupController(controller, model) {
      this._super(controller, model);

      controller.addedGenres.clear();
      controller.set('hasAddedGenres', false);
      controller.nonAddedGenres.clear();
      let allGenres = model;
      let addedGenres = controller.movie.movie_genres;
      allGenres.forEach(genre => {
        let isAdded = false; // Check if it's added

        addedGenres.forEach(addedGenre => {
          if (addedGenre.genre === genre.id) {
            isAdded = true;
          }
        });

        if (isAdded) {
          controller.addedGenres.pushObject(genre);
        } else {
          controller.nonAddedGenres.pushObject(genre);
        }
      });

      if (controller.addedGenres.length > 0) {
        controller.set('hasAddedGenres', true);
      }

      controller.set('isLoading', false);
    },

    actions: {
      addGenre() {
        let movie = this.controller.get('movie');
        let addedGenres = this.controller.get('addedGenres');
        let nonAddedGenres = this.controller.get('nonAddedGenres');
        let selectedGenre = this.controller.get('selectedGenre');

        if (selectedGenre === undefined) {
          return;
        }

        this.controller.set('isAdding', true); // Save to database

        this.movieService.createOne('movie-genres', {
          movie: movie.id,
          genre: selectedGenre.id,
          live_start: (0, _moment.default)().format('YYYY-MM-DD HH:mm:ss'),
          live_end: null
        }).then(addedMovieGenre => {
          addedGenres.pushObject(selectedGenre);
          nonAddedGenres.removeObject(selectedGenre);
          movie.movie_genres.pushObject(addedMovieGenre);
          this.controller.set('hasAddedGenres', true);
          this.notificationService.show('Genre added', `<strong>${selectedGenre.name}</strong> added to movie.`);
        }, error => {
          if (error === 'RELATIONSHIP_ALREADY_EXIST_ERROR') {
            this.notificationService.show('Genre already added', `<strong>${selectedGenre.name}</strong> is already added.`);
          } else {
            this.notificationService.show(`Could not add <strong>${selectedGenre.name}</strong> to movie.`);
          }
        }).finally(() => {
          this.controller.set('isAdding', false);
        });
      },

      deleteGenre: function (genre) {
        if (!confirm(`Are you sure you want to delete genre "${genre.get('name')}"?`)) {
          return;
        }

        let movie = this.controller.get('movie');
        let movieGenreToDelete = movie.movie_genres.find(movieGenre => {
          return movieGenre.genre === genre.id;
        });

        if (!movieGenreToDelete.id) {
          alert('Could not delete movie genre.');
          return;
        }

        let addedGenres = this.controller.get('addedGenres');
        let nonAddedGenres = this.controller.get('nonAddedGenres');
        genre.set('isDeleting', true); // Save to database

        this.movieService.deleteOne('movie-genres', movieGenreToDelete.id).then(deletedMovieGenre => {
          genre.set('isDeleting', false);
          addedGenres.removeObject(genre);
          nonAddedGenres.pushObject(genre);
          movie.movie_genres = movie.movie_genres.filter(movieGenre => {
            return movieGenre.id !== movieGenreToDelete.id;
          });

          if (addedGenres.length === 0) {
            this.controller.set('hasAddedGenres', false);
          }

          this.notificationService.show('Genre deleted', `<strong>${genre.name}</strong> deleted from movie.`);
        });
      }
    }
  });

  _exports.default = _default;
});