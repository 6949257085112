define("universal-cms/components/dreamworks/resource-retailer-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    util: Ember.inject.service(),
    classNames: 'resource-retailer-editor',
    resourceRetailers: [],
    isLoadingRetailers: false,
    loadRetailersError: null,
    retailers: null,
    showSeasons: false,
    pageResourceType: null,
    selectedSeason: null,
    selectedSeasonIndex: Ember.computed('resourceRetailers.seasons.[]', 'selectedSeason', function () {
      if (!this.selectedSeason) {
        return -1;
      }

      if (!this.resourceRetailers.seasons) {
        return -1;
      }

      return this.resourceRetailers.seasons.findIndex(season => season.number === this.selectedSeason.number);
    }),
    numSeasons: Ember.computed('resourceRetailers.seasons.[]', function () {
      if (typeof this.resourceRetailers.seasons !== 'object') {
        return 0;
      }

      return this.resourceRetailers.seasons.length;
    }),
    retailerToAdd: null,
    selectedSeasonRetailers: Ember.computed('selectedSeason.{retailers.[],data.[]}', function () {
      if (!this.selectedSeason && (!this.selectedSeason.data || this.selectedSeason.retailers)) {
        return null;
      }

      if (this.showSeasons) {
        return this.selectedSeason.data.map(retailer => {
          const retailerObject = this.retailers.find(_retailer => _retailer.id === retailer.vendor);
          retailer.set('name', retailerObject.name);
          return retailer;
        });
      } else {
        return this.selectedSeason.retailers.map(retailer => {
          const retailerObject = this.retailers.find(_retailer => _retailer.id === retailer.vendor);
          retailer.set('name', retailerObject.name);
          return retailer;
        });
      }
    }),
    removeSeasonButtonDisabled: Ember.computed('selectedSeason', function () {
      return !this.selectedSeason;
    }),
    addRetailerButtonDisabled: Ember.computed('retailerToAdd', function () {
      return !this.retailerToAdd;
    }),
    nonAddedRetailers: Ember.computed('retailers.[]', 'selectedSeasonRetailers.[]', function () {
      if (!this.selectedSeasonRetailers) {
        return this.retailers;
      }

      return this.retailers.filter(retailer => this.selectedSeasonRetailers.findIndex(selectedSeasonRetailer => selectedSeasonRetailer.vendor === retailer.id) === -1);
    }),
    moveSeasonUpButtonDisabled: Ember.computed('selectedSeason', 'selectedSeasonIndex', function () {
      return !this.selectedSeason || this.selectedSeasonIndex === 0;
    }),
    moveSeasonDownButtonDisabled: Ember.computed('selectedSeason', 'selectedSeasonIndex', function () {
      return !this.selectedSeason || this.selectedSeasonIndex === this.numSeasons - 1;
    }),

    async init() {
      this._super(...arguments);

      await this.send('loadRetailers');
      console.log('JUST LOADED RETAILERS: ', this.resourceRetailers);

      if (typeof this.resourceRetailers === 'undefined' || this.resourceRetailers === null) {
        this.set('resourceRetailers', []);
      }

      if (this.showSeasons) {
        // Found seasons
        if (this.resourceRetailers.seasons) {
          if (this.resourceRetailers.seasons.length > 0) {
            this.set('selectedSeason', this.resourceRetailers.seasons[0]);
          }
        } else {
          this.resourceRetailers.set('seasons', []);
        }
      } else {
        this.set('selectedSeason', Ember.Object.create({
          'retailers': this.resourceRetailers
        }));
      }
    },

    actions: {
      async loadRetailers() {
        try {
          this.set('isLoadingRetailers', true);
          this.set('loadRetailersError', null);
          const retailers = await this.dreamworksService.getMany('retailers');
          this.set('retailers', retailers);
        } catch (error) {
          console.log('error', error);
          this.set('loadRetailersError', 'Could not load retailers.');
        } finally {
          this.set('isLoadingRetailers', false);
        }
      },

      changeSeason(season) {
        this.set('selectedSeason', season);
      },

      addSeason() {
        // const nextSeasonNumber = (this.resourceRetailers.seasons && typeof this.resourceRetailers.seasons === 'object' ? this.resourceRetailers.seasons.length + 1 : 1);
        const seasonNumber = prompt('Enter season number (e.g. 1 or 1-2):', '');

        if (!seasonNumber) {
          return;
        }

        this.resourceRetailers.seasons.pushObject({
          number: seasonNumber,
          data: []
        });
        this.set('selectedSeason', this.resourceRetailers.seasons.find(season => season.number === seasonNumber));
      },

      addRetailer() {
        if (!this.retailerToAdd) {
          return;
        }

        if (this.selectedSeason.data) {
          this.selectedSeason.data.pushObject(Ember.Object.create({
            vendor: this.retailerToAdd.id,
            link: this.retailerToAdd.link
          }));
        } else if (this.selectedSeason.retailers) {
          this.resourceRetailers.pushObject(Ember.Object.create({
            vendor: this.retailerToAdd.id,
            link: this.retailerToAdd.link
          }));
          this.selectedSeason.set('retails', this.resourceRetailers);
        }

        this.set('retailerToAdd', null);
      },

      deleteRetailer(retailerToRemove) {
        if (this.selectedSeason.data) {
          this.selectedSeason.data.removeObject(retailerToRemove);
        } else if (this.selectedSeason.retailers) {
          this.selectedSeason.retailers.removeObject(retailerToRemove);
        }
      },

      removeSelectedSeason() {
        if (!confirm(`Are you sure you want to remove "Season ${this.selectedSeason.number}"?`)) {
          return;
        }

        this.resourceRetailers.seasons.removeObject(this.selectedSeason);
        this.set('selectedSeason', null);
      },

      moveSeason(direction) {
        const newSeasonsOrder = this.util.moveArrayItems(this.util.cloneObject(this.resourceRetailers.seasons), this.selectedSeasonIndex, direction === 'up' ? this.selectedSeasonIndex - 1 : this.selectedSeasonIndex + 1);
        this.resourceRetailers.set('seasons', newSeasonsOrder);
        this.set('selectedSeason', this.resourceRetailers.seasons[this.selectedSeasonIndex]);
      },

      onReordered() {
        if (this.showSeasons) {
          this.resourceRetailers.seasons[this.selectedSeasonIndex].set('data', this.selectedSeasonRetailers);
        } else {
          this.set('resourceRetailers', this.selectedSeasonRetailers);
        }
      }

    }
  });

  _exports.default = _default;
});