define("universal-cms/instance-initializers/auth", ["exports", "universal-cms/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize(applicationInstance) {
    const cookie = localStorage.getItem('auth');
    const authEncoded = localStorage.getItem('auth_encoded');

    if (cookie && authEncoded) {
      let auth = applicationInstance.lookup('service:auth');
      let movieService = applicationInstance.lookup('service:movie');
      let serviceResource = applicationInstance.lookup('service:serviceResource');
      let universalStudiosService = applicationInstance.lookup('service:universalStudios');
      let universalBrandService = applicationInstance.lookup('service:universalBrand');
      let dreamworksService = applicationInstance.lookup('service:dreamworks');
      let uwatchService = applicationInstance.lookup('service:uwatch');
      let jwt = JSON.parse(cookie);
      let connectSrc = ["'self'"]; // Movie service

      movieService.setUrl(jwt.decoded.scopes.mappings.Movies);
      movieService.setWebsiteUrl(jwt.decoded.scopes.mappings.Website);
      connectSrc.push(movieService.getUrl()); // Website service

      serviceResource.setUrl(jwt.decoded.scopes.mappings.Website);
      connectSrc.push(serviceResource.getUrl());
      auth.setUrl(jwt.decoded.scopes.mappings.Auth);
      connectSrc.push(auth.getUrl()); // Studios

      if (typeof jwt.decoded.scopes.mappings.UniversalStudios !== 'undefined') {
        universalStudiosService.setUrl(jwt.decoded.scopes.mappings.UniversalStudios);
      } else {
        universalStudiosService.setUrl('http://newuniversalstudios.upqa.com');
      }

      connectSrc.push(universalStudiosService.getUrl()); // Brand

      universalBrandService.setUrl(jwt.decoded.scopes.mappings.UniversalBrand);
      connectSrc.push(universalBrandService.getUrl()); // DreamWorks

      dreamworksService.setUrl(jwt.decoded.scopes.mappings.DreamWorks);
      dreamworksService.setGabbysWebsiteUrl(jwt.decoded.scopes.mappings.GabbysWebsite);
      connectSrc.push(dreamworksService.getUrl()); // uwatch

      uwatchService.setUrl(jwt.decoded.scopes.mappings.UWatchApi);
      movieService.setUWatchUrl(jwt.decoded.scopes.mappings.UWatchApi);
      connectSrc.push(uwatchService.getUrl());
      _environment.default.contentSecurityPolicy['connect-src'] = connectSrc;
      auth.login(jwt, authEncoded);
    }
  }

  var _default = {
    name: 'auth',
    initialize: initialize
  };
  _exports.default = _default;
});