define("universal-cms/routes/universal-studios/slider/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      const controller = this.controllerFor(this.routeName);
      const sliderId = params.slider_id;
      const isNew = sliderId === 'add';
      controller.set('isNew', isNew);
      let rsvp = {};
      rsvp.slider = isNew ? Ember.Object.create({
        isNew: true
      }) : this.universalStudiosService.getOne('sliders', sliderId).then(slider => {
        if (slider.get('desktop_image')) {
          slider.set('desktop_image_url', this.auth.getUniversalStudiosUrl(`uploads/${slider.get('desktop_image')}`));
        }

        if (slider.get('mobile_image')) {
          slider.set('mobile_image_url', this.auth.getUniversalStudiosUrl(`uploads/${slider.get('mobile_image')}`));
        }

        return slider;
      }, () => {
        controller.set('error', true);
      });
      return Ember.RSVP.hash(rsvp);
    }

  });

  _exports.default = _default;
});