define("universal-cms/routes/universal-brand/pages/edit-featured-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      const controller = this.controllerFor(this.routeName);
      controller.set('pageId', params.page_id);
      controller.set('pageVersionId', params.page_version_id);
      const carouselFeatureItemId = params.carousel_feature_item_id;
      const isNew = carouselFeatureItemId === 'add';
      controller.set('isNew', isNew);
      let rsvp = {};
      rsvp.carouselFeatureItem = isNew ? Ember.Object.create({
        isNew: true
      }) : this.universalBrandService.getOne('carousel-feature-items', `${carouselFeatureItemId}?relationships[]=desktopImage&relationships[]=mobileImage`).then(carouselFeatureItem => {
        return carouselFeatureItem;
      }, () => {
        controller.set('error', true);
      });
      rsvp.pages = this.universalBrandService.getAll('pages');
      return Ember.RSVP.hash(rsvp);
    }

  });

  _exports.default = _default;
});