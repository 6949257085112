define("universal-cms/controllers/dashboard/users/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      createUser(data) {
        let groupId = data.data.group_id;
        let languageIds = data.data.language_ids || [];
        return new Promise((resolve, reject) => {
          if (!groupId) {
            this.notificationService.show('Error', 'Could not add user.');
            reject();
          } else {
            return this.auth.createOne('users', {
              username: data.data.username,
              password: data.data.password,
              group: groupId,
              language_ids: languageIds
            }).then(createdUser => {
              this.auth.getOne('groups', groupId).then(group => {
                createdUser.set('group', group);
                this.get('model.users').pushObject(createdUser);
                this.notificationService.show('User added', `<strong>${createdUser.username}</strong> was successfully added.`);
                resolve(createdUser);
              }, error => {
                this.notificationService.show('Error', 'Could not add user.');
                reject();
              });
            }, error => {
              this.notificationService.show('Error', 'Could not add user.');
              reject();
            });
          }
        });
      },

      deleteUser(user) {
        if (!confirm('Are you sure you want to delete user?')) {
          return;
        }

        user.set('isDeleting', true);
        this.auth.deleteOne('users', user.get('id')).then(() => {
          this.notificationService.show('User deleted', `<strong>${user.username}</strong> was successfully deleted.`);
          this.get('model.users').removeObject(user);
        }, error => {
          user.set('isDeleting', false);
          this.notificationService.show('Error', `Could not delete user <strong>${user.username}</strong>.`);
        });
      },

      onSavedUser() {
        this.send('refreshModel');
      }

    }
  });

  _exports.default = _default;
});