define("universal-cms/components/models-table/dreamworks/page-actions", ["exports", "universal-cms/components/models-table/models-table"], function (_exports, _modelsTable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _modelsTable.default.extend({});

  _exports.default = _default;
});