define("universal-cms/routes/universal-studios/banner-headers/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      const controller = this.controllerFor(this.routeName);
      const bannerHeaderId = params.banner_header_id;
      const isNew = bannerHeaderId === 'add';
      controller.set('isNew', isNew);
      let rsvp = {};
      rsvp.bannerHeader = isNew ? Ember.Object.create({
        isNew: true
      }) : this.universalStudiosService.getOne('banner-headers', bannerHeaderId).then(bannerHeader => {
        if (bannerHeader.get('desktop_image')) {
          bannerHeader.set('desktop_image_url', this.auth.getUniversalStudiosUrl(`uploads/${bannerHeader.get('desktop_image')}`));
        }

        if (bannerHeader.get('mobile_image')) {
          bannerHeader.set('mobile_image_url', this.auth.getUniversalStudiosUrl(`uploads/${bannerHeader.get('mobile_image')}`));
        }

        return bannerHeader;
      }, () => {
        controller.set('error', true);
      });
      return Ember.RSVP.hash(rsvp);
    }

  });

  _exports.default = _default;
});