define("universal-cms/controllers/universal-studios/playlists/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    error: false,
    heading: Ember.computed('isNew', 'model.playlist.title', function () {
      return this.isNew === true ? 'Add Playlist' : this.get('model.playlist.title');
    }),
    isSaving: false,
    saveButtonText: Ember.computed('isNew', 'isSaving', function () {
      if (this.isNew === true) {
        return this.isSaving === true ? 'Adding...' : 'Add';
      } else {
        return this.isSaving === true ? 'Saving...' : 'Save';
      }
    }),
    actions: {
      save() {
        this.set('isSaving', true);
        const playlist = this.get('model.playlist');
        let saveData = playlist.getProperties('title', 'description', 'playlistid');

        if (this.isNew) {
          this.universalStudiosService.createOne('playlists', saveData).then(createResponse => {
            if (typeof createResponse.errors === 'undefined') {
              this.notificationService.show('Playlist Added', `<strong>${createResponse.get('title')}</strong> has been added.`);
              this.target.transitionTo(this.routeName, createResponse.get('id'));
            } else {
              this.notificationService.show('Error', this.universalStudiosService.formatErrorResponse(createResponse.errors));
            }
          }, () => {
            this.notificationService.show('Error', 'Could not add playlist.');
          }).finally(() => {
            this.set('isSaving', false);
          });
        } else {
          this.universalStudiosService.updateOne('playlists', playlist.get('id'), saveData, true).then(updateResponse => {
            if (typeof updateResponse.errors === 'undefined') {
              this.notificationService.show('Playlist Updated', `<strong>${updateResponse.get('title')}</strong> has been updated.`);
              this.target.transitionTo(this.routeName, updateResponse.get('id'));
            } else {
              this.notificationService.show('Error', this.universalStudiosService.formatErrorResponse(updateResponse.errors));
            }
          }, () => {
            this.notificationService.show('Error', 'Could not update playlist.');
          }).finally(() => {
            this.set('isSaving', false);
          });
        }
      }

    }
  });

  _exports.default = _default;
});