define("universal-cms/routes/website/seo/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      return Ember.RSVP.hash({
        page: Ember.ArrayProxy.create(this.serviceResource.getResource('website', 'seo-pages', params.page_id + (params.resource_id !== null ? '/' + params.resource_id : ''), 'seo-pages')),
        seoTypes: this.modelFor('website.seo').seoTypes
      });
    }

  });

  _exports.default = _default;
});