define("universal-cms/templates/components/gabbys-website/language-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "voZnuxRo",
    "block": "{\"symbols\":[\"language\"],\"statements\":[[9,\"div\",true],[12,\"class\",\"select-language-container\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"label\",true],[12,\"class\",\"select-language-container__label\",null],[10],[1,1,0,0,\"\\n        Language:\\n        \"],[9,\"select\",false],[23,\"class\",\"form-control\",null],[14,\"disabled\",[27,[26,5,\"AppendSingleId\"],[]],null],[3,0,0,[27,[26,7,\"ModifierHead\"],[]],[\"change\",[31,163,6,[27,[26,6,\"CallHead\"],[]],[[27,[24,0],[]],\"selectLanguage\"],null]],null],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,2,\"BlockHead\"],[]],[[27,[24,0],[\"languages\",\"length\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[5,[27,[26,4,\"BlockHead\"],[]],[[31,0,0,[27,[26,3,\"CallHead\"],[]],[[31,0,0,[27,[26,3,\"CallHead\"],[]],[[27,[24,0],[\"availableLanguages\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"                    \"],[9,\"option\",true],[13,\"value\",[27,[24,1],[\"locale_code\"]],null],[13,\"selected\",[31,403,2,[27,[26,2,\"CallHead\"],[]],[[31,407,2,[27,[26,1,\"CallHead\"],[]],[[27,[24,1],[\"locale_code\"]],[27,[26,0,\"Expression\"],[\"locale_code\"]]],null],\"selected\"],null],null],[10],[1,1,0,0,\"\\n                        \"],[1,0,0,0,[27,[24,1],[\"name\"]]],[1,1,0,0,\"\\n                    \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"                \"],[9,\"option\",true],[12,\"disabled\",\"\",null],[12,\"selected\",\"\",null],[10],[1,1,0,0,\"No languages\"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"        \"],[11],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\\n    \"],[9,\"img\",true],[13,\"src\",[27,[24,0],[\"currentLanguageFlagUrl\"]],null],[12,\"class\",\"select-language-container__flag\",null],[12,\"alt\",\"\",null],[10],[11],[1,1,0,0,\"\\n\"],[11]],\"hasEval\":false,\"upvars\":[\"selectedLanguage\",\"eq\",\"if\",\"-track-array\",\"each\",\"selectLanguageDisabled\",\"action\",\"on\"]}",
    "meta": {
      "moduleName": "universal-cms/templates/components/gabbys-website/language-selector.hbs"
    }
  });

  _exports.default = _default;
});