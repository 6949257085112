define("universal-cms/services/notification", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    show(title, text) {
      _jquery.default.gritter.add({
        title: title,
        text: text,
        sticky: false,
        time: 3500,
        fade_out_speed: 400
      });
    }

  });

  _exports.default = _default;
});