define("universal-cms/routes/uwatch/resources/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController(controller, model) {
      controller.set('shouldEditInline', true);
      controller.set('isSortable', true);
      controller.set('disallowEdit', false);

      if (['faqs'].includes(model.resourceName)) {
        controller.set('shouldEditInline', false);
      }

      this._super(controller, model);
    }

  });

  _exports.default = _default;
});