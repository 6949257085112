define("universal-cms/ember-quill/tests/templates/components/models-table/seo-page-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9gDswaqB",
    "block": "{\"symbols\":[],\"statements\":[[5,[27,[26,2,\"BlockHead\"],[]],[[27,[26,0,\"Expression\"],[\"resource_id\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"    \"],[7,\"link-to\",[[23,\"class\",\"btn btn-primary btn-xs\",null]],[[\"@route\",\"@model\",\"@query\"],[\"website.seo.edit\",[27,[26,0,\"AppendSingleId\"],[\"id\"]],[31,95,4,[27,[26,1,\"CallHead\"],[]],null,[[\"resource_id\"],[[27,[26,0,\"Expression\"],[\"resource_id\"]]]]]]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"Edit\"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"    \"],[7,\"link-to\",[[23,\"class\",\"btn btn-primary btn-xs\",null]],[[\"@route\",\"@model\"],[\"website.seo.edit\",[27,[26,0,\"AppendSingleId\"],[\"id\"]]]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"Edit\"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"record\",\"hash\",\"if\"]}",
    "meta": {
      "moduleName": "universal-cms/ember-quill/tests/templates/components/models-table/seo-page-actions.hbs"
    }
  });

  _exports.default = _default;
});