define("universal-cms/controllers/website/preview/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      onSave(data) {
        return this.serviceResource.updateResource('website', 'preview-tokens', data, 'preview-tokens').then(previewToken => {
          this.notificationService.show('Saved', 'Preview token saved.');
          return previewToken;
        }, error => {
          this.notificationService.show('Error', 'Could not save preview token.');
        });
      }

    },
    pages: Ember.computed(function () {
      let pages = [{
        title: 'Home Page',
        path: ''
      }];
      let moviesPage = {
        title: 'Movies',
        subPages: []
      };
      this.get('model.movies').forEach(movie => {
        moviesPage.subPages.push({
          title: movie.get('title'),
          path: `movies/${movie.get('slug')}`
        });
      });
      pages.push(moviesPage);
      pages.push({
        title: 'About',
        path: 'about'
      });
      return pages;
    })
  });

  _exports.default = _default;
});