define("universal-cms/ember-quill/tests/templates/components/models-table/dreamworks/page-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C7PH9drx",
    "block": "{\"symbols\":[],\"statements\":[[9,\"a\",true],[13,\"href\",[27,[26,0,\"AppendSingleId\"],[\"url\"]],null],[12,\"target\",\"_blank\",null],[10],[1,1,0,0,\"\\n    \"],[1,0,0,0,[27,[26,0,\"Expression\"],[\"data\",\"data\",\"slug\"]]],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"record\"]}",
    "meta": {
      "moduleName": "universal-cms/ember-quill/tests/templates/components/models-table/dreamworks/page-url.hbs"
    }
  });

  _exports.default = _default;
});