define("universal-cms/routes/universal-brand/news/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      const controller = this.controllerFor(this.routeName);
      const newsId = params.news_id;
      const isNew = newsId === 'add';
      controller.set('isNew', isNew);
      let rsvp = {};
      rsvp.article = isNew ? Ember.Object.create({
        isNew: true
      }) : this.universalBrandService.getOne('articles', `${newsId}?relationships[]=bannerImage&relationships[]=mobileBannerImage&relationships[]=thumbnailImage`).then(newsArticle => {
        return newsArticle;
      }, () => {
        controller.set('error', true);
      });
      rsvp.businesses = this.universalBrandService.getAll('businesses').then(businesses => {
        return businesses;
      }, () => {
        controller.set('error', 'Could not load businesses');
      });
      return Ember.RSVP.hash(rsvp);
    }

  });

  _exports.default = _default;
});