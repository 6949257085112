define("universal-cms/components/super-table-yield/table-body-cell/input-media", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      updateValue(value) {
        this.value.set(this.column, value);

        if (typeof this.doneEditing === 'function') {
          this.doneEditing();
        }

        if (typeof this.valueUpdated === 'function') {
          this.valueUpdated(value);
        }
      }

    }
  });

  _exports.default = _default;
});