define("universal-cms/templates/components/super-table-yield/table-body-row/delete-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ICVU64Mh",
    "block": "{\"symbols\":[],\"statements\":[[9,\"button\",true],[12,\"class\",\"btn btn-xs btn-danger\",null],[13,\"disabled\",[27,[26,0,\"AppendSingleId\"],[]],null],[12,\"type\",\"button\",null],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,1,\"BlockHead\"],[]],[[27,[26,0,\"Expression\"],[]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"        Delete\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"        Deleting...\\n\"]],\"parameters\":[]}]]],[11]],\"hasEval\":false,\"upvars\":[\"isDeleting\",\"unless\"]}",
    "meta": {
      "moduleName": "universal-cms/templates/components/super-table-yield/table-body-row/delete-button.hbs"
    }
  });

  _exports.default = _default;
});