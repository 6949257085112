define("universal-cms/routes/dashboard/users/list", ["exports", "universal-cms/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    async model() {
      const authOptions = await this.auth.getOptions();
      const users = await this.auth.getAll('users', null, ['group', 'languages']);
      const formattedUsers = users.map(user => {
        const numLanguages = user.languages ? Object.keys(user.languages).length : 0;
        let languages = [];

        if (numLanguages > 0) {
          languages = Object.keys(user.languages).map(languageIndex => {
            const userLanguage = user.languages[languageIndex];
            userLanguage.flagUrl = `${this.authUrl}/images/flags/${userLanguage.locale_code}.png`;
            return userLanguage;
          });
        }

        return {
          id: user.id,
          username: user.username,
          links: user.links,
          group: user.group,
          created_at: user.created_at,
          updated_at: user.updated_at,
          languages
        };
      });
      return Ember.RSVP.hash({
        users: formattedUsers,
        groups: this.auth.getAll('groups'),
        authOptions
      });
    },

    init() {
      this._super();

      this.set('authUrl', _environment.default.authUrl);
    },

    actions: {
      refreshModel() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});