define("universal-cms/controllers/website/seo/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['resource_id'],
    resource_id: null,
    isUploadingImage: false,
    seoTypes: Ember.computed('model.{page,seoTypes}', function () {
      let seoTypes = [];
      const page = this.get('model.page');
      const defaultValues = page.get('default_values');

      for (let seoType of this.get('model.seoTypes')) {
        // Find value
        let seoTypeMatch = page.values.find(valueItem => {
          return valueItem.seo_type_id === seoType.get('id');
        });
        let defaultValue = typeof defaultValues[seoType.key] !== 'undefined' ? defaultValues[seoType.key] : '';
        seoTypes.push(Ember.Object.create({
          id: seoType.id,
          description: seoType.get('description'),
          helperText: seoType.get('helper_text'),
          value: seoTypeMatch !== undefined ? seoTypeMatch.value : null,
          defaultValue: defaultValue,
          isImage: seoType.get('key') === 'og_image' || seoType.get('key') === 'twitter_image',
          isUploadingImage: false
        }));
      }

      return seoTypes;
    }),
    actions: {
      save() {
        const page_id = this.get('model.page').id;
        const resource_id = this.resource_id;
        this.set('isSaving', true);
        let seoTypeSavePromises = []; // Go through all fields

        for (let seoType of this.seoTypes) {
          seoTypeSavePromises.push(new Ember.RSVP.Promise((resolve, reject) => {
            // Check if it exists
            let getData = {
              page: page_id,
              seo_type: seoType.id
            };

            if (resource_id !== null) {
              getData.resource_id = resource_id;
            }

            this.serviceResource.getResources('website', 'seo-values', getData, 'seo-values').then(seoValues => {
              if (seoValues.length === 1) {
                // If exists, update if not empty, delete if empty
                let seoValue = seoValues[0];

                if (seoType.value !== null && seoType.value !== '') {
                  seoValue.set('value', seoType.value);
                  this.serviceResource.updateResource('website', 'seo-values', seoValue, 'seo-values').then(updatedSeoValue => {
                    resolve();
                  }, error => {
                    reject(error);
                  });
                } else {
                  // Delete because field was empty
                  this.serviceResource.deleteResource('website', 'seo-values', seoValue).then(deletedSeoValue => {
                    resolve();
                  }, error => {
                    reject(error);
                  });
                }
              } else if (seoType.value !== null && seoType.value !== '') {
                // If not exist, create (only if value is not empty)
                this.serviceResource.newResource('website', 'seo-values', {
                  page: page_id,
                  resource_id: resource_id,
                  seo_type_id: seoType.id,
                  value: seoType.value
                }, 'seo-values').then(createdSeoValue => {
                  resolve();
                }, error => {
                  reject(error);
                });
              } else {
                resolve();
              }
            }, error => {
              reject(error);
            });
          }));
        }

        Ember.RSVP.Promise.all(seoTypeSavePromises).then(result => {
          this.notificationService.show('Saved', 'Page SEO saved successfully.');
          this.set('isSaving', false);
        }, errors => {
          this.notificationService.show('Error', 'Could not save page SEO.');
          this.set('isSaving', false);
        });
      },

      onBeforeUpload() {
        this.set('isUploadingImage', true);
      },

      onUpload(resource, result) {
        resource.set('value', result.path);
        this.set('isUploadingImage', false);
      },

      goBack() {
        this.transitionToRoute('website.seo.list');
      }

    }
  });

  _exports.default = _default;
});