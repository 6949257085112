define("universal-cms/routes/universal-brand/pages/edit-leader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      const controller = this.controllerFor(this.routeName);
      controller.set('pageId', params.page_id);
      controller.set('pageVersionId', params.page_version_id);
      const leaderId = params.leader_id;
      const isNew = leaderId === 'add';
      controller.set('isNew', isNew);
      let rsvp = {};
      rsvp.leader = isNew ? Ember.Object.create({
        isNew: true
      }) : this.universalBrandService.getOne('leaders', `${leaderId}?relationships[]=thumbnailImage`).then(leader => {
        return leader;
      }, () => {
        controller.set('error', true);
      });
      return Ember.RSVP.hash(rsvp);
    }

  });

  _exports.default = _default;
});