define("universal-cms/routes/option-types/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return Ember.RSVP.hash({
        optionTypes: this.movieService.getAll('option-types'),
        optionGroups: this.movieService.getAll('option-groups')
      });
    },

    setupController: function (controller, model) {
      this._super(controller, model);

      model.optionTypes.forEach((optionType, optionTypeIndex) => {
        model.optionGroups.forEach(optionGroup => {
          if (typeof model.optionTypes[optionTypeIndex].get('groupName') === 'undefined' && optionGroup.get('id') === optionType.get('group')) {
            model.optionTypes[optionTypeIndex].set('groupName', optionGroup.get('name'));
          }
        });
      });
    }
  });

  _exports.default = _default;
});