define("universal-cms/ember-quill/tests/templates/gabbys-website/gabbys-website-events/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "005KoHtZ",
    "block": "{\"symbols\":[],\"statements\":[[9,\"h1\",true],[12,\"class\",\"page-header less-bottom-margin\",null],[10],[1,1,0,0,\"\\n    Events\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[9,\"p\",true],[10],[1,1,0,0,\"\\n    \"],[7,\"link-to\",[[23,\"class\",\"btn btn-xs btn-primary\",null]],[[\"@route\",\"@model\",\"@query\"],[\"gabbys-website.gabbys-website-events.edit\",\"add\",[31,151,4,[27,[26,1,\"CallHead\"],[]],null,[[\"lang\"],[[27,[26,0,\"Expression\"],[\"selectedLanguage\",\"id\"]]]]]]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"Add Event\"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[7,\"gabbys-website/language-selector\",[],[[\"@languages\",\"@selectedLanguage\"],[[27,[24,0],[\"languages\"]],[27,[26,0,\"AppendSingleId\"],[\"selectedLanguage\"]]]],null],[1,1,0,0,\"\\n\\n\"],[7,\"models-table\",[],[[\"@data\",\"@columns\",\"@showColumnsDropdown\",\"@useFilteringByColumns\",\"@multipleColumnsSorting\",\"@filteringIgnoreCase\",\"@columnComponents\"],[[27,[26,0,\"AppendSingleId\"],[\"resources\"]],[27,[26,2,\"AppendSingleId\"],[]],false,false,false,true,[31,599,4,[27,[26,1,\"CallHead\"],[]],null,[[\"pageActions\"],[[31,638,9,[27,[26,4,\"CallHead\"],[]],[\"models-table/gabbys-website/page-actions\"],[[\"deletePage\"],[[31,715,6,[27,[26,3,\"CallHead\"],[]],[[27,[24,0],[]],\"deleteEvent\"],null]]]]]]]]],null],[1,1,0,0,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"hash\",\"tableColumns\",\"action\",\"component\"]}",
    "meta": {
      "moduleName": "universal-cms/ember-quill/tests/templates/gabbys-website/gabbys-website-events/list.hbs"
    }
  });

  _exports.default = _default;
});