define("universal-cms/helpers/option-type-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.optionTypeType = optionTypeType;

  function optionTypeType(params) {
    switch (params[0]) {
      case 'text':
        return 'Text';

      case 'date':
        return 'Date';

      case 'long_text':
        return 'Long Text';

      case 'numeric':
        return 'Numeric';
    }

    return params;
  }

  var _default = Ember.Helper.helper(optionTypeType);

  _exports.default = _default;
});