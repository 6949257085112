define("universal-cms/controllers/universal-brand/businesses/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      deleteBusiness(business) {
        return new Ember.RSVP.Promise((resolve, reject) => {
          business.set('isDeleting', true);
          this.universalBrandService.deleteOne('businesses', business.get('business_id')).then(() => {
            this.notificationService.show('Business deleted', `Business was successfully deleted.`);
            this.model.removeObject(business);
            resolve();
          }, () => {
            business.set('isDeleting', false);
            this.notificationService.show('Error', `Could not delete business.`);
            reject();
          });
        });
      }

    }
  });

  _exports.default = _default;
});