define("universal-cms/controllers/dreamworks/leadership/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    externalBreadcrumbLink: undefined,
    isSavingPositions: false,

    init() {
      this._super(...arguments);

      this.set('externalBreadcrumbLink', this.dreamworksService.getUrl('about/leadership'));
    },

    async savePositions() {
      try {
        this.set('isSavingPositions', true);
        const saveResponse = await this.dreamworksService.post('api/leadership/re-order', {
          'leaders': this.model.leaders
        });
        console.log('saveResponse', saveResponse);
        this.notificationService.show('Saved', 'Leaders reordered.');
      } catch (saveError) {
        if (saveError) {
          console.log('savePositions Error:', saveError);
        }

        this.notificationService.show('Error', 'Could not save positions.');
      } finally {
        this.set('isSavingPositions', false);
      }
    },

    actions: {
      onSorted() {
        this.savePositions();
      },

      async deleteLeader(leaderToDelete) {
        if (!confirm('Are you sure you want to delete leader?')) {
          return;
        }

        try {
          leaderToDelete.set('isDeleting', true);
          await this.dreamworksService.deleteOne('leadership', leaderToDelete.id);
          this.model.leaders.removeObject(leaderToDelete);
          this.notificationService.show('Deleted', 'Leader deleted.');
        } catch (deleteError) {
          this.notificationService.show('Error', 'Could not delete leader.');
          leaderToDelete.set('isDeleting', false);
        }
      }

    }
  });

  _exports.default = _default;
});