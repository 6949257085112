define("universal-cms/routes/dreamworks/resource-edit", ["exports", "ember-onbeforeunload/mixins/confirmation"], function (_exports, _confirmation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_confirmation.default, {
    util: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),
    resourceTypeColumn: null,

    isPageDirty() {
      return false;
    },

    async model(params, transition) {
      const isNew = params.id === 'add';
      const controller = this.controllerFor(this.routeName);
      const resourceType = controller.resourceType;
      const resourceSingularLowerCaseText = controller.get('text.singular').toLowerCase().replace(' ', '-');
      controller.set('text.singularLowerCase', resourceSingularLowerCaseText); // Convert resource type

      const convertedResourceType = this.dreamworksService.convertResourceType(resourceType);
      let resourceSaveData = Ember.Object.create();
      let pageSaveData = Ember.Object.create({
        name: '',
        slug: '',
        components: []
      });
      let resource = null;
      let page = null;
      let resourceData = Ember.Object.create({
        tmp_data: null
      });
      let pageData = Ember.Object.create({
        tmp_data: null
      });
      let pageTypeOptions;

      if (isNew) {
        // Add
        pageSaveData.set('components', this.getInitialComponents(resourceType)); // Get language ID from query params if creating a new one

        const urlParams = new URLSearchParams(transition.to.queryParams);
        const langQueryParam = urlParams.get('lang');
        pageTypeOptions = await this.dreamworksService.getOptions(`page-type-options?resource_type=${convertedResourceType}&language_id=${langQueryParam || 1}`);
      } else {
        // Update
        if (resourceType === 'pages') {
          page = await this.dreamworksService.getOne('pages', params.id, {// language_id: selectedLanguage.id,
          });

          if (!page) {
            throw new Error(`Could not find page with ID "${params.id}".`);
          } // if (page.slug === '/') {
          //     page.set('isNameAndSlugLocked', true);
          // }


          pageData = await this.dreamworksService.getFirst('page-data', {
            resource_id: params.id
          });
          pageTypeOptions = await this.dreamworksService.getOptions(`page-type-options?resource_type=page&language_id=${pageData.language_id}`);
        } else {
          resource = await this.dreamworksService.getOne(resourceType, params.id);

          if (!resource) {
            throw new Error(`Could not find resource with ID "${params.id}".`);
          }

          pageTypeOptions = await this.dreamworksService.getOptions(`page-type-options?resource_type=${convertedResourceType}&language_id=${resource.language_id}`);
          const dataColumn = this.get('resourceTypeColumn') || resourceSingularLowerCaseText;
          resourceData = await this.dreamworksService.getFirst(`${dataColumn}-data`, {
            resource_id: resource.id
          });

          if (!resourceData) {
            resourceData = Ember.Object.create();
          }

          if (resourceData.save_data) {
            resourceSaveData = resourceData.save_data;

            if (resourceSaveData.name) {
              resource.set('name', resourceSaveData.name);
            }
          }

          const formattedType = (dataColumn || resourceSingularLowerCaseText).replace(/-/g, '_'); // Get resource page

          const pageResponse = await this.dreamworksService.get(`/api/pages?reference_id=${resource.id}&type=${formattedType}`);

          if (!pageResponse || !pageResponse.data || !pageResponse.data.data || pageResponse.data.data.length === 0) {
            throw new Error(`Could not find ${resourceSingularLowerCaseText} page.`);
          }

          page = pageResponse.data.data[0];
          pageData = page.data;

          if (resourceType === 'games') {
            page.set('url', page.url.replace('games', 'explore'));
            page.set('slug', page.url.replace('games', 'explore'));
            page.set('preview_url', page.url.replace('games', 'explore'));
          }
        }

        if (typeof pageData !== 'object') {
          throw new Error(`Could not load ${resourceSingularLowerCaseText} page data.`);
        }

        if (pageData === null) {
          pageData = Ember.Object.create({
            isNew: true,
            tmp_data: null,
            save_data: Ember.Object.create()
          });
        } else {
          if (pageData.save_data) {
            pageSaveData = pageData.save_data;

            if (resourceType === 'pages') {
              if (pageSaveData.slug && pageSaveData.slug.charAt(0) === '/') {
                pageSaveData.set('slug', pageSaveData.slug.substring(1));
              }
            } else {
              if (pageTypeOptions.slug_prefix) {
                const newSlug = pageSaveData.slug.replace(new RegExp(`^(/${pageTypeOptions.slug_prefix}/?)`), '');
                pageSaveData.set('slug', newSlug);
              }
            }
          }
        }
      }

      controller.set('isNew', isNew);
      resourceSaveData = this.initEmptyKeys(resourceSaveData, resourceType);

      if (resourceSaveData.custom === true) {
        const index = pageSaveData.components.findIndex(component => {
          return component.key === 'page-introduction';
        });
        pageSaveData.components[index] = Ember.Object.create({
          'key': 'media-introduction',
          'data': Ember.Object.create({
            'type': resourceData.resource_type,
            'moduleName': 'media-introduction'
          })
        });
      }

      const languageId = pageTypeOptions && pageTypeOptions.language ? pageTypeOptions.language.id : 1;
      const language = pageTypeOptions && pageTypeOptions.language ? pageTypeOptions.language : null;
      return Ember.RSVP.hash({
        languageId,
        language,
        resourceType,
        // resourceOptions,
        resource,
        resourceSaveData,
        resourceData,
        // selectedLanguage,
        pageTypeOptions,
        page,
        pageSaveData,
        pageData
      });
    },

    afterModel() {
      this._super(...arguments);

      const controller = this.controllerFor(this.routeName);
      controller.set('isRefreshingModel', false);
    },

    initEmptyKeys(resourceSaveData, resourceType) {
      if (resourceType === 'movies' || resourceType === 'tv-shows' || resourceType === 'games') {
        if (!resourceSaveData.hero_image) {
          resourceSaveData.set('hero_image', Ember.Object.create({
            'landscape': null,
            'portrait': null
          }));
        }

        if (!resourceSaveData.custom_buttons) {
          resourceSaveData.set('custom_buttons', []);
        }

        if (!resourceSaveData.tickets) {
          resourceSaveData.set('tickets', Ember.Object.create({
            'sectionTitle': null,
            'url': null,
            'hideBtn': false
          }));
        }

        if (!resourceSaveData.retailers) {
          if (resourceType === 'movies') {
            resourceSaveData.set('retailers', Ember.Object.create({
              'digital': [],
              'physical': []
            }));
          } else if (resourceType === 'tv-shows') {
            resourceSaveData.set('retailers', Ember.Object.create({
              'digital': Ember.Object.create({
                'seasons': []
              }),
              'physical': Ember.Object.create({
                'seasons': []
              }),
              'ondemand': Ember.Object.create({
                'seasons': []
              })
            }));
          }
        } else {
          // Fix existing TV-shows here
          if (resourceType === 'tv-shows') {
            if (Array.isArray(resourceSaveData.retailers.digital)) {
              resourceSaveData.retailers.set('digital', Ember.Object.create({
                'seasons': []
              }));
            }

            if (Array.isArray(resourceSaveData.retailers.physical)) {
              resourceSaveData.retailers.set('physical', Ember.Object.create({
                'seasons': []
              }));
            }

            if (Array.isArray(resourceSaveData.retailers.ondemand)) {
              resourceSaveData.retailers.set('ondemand', Ember.Object.create({
                'seasons': []
              }));
            }
          }
        }

        if (!resourceSaveData.product_features) {
          // if no product features, set?
          if (resourceType === 'movies') {
            resourceSaveData.set('product_features', Ember.Object.create({
              'digital': Ember.Object.create({
                'product_image': null,
                'description': null
              }),
              '4k_ultra_hd': Ember.Object.create({
                'product_image': null,
                'description': null
              }),
              'blu_ray': Ember.Object.create({
                'product_image': null,
                'description': null
              }),
              'dvd': Ember.Object.create({
                'product_image': null,
                'description': null
              })
            }));
          }
        }

        if (!resourceSaveData.product_features) {
          if (resourceType === 'movies') {
            resourceSaveData.set('product_features', Ember.Object.create({
              'digital': Ember.Object.create({
                'product_image': null,
                'description': null
              }),
              '4k_ultra_hd': Ember.Object.create({
                'product_image': null,
                'description': null
              }),
              'blu_ray': Ember.Object.create({
                'product_image': null,
                'description': null
              }),
              'dvd': Ember.Object.create({
                'product_image': null,
                'description': null
              })
            }));
          }
        } else {
          if (!resourceSaveData.product_features.digital) {
            resourceSaveData.product_features.set('digital', {
              product_name: null,
              description: null
            });
          }

          if (!resourceSaveData.product_features['4k_ultra_hd']) {
            resourceSaveData.product_features.set('4k_ultra_hd', {
              product_name: null,
              description: null
            });
          }

          if (!resourceSaveData.product_features.blu_ray) {
            resourceSaveData.product_features.set('blu_ray', {
              product_name: null,
              description: null
            });
          }

          if (!resourceSaveData.product_features.dvd) {
            resourceSaveData.product_features.set('dvd', {
              product_name: null,
              description: null
            });
          }
        }

        if (!resourceSaveData.product_images) {
          if (resourceType === 'movies') {
            resourceSaveData.set('product_images', Ember.Object.create({
              'box_bg': null,
              'ultra_hd': null,
              'blu_ray_dvd': null
            }));
          }
        }
      }

      if (resourceType === 'movies' || resourceType === 'tv-shows' || resourceType === 'franchises' || resourceType === 'games') {
        if (!resourceSaveData.about_image) {
          resourceSaveData.set('about_image', Ember.Object.create({
            'landscape': null,
            'portrait': null
          }));
        }

        if (!resourceSaveData.social) {
          resourceSaveData.set('social', Ember.Object.create({
            'instagram_username': null,
            'twitter': null,
            'facebook': null
          }));
        }
      }

      if (resourceType === 'events') {
        if (!resourceSaveData.is_external) {
          resourceSaveData.set('is_external', false);
        }

        if (!resourceSaveData.items) {
          resourceSaveData.set('items', []);
        }
      }

      return resourceSaveData;
    },

    getInitialComponents(resourceType) {
      let components = []; // Movie

      if (resourceType === 'movies') {
        components.push({
          'key': 'media-introduction',
          'data': {
            'type': 'movie',
            'moduleName': 'media-introduction'
          }
        });
        components.push({
          'key': 'media-bio',
          'data': {
            'type': 'movie',
            'moduleName': 'media-bio',
            'sectionTitle': 'About'
          }
        });
        components.push({
          'key': 'video-gallery',
          'data': {
            'type': 'movie',
            'moduleName': 'video-gallery',
            'sectionTitle': 'Videos'
          }
        });
        components.push({
          'key': 'image-gallery',
          'data': {
            'moduleName': 'image-gallery',
            'sectionProperty': 'Movie',
            'sectionTitle': 'Gallery'
          }
        });
        components.push({
          'key': 'social-hub',
          'data': {
            'moduleName': 'social-hub',
            'sectionTitle': 'Social',
            'sectionProperty': 'Movie',
            'socialBackground': null,
            'sectionDescription': ''
          }
        });
        components.push({
          'key': 'social-callout',
          'data': {
            'moduleName': 'social-callout',
            'sectionTitle': 'Social',
            'sectionProperty': 'Movie',
            'socialBackground': null,
            'sectionDescription': ''
          }
        });
        components.push({
          'key': 'featured-diptych',
          'data': {
            'moduleName': 'featured-diptych',
            'sectionTitle': 'Related',
            'items': []
          }
        });
      } else if (resourceType === 'franchises') {
        components.push({
          'key': 'media-theater',
          'data': {
            'items': []
          }
        });
        components.push({
          'key': 'media-bio',
          'data': {
            'sectionTitle': 'About',
            'id': null,
            'type': 'franchise',
            'moduleName': 'media-bio',
            'property_title': true
          }
        });
        components.push({
          'key': 'video-gallery',
          'data': {
            'type': 'franchise',
            'moduleName': 'video-gallery',
            'sectionTitle': 'Videos'
          }
        });
        components.push({
          'key': 'media-carousel',
          'data': {
            'moduleName': 'media-carousel',
            'sectionTitle': 'Movies',
            'sectionProperty': 'Movie',
            'thumbnail': 'poster',
            'items': []
          }
        });
        components.push({
          'key': 'media-carousel',
          'data': {
            'moduleName': 'media-carousel',
            'sectionTitle': 'TV Shows',
            'sectionProperty': 'TvShow',
            'thumbnail': 'poster',
            'items': []
          }
        });
        components.push({
          'key': 'media-carousel',
          'data': {
            'moduleName': 'media-carousel',
            'sectionTitle': 'Games',
            'sectionProperty': 'Game',
            'thumbnail': 'poster',
            'items': []
          }
        });
        components.push({
          'key': 'image-gallery',
          'data': {
            'moduleName': 'image-gallery',
            'sectionProperty': 'Franchise',
            'sectionTitle': 'Gallery'
          }
        });
        components.push({
          'key': 'social-hub',
          'data': {
            'moduleName': 'social-hub',
            'sectionTitle': 'Social',
            'sectionProperty': 'Franchise'
          }
        });
        components.push({
          'key': 'social-callout',
          'data': {
            'moduleName': 'social-callout',
            'sectionTitle': 'Social',
            'sectionProperty': 'Franchise'
          }
        });
        components.push({
          'key': 'social-callout',
          'data': {
            'moduleName': 'social-callout',
            'sectionTitle': 'Social',
            'sectionProperty': 'Franchise'
          }
        });
        components.push({
          'key': 'featured-diptych',
          'data': {
            'moduleName': 'featured-diptych',
            'sectionTitle': 'Related',
            'items': []
          }
        });
      } else if (resourceType === 'tv-shows') {
        components.push({
          'key': 'page-introduction',
          'data': {
            'moduleName': 'page-introduction',
            'type': 'tv_show'
          }
        });
        components.push({
          'key': 'media-bio',
          'data': {
            'type': 'tv_show',
            'moduleName': 'media-bio',
            'sectionTitle': 'About'
          }
        });
        components.push({
          'key': 'video-gallery',
          'data': {
            'type': 'tv_show',
            'moduleName': 'video-gallery',
            'sectionTitle': 'Videos'
          }
        });
        components.push({
          'key': 'image-gallery',
          'data': {
            'moduleName': 'image-gallery',
            'sectionProperty': 'TvShow',
            'sectionTitle': 'Gallery'
          }
        });
        components.push({
          'key': 'social-hub',
          'data': {
            'moduleName': 'social-hub',
            'sectionTitle': 'Social',
            'sectionProperty': 'TvShow',
            'socialBackground': null,
            'sectionDescription': ''
          }
        });
        components.push({
          'key': 'social-callout',
          'data': {
            'moduleName': 'social-callout',
            'sectionTitle': 'Social',
            'sectionProperty': 'TvShow',
            'socialBackground': null,
            'sectionDescription': ''
          }
        });
        components.push({
          'key': 'featured-diptych',
          'data': {
            'moduleName': 'featured-diptych',
            'sectionTitle': 'Related',
            'items': []
          }
        });
      }

      return this.util.emberify(components);
    },

    actions: {
      refreshModel() {
        this.refresh();
      },

      addCustomButton(resourceSaveData) {
        this.currentModel.resourceSaveData.custom_buttons.pushObject(Ember.Object.create({
          'title': null,
          'url': null
        }));
      },

      deleteCustomButton(customButton) {
        this.currentModel.resourceSaveData.custom_buttons.removeObject(customButton);
      }

    }
  });

  _exports.default = _default;
});