define("universal-cms/components/dreamworks/cast-and-crew-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'dreamworks-cast-and-crew-editor',
    type: undefined,
    newMember: undefined,
    members: undefined,

    init() {
      this._super(...arguments);

      if (this.type !== 'cast' && this.type !== 'crew') {
        console.warn(`Invalid cast-and-crew-editor type "${this.type}".`);
        return;
      }

      if (!this.members) {
        this.set('members', []);
      }

      this.resetNewMember();
    },

    resetNewMember() {
      this.set('newMember', Ember.Object.create({
        name: '',
        note: ''
      }));
    },

    _onChange() {
      if (typeof this.onChange === 'function') {
        this.onChange(this.members);
      }
    },

    focusNameInput() {
      this.element.querySelector('.input-name').focus();
    },

    actions: {
      addMember() {
        if (!this.newMember.name) {
          this.element.querySelector('.input-name').focus();
          return;
        }

        if (!this.members) {
          this.set('members', []);
        }

        this.members.pushObject(this.newMember);
        this.resetNewMember();
        Ember.run.scheduleOnce('afterRender', this, this.focusNameInput);

        this._onChange();
      },

      removeMember(memberToRemove) {
        if (!confirm(`Are you sure you want to remove "${memberToRemove.name}"?`)) {
          return;
        }

        this.members.removeObject(memberToRemove);

        this._onChange();
      }

    }
  });

  _exports.default = _default;
});