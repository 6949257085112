define("universal-cms/controllers/dreamworks/retailers/list", ["exports", "universal-cms/controllers/dreamworks/resource-list"], function (_exports, _resourceList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resourceList.default.extend({
    resourceType: 'retailers',
    text: {
      singular: 'Retailer',
      plural: 'Retailers'
    },

    init() {
      this.set('tableColumns', [{
        'title': 'Name',
        'propertyName': 'name'
      }, {
        'title': 'Added',
        'component': 'models-table/dreamworks/date'
      }, {
        'title': 'Manage',
        'component': 'resourceActions',
        'editable': false
      }]);

      this._super(...arguments);
    }

  });

  _exports.default = _default;
});