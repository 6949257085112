define("universal-cms/controllers/retailers/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    tableColumns: null,

    init() {
      this._super(...arguments);

      this.set('tableColumns', [{
        'propertyName': 'name',
        'title': 'Name'
      }, {
        'title': 'Manage',
        'component': 'models-table/retailer-actions'
      }]);
    },

    actions: {
      async createRetailer(data) {
        const vm = this;
        console.log('this.model', this.model);
        console.log('this.model.pushObject', typeof this.model.pushObject);

        try {
          const createdRetailer = await this.movieService.createOne('retailers', {
            name: data.retailer.name,
            logo: data.retailer.logo
          });
          this.model.pushObject(createdRetailer);
          this.notificationService.show('Retailer created', `<strong>${createdRetailer.name}</strong> was successfully created.`);
          data.retailer.setProperties({
            name: createdRetailer.get('name'),
            logo: createdRetailer.get('logo')
          });
          return createdRetailer;
        } catch (error) {
          console.log('Create retailer error', error);
          this.notificationService.show('Error', 'Could not create retailer.');
        }
      },

      deleteRetailer(retailer) {
        retailer.set('isDeleting', true);
        return this.movieService.deleteOne('retailers', retailer.get('id')).then(() => {
          this.notificationService.show('Retailers deleted', `<strong>${retailer.name}</strong> was successfully deleted.`);
          this.model.removeObject(retailer);
        });
      },

      async updateRetailersOrder(orderedRetailers) {
        let newOrder = [];

        for (let movie of orderedRetailers) {
          newOrder.push(movie.get('id'));
        }

        let saveData = {
          movies: newOrder
        };

        try {
          console.log('before');
          await this.movieService.replaceOne(`retailers`, 'update-order', saveData);
          console.log('after');
          this.notificationService.show('Saved', `Retailer has been moved.`);
        } catch (error) {
          console.log('error', error);
          this.notificationService.show('Error', `Could not save retailers order.`);
        }
      }

    }
  });

  _exports.default = _default;
});