define("universal-cms/controllers/uwatch/about", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    isSaving: false,
    actions: {
      async save() {
        this.set('isSaving', true);
        this.uwatchService.newResource('uwatch', 'about', {
          about_title: this.model.about_title,
          about: this.model.about,
          why_title: this.model.why_title,
          why: this.model.why,
          when_title: this.model.when_title,
          when: this.model.when,
          where_title: this.model.where_title,
          where: this.model.where
        }).then(aboutPage => {
          this.set('model', aboutPage[0]);
          this.set('isSaving', false);
          this.notificationService.show('Saved', `About was successfully saved.`);
        }, error => {
          this.set('isSaving', false);
          this.notificationService.show('Error', 'Could not save page.');
        });
      }

    }
  });

  _exports.default = _default;
});