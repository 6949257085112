define("universal-cms/controllers/website/movie-promos/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    isAdding: false,
    isSaving: false,
    isDeleting: false,
    isLoading: false,
    shouldEditInline: false,
    newResource: null,

    init() {
      this._super(...arguments);

      this.set('newResource', {
        movie_id: null,
        position: null
      });
    },

    actions: {
      selectUpdate(e) {
        let {
          name,
          value
        } = e.target;
        this.newResource[name] = value;
      },

      createResource() {
        // Validate
        if (this.newResource.movie_id === null) {
          this.notificationService.show('Error', 'Choose movie.');
          return;
        } else if (this.newResource.position === null) {
          this.notificationService.show('Error', 'Choose position.');
          return;
        }

        this.send('newResource', this.newResource);
      },

      onPublish(data) {
        data.resource.set('live_start', data.publishDate);
        data.resource.set('live_end', data.unpublishDate);
        return this.serviceResource.updateResource('website', 'movie-promos', data.resource, 'movie_promos').then(moviePromo => {
          data.doneCallback(moviePromo);
          this.notificationService.show('Published', 'Movie promo was published.');
          return moviePromo;
        });
      },

      onUnpublish(data) {
        data.resource.set('live_start', null);
        data.resource.set('live_end', null);
        return this.serviceResource.updateResource('website', 'movie-promos', data.resource, 'movie_promos').then(moviePromo => {
          data.doneCallback(moviePromo);
          this.notificationService.show('Unublished', 'Movie promo was unpublished.');
          return moviePromo;
        });
      }

    }
  });

  _exports.default = _default;
});