define("universal-cms/controllers/universal-studios/connect/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    error: false,
    heading: Ember.computed('isNew', 'model.connect.title', function () {
      return this.isNew === true ? 'Add Connect' : this.get('model.connect.title');
    }),
    isSaving: false,
    saveButtonText: Ember.computed('isNew', 'isSaving', function () {
      if (this.isNew === true) {
        return this.isSaving === true ? 'Adding...' : 'Add';
      } else {
        return this.isSaving === true ? 'Saving...' : 'Save';
      }
    }),
    actions: {
      save() {
        this.set('isSaving', true);
        const connect = this.get('model.connect');
        let saveData = connect.getProperties('title', 'description', 'website_link', 'sign_up_link', 'facebook_username', 'twitter_username', 'instagram_username', 'snapchat_username', 'image');

        if (saveData.sign_up_link === null) {
          saveData.sign_up_link = '';
        }

        if (this.isNew) {
          this.universalStudiosService.createOne('connects', saveData, true).then(createResponse => {
            if (typeof createResponse.errors === 'undefined') {
              this.notificationService.show('Connect Added', `<strong>${createResponse.get('title')}</strong> has been added.`);
              this.target.transitionTo(this.routeName, createResponse.get('id'));
            } else {
              this.notificationService.show('Error', this.universalStudiosService.formatErrorResponse(createResponse.errors));
            }
          }, () => {
            this.notificationService.show('Error', 'Could not add connect.');
          }).finally(() => {
            this.set('isSaving', false);
          });
        } else {
          this.universalStudiosService.updateOne('connects', connect.get('id'), saveData, true).then(updateResponse => {
            if (typeof updateResponse.errors === 'undefined') {
              this.notificationService.show('Connect Updated', `<strong>${updateResponse.get('title')}</strong> has been updated.`);
              this.target.transitionTo(this.routeName, updateResponse.get('id'));
            } else {
              this.notificationService.show('Error', this.universalStudiosService.formatErrorResponse(updateResponse.errors));
            }
          }, () => {
            this.notificationService.show('Error', 'Could not update connect.');
          }).finally(() => {
            this.set('isSaving', false);
          });
        }
      }

    }
  });

  _exports.default = _default;
});