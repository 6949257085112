define("universal-cms/components/dreamworks/resource-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    util: Ember.inject.service(),
    classNames: 'dreamworks-resource-form',
    formOptions: undefined,
    items: undefined,
    maxItems: 0,
    formattedItems: undefined,
    isFormattingItems: false,
    editItem: null,
    editItemIndex: -1,
    isSaving: false,
    resourceType: null,
    reachedMaxItems: Ember.computed('maxItems', 'items.[]', function () {
      return this.maxItems > 0 && this.items && this.items.length >= this.maxItems;
    }),
    maxItemsWarningsText: Ember.computed('maxItems', function () {
      return `(maximum item${this.maxItems !== 1 ? 's' : ''} of ${this.maxItems} reached)`;
    }),
    saveButtonText: Ember.computed('editItem.isNew', 'isSaving', function () {
      if (this.isSaving) {
        return !this.editItem || this.editItem.isNew ? 'Adding...' : 'Saving...';
      } else {
        return !this.editItem || this.editItem.isNew ? 'Add' : 'Save';
      }
    }),
    resourceTypeText: Ember.computed('resourceType', function () {
      let resourceType = this.resourceType ? this.dreamworksService.convertResourceType(this.resourceType) : null;
      return this.dreamworksService.resourceTypeToText(resourceType);
    }),
    noResourcesText: Ember.computed('resourceTypeText', function () {
      return `No ${this.resourceTypeText.plural.toLowerCase()}`;
    }),

    async init() {
      this._super(...arguments);

      if (this.formOptions) {
        this.set('formOptions', this.util.emberify(this.formOptions));
      }

      await this.setFormattedItems();
    },

    async setFormattedItems() {
      this.set('isFormattingItems', true);
      let formattedItems = [];

      if (this.items) {
        let itemIndex = 0;

        for (let item of this.util.cloneObject(this.items)) {
          let values = [];

          for (let formOption of this.formOptions) {
            let value = item[formOption.column];
            let formattedValue;

            if (formOption.type === 'resource') {
              try {
                const resource = await this.dreamworksService.getOne(this.dreamworksService.resourceTypeToEndpoint(this.dreamworksService.sectionPropertyToResourceType(item.itemProperty)), value);
                formattedValue = Ember.Object.create({
                  value: `${resource.data.save_data.name} (${item.itemProperty})`
                });
              } catch (loadResourceError) {
                formattedValue = Ember.Object.create({
                  error: `Could not load resource (${value}).`
                });
              }
            } else if (formOption.type === 'games') {
              try {
                const resource = await this.dreamworksService.getOne(this.dreamworksService.resourceTypeToEndpoint(this.dreamworksService.sectionPropertyToResourceType('Game')), value);
                formattedValue = Ember.Object.create({
                  value: `${resource.data.save_data.name} (Game)`
                });
              } catch (loadResourceError) {
                formattedValue = Ember.Object.create({
                  error: `Could not load resource (${value}).`
                });
              }
            } else if (formOption.type === 'asset') {
              const assetType = formOption.assetType || 'image';
              formattedValue = Ember.Object.create({
                type: assetType,
                assetId: value,
                url: this.dreamworksService.getUrl(`api/assets/${value}/render`)
              });
            } else {
              formattedValue = Ember.Object.create({
                value: value,
                rows: formOption.rows || 1
              });
            }

            values.push(formattedValue);
          }

          item.set('index', itemIndex);
          item.set('values', values);
          formattedItems.pushObject(item);
          itemIndex++;
        }
      }

      this.set('formattedItems', formattedItems);
      this.set('isFormattingItems', false);
    },

    actions: {
      enterEditMode(itemToEdit, itemIndexToEdit) {
        // Reset this.formOptions assetType???
        let editItem = Ember.Object.create({
          isNew: !itemToEdit,
          resourceType: itemToEdit && itemToEdit.itemProperty ? this.dreamworksService.sectionPropertyToResourceType(itemToEdit.itemProperty) : null
        });
        let formOptions = [];

        for (let formOption of this.formOptions) {
          formOption.set('value', !editItem.isNew ? itemToEdit.get(formOption.column) : null);
          formOptions.push(formOption);
        }

        editItem.setProperties({
          'id': !editItem.isNew ? itemToEdit.id : null,
          'formOptions': formOptions
        });
        this.set('editItem', editItem);
        this.set('editItemIndex', itemIndexToEdit);
      },

      async save() {
        this.set('isSaving', true);
        let item;

        if (this.editItem.isNew) {
          item = Ember.Object.create({
            itemProperty: this.dreamworksService.resourceTypeToSectionProperty(this.editItem.resourceType)
          });
        } else {
          item = this.items[this.editItemIndex];

          if (!item) {
            this.notificationService.show('Error', `Could not find item.`);
          }
        }

        for (let formOption of this.formOptions) {
          item.set(formOption.column, formOption.value);
        }

        if (this.editItem.isNew) {
          delete item.values;

          if (!this.items) {
            this.set('items', []);
          }

          this.items.pushObject(item);
        }

        this.set('editItem', null);
        this.set('editItemIndex', -1);
        this.set('isSaving', false);

        if (typeof this.onItemsUpdated === 'function') {
          this.onItemsUpdated(this.items);
        }

        await this.setFormattedItems();
      },

      cancel() {
        this.set('editItem', null);
        this.set('editItemIndex', -1);
      },

      add() {
        this.send('enterEditMode');
      },

      onSorted() {
        let items = []; // console.log('FORMATTED CURRENT ITEMS:', this.formattedItems);

        items = this.formattedItems; // for (let formattedItem of this.formattedItems) {
        //     let item = this.items.find((_item, _itemIndex) => _itemIndex === formattedItem.index);
        //     items.pushObject(item);
        // }

        this.set('items', items);
      },

      removeItem(formattedItemToRemove) {
        if (!confirm(`Are you sure you want to remove?`)) {
          return;
        }

        let itemToRemove = this.items.find((item, itemIndex) => itemIndex === formattedItemToRemove.index);
        this.formattedItems.removeObject(formattedItemToRemove);
        this.items.removeObject(itemToRemove);
      },

      onTypeChanged(type) {
        this.editItem.set('resourceType', type);
      }

    }
  });

  _exports.default = _default;
});