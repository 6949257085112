define("universal-cms/ember-quill/tests/templates/components/person-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PRN9k4th",
    "block": "{\"symbols\":[],\"statements\":[[9,\"div\",true],[12,\"class\",\"row\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"div\",true],[12,\"class\",\"col-sm-12 col-md-8\",null],[10],[1,1,0,0,\"\\n        \"],[9,\"div\",true],[12,\"class\",\"form-group\",null],[10],[1,1,0,0,\"\\n            \"],[9,\"label\",true],[10],[1,1,0,0,\"Name\"],[11],[1,1,0,0,\"\\n            \"],[7,\"input\",[[23,\"class\",\"form-control\",null]],[[\"@type\",\"@value\"],[\"text\",[27,[26,4,\"AppendSingleId\"],[]]]],null],[1,1,0,0,\"\\n        \"],[11],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[5,[27,[26,6,\"BlockHead\"],[]],[[27,[26,5,\"Expression\"],[]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"    \"],[9,\"button\",true],[12,\"class\",\"btn btn-primary\",null],[12,\"disabled\",\"\",null],[10],[1,0,0,0,[27,[26,3,\"AppendSingleId\"],[]]],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"    \"],[9,\"button\",false],[23,\"class\",\"btn btn-primary\",null],[3,0,0,[27,[26,1,\"ModifierHead\"],[]],[[27,[24,0],[]],\"save\",[27,[26,0,\"Expression\"],[]]],null],[10],[1,0,0,0,[27,[26,2,\"AppendSingleId\"],[]]],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"],[5,[27,[26,6,\"BlockHead\"],[]],[[27,[26,7,\"Expression\"],[]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"    \"],[7,\"link-to\",[[23,\"class\",\"btn btn-default\",null]],[[\"@route\"],[\"people.list\"]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"Back\"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"person\",\"action\",\"saveText\",\"savingText\",\"name\",\"isSaving\",\"if\",\"isEditing\"]}",
    "meta": {
      "moduleName": "universal-cms/ember-quill/tests/templates/components/person-form.hbs"
    }
  });

  _exports.default = _default;
});