define("universal-cms/components/service-resource-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isSavingPositions: false,
    isAdding: false,
    isLoading: Ember.computed('isSavingPositions', function () {
      return this.isSavingPositions === true;
    }),
    fields: Ember.computed('options', function () {
      let resourceFields = this.get('options.resource_fields');
      let shouldEditInline = true;
      let fields = [];

      for (let field of resourceFields) {
        shouldEditInline = shouldEditInline && field.type !== 'image' && field.type !== 'relationship' && field.type !== 'textarea' && field.type !== 'wysiwyg';

        if (field.name === 'position') {
          Ember.set(field, 'columnClass', field.name);
        }

        if (field.name === 'position' || field.name === 'title' || field.name === 'name' || field.name === 'date' || field.name === 'page_id' || field.name === 'url' || field.name === 'question') {
          fields.push(field);
        }
      }

      shouldEditInline = this.shouldEditInline !== undefined ? this.shouldEditInline : shouldEditInline;
      this.set('shouldEditInline', shouldEditInline);
      let returnFields = shouldEditInline ? resourceFields : fields;
      return returnFields;
    }),
    actions: {
      onNewResource(resource) {
        this.set('isLoading', true);
        this.set('isAdding', true);

        try {
          return this.newResource(resource).then(() => {}).finally(() => {
            this.set('isLoading', false);
            this.set('isAdding', false);
          });
        } catch (error) {
          this.set('isLoading', false);
          this.set('isAdding', false);
        }
      },

      onUpdateResource(resource) {
        this.set('isLoading', true);
        return this.updateResource(resource).then(() => {}).finally(() => {
          this.set('isLoading', false);
        });
      },

      onDeleteResource(resource) {
        this.set('isLoading', true);

        if (!confirm('Are you sure you want to delete resource?')) {
          return new Ember.RSVP.Promise((resolve, reject) => {
            reject();
          });
        }

        return this.deleteResource(resource);
      },

      positionsChanged() {
        this.set('isSavingPositions', true);
        return this.savePositions({
          resources: this.resources
        }).then(() => {
          this.set('isSavingPositions', false);
        });
      }

    }
  });

  _exports.default = _default;
});