define("universal-cms/controllers/website/home-page-promos/edit/spot-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    isAdding: false,
    isSaving: false,
    isDeleting: false,
    isLoading: false,
    isUploading: false,
    saveButtonText: 'Save',
    saveButtonDisabled: false,
    newResource: null,
    spotSizes: null,
    updatedOptions: null,

    init() {
      this._super(...arguments);

      this.set('newResource', {});
      this.set('spotSizes', [{
        text: 'Small (640 x 500)',
        value: 'small'
      }, {
        text: 'Medium (960 x 500)',
        value: 'medium'
      }, {
        text: 'Large (1280 x 500)',
        value: 'large'
      }, {
        text: 'Full (1920 x 500)',
        value: 'full'
      }]);
      this.set('updatedOptions', {});
    },

    spotOptionTypeMap: Ember.computed('model.{spot_options,spot_options.[],spot_type_options}', function () {
      let map = {};

      for (let spotTypeOption of this.model.spot_type_options) {
        map[spotTypeOption.id] = Ember.Object.create({
          promo_spot_id: this.model.spot.id,
          promo_spot_type_option_id: spotTypeOption.id,
          value: ''
        });
      }

      for (let spotOption of this.model.spot_options) {
        map[spotOption.promo_spot_type_option_id] = spotOption;
      }

      return map;
    }),
    actions: {
      selectUpdate(size) {
        let spot = this.get('model.spot');

        if (spot.size !== size) {
          this.set('newSize', size);
        } else {
          this.set('newSize', '');
        }
      },

      optionUpdate(spotOptionType, e) {
        let value = e.target.value;
        let option = this.spotOptionTypeMap[spotOptionType.id];
        let updatedOptions = this.updatedOptions;
        option.value = value;
        updatedOptions[spotOptionType.id] = option;
      },

      onBeforeUpload() {
        this.set('isUploading', true);
        this.set('saveButtonText', 'Uploading...');
        this.set('saveButtonDisabled', true);
      },

      onUpload(optionType, media) {
        let currentOption = this.spotOptionTypeMap[optionType.id];
        currentOption.value = media.path;
        let updatedOptions = this.updatedOptions;
        updatedOptions[optionType.id] = currentOption; // Save to db

        let spot = this.get('model.spot');
        let promo = this.get('model.promo');

        if (updatedOptions[optionType.id].id) {
          this.serviceResource.updateResource('website', `home-page-promos/${promo.id}/spots/${spot.id}/options`, updatedOptions[optionType.id], 'home_page_promo_spot_options').then(spotOption => {
            this.model.spot_options.arrayContentDidChange();
            this.set('isUploading', false);
            this.set('saveButtonText', 'Save');
            this.set('saveButtonDisabled', false);
            this.notificationService.show('Image Upload', 'Image Uploaded Successfully.');
          });
        } else {
          this.serviceResource.newResource('website', `home-page-promos/${promo.id}/spots/${spot.id}/options`, updatedOptions[optionType.id], 'home_page_promo_spot_options').then(spotOption => {
            this.model.spot_options.pushObject(spotOption[0]);
            this.model.spot_options.arrayContentDidChange();
            this.set('isUploading', false);
            this.set('saveButtonText', 'Save');
            this.set('saveButtonDisabled', false);
            this.updatedOptions[optionType.id] = spotOption[0];
            this.notificationService.show('Image Upload', 'Image Uploaded Successfully.');
          });
        }
      },

      save() {
        let spot = this.get('model.spot');
        let promo = this.get('model.promo');
        let newSize = this.newSize;
        let updatedOptions = this.updatedOptions;
        let promises = [];

        if (newSize) {
          spot.set('size', newSize);
          promises.push(this.serviceResource.updateResource('website', `home-page-promos/${promo.id}/spots`, spot, 'home_page_promo_spots'));
        }

        for (let option in updatedOptions) {
          if (updatedOptions[option].id) {
            promises.push(this.serviceResource.updateResource('website', `home-page-promos/${promo.id}/spots/${spot.id}/options`, updatedOptions[option], 'home_page_promo_spot_options'));
          } else {
            promises.push(this.serviceResource.newResource('website', `home-page-promos/${promo.id}/spots/${spot.id}/options`, updatedOptions[option], 'home_page_promo_spot_options'));
          }
        }

        if (promises.length) {
          this.set('saveButtonDisabled', true);
          this.set('isSaving', true);
          this.set('saveButtonText', 'Saving...');
          Ember.RSVP.Promise.all(promises).then(savedSpotOptions => {
            for (let spotOptionIndex = 0, numSpotOptions = savedSpotOptions.length; spotOptionIndex < numSpotOptions; spotOptionIndex++) {
              let spotOption = savedSpotOptions[spotOptionIndex];
              let modelSpotOptionIndex = this.model.spot_options.findIndex(innerSpotOption => {
                if (typeof innerSpotOption[0] !== 'undefined') {
                  innerSpotOption = innerSpotOption[0];
                }

                if (typeof savedSpotOptions[spotOptionIndex][0] !== 'undefined') {
                  savedSpotOptions[spotOptionIndex] = savedSpotOptions[spotOptionIndex][0];
                }

                return innerSpotOption.id === savedSpotOptions[spotOptionIndex].id;
              });
              let spotOptionToPush = typeof spotOption[0] !== 'undefined' ? spotOption[0] : spotOption;

              if (modelSpotOptionIndex !== -1) {
                this.model.spot_options[modelSpotOptionIndex] = spotOptionToPush;
              } else {
                this.model.spot_options.pushObject(spotOptionToPush);
              }
            }

            this.model.spot_options.arrayContentDidChange();
            this.set('isSaving', false);
            this.set('saveButtonText', 'Save');
            this.set('saveButtonDisabled', false);
            this.notificationService.show('Spot Updated', 'Spot Updated Successfully');
            this.set('updatedOptions', {});
          });
        }
      }

    },
    mappings: Ember.computed('model', function () {
      let map = {
        'promo_spot_type_id': {}
      };

      for (let spotType of this.get('model.spot_types')) {
        map.promo_spot_type_id[spotType.id] = spotType.name;
      }

      return map;
    })
  });

  _exports.default = _default;
});