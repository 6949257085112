define("universal-cms/components/dreamworks/media-theater-item-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isOpen: false,
    isNew: true,
    openStatusChange: Ember.observer('isOpen', async function () {
      if (!this.isOpen) {
        this.set('item', null);
        this.set('customItem', null);
        this.set('mediaTheaterOption', null);
        this.set('selectedResourceId', null);
        this.set('customCta', null);
        this.set('customCtaOne', null);
        this.set('customCtaTwo', null);
        this.set('isNew', true);
        this.set('hideBtn', false);
      }

      let selectedResourceType = 'movie';

      if (this.item !== null) {
        switch (this.item.slideProperty) {
          case 'Movie':
            selectedResourceType = 'movie';
            break;

          case 'TvShow':
            selectedResourceType = 'tv_show';
            break;

          case 'Franchise':
            selectedResourceType = 'franchise';
            break;

          case 'Game':
            selectedResourceType = 'game';
            break;
        }

        if (this.item.slideProperty) {
          this.set('mediaTheaterOption', 'resource');
        } else if (this.item.resourceTypeTitle === 'Custom') {
          this.set('mediaTheaterOption', 'custom');
          this.set('item', this.item);
          this.set('customItem', this.item);
        }

        this.set('selectedResourceId', this.item.id);

        if (this.item.slideProperty) {
          if (this.selectedResourceId) {
            this.set('isNew', false);
          } else {
            this.set('isNew', true);
          }
        } else {
          if (this.customItem) {
            this.set('isNew', false);
          } else {
            this.set('isNew', true);
          }
        }

        if (this.item.cta) {
          this.set('customCta', this.item.cta);
        }

        if (this.item.ctaOne) {
          this.set('customCtaOne', this.item.ctaOne);
        }

        if (this.item.hideBtn) {
          this.set('hideBtn', this.item.hideBtn);
        }

        if (this.item.ctaTwo) {
          this.set('customCtaTwo', this.item.ctaTwo);
        }

        console.log(this.item);
      }

      this.set('selectedResourceType', selectedResourceType);
    }),
    mediaTheaterOptionChanged: Ember.observer('mediaTheaterOption', function () {
      // create new custom object if there isn't one
      if (this.mediaTheaterOption === 'custom' && this.customItem === null) {
        this.set('customItem', Ember.Object.create({
          sectionTitle: 'Media Theater',
          hero_image: Ember.Object.create({
            landscape: null,
            portrait: null
          }),
          cta: Ember.Object.create({
            text: null,
            url: null
          })
        }));
      }
    }),
    selectedResourceTypeChanged: Ember.observer('selectedResourceType', async function () {
      await this.loadResources();
    }),
    resourceDropdownChooseText: Ember.computed('selectedResourceType', function () {
      return `Choose ${this.selectedResourceType}`;
    }),
    item: null,
    customItem: null,
    // backupItem: null,
    mediaTheaterOption: null,
    selectedResourceType: null,
    hideBtn: false,
    isLoadingResources: false,
    loadResourcesError: null,
    resources: null,
    selectedResourceId: null,
    customCta: Ember.Object.create({
      text: null,
      url: null
    }),
    customCtaOne: Ember.Object.create({
      text: null,
      url: null
    }),
    customCtaTwo: Ember.Object.create({
      text: null,
      url: null
    }),
    formattedResources: Ember.computed('resources.[]', 'selectedType', function () {
      if (this.resources === null || typeof this.resources !== 'object') {
        return [];
      }

      return this.resources.map(resource => {
        return {
          text: resource.name,
          value: resource.id
        };
      });
    }),

    async init() {
      this._super(...arguments); // this.set('backupItem', JSON.parse(JSON.stringify(this.get('item'))));

    },

    async loadResources() {
      let apiEndpoint = null;

      switch (this.selectedResourceType) {
        case 'movie':
          apiEndpoint = 'movies';
          break;

        case 'tv_show':
          apiEndpoint = 'tv-shows';
          break;

        case 'game':
          apiEndpoint = 'games';
          break;

        case 'franchise':
          apiEndpoint = 'franchises';
          break;

        default:
          console.error(`Invalid resource "${this.selectedResourceType}".`);
          break;
      }

      if (apiEndpoint === null) {
        return;
      }

      try {
        this.set('isLoadingResources', true);
        const resources = await this.dreamworksService.getMany(apiEndpoint);
        this.set('resources', resources);
      } catch (error) {
        this.set('loadResourcesError', 'Could not load resources.');
      } finally {
        this.set('isLoadingResources', false);
      }
    },

    actions: {
      save() {
        let slideProperty;

        switch (this.selectedResourceType) {
          case 'movie':
            slideProperty = 'Movie';
            break;

          case 'tv_show':
            slideProperty = 'TvShow';
            break;

          case 'game':
            slideProperty = 'Game';
            break;

          case 'franchise':
            slideProperty = 'Franchise';
            break;
        } // checking what type of media theater slide this is


        if (this.mediaTheaterOption === 'resource') {
          this.item.setProperties({
            originalId: this.item.isNew ? null : this.item.id,
            slideProperty: slideProperty,
            id: this.selectedResourceId,
            cta: this.customCta,
            ctaOne: this.customCtaOne,
            ctaTwo: this.customCtaTwo,
            hideBtn: this.hideBtn
          });
        } else {
          this.item.setProperties({
            heading: this.customItem.heading,
            topline: this.customItem.topline,
            hero_image: this.customItem.hero_image,
            video_id: this.customItem.video_id,
            cta: this.customItem.cta,
            hideBtn: this.hideBtn
          });
        } // manually setting so it doesn't push to the items object twice when it's new before saving/publishing


        if (this.isNew) {
          this.item.setProperties({
            isNew: true
          });
        } else {
          this.item.setProperties({
            isNew: false
          });
        }

        this.onSave();
        this.send('close');
      },

      close() {
        let revertChanges = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
        this.set('isOpen', false);
        this.set('item', null);
        this.set('mediaTheaterOption', null);
        this.set('customItem', null);
      },

      hideBtn() {
        this.set('hideBtn', true);
      }

    }
  });

  _exports.default = _default;
});