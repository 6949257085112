define("universal-cms/ember-quill/tests/templates/components/dreamworks/page-component-modals/featured-diptych", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mXUBcfA0",
    "block": "{\"symbols\":[],\"statements\":[[9,\"h2\",true],[10],[1,1,0,0,\"\\n    Featured Diptych\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[7,\"pxl-form-field\",[],[[\"@label\",\"@value\"],[\"Section Title\",[27,[26,0,\"AppendSingleId\"],[\"data\",\"sectionTitle\"]]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[1,1,0,0,\"\\n\\n\"],[7,\"dreamworks/resource-form\",[],[[\"@formOptions\",\"@items\",\"@maxItems\",\"@resourceType\",\"@onItemsUpdated\"],[[27,[26,1,\"AppendSingleId\"],[]],[27,[26,0,\"AppendSingleId\"],[\"data\",\"items\"]],2,[27,[26,2,\"AppendSingleId\"],[]],[31,296,6,[27,[26,4,\"CallHead\"],[]],[[27,[24,0],[]],[31,304,3,[27,[26,3,\"CallHead\"],[]],[[27,[26,0,\"Expression\"],[\"data\",\"items\"]]],null]],null]]],null],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"pageComponent\",\"resourceFormOptions\",\"resourceType\",\"mut\",\"action\"]}",
    "meta": {
      "moduleName": "universal-cms/ember-quill/tests/templates/components/dreamworks/page-component-modals/featured-diptych.hbs"
    }
  });

  _exports.default = _default;
});