define("universal-cms/ember-quill/tests/templates/genres/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S3HA/Jt9",
    "block": "{\"symbols\":[],\"statements\":[[9,\"h1\",true],[12,\"class\",\"page-header\",null],[10],[1,1,0,0,\"\\n    Genres\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[7,\"models-table\",[],[[\"@data\",\"@columns\",\"@showColumnsDropdown\",\"@useFilteringByColumns\",\"@multipleColumnsSorting\",\"@filteringIgnoreCase\",\"@columnComponents\"],[[27,[26,0,\"AppendSingleId\"],[]],[27,[26,1,\"AppendSingleId\"],[]],false,false,false,true,[31,275,4,[27,[26,4,\"CallHead\"],[]],null,[[\"manage\"],[[31,309,9,[27,[26,3,\"CallHead\"],[]],[\"models-table/genre-actions\"],[[\"deleteGenre\"],[[31,373,6,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[]],\"deleteGenre\"],null]]]]]]]]],null],[1,1,0,0,\"\\n\\n\"],[9,\"div\",true],[12,\"class\",\"panel panel-inverse\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"div\",true],[12,\"class\",\"panel-heading\",null],[10],[1,1,0,0,\"\\n        \"],[9,\"h4\",true],[12,\"class\",\"panel-title\",null],[10],[1,1,0,0,\"Add\"],[11],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\\n    \"],[9,\"div\",true],[12,\"class\",\"panel-body\",null],[10],[1,1,0,0,\"\\n        \"],[7,\"genre-form\",[],[[\"@mode\",\"@genre\",\"@createGenre\",\"@isSaving\"],[\"add\",[27,[26,0,\"AppendSingleId\"],[]],[31,627,6,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[]],\"createGenre\"],null],[27,[26,5,\"AppendSingleId\"],[]]]],null],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"tableColumns\",\"action\",\"component\",\"hash\",\"isSaving\"]}",
    "meta": {
      "moduleName": "universal-cms/ember-quill/tests/templates/genres/list.hbs"
    }
  });

  _exports.default = _default;
});