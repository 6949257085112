define("universal-cms/controllers/website/home-page-promos/edit/spots", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    isAdding: false,
    isSaving: false,
    isDeleting: false,
    isLoading: false,
    newResource: null,

    init() {
      this._super(...arguments);

      this.set('newResource', {});
    },

    actions: {
      selectUpdate(target) {
        this.newResource.promo_id = this.get("model.resource.id");
        this.newResource[target.name] = target.value;

        if (target.name === 'promo_spot_type_id') {
          this.newResource.promo_spot_type_name = target.options[target.selectedIndex].text;
        }
      },

      createResource() {
        if (this.newResource.promo_spot_type_id === undefined) {
          this.notificationService.show('Error', 'Choose spot type.');
          return;
        } else if (this.newResource.size === undefined) {
          this.notificationService.show('Error', 'Choose size.');
          return;
        } else if (this.newResource.promo_spot_type_name === 'Ad' && this.newResource.size !== 'small') {
          this.notificationService.show('Error', 'Ads can only be small size.');
          return;
        }

        this.send('newResource', this.newResource, this);
      },

      deleteResource(spot) {
        if (!confirm('Are you sure you want to delete home page promo spot?')) {
          return;
        }

        spot.set('isDeleting', true);
        this.serviceResource.deleteResource('website', `home-page-promos/${spot.promo_id}/spots`, spot).then(d => {
          this.model.spots.removeObject(spot);
          spot.set('isDeleting', false);
          this.notificationService.show('Promo Spot deleted', 'Home page promo spot was successfully deleted.');
        });
      },

      changePosition(e) {
        let newPosition = e.target.value;
        this.set('model.resource.position', newPosition);
      },

      updateResource(homePagePromo) {
        this.set('isSaving', true);
        this.serviceResource.updateResource('website', 'home-page-promos', homePagePromo, 'home-page-promos').then(result => {
          this.notificationService.show('Home Page Promo Saved', 'Home Page Promo was successfully saved.');
        }).finally(() => {
          this.set('isSaving', false);
        });
      }

    },
    mappings: Ember.computed("model", function () {
      let map = {
        "promo_spot_type_id": {}
      };

      for (let spotType of this.get("model.spot_types")) {
        map.promo_spot_type_id[spotType.id] = spotType.name;
      }

      return map;
    })
  });

  _exports.default = _default;
});