define("universal-cms/routes/dreamworks/tv-shows/list", ["exports", "universal-cms/routes/dreamworks/resource-list"], function (_exports, _resourceList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resourceList.default.extend({});

  _exports.default = _default;
});