define("universal-cms/routes/login", ["exports", "jquery", "universal-cms/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    cookies: Ember.inject.service(),

    activate() {
      if (this.get('auth.isAuthed')) {
        this.set('auth.isAuthed', 'false');
        this.transitionTo('application');
      }
    },

    actions: {
      login(username, password) {
        this.controller.set('isSigningIn', true);

        _jquery.default.ajax({
          url: `${_environment.default.authUrl + (_environment.default.authUrl.substr(-1) !== '/' ? '/' : '')}auth`,
          type: 'POST',
          data: {
            'username': username,
            'password': password
          }
        }).then(jwtResponse => {
          let connectSrc = ["'self'"];

          if (typeof jwtResponse.error === 'string') {
            throw new Error(jwtResponse.error);
          } // Movie service


          this.movieService.setUrl(jwtResponse.decoded.scopes.mappings.Movies);
          this.movieService.setWebsiteUrl(jwtResponse.decoded.scopes.mappings.Website);
          connectSrc.push(this.movieService.getUrl()); // Website service

          this.serviceResource.setUrl(jwtResponse.decoded.scopes.mappings.Website);
          connectSrc.push(this.serviceResource.getUrl());
          this.auth.setUrl(jwtResponse.decoded.scopes.mappings.Auth);
          connectSrc.push(this.auth.getUrl());

          if (typeof jwtResponse.decoded.scopes.mappings.UniversalStudios !== 'undefined') {
            this.universalStudiosService.setUrl(jwtResponse.decoded.scopes.mappings.UniversalStudios);
          } else {
            this.universalStudiosService.setUrl('http://newuniversalstudios.upqa.com');
          }

          connectSrc.push(this.universalStudiosService.getUrl());
          this.universalBrandService.setUrl(jwtResponse.decoded.scopes.mappings.UniversalBrand);
          connectSrc.push(this.universalBrandService.getUrl()); // DreamWorks

          this.dreamworksService.setUrl(jwtResponse.decoded.scopes.mappings.DreamWorks);
          this.dreamworksService.setGabbysWebsiteUrl(jwtResponse.decoded.scopes.mappings.GabbysWebsite);
          connectSrc.push(this.dreamworksService.getUrl());
          this.uwatchService.setUrl(jwtResponse.decoded.scopes.mappings.UWatchApi);
          this.movieService.setUWatchUrl(jwtResponse.decoded.scopes.mappings.UWatchApi);
          connectSrc.push(this.uwatchService.getUrl());
          _environment.default.contentSecurityPolicy['connect-src'] = connectSrc;
          const encoded = jwtResponse.encoded;
          jwtResponse.userLanguages = jwtResponse && jwtResponse.decoded && jwtResponse.decoded.sub && jwtResponse.decoded.sub.languages ? jwtResponse.decoded.sub.languages : [];

          if (jwtResponse.userLanguages && jwtResponse.userLanguages.length > 0) {
            jwtResponse.languageLocaleCode = jwtResponse.userLanguages[0].language.locale_code;
          } else {
            jwtResponse.languageLocaleCode = null;
          }

          delete jwtResponse.encoded;
          delete jwtResponse.decoded.sub; // Set cookie

          localStorage.setItem('auth', JSON.stringify(jwtResponse));
          localStorage.setItem('auth_encoded', encoded);
          this.auth.login(jwtResponse, encoded); // Clear preferred language when signing in

          localStorage.removeItem('language_locale_code');
          this.transitionTo('/');
        }, e => {
          this.notificationService.show('Error', 'Could not sign in right now.');
          this.controller.set('isSigningIn', false);
        }).fail(error => {
          this.notificationService.show('Could not sign in', error.message || 'Invalid email or password.');
          this.controller.set('isSigningIn', false);
        });
      }

    }
  });

  _exports.default = _default;
});