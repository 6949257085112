define("universal-cms/components/pxl-form-items", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    pxlMediaLibrary: Ember.inject.service(),
    classNames: 'pxl-form-items',
    label: undefined,
    items: [],
    formattedItems: [],
    isLoadingItems: false,
    loadItemsError: false,
    fields: [],
    isAssetPickerOpen: false,

    async init() {
      this._super(...arguments);

      await this.loadItems();
    },

    async loadItems() {
      try {
        this.set('isLoadingItems', true);
        this.set('loadItemsError', false);
        let formattedItems = [];
        let itemIndex = 0;

        if (!this.items) {
          this.set('items', []);
        }

        for (let item of this.items) {
          const itemColumns = Object.keys(item);
          let formattedItem = {
            index: itemIndex,
            item: item,
            fields: []
          };

          for (let itemColumn of itemColumns) {
            const itemColumnField = Ember.Object.create(this.fields.find(field => field.column === itemColumn));
            const itemValue = item[itemColumn];
            itemColumnField.set('value', itemValue);
            formattedItem.fields.push(itemColumnField);
          }

          formattedItems.pushObject(formattedItem);
          itemIndex++;
        }

        this.set('formattedItems', formattedItems);
      } catch (error) {
        console.log('error', error);
        this.set('loadItemsError', true);
      } finally {
        this.set('isLoadingItems', false);
      }
    },

    actions: {
      async addItem() {
        let newItem = {};

        for (let field of this.fields) {
          newItem[field.column] = null;
        }

        this.items.pushObject(Ember.Object.create(newItem));
        await this.loadItems();
      },

      removeItem(item) {
        const itemToRemove = this.items.find((_item, _itemIndex) => item.index === _itemIndex);
        this.items.removeObject(itemToRemove);
        this.loadItems();
      },

      onReordered() {
        let reorderedItems = [];

        for (let formattedItem of this.formattedItems) {
          let itemIndex = formattedItem.index;
          reorderedItems.push(this.items[itemIndex]);
        }

        this.set('items', reorderedItems);
        this.loadItems();
      },

      updateFieldValue(item, field) {
        const itemToUpdate = this.items.find((_item, _itemIndex) => item.index === _itemIndex);
        itemToUpdate.set(field.column, field.value);
      },

      async openAssetPicker(item, field) {
        field.set('isOpening', true);
        const itemToUpdate = this.items.find((_item, _itemIndex) => item.index === _itemIndex);

        try {
          const choosenMediaItem = await this.pxlMediaLibrary.chooseMediaItemAsync({
            type: 'image',
            expectedTypes: null,
            selectable: true
          }, {
            onOpened: () => {
              field.set('isOpening', false);
              this.set('isAssetPickerOpen', true);
            },
            onClosed: () => {
              this.set('isAssetPickerOpen', false);
            }
          }, 'dreamworks');
          itemToUpdate.set(field.column, choosenMediaItem.id);
          this.loadItems();
        } catch (chooseMediaError) {
          if (chooseMediaError === 'close') {
            return;
          }

          this.notificationService.show('Error', 'Could not choose asset.');
        }
      }

    }
  });

  _exports.default = _default;
});