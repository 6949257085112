define("universal-cms/controllers/dreamworks/home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      async onSaved() {
        this.send('refreshModel');
      },

      async onRevertedUnpublishedChanges() {
        this.send('refreshModel');
      },

      async onPublishedChanges() {
        this.send('refreshModel');
      },

      async onUnpublished() {
        this.send('refreshModel');
      }

    }
  });

  _exports.default = _default;
});