define("universal-cms/controllers/gabbys-website/gabbys-website-episodes/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    resourceType: 'gabbys-website-episodes',
    type: 'gabbys-website',
    pageType: null,
    text: {
      singular: 'Episode',
      plural: 'Episodes'
    },

    init() {
      this._super(...arguments);

      this.set('tableColumns', [{
        'title': 'Name',
        'propertyName': 'name'
      }, {
        'title': 'Added',
        'component': 'models-table/dreamworks/date'
      }, {
        'title': 'Manage',
        'component': 'pageActions',
        'editable': false
      }]);
    },

    languages: Ember.computed('model.resourceOptions', function () {
      const languages = this.model.resourceOptions.languages || [];
      return languages;
    }),
    actions: {
      async deleteEpisode(episodeToDelete) {
        if (!confirm(`Are you sure you want to delete ${this.get('text.singular').toLowerCase()} "${episodeToDelete.name}"?`)) {
          return;
        }

        try {
          episodeToDelete.set('isDeleting', true);
          await this.dreamworksService.deleteOne(this.get('resourceType'), episodeToDelete.id);
          this.model.resources.removeObject(episodeToDelete);
          this.notificationService.show(`${this.get('text.singular')} Deleted`, `${this.get('text.singular')} <strong>${episodeToDelete.name}</strong> has been deleted.`);
        } catch (error) {
          this.notificationService.show('Error', error.message || `Could not delete <strong>${episodeToDelete.name}</strong>.`);
        } finally {
          episodeToDelete.set('isDeleting', false);
        }
      }

    }
  });

  _exports.default = _default;
});