define("universal-cms/controllers/asset-types/list", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    tableColumns: null,

    init() {
      this._super(...arguments);

      this.set('tableColumns', [{
        'propertyName': 'name',
        'title': 'Name'
      }, {
        'propertyName': 'description',
        'title': 'Description'
      }, {
        'propertyName': 'group.name',
        'title': 'Group'
      }, {
        'propertyName': 'type',
        'title': 'Type',
        'component': 'models-table/asset-type-type'
      }, {
        'title': 'Allow Many',
        'component': 'models-table/asset-type-allow-many'
      }, {
        title: 'Manage',
        component: 'manage',
        editable: false
      }]);
    },

    actions: {
      createAssetType(data) {
        return this.movieService.createOne('asset-types', {
          asset_group_id: data.data.assetGroupId,
          name: data.data.name,
          description: data.data.description,
          allow_many: data.data.allowMany,
          type: data.data.type,
          live_start: (0, _moment.default)().format('YYYY-MM-DD HH:mm:ss'),
          live_end: null
        }).then(createdAssetType => {
          this.notificationService.show('Asset type added', `<strong>${data.data.name}</strong> was successfully added.`);
          let assetGroups = this.get('model.assetGroups');

          if (createdAssetType.type === 'image') {
            createdAssetType.type = 'Image';
          } else if (createdAssetType.type === 'video') {
            createdAssetType.type = 'Video';
          }

          for (let assetGroupIndex = 0, numAssetGroups = assetGroups.length; assetGroupIndex < numAssetGroups; assetGroupIndex++) {
            if (assetGroups[assetGroupIndex].id === createdAssetType.asset_group_id) {
              createdAssetType.set('group', Ember.Object.create());
              createdAssetType.group.set('name', assetGroups[assetGroupIndex].name);
              break;
            }
          } // Add to list


          this.get('model.assetTypes').pushObject(createdAssetType);
          return createdAssetType;
        });
      },

      deleteAssetType(assetType) {
        if (!confirm(`Are you sure you want to delete asset type "${assetType.get('name')}"?`)) {
          return;
        }

        assetType.set('isDeleting', true);
        this.movieService.deleteOne('asset-types', assetType.id).then(() => {
          this.notificationService.show('Asset type deleted', `<strong>${assetType.name}</strong> was successfully deleted.`);
          this.get('model.assetTypes').removeObject(assetType);
        }).catch(() => {
          this.notificationService.show('Error', `Could not delete asset type <strong>${assetType.name}</strong>.`);
          assetType.set('isDeleting', false);
        });
      }

    }
  });

  _exports.default = _default;
});