define("universal-cms/controllers/universal-brand/pages/edit-menu-thumbnail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    error: false,
    heading: Ember.computed('isNew', 'model.menuThumbnail.title', function () {
      return this.isNew === true ? 'Add Featured Banner' : this.get('model.menuThumbnail.title');
    }),
    isSaving: false,
    saveButtonText: Ember.computed('isNew', 'isSaving', function () {
      if (this.isNew === true) {
        return this.isSaving === true ? 'Adding...' : 'Add';
      } else {
        return this.isSaving === true ? 'Saving...' : 'Save';
      }
    }),
    actions: {
      save() {
        this.set('isSaving', true);
        const menuThumbnail = this.get('model.menuThumbnail');
        let saveData = menuThumbnail.getProperties('title', 'thumbnail_image_id');

        if (this.isNew === true && menuThumbnail.get('type') === 'internal') {
          saveData.link = `/${this.get('selectedPage.slug')}`;
        } else {
          saveData.link = menuThumbnail.get('link');
        }

        saveData.link_type = menuThumbnail.get('type');
        saveData.page_version_id = this.pageVersionId;

        if (this.isNew) {
          this.universalBrandService.createOne('menu-thumbnails', saveData).then(createResponse => {
            if (typeof createResponse.errors === 'undefined') {
              this.notificationService.show('Featured Banner Added', `<strong>${createResponse.get('title')}</strong> has been added.`);
              this.target.transitionTo(this.routeName, createResponse.get('menu_thumbnail_id'));
            } else {
              this.notificationService.show('Error', this.universalBrandService.formatErrorResponse(createResponse.errors));
            }
          }, createError => {
            this.notificationService.show('Error', createError);
          }).finally(() => {
            this.set('isSaving', false);
          });
        } else {
          this.universalBrandService.updateOne('menu-thumbnails', menuThumbnail.get('menu_thumbnail_id'), saveData, true).then(updateResponse => {
            if (typeof updateResponse.errors === 'undefined') {
              this.notificationService.show('Featured Banner Updated', `<strong>${updateResponse.get('title')}</strong> has been updated.`);
              this.target.transitionTo(this.routeName, updateResponse.get('menu_thumbnail_id'));
            } else {
              this.notificationService.show('Error', this.universalBrandService.formatErrorResponse(updateResponse.errors));
            }
          }, updateError => {
            this.notificationService.show('Error', updateError);
          }).finally(() => {
            this.set('isSaving', false);
          });
        }
      },

      setInternalPage(page) {
        this.set('selectedPage', page);
      }

    }
  });

  _exports.default = _default;
});