define("universal-cms/templates/asset-types/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7LDt5SdD",
    "block": "{\"symbols\":[],\"statements\":[[9,\"h1\",true],[12,\"class\",\"page-header\",null],[10],[1,1,0,0,\"\\n    Asset Types\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[7,\"models-table\",[],[[\"@data\",\"@columns\",\"@showColumnsDropdown\",\"@useFilteringByColumns\",\"@multipleColumnsSorting\",\"@filteringIgnoreCase\",\"@columnComponents\"],[[27,[26,0,\"AppendSingleId\"],[\"assetTypes\"]],[27,[26,1,\"AppendSingleId\"],[]],false,false,false,true,[31,291,4,[27,[26,4,\"CallHead\"],[]],null,[[\"manage\"],[[31,325,9,[27,[26,3,\"CallHead\"],[]],[\"models-table/asset-type-actions\"],[[\"deleteAssetType\"],[[31,398,6,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[]],\"deleteAssetType\"],null]]]]]]]]],null],[1,1,0,0,\"\\n\\n\"],[9,\"div\",true],[12,\"class\",\"panel panel-inverse\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"div\",true],[12,\"class\",\"panel-heading\",null],[10],[1,1,0,0,\"\\n        \"],[9,\"h4\",true],[12,\"class\",\"panel-title\",null],[10],[1,1,0,0,\"Add\"],[11],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\\n    \"],[9,\"div\",true],[12,\"class\",\"panel-body\",null],[10],[1,1,0,0,\"\\n        \"],[7,\"asset-type-form\",[],[[\"@mode\",\"@assetGroups\",\"@createAssetType\"],[\"add\",[27,[26,0,\"AppendSingleId\"],[\"assetGroups\"]],[31,682,6,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[]],\"createAssetType\"],null]]],null],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"tableColumns\",\"action\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "universal-cms/templates/asset-types/list.hbs"
    }
  });

  _exports.default = _default;
});