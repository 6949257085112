define("universal-cms/components/super-table-yield/table-body-row/delete-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'td',
    attributeBindings: ['style'],
    style: Ember.String.htmlSafe('cursor: pointer; text-align: center;'),
    isDeleting: false,

    click() {
      if (typeof this.onClick === 'function') {
        if (!confirm('Are you sure you want to delete?')) {
          return;
        }

        this.set('isDeleting', true);
        this.onClick();
      }
    }

  });

  _exports.default = _default;
});