define("universal-cms/services/service-resource", ["exports", "jquery", "universal-cms/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    getOptions(serviceName, resourceName) {
      let url = this.getUrl('api/' + resourceName);
      url += url.indexOf('?') === -1 ? '?' : '&';
      url += `nocache=${Math.floor(Math.random() * 99999)}`;
      return _jquery.default.ajax({
        'method': 'OPTIONS',
        'url': url,
        'beforeSend': xhr => {
          xhr.setRequestHeader('X-Auth', 'Bearer ' + this.get('auth.jwt'));

          if (_environment.default.BASIC_AUTH_USERNAME) {
            xhr.setRequestHeader('Authorization', `Basic ${btoa(_environment.default.BASIC_AUTH_USERNAME + ':' + _environment.default.BASIC_AUTH_PASSWORD)}`);
          }
        }
      }).then(data => {
        return Ember.Object.create(data.data);
      });
    },

    getResources(serviceName, resourceLocation, params, resourceName) {
      let url = this.getUrl('api/' + resourceLocation);
      url += url.indexOf('?') === -1 ? '?' : '&';
      url += `nocache=${Math.floor(Math.random() * 99999)}`;
      return _jquery.default.ajax({
        'method': 'GET',
        'url': url,
        'data': params,
        'beforeSend': xhr => {
          xhr.setRequestHeader('X-Auth', 'Bearer ' + this.get('auth.jwt'));

          if (_environment.default.BASIC_AUTH_USERNAME) {
            xhr.setRequestHeader('Authorization', `Basic ${btoa(_environment.default.BASIC_AUTH_USERNAME + ':' + _environment.default.BASIC_AUTH_PASSWORD)}`);
          }
        }
      }).then(data => {
        let name = resourceName ? resourceName : resourceLocation;
        let resources = data.data[name.replace(/-/g, '_')];

        if (resources.length > 0) {
          if (typeof resources[0].position !== 'undefined') {
            resources = resources.sortBy('position');
          }
        }

        return Ember.A(resources.map(resource => {
          return Ember.Object.create(resource);
        }));
      });
    },

    getResource(serviceName, resourceLocation, resource, resourceName) {
      let url = this.getUrl('api/' + resourceLocation + "/" + resource);
      url += url.indexOf('?') === -1 ? '?' : '&';
      url += `nocache=${Math.floor(Math.random() * 99999)}`;
      return _jquery.default.ajax({
        'method': 'GET',
        'url': url,
        'beforeSend': xhr => {
          xhr.setRequestHeader('X-Auth', 'Bearer ' + this.get('auth.jwt'));

          if (_environment.default.BASIC_AUTH_USERNAME) {
            xhr.setRequestHeader('Authorization', `Basic ${btoa(_environment.default.BASIC_AUTH_USERNAME + ':' + _environment.default.BASIC_AUTH_PASSWORD)}`);
          }
        }
      }).then(data => {
        let name = resourceName ? resourceName : resourceLocation;
        let resources = data.data[name.replace(/-/g, '_')];
        resources = resources.sortBy('position');
        return Ember.A(resources.map(resource => {
          return Ember.Object.create(resource);
        }))[0];
      });
    },

    newResource(serviceName, resourceLocation, resource, resourceName) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        return _jquery.default.ajax({
          'method': 'POST',
          'contentType': 'application/json',
          'url': this.getUrl('api/' + resourceLocation),
          'data': JSON.stringify(resource),
          'beforeSend': xhr => {
            xhr.setRequestHeader('X-Auth', 'Bearer ' + this.get('auth.jwt'));

            if (_environment.default.BASIC_AUTH_USERNAME) {
              xhr.setRequestHeader('Authorization', `Basic ${btoa(_environment.default.BASIC_AUTH_USERNAME + ':' + _environment.default.BASIC_AUTH_PASSWORD)}`);
            }
          }
        }).then(data => {
          let name = resourceName ? resourceName : resourceLocation;

          if (data.status === 'fail') {
            return reject(data.data);
          }

          resolve(Ember.A(data.data[name.replace(/-/g, '_')].map(resource => {
            return Ember.Object.create(resource);
          })));
        }).fail(error => {
          reject(error);
        });
      });
    },

    updateResource(serviceName, resourceLocation, resource, resourceName) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        return _jquery.default.ajax({
          'method': 'PUT',
          'contentType': 'application/json',
          'url': this.getUrl('api/' + resourceLocation + '/' + resource.id),
          'data': JSON.stringify(resource),
          'beforeSend': xhr => {
            xhr.setRequestHeader('X-Auth', 'Bearer ' + this.get('auth.jwt'));

            if (_environment.default.BASIC_AUTH_USERNAME) {
              xhr.setRequestHeader('Authorization', `Basic ${btoa(_environment.default.BASIC_AUTH_USERNAME + ':' + _environment.default.BASIC_AUTH_PASSWORD)}`);
            }
          }
        }).then(data => {
          if (data.status === 'fail') {
            return reject(data.data);
          }

          Ember.A(data.data[resourceName.replace(/-/g, '_')].map(resource => {
            resolve(Ember.Object.create(resource));
          }));
        }).fail(error => {
          reject(error);
        });
      });
    },

    deleteResource(serviceName, resourceName, resource) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        let url = this.getUrl('api/' + resourceName);

        if (resource) {
          url += '/' + resource.get('id');
        }

        return _jquery.default.ajax({
          'method': 'DELETE',
          'contentType': 'application/json',
          'url': url,
          'data': JSON.stringify(resource),
          'beforeSend': xhr => {
            xhr.setRequestHeader('X-Auth', 'Bearer ' + this.get('auth.jwt'));

            if (_environment.default.BASIC_AUTH_USERNAME) {
              xhr.setRequestHeader('Authorization', `Basic ${btoa(_environment.default.BASIC_AUTH_USERNAME + ':' + _environment.default.BASIC_AUTH_PASSWORD)}`);
            }
          }
        }).then(() => {
          resolve();
        }).fail(error => {
          reject(error);
        });
      });
    },

    setUrl(url) {
      this.set('url', url);
    },

    getUrl() {
      let route = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      return this.url + (route !== null ? (route.substring(0, 1) !== '/' ? '/' : '') + route : '');
    }

  });

  _exports.default = _default;
});