define("universal-cms/controllers/asset-groups/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      updateAssetGroup(data) {
        return this.movieService.replaceOne('asset-groups', data.assetGroup.id, {
          name: data.data.name,
          description: data.data.description
        }).then(replacedAssetGroup => {
          this.notificationService.show('Asset group saved', `<strong>${replacedAssetGroup.name}</strong> was successfully saved.`);
          data.assetGroup.setProperties({
            name: replacedAssetGroup.get('name'),
            description: replacedAssetGroup.get('description')
          });
          return replacedAssetGroup;
        }, error => {
          this.notificationService.show('Error', 'Could not save asset group.');
        });
      }

    }
  });

  _exports.default = _default;
});