define("universal-cms/templates/components/super-table-yield/table-body-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j5d0+Nmw",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[5,[27,[26,2,\"BlockHead\"],[]],[[27,[26,1,\"Expression\"],[]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"    \"],[7,\"super-table-yield/table-body-row/drag-handle\",[],[[\"@onGrab\"],[[31,75,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[]],\"onHandleGrab\"],null]]],null],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"],[16,1,null],[1,1,0,0,\"\\n\\n\"],[5,[27,[26,2,\"BlockHead\"],[]],[[27,[26,3,\"Expression\"],[]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"    \"],[9,\"td\",true],[10],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\"],[5,[27,[26,2,\"BlockHead\"],[]],[[27,[26,4,\"Expression\"],[]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"    \"],[7,\"super-table-yield/table-body-row/delete-button\",[],[[\"@onClick\"],[[31,241,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[]],\"onDeleteClick\"],null]]],null],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"orderable\",\"if\",\"creatable\",\"deletable\"]}",
    "meta": {
      "moduleName": "universal-cms/templates/components/super-table-yield/table-body-row.hbs"
    }
  });

  _exports.default = _default;
});