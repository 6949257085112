define("universal-cms/components/pxl-form-select", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'pxl-form-select-container',
    label: null,
    required: false,
    options: undefined,
    value: undefined,
    filterText: '',
    filter: false,
    isSearchMode: false,
    chooseText: 'None',
    isLoading: false,
    optionText: Ember.computed('isLoading', 'selectedOption.text', 'chooseText', function () {
      if (this.isLoading) {
        return 'Loading...';
      } else if (this.selectedOption && this.selectedOption.text) {
        return this.selectedOption.text;
      } else {
        return this.chooseText;
      }
    }),
    selectedOption: Ember.computed('formattedOptions.[]', 'value', function () {
      return this.formattedOptions.find(option => {
        return option.value === this.value;
      });
    }),
    formattedOptions: Ember.computed('options.[]', 'value', 'filterText', function () {
      if (!this.options) {
        return [];
      }

      let options = this.options.map(option => {
        if (typeof option !== 'object') {
          option = {
            text: option,
            value: option
          };
        }

        let formattedOption = Ember.Object.create(option);
        formattedOption.set('selected', option.value === this.value);
        return formattedOption;
      });

      if (this.filterText) {
        const filterTextLowerCase = this.filterText.toLowerCase();
        options = options.filter(option => {
          return option.text.toLowerCase().indexOf(filterTextLowerCase) !== -1;
        });
      }

      return options;
    }),
    actions: {
      select(value) {
        if (typeof this.onChange === 'function') {
          this.onChange(value);
        }

        this.set('isSearchMode', false);
      },

      selectedOptionClick() {
        if (this.isLoading) {
          return;
        }

        if (!this.isSearchMode) {
          let justOpened = true;
          this.set('isSearchMode', true); // Bind click listener

          (0, _jquery.default)(window).on('click', windowClickEvent => {
            if (justOpened) {
              justOpened = false;
              return;
            }

            const $clickedElement = (0, _jquery.default)(windowClickEvent.target);
            const clickedOnSelectDropdown = $clickedElement.hasClass('pxl-form-select') || $clickedElement.hasClass('selected-option');
            const clickedOnSelectOption = !$clickedElement.hasClass('selected-option') && $clickedElement.hasClass('option');

            if (!clickedOnSelectDropdown && !clickedOnSelectOption || clickedOnSelectDropdown) {
              this.set('isSearchMode', false);
              (0, _jquery.default)(window).off('click');
            } else if (clickedOnSelectOption) {
              this.set('isSearchMode', false);
              (0, _jquery.default)(window).off('click');
            }
          });
        }

        if (this.filter) {
          Ember.run.scheduleOnce('afterRender', this, this.focusSearchInput);
        }
      }

    },

    focusSearchInput() {
      this.element.querySelectorAll('.search-input')[0].focus();
    }

  });

  _exports.default = _default;
});