define("universal-cms/templates/components/pxl-youtube-video-form-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3hUnnhYD",
    "block": "{\"symbols\":[],\"statements\":[[9,\"div\",true],[13,\"class\",[32,[\"form-group \",[31,25,2,[27,[26,2,\"CallHead\"],[]],[[27,[26,1,\"Expression\"],[]],\"required\"],null]]],null],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,2,\"BlockHead\"],[]],[[27,[26,0,\"Expression\"],[]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"        \"],[9,\"label\",true],[10],[1,1,0,0,\"\\n            \"],[1,0,0,0,[27,[26,0,\"AppendSingleId\"],[]]],[1,1,0,0,\"\\n        \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n    \"],[9,\"div\",true],[12,\"class\",\"input-group pxl-youtube-video-form-field-input-group\",null],[10],[1,1,0,0,\"\\n        \"],[9,\"span\",true],[12,\"class\",\"input-group-addon\",null],[10],[1,1,0,0,\"\\n            \"],[1,0,0,0,[27,[26,3,\"AppendSingleId\"],[]]],[1,1,0,0,\"\\n        \"],[11],[1,1,0,0,\"\\n\\n        \"],[7,\"input\",[[23,\"class\",\"form-control\",null]],[[\"@type\",\"@value\",\"@input\"],[\"text\",[27,[26,4,\"AppendSingleId\"],[]],[31,369,6,[27,[26,5,\"CallHead\"],[]],[[27,[24,0],[]],\"onChange\"],null]]],null],[1,1,0,0,\"\\n\\n        \"],[9,\"span\",true],[12,\"class\",\"input-group-btn\",null],[10],[1,1,0,0,\"\\n            \"],[9,\"button\",false],[23,\"class\",\"btn btn-default\",null],[14,\"disabled\",[27,[26,6,\"AppendSingleId\"],[]],null],[23,\"type\",\"button\",null],[3,0,0,[27,[26,5,\"ModifierHead\"],[]],[[27,[24,0],[]],\"openVideo\"],null],[10],[1,1,0,0,\"\\n                Open\\n            \"],[11],[1,1,0,0,\"\\n        \"],[11],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"label\",\"required\",\"if\",\"preUrl\",\"value\",\"action\",\"openButtonDisabled\"]}",
    "meta": {
      "moduleName": "universal-cms/templates/components/pxl-youtube-video-form-field.hbs"
    }
  });

  _exports.default = _default;
});