define("universal-cms/components/dreamworks/page-components-manager", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'page-components-manager',
    util: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),
    page: undefined,
    pageData: undefined,
    pageSaveData: undefined,
    resource: undefined,
    resourceSaveData: undefined,
    resourceType: undefined,
    components: null,
    isLoadingComponents: false,
    originalComponentsOrder: undefined,
    originalPageSaveDataComponents: undefined,
    isPageComponentModalOpen: false,
    editingPageComponent: null,
    editingPageComponentIndex: null,
    editingPageComponentName: null,
    editableComponents: undefined,
    activeComponents: Ember.computed('components.[]', 'pageSaveData.components.{[],@each.data}', function () {
      if (!this.components) {
        return [];
      } // Clear active components div


      (0, _jquery.default)('#active-components').children('.component').remove();

      if (!this.get('pageSaveData.components')) {
        return [];
      }

      const pageSaveDataComponents = this.util.cloneObject(this.get('pageSaveData.components'));
      return pageSaveDataComponents.map(pageSaveDataComponent => {
        pageSaveDataComponent.set('component', this.components.find(component => component.key === pageSaveDataComponent.key)); // TODO: Hack due to Laravel's "json" $casts converts empty objects to arrays

        if (Array.isArray(pageSaveDataComponent.data)) {
          pageSaveDataComponent.set('data', Ember.Object.create());
        }

        let previewTitle = pageSaveDataComponent.component.name;
        let previewImage;

        switch (pageSaveDataComponent.key) {
          case 'page-introduction':
            if (pageSaveDataComponent.data.heading) {
              previewTitle = pageSaveDataComponent.data.heading;
            }

            previewImage = pageSaveDataComponent.data.hero_image ? pageSaveDataComponent.data.hero_image.portrait || pageSaveDataComponent.data.hero_image.landscape : null;
            break;

          case 'standard-diptych':
            previewTitle = pageSaveDataComponent.data.name || 'Standard Diptych'; //
            // // Check if portrait image
            // if (pageSaveDataComponent.data.image) {
            //     if (pageSaveDataComponent.data.image.portrait) {
            //         previewImage = pageSaveDataComponent.data.image.portrait;
            //     } else if (pageSaveDataComponent.data.image.landscape) {
            //         previewImage = pageSaveDataComponent.data.image.landscape;
            //     } else {
            //         previewImage = pageSaveDataComponent.data.image;
            //     }
            // }

            break;

          case 'media-theater':
            // if (pageSaveDataComponent.data.items && pageSaveDataComponent.data.items.length > 0) {
            //     const firstMediaTheaterItemResponse = await this.dreamworksService.getOne('tv-shows', pageSaveDataComponent.data.items[0].id);
            //     console.log(firstMediaTheaterItemResponse);
            // }
            break;

          default:
            if (pageSaveDataComponent.data && typeof pageSaveDataComponent.data.sectionTitle === 'string') {
              previewTitle = pageSaveDataComponent.data.sectionTitle;
            }

        }

        pageSaveDataComponent.setProperties({
          'previewTitle': previewTitle,
          'previewImage': previewImage,
          'isEditable': this.editableComponents.includes(pageSaveDataComponent.key)
        });
        return pageSaveDataComponent;
      });
    }),
    availableComponents: Ember.computed('components.[]', 'pageSaveData.components.[]', 'resourceType', function () {
      if (!this.components) {
        return [];
      }

      let availableComponents = [];
      const commonGabbysComponents = ['html-content', 'gabbys-website-character-carousel', 'gabbys-website-character-grid', 'gabbys-website-character-intro', 'gabbys-website-extra-media', 'gabbys-website-hero', 'gabbys-website-media-carousel', 'gabbys-website-resources-grid', 'gabbys-website-standard-diptych', 'gabbys-website-standard-diptych-carousel', 'gabbys-website-standard-diptych-full', 'gabbys-website-standard-diptych-grid', 'gabbys-website-video-teaser', 'gabbys-website-videos-grid', 'gabbys-website-episodes-grid', 'gabbys-website-intro', 'gabbys-website-image-gallery', 'gabbys-website-featured-resource', 'gabbys-website-event-intro', 'gabbys-website-event-dates', // 'gabbys-website-related-resources-grid',
      'gabbys-website-broadcasters-promo', 'gabbys-website-featured-promos', 'gabbys-website-music-samples-promos', 'gabbys-website-shop-grid', 'gabbys-website-faq', 'gabbys-website-signup-newsletter', 'gabbys-website-featured-partners']; // Filter out components depending on page type

      switch (this.resourceType) {
        case 'movies':
          availableComponents = ['media-bio', 'ticket-search', 'media-grid', 'featured-diptych', 'business-partners', 'character-bios', 'standard-diptych', 'standard-diptych-carousel', 'html-content', 'dragons-explore-intro', 'trolls-explore-section', 'explore', 'image-gallery', 'poster-gallery', 'home-ent-retailers', 'home-ent-features', 'video-gallery', 'extra-media', 'home-ent-offers', 'social-hub', 'social-callout'];
          break;

        case 'franchises':
          availableComponents = ['media-theater', 'media-bio', 'video-gallery', 'image-gallery', 'social-hub', 'media-carousel', 'business-partners', 'character-bios', 'featured-diptych', 'games-carousel', 'ticket-search', 'standard-diptych', 'html-content', 'dragons-explore-intro', 'trolls-explore-section', 'explore', 'poster-gallery', 'combined-media', 'standard-diptych-carousel', 'extra-media', 'media-grid', 'social-callout'];
          break;

        case 'tv-shows':
          availableComponents = ['video-gallery', 'image-gallery', 'social-hub', 'featured-diptych', 'standard-diptych', 'standard-diptych-carousel', 'extra-media', 'media-grid', 'character-bios', 'html-content', 'business-partners', 'poster-gallery', 'social-callout'];
          break;

        case 'games':
          availableComponents = ['html-content', 'featured-diptych', 'standard-diptych', 'media-grid', 'social-hub', 'media-carousel', 'standard-diptych-carousel', 'extra-media', 'games-carousel', 'image-gallery', 'social-callout'];
          break;

        case 'gabbys-website':
          availableComponents = commonGabbysComponents;
          break;

        case 'gabbys-website-characters':
          availableComponents = commonGabbysComponents;
          break;

        case 'gabbys-website-events':
          availableComponents = commonGabbysComponents;
          break;

        case 'gabbys-website-episodes':
          availableComponents = commonGabbysComponents;
          break;

        case 'gabbys-website-partners':
          availableComponents = commonGabbysComponents;
          break;

        default:
          if (this.isGabbysWebsitePage && this.resourceType === 'pages') {
            availableComponents = commonGabbysComponents;
          } else if (!this.page || this.page.type === 'default') {
            availableComponents = ['media-theater', 'html-content', 'featured-diptych', 'standard-diptych', 'media-grid', 'social-hub', 'media-carousel', 'media-catalog', 'featured-carousel', 'social-callout'];
            break;
          } else {
            return this.components;
          }

      }

      return this.components.filter(component => {
        return availableComponents.includes(component.key);
      });
    }),
    isGabbysWebsitePage: Ember.computed('routing.currentRouteName', function () {
      const serviceMappings = this.auth.decoded.scopes.mappings;
      const serviceMappingsKeys = Object.keys(serviceMappings);
      const isValidServiceMappingKeys = serviceMappingsKeys.includes('DreamWorks') && serviceMappingsKeys.includes('GabbysWebsite');
      return this.routing.currentRouteName.startsWith('gabbys-website') && isValidServiceMappingKeys;
    }),

    async init() {
      this._super(...arguments);

      this.initEditableComponents();
      this.set('originalPageSaveDataComponents', this.util.cloneObject(this.pageSaveData.components || []));
      await this.loadComponents(true);
    },

    initEditableComponents() {
      let editableComponents = ['html-content', 'featured-carousel', 'media-bio', 'media-carousel', 'media-theater', 'media-catalog', 'business-partners', 'media-grid', 'games-carousel', 'standard-diptych', 'extra-media', 'social-hub', 'combined-media', 'standard-diptych-carousel', 'home-ent-retailers', 'home-ent-offers', 'social-callout', 'gabbys-website-character-carousel', 'gabbys-website-character-grid', 'gabbys-website-extra-media', 'gabbys-website-hero', 'gabbys-website-media-carousel', 'gabbys-website-standard-diptych', 'gabbys-website-standard-diptych-carousel', 'gabbys-website-standard-diptych-full', 'gabbys-website-standard-diptych-grid', 'gabbys-website-resources-grid', 'gabbys-website-video-teaser', 'gabbys-website-videos-grid', 'gabbys-website-episodes-grid', 'gabbys-website-intro', 'gabbys-website-image-gallery', 'gabbys-website-featured-resource', 'gabbys-website-event-intro', 'gabbys-website-event-dates', // 'gabbys-website-related-resources-grid',
      'gabbys-website-broadcasters-promo', 'gabbys-website-featured-promos', 'gabbys-website-music-samples-promos', 'gabbys-website-shop-grid', 'gabbys-website-faq', 'gabbys-website-signup-newsletter', 'gabbys-website-featured-partners'];

      if (this.page !== null) {
        if (this.page.type === 'default') {
          editableComponents.push('page-introduction'); // editableComponents.push('media-grid');

          editableComponents.push('standard-diptych');
        } else {
          editableComponents.push('image-gallery');
          editableComponents.push('video-gallery');
        }

        if (this.page.type === 'tv_show' || this.page.type === 'movie') {
          editableComponents.push('character-bios');
        } // if (this.page.type !== 'franchise') {


        editableComponents.push('featured-diptych'); // }
      } else {
        editableComponents.push('featured-diptych');
      }

      this.set('editableComponents', editableComponents);
    },

    async loadComponents(isFirst) {
      this.set('isLoadingComponents', true);
      const pageComponentTypesResponse = await this.dreamworksService.getMany('page-component-types');
      this.set('components', pageComponentTypesResponse);
      this.set('isLoadingComponents', false);
      Ember.run.scheduleOnce('afterRender', this, this.initSortable, isFirst);
    },

    initSortable(isFirst) {
      let justRemoved = false;
      const activeComponentsSortable = Sortable.create(document.getElementById('active-components'), {
        group: {
          name: 'components',
          pull: true,
          put: true
        },
        draggable: '.component',
        animation: 150,
        onSort: event => {
          if (event.from.getAttribute('id') === 'available-components') {
            return;
          }

          const {
            oldIndex,
            newIndex
          } = event;

          if (oldIndex === newIndex) {
            return false;
          } else if (justRemoved === true) {
            justRemoved = false; // Don't sort if just removed

            return false;
          }

          let newComponentsOrder = this.util.moveArrayItems(this.get('pageSaveData.components'), oldIndex, newIndex);
          newComponentsOrder = newComponentsOrder.map(component => component);
          this.set('pageSaveData.components', newComponentsOrder);
          this.onPageComponentsSorted();
        },
        onAdd: event => {
          const componentId = (0, _jquery.default)(event.item).data('id');
          const component = this.components.find(component => component.id === componentId);
          const resourceTypeOption = this.dreamworksService.getResourceTypeOption(this.dreamworksService.convertResourceType(this.resourceType)); // Set component data

          let componentData = Ember.Object.create();

          if (resourceTypeOption) {
            // Automatically set sectionProperty when adding certain components to a resource page
            if (component.key === 'media-bio' || component.key === 'standard-diptych' || component.key === 'business-partners' || component.key === 'media-grid' || component.key === 'games-carousel' || component.key === 'image-gallery' || component.key === 'poster-gallery' || component.key === 'social-callout') {
              componentData.set('sectionProperty', resourceTypeOption.sectionProperty);
            }
          }

          if (component.key === 'social-hub') {
            componentData.set('sectionProperty', resourceTypeOption.sectionProperty);
            componentData.set('moduleName', 'social-hub');
            componentData.set('sectionTitle', 'Social');
            componentData.set('isHome', this.page.slug === '/');
            componentData.set('id', this.resource.id);
          } // if (component.key === 'media-catalog') {
          //     componentData.set('sectionProperty', resourceTypeOption.sectionProperty);
          // }


          if (component.key === 'media-theater') {
            componentData.set('items', []);
          }

          if (component.key === 'poster-gallery') {
            componentData.set('sectionTitle', 'Posters');
          }

          if (component.key === 'media-bio') {
            componentData.set('sectionTitle', 'About');
            componentData.set('type', resourceTypeOption.value);
          }

          if (component.key === 'video-gallery') {
            componentData.set('sectionTitle', 'Videos');
            componentData.set('type', resourceTypeOption.value);
            componentData.set('moduleName', 'video-gallery');
          }

          if (component.key === 'home-ent-retailers') {
            componentData.set('sectionProperty', resourceTypeOption.sectionProperty);
            componentData.set('id', this.resource.id);
          }

          if (component.key === 'social-callout') {
            componentData.set('sectionProperty', resourceTypeOption.sectionProperty);
            componentData.set('sectionTitle', 'Social');
            componentData.set('id', this.resource.id);
          }

          if (component.key === 'home-ent-features') {
            componentData.set('sectionTitle', 'Product Features');
            componentData.set('sectionProperty', resourceTypeOption.sectionProperty);
            componentData.set('id', this.resource.id);
          }

          if (component.key === 'home-ent-offers') {
            // componentData.set('sectionTitle', 'Limited Time Offers');
            componentData.set('sectionProperty', resourceTypeOption.sectionProperty);
            componentData.set('id', this.resource.id);
          }

          if (component.key === 'ticket-search') {
            componentData.set('type', resourceTypeOption.value);
            componentData.set('moduleName', 'ticket-search');
            componentData.set('sectionTitle', 'Tickets');
            componentData.set('id', this.resource.id);
          }

          if (component.key === 'extra-media') {
            componentData.set('sectionProperty', resourceTypeOption.sectionProperty);
            componentData.set('moduleName', 'extra-media');
            componentData.set('id', this.resource.id);
          }

          if (component.key === 'character-bios') {
            componentData.set('sectionProperty', resourceTypeOption.sectionProperty);
            componentData.set('moduleName', 'character-bios');
            componentData.set('id', this.resource.id);
          }

          if (component.key === 'media-grid' || component.key === 'games-carousel') {
            componentData.set('id', this.page ? this.page.id : null);
          }

          if (!this.get('pageSaveData.components')) {
            this.set('pageSaveData.components', []);
          }

          this.get('pageSaveData.components').insertAt(event.newIndex, Ember.Object.create({
            key: component.key,
            data: componentData
          }));
        },
        onMove: event => {
          if (event.dragged && event.dragged.classList.contains('locked')) {
            return false;
          } else if (event.related) {
            return !event.related.classList.contains('locked');
          }
        },
        onRemove: event => {
          justRemoved = true;
          this.get('pageSaveData.components').removeAt(event.oldIndex);
        }
      });

      if (isFirst) {
        this.set('originalComponentsOrder', activeComponentsSortable.toArray());
      }

      this.set('activeComponentsSortable', activeComponentsSortable);
      Sortable.create(document.getElementById('available-components'), {
        group: {
          name: 'components',
          pull: 'clone',
          put: true
        },
        draggable: '.component',
        animation: 150
      });
    },

    resetEditingPageComponent() {
      this.setProperties({
        'editingPageComponent': null,
        'editingPageComponentIndex': null,
        'editingPageComponentName': null
      });
    },

    actions: {
      openPageComponentModal(pageComponent, pageComponentIndex) {
        let editingPageComponent = this.util.cloneObject(pageComponent);
        this.set('editingPageComponent', editingPageComponent);
        this.set('editingPageComponentIndex', pageComponentIndex);
        this.set('editingPageComponentName', `dreamworks/page-component-modals/${pageComponent.key}`);
        this.set('isPageComponentModalOpen', true);
      },

      onSavePageComponent(data) {
        const pageSaveDataComponent = this.get('pageSaveData.components')[this.editingPageComponentIndex];
        pageSaveDataComponent.set('data', data);
        this.resetEditingPageComponent();
      },

      onCancelEditPageComponent(backupData) {
        this.resetEditingPageComponent();
      }

    }
  });

  _exports.default = _default;
});