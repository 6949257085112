define("universal-cms/components/asset-uploader", ["exports", "ember-uploader", "universal-cms/config/environment", "jquery"], function (_exports, _emberUploader, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberUploader.default.FileField.extend({
    onFileToUploadChanged: Ember.observer('fileToUpload', function () {
      if (!this.fileToUpload) {
        (0, _jquery.default)(`#${this.elementId}`).val('');
      }
    }),
    filesDidChange: function (files) {
      this.set('fileToUpload', files[0]);
    }
  });

  _exports.default = _default;
});