define("universal-cms/components/retailer-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'retailer-editor',
    retailers: [],
    retailerToAdd: null,
    resourceRetailers: [],

    init() {
      this._super(...arguments);
    },

    addedVendorIds: Ember.computed('resourceRetailers.[]', function () {
      if (!this.resourceRetailers) {
        return [];
      }

      return this.resourceRetailers.map(retailer => retailer.retailer_id);
    }),
    nonAddedRetailers: Ember.computed('retailers.[]', 'addedVendorIds.[]', function () {
      return this.retailers.filter(retailer => !this.addedVendorIds.includes(retailer.id));
    }),
    formattedRetailers: Ember.computed('resourceRetailers.[]', 'retailers.[]', function () {
      if (!this.resourceRetailers) {
        return [];
      }

      return this.resourceRetailers.map(resourceRetailer => {
        if (typeof resourceRetailer.set !== 'function') {
          resourceRetailer = Ember.Object.create(resourceRetailer);
        }

        const retailerData = this.retailers.find(retailer => retailer.id === resourceRetailer.retailer_id);

        if (retailerData) {
          resourceRetailer.set('name', retailerData.name);
        }

        return resourceRetailer;
      });
    }),
    actions: {
      addRetailer() {
        if (!this.retailerToAdd) {
          return;
        }

        this.resourceRetailers.pushObject(Ember.Object.create({
          retailer_id: this.retailerToAdd.id,
          url: this.retailerToAdd.url,
          featured: this.retailerToAdd.featured
        }));
        this.set('retailerToAdd', null);
      },

      deleteRetailer(retailerToDelete) {
        this.set('resourceRetailers', this.resourceRetailers.filter(resourceRetailer => resourceRetailer.retailer_id !== retailerToDelete.retailer_id));
      },

      onRetailerUrlChange(retailer) {
        const resourceRetailer = this.resourceRetailers.find(resourceRetailer => resourceRetailer.retailer_id === retailer.retailer_id);

        if (!resourceRetailer) {
          console.warn('No retailerObject.');
          return;
        }

        resourceRetailer.set('url', retailer.url);
      },

      onReordered() {
        this.set('resourceRetailers', this.formattedRetailers);
      }

    }
  });

  _exports.default = _default;
});