define("universal-cms/controllers/people/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      updatePerson(person, data) {
        this.set('isSaving', true);
        this.movieService.replaceOne('people', person.id, {
          name: data.get('name')
        }).then(replacedPerson => {
          this.notificationService.show('Person saved', 'Person was successfully saved.');
          person.set('name', replacedPerson.get('name'));
          this.set('isSaving', false);
          return replacedPerson;
        });
      }

    }
  });

  _exports.default = _default;
});