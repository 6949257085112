define("universal-cms/controllers/website/home-page-promos/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    isAdding: false,
    isSaving: false,
    isDeleting: false,
    isLoading: false,
    newResource: null,

    init() {
      this._super(...arguments);

      this.set('newResource', {});
    },

    actions: {
      selectUpdate(target) {
        this.newResource.movie_promo_id = this.get("model.resource.id");
        this.newResource[target.name] = target.value;
      },

      createResource() {
        this.send('newResource', this.newResource);
      }

    },
    mappings: Ember.computed("model", function () {
      let map = {
        "promo_spot_type_id": {}
      };

      for (let spotType of this.get("model.spot_types")) {
        map.promo_spot_type_id[spotType.id] = spotType.name;
      }

      return map;
    })
  });

  _exports.default = _default;
});