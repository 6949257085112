define("universal-cms/services/dreamworks", ["exports", "universal-cms/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    auth: Ember.inject.service(),
    util: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),

    setUrl(url) {
      // if (environment.environment === 'production') {
      // url = 'https://dreamworksprod.films.apps.nbcuni.com'
      // }
      this.set('url', url);
    },

    setGabbysWebsiteUrl(url) {
      this.set('gabbysWebsiteUrl', url);
    },

    getUrl() {
      let endpoint = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      return this.url + (endpoint !== null ? (endpoint.substring(0, 1) !== '/' ? '/' : '') + endpoint : '');
    },

    getGabbysWebsiteUrl() {
      let endpoint = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      return this.gabbysWebsiteUrl + (endpoint !== null ? (endpoint.substring(0, 1) !== '/' ? '/' : '') + endpoint : '');
    },

    async getOne(resourceUrl, id) {
      let extraParams = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

      try {
        let url = this.getUrl(`api/${resourceUrl}/${id}`);
        url = url.replace(/www\.dreamworks\.com/, 'dreamworksprod.films.apps.nbcuni.com');
        url = url.replace(/www\./, '');

        if (extraParams) {
          const queryParams = new URLSearchParams(extraParams).toString();
          url += `?${queryParams}`;
        }

        const response = await axios.get(url, {
          auth: this.getAuthOptions(),
          headers: {
            'X-Auth': `Bearer ${this.auth.jwt}`
          }
        });

        if (typeof response.data.data !== 'object') {
          throw new Error('Could not parse response.');
        }

        return this.util.emberify(response.data.data);
      } catch (error) {
        if (error.response.status === 401) {
          throw new Error('Could not authorize DreamWorks API.');
        } else if (error.response.status === 404) {
          return null;
        }

        throw error;
      }
    },

    async getMany(resourceUrl, params) {
      try {
        let url = this.getUrl(`api/${resourceUrl}`);
        url = url.replace(/www\.dreamworks\.com/, 'dreamworksprod.films.apps.nbcuni.com');
        url = url.replace(/www\./, '');
        const response = await axios.get(url, {
          params: params,
          auth: this.getAuthOptions(),
          headers: {
            'X-Auth': `Bearer ${this.auth.jwt}`
          }
        });

        if (typeof response.data.data !== 'object') {
          throw new Error('Could not parse response.');
        }

        return this.util.emberify(typeof params === 'object' && params.limit ? response.data : response.data.data);
      } catch (error) {
        if (error.response.status === 401) {
          throw new Error('Could not authorize DreamWorks API.');
        } else {
          throw error;
        }
      }
    },

    async getFirst(resourceUrl, params) {
      const items = await this.getMany(resourceUrl, params);
      return items && items[0] ? items[0] : null;
    },

    async createOne(resourceUrl, data) {
      try {
        let url = this.getUrl(`api/${resourceUrl}`);
        url = url.replace(/www\.dreamworks\.com/, 'dreamworksprod.films.apps.nbcuni.com');
        url = url.replace(/www\./, '');
        const response = await axios.post(url, data, {
          auth: this.getAuthOptions(),
          headers: {
            'X-Auth': `Bearer ${this.auth.jwt}`
          }
        });

        if (typeof response.data.data !== 'object') {
          throw new Error('Could not create resource.');
        }

        return this.util.emberify(response.data.data);
      } catch (error) {
        if (error.response.status === 401) {
          throw new Error('Could not authorize DreamWorks API.');
        } else {
          throw typeof error.response.data.validation_errors === 'object' ? this.util.toErrorString(error.response.data.validation_errors) : error.message;
        }
      }
    },

    async updateOne(resourceUrl, id, data) {
      try {
        let url = this.getUrl(`api/${resourceUrl}/${id}`);
        url = url.replace(/www\.dreamworks\.com/, 'dreamworksprod.films.apps.nbcuni.com');
        url = url.replace(/www\./, '');
        const response = await axios.put(url, data, {
          auth: this.getAuthOptions(),
          headers: {
            'X-Auth': `Bearer ${this.auth.jwt}`
          }
        });

        if (typeof response.data.data !== 'object') {
          throw new Error('Could not update resource.');
        }

        return this.util.emberify(response.data.data);
      } catch (error) {
        console.log(error);

        if (error.response && error.response.status === 401) {
          throw new Error('Could not authorize DreamWorks API.');
        } else {
          throw typeof error.response.data.validation_errors === 'object' ? this.util.toErrorString(error.response.data.validation_errors) : error.message;
        }
      }
    },

    async deleteOne(resourceUrl, id) {
      try {
        let url = this.getUrl(`api/${resourceUrl}/${id}`);
        url = url.replace(/www\.dreamworks\.com/, 'dreamworksprod.films.apps.nbcuni.com');
        url = url.replace(/www\./, '');
        await axios.delete(url, {
          auth: this.getAuthOptions(),
          headers: {
            'X-Auth': `Bearer ${this.auth.jwt}`
          }
        });
      } catch (error) {
        if (error.response.status === 401) {
          throw new Error('Could not authorize DreamWorks API.');
        } else {
          throw error;
        }
      }
    },

    async get(endpoint) {
      let params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      try {
        if (!endpoint.startsWith('/')) {
          endpoint = `/${endpoint}`;
        }

        let url = this.getUrl(endpoint);
        url = url.replace(/dreamworks\.com/, 'dreamworksprod.films.apps.nbcuni.com');
        url = url.replace(/www\./, '');
        const response = await axios.get(url, {
          params: params,
          auth: this.getAuthOptions(),
          headers: {
            'X-Auth': `Bearer ${this.auth.jwt}`
          }
        });
        return this.util.emberify(response);
      } catch (error) {
        if (error.response.status === 401) {
          throw new Error('Could not authorize DreamWorks API.');
        } else {
          throw error;
        }
      }
    },

    async post(endpoint) {
      let data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      try {
        if (!endpoint.startsWith('/')) {
          endpoint = `/${endpoint}`;
        }

        return await axios.post(this.getUrl(endpoint), data, {
          auth: this.getAuthOptions(),
          headers: {
            'X-Auth': `Bearer ${this.auth.jwt}`
          }
        });
      } catch (error) {
        if (error.response.status === 401) {
          throw new Error('Could not authorize DreamWorks API.');
        } else {
          throw error;
        }
      }
    },

    async put(endpoint) {
      let data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      try {
        if (!endpoint.startsWith('/')) {
          endpoint = `/${endpoint}`;
        }

        const putResponse = await axios.put(this.getUrl(endpoint), data, {
          auth: this.getAuthOptions(),
          headers: {
            'X-Auth': `Bearer ${this.auth.jwt}`
          }
        });
        return putResponse;
      } catch (error) {
        if (error.response.status === 401) {
          throw new Error('Could not authorize DreamWorks API.');
        } else {
          throw error;
        }
      }
    },

    async uploadFile(endpoint, data) {
      try {
        const url = this.getUrl(`api/${endpoint}`);
        let formData = new FormData();

        for (let key in data) {
          if (typeof data[key] === 'undefined') {
            continue;
          }

          formData.append(key, data[key]);
        }

        const response = await axios.post(url, formData, {
          auth: this.getAuthOptions(),
          headers: {
            'X-Auth': `Bearer ${this.auth.jwt}`
          }
        });

        if (typeof response.data.data !== 'object') {
          throw new Error('Could not upload file.');
        }

        return this.util.emberify(response.data.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          throw new Error('Could not authorize DreamWorks API.');
        } else {
          throw error.response && typeof error.response.data.validation_errors === 'object' ? {
            'message': this.util.toErrorString(error.response.data.validation_errors)
          } : error.message;
        }
      }
    },

    async getOptions(resourceUrl) {
      try {
        let url = this.getUrl(`api/${resourceUrl}`);
        url = url.replace(/www\.dreamworks\.com/, 'dreamworksprod.films.apps.nbcuni.com');
        url = url.replace(/www\./, '');
        const response = await axios.options(url);

        if (typeof response.data.data !== 'object') {
          throw new Error('Could not get options.');
        }

        return this.util.emberify(response.data.data);
      } catch (error) {
        throw error.response && error.response.data && typeof error.response.data.validation_errors === 'object' ? this.util.toErrorString(error.response.data.validation_errors) : error.message;
      }
    },

    getAuthOptions() {
      if (_environment.default.DW_AUTH_USERNAME) {
        return {
          username: _environment.default.DW_AUTH_USERNAME,
          password: _environment.default.DW_AUTH_PASSWORD
        };
      }

      return null;
    },

    resourceTypeToTitle(resourceType) {
      switch (resourceType) {
        case 'franchise':
          return 'Franchise';

        case 'movie':
          return 'Movie';

        case 'tv_show':
          return 'TV Show';

        case 'game':
          return 'Game';

        case 'gabbys_website_character':
          return 'Character';

        case 'gabbys_website_event':
          return 'Event';

        case 'gabbys_website_episode':
          return 'Episode';

        case 'gabbys_website_partner':
          return 'Partner';

        case 'page':
          return 'Page';

        default:
          return resourceType;
      }
    },

    resourceTypeToEndpoint(resourceType) {
      switch (resourceType) {
        case 'franchise':
          return 'franchises';

        case 'movie':
          return 'movies';

        case 'tv_show':
          return 'tv-shows';

        case 'game':
          return 'games';

        case 'gabbys_website_character':
          return 'gabbys-website-characters';

        case 'gabbys_website_event':
          return 'gabbys-website-events';

        case 'gabbys_website_episode':
          return 'gabbys-website-episodes';

        case 'gabbys_website_partner':
          return 'gabbys-website-partners';

        case 'page':
          return 'pages';

        default:
          return resourceType;
      }
    },

    getAllResourceTypeOptions() {
      return [{
        value: 'franchise',
        text: 'Franchise',
        apiEndpoint: 'franchises',
        sectionProperty: 'Franchise'
      }, {
        value: 'movie',
        text: 'Movie',
        apiEndpoint: 'movies',
        sectionProperty: 'Movie'
      }, {
        value: 'tv_show',
        text: 'TV Show',
        apiEndpoint: 'tv-shows',
        sectionProperty: 'TvShow'
      }, {
        value: 'game',
        text: 'Game',
        apiEndpoint: 'games',
        sectionProperty: 'Game'
      }, {
        value: 'gabbys_website_character',
        text: 'Character',
        apiEndpoint: 'gabbys-website-characters',
        sectionProperty: 'GabbysWebsiteCharacter'
      }, {
        value: 'gabbys_website_event',
        text: 'Event',
        apiEndpoint: 'gabbys-website-events',
        sectionProperty: 'GabbysWebsiteEvent'
      }, {
        value: 'gabbys_website_episode',
        text: 'Episode',
        apiEndpoint: 'gabbys-website-episodes',
        sectionProperty: 'GabbysWebsiteEpisode'
      }, {
        value: 'gabbys_website_partner',
        text: 'Partner',
        apiEndpoint: 'gabbys-website-partners',
        sectionProperty: 'GabbysWebsitePartner'
      }, {
        value: 'page',
        text: 'Page',
        apiEndpoint: 'pages',
        sectionProperty: 'Page'
      }];
    },

    getResourceTypeOptions() {
      let pageType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      // TODO: Use from getAllResourceTypeOptions()
      let resourceTypeOptions;

      switch (pageType) {
        case 'default':
          resourceTypeOptions = [{
            value: 'movie',
            text: 'Movie',
            apiEndpoint: 'movies',
            sectionProperty: 'Movie'
          }, {
            value: 'tv_show',
            text: 'TV Show',
            apiEndpoint: 'tv-shows',
            sectionProperty: 'TvShow'
          }, {
            value: 'game',
            text: 'Game',
            apiEndpoint: 'games',
            sectionProperty: 'Game'
          }];
          break;

        case 'movie':
          resourceTypeOptions = [{
            value: 'movie',
            text: 'Movie',
            apiEndpoint: 'movies',
            sectionProperty: 'Movie'
          }];
          break;

        case 'tv_show':
          resourceTypeOptions = [{
            value: 'tv_show',
            text: 'TV Show',
            apiEndpoint: 'tv-shows',
            sectionProperty: 'TvShow'
          }];
          break;

        case 'franchise':
          resourceTypeOptions = [{
            value: 'franchise',
            text: 'Franchise',
            apiEndpoint: 'franchises',
            sectionProperty: 'Franchise'
          }];
          break;

        case 'game':
          resourceTypeOptions = [{
            value: 'game',
            text: 'Game',
            apiEndpoint: 'games',
            sectionProperty: 'Game'
          }];
          break;

        case 'gabbys_website_default':
          resourceTypeOptions = [{
            value: 'page',
            text: 'Page',
            apiEndpoint: 'pages',
            sectionProperty: 'Page'
          }, {
            value: 'gabbys_website_character',
            text: 'Character',
            apiEndpoint: 'gabbys-website-characters',
            sectionProperty: 'GabbysWebsiteCharacter'
          }, {
            value: 'gabbys_website_event',
            text: 'Event',
            apiEndpoint: 'gabbys-website-events',
            sectionProperty: 'GabbysWebsiteEvent'
          }, {
            value: 'gabbys_website_episode',
            text: 'Episode',
            apiEndpoint: 'gabbys-website-episodes',
            sectionProperty: 'GabbysWebsiteEpisode'
          }, {
            value: 'gabbys_website_partner',
            text: 'Partner',
            apiEndpoint: 'gabbys-website-partners',
            sectionProperty: 'GabbysWebsitePartner'
          }];
          break;

        case 'gabbys_website_character':
          resourceTypeOptions = [{
            value: 'gabbys_website_character',
            text: 'Character',
            apiEndpoint: 'gabbys-website-characters',
            sectionProperty: 'GabbysWebsiteCharacter'
          }];
          break;

        case 'gabbys_website_event':
          resourceTypeOptions = [{
            value: 'gabbys_website_event',
            text: 'Event',
            apiEndpoint: 'gabbys-website-events',
            sectionProperty: 'GabbysWebsiteEvent'
          }];
          break;

        case 'gabbys_website_episode':
          resourceTypeOptions = [{
            value: 'gabbys_website_episode',
            text: 'Episode',
            apiEndpoint: 'gabbys-website-episodes',
            sectionProperty: 'GabbysWebsiteEpisode'
          }];
          break;

        case 'gabbys_website_partner':
          resourceTypeOptions = [{
            value: 'gabbys_website_partner',
            text: 'Partner',
            apiEndpoint: 'gabbys-website-partners',
            sectionProperty: 'GabbysWebsitePartner'
          }];
          break;

        default:
          console.warn(`Non-implemented getResourceTypeOptions() pageType ${pageType}.`);
      }

      return resourceTypeOptions;
    },

    resourceTypeToSectionProperty(resourceType) {
      switch (resourceType) {
        case 'movie':
          return 'Movie';

        case 'tv_show':
          return 'TvShow';

        case 'franchise':
          return 'Franchise';

        case 'game':
          return 'Game';

        case 'gabbys_website_character':
          return 'GabbysWebsiteCharacter';

        case 'gabbys_website_event':
          return 'GabbysWebsiteEvent';

        case 'gabbys_website_episode':
          return 'GabbysWebsiteEpisode';

        case 'gabbys_website_partner':
          return 'GabbysWebsitePartner';

        default:
          console.warn(`Non-implemented resourceTypeToSectionProperty() resourceType ${resourceType}.`);
      }
    },

    sectionPropertyToResourceType(sectionProperty) {
      switch (sectionProperty) {
        case 'Movie':
          return 'movie';

        case 'TvShow':
          return 'tv_show';

        case 'Franchise':
          return 'franchise';

        case 'Game':
          return 'game';

        case 'GabbysWebsiteCharacter':
          return 'gabbys_website_character';

        case 'GabbysWebsiteEvent':
          return 'gabbys_website_event';

        case 'GabbysWebsiteEpisode':
          return 'gabbys_website_episode';

        case 'GabbysWebsitePartner':
          return 'gabbys_website_partner';

        default:
          console.warn(`Non-implemented sectionPropertyToResourceType() resourceType ${sectionProperty}.`);
      }
    },

    resourceTypeToText(resourceType) {
      switch (resourceType) {
        case 'movie':
          return {
            singular: 'Movie',
            plural: 'Movies'
          };

        case 'tv_show':
          return {
            singular: 'TV Show',
            plural: 'TV Shows'
          };

        case 'franchise':
          return {
            singular: 'Franchise',
            plural: 'Franchises'
          };

        case 'game':
          return {
            singular: 'Game',
            plural: 'Games'
          };

        case 'gabbys_website_character':
          return {
            singular: 'Character',
            plural: 'Characters'
          };

        case 'gabbys_website_event':
          return {
            singular: 'Event',
            plural: 'Events'
          };

        case 'gabbys_website_episode':
          return {
            singular: 'Episode',
            plural: 'Episodes'
          };

        case 'gabbys_website_partner':
          return {
            singular: 'Partner',
            plural: 'Partners'
          };

        default:
          return {
            singular: 'Resource',
            plural: 'Resources'
          };
      }
    },

    getResourceTypeOption(value) {
      return this.getAllResourceTypeOptions().find(resourceTypeOption => resourceTypeOption.value === value);
    },

    getResourceTypeOptionFromSectionProperty(sectionProperty) {
      return this.getAllResourceTypeOptions().find(resourceTypeOption => resourceTypeOption.sectionProperty === sectionProperty);
    },

    convertResourceType(resourceType) {
      switch (resourceType) {
        case 'franchises':
          return 'franchise';

        case 'movies':
          return 'movie';

        case 'tv-shows':
          return 'tv_show';

        case 'games':
          return 'game';

        case 'gabbys-website-characters':
          return 'gabbys_website_character';

        case 'gabbys-website-events':
          return 'gabbys_website_event';

        case 'gabbys-website-episodes':
          return 'gabbys_website_episode';

        case 'gabbys-website-partners':
          return 'gabbys_website_partner';

        default:
          return null;
      }
    },

    clearAkamaiCache() {
      if (_environment.default.environment !== 'production') {
        return;
      }

      $.ajax({
        'method': 'GET',
        'url': `${this.url}/clear-akamai?nocache=${Math.floor(Math.random() * 99999)}`,
        'contentType': 'application/json'
      });
    },

    clearCloudflareCache() {
      if (_environment.default.environment !== 'production') {
        return;
      }

      $.ajax({
        'method': 'GET',
        'url': `${this.url}/clear-cloudflare?nocache=${Math.floor(Math.random() * 99999)}`,
        'contentType': 'application/json'
      });
    }

  });

  _exports.default = _default;
});