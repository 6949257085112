define("universal-cms/controllers/universal-brand/pages/edit-featured-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    error: false,
    heading: Ember.computed('isNew', 'model.carouselFeatureItem.title', function () {
      return this.isNew === true ? 'Add Featured Banner' : this.get('model.carouselFeatureItem.title');
    }),
    isSaving: false,
    saveButtonText: Ember.computed('isNew', 'isSaving', function () {
      if (this.isNew === true) {
        return this.isSaving === true ? 'Adding...' : 'Add';
      } else {
        return this.isSaving === true ? 'Saving...' : 'Save';
      }
    }),
    actions: {
      save() {
        this.set('isSaving', true);
        const carouselFeatureItem = this.get('model.carouselFeatureItem');
        let saveData = carouselFeatureItem.getProperties('partnership', 'title', 'button_text', 'desktop_image_id', 'mobile_image_id', 'mp4_video_id');
        saveData.show_gradient_overlay = carouselFeatureItem.get('show_gradient_overlay') ? 1 : 0;
        saveData.show_text = carouselFeatureItem.get('show_text') ? 1 : 0;
        saveData.show_button = carouselFeatureItem.get('show_button') ? 1 : 0;

        if (this.isNew === true && carouselFeatureItem.get('buttonLinkType') === 'internal') {
          saveData.button_link = `/${this.get('selectedPage.slug')}`;
        } else {
          saveData.button_link = carouselFeatureItem.get('button_link');
        }

        saveData.page_version_id = this.pageVersionId;

        if (this.isNew) {
          this.universalBrandService.createOne('carousel-feature-items', saveData).then(createResponse => {
            if (typeof createResponse.errors === 'undefined') {
              this.notificationService.show('Featured Banner Added', `<strong>${createResponse.get('title')}</strong> has been added.`);
              this.target.transitionTo(this.routeName, createResponse.get('carousel_feature_item_id'));
            } else {
              this.notificationService.show('Error', this.universalBrandService.formatErrorResponse(createResponse.errors));
            }
          }, createError => {
            this.notificationService.show('Error', 'Could not add.');
          }).finally(() => {
            this.set('isSaving', false);
          });
        } else {
          this.universalBrandService.updateOne('carousel-feature-items', carouselFeatureItem.get('carousel_feature_item_id'), saveData, true).then(updateResponse => {
            if (typeof updateResponse.errors === 'undefined') {
              this.notificationService.show('Featured Banner Updated', `<strong>${updateResponse.get('title')}</strong> has been updated.`);
              this.target.transitionTo(this.routeName, updateResponse.get('carousel_feature_item_id'));
            } else {
              this.notificationService.show('Error', this.universalBrandService.formatErrorResponse(updateResponse.errors));
            }
          }, updateError => {
            this.notificationService.show('Error', 'Could not edit.');
          }).finally(() => {
            this.set('isSaving', false);
          });
        }
      },

      setInternalButtonPage(page) {
        this.set('selectedPage', page);
      }

    }
  });

  _exports.default = _default;
});