define("universal-cms/controllers/asset-types/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      updateAssetType(data) {
        // Get group and update asset with that
        // let assetGroups = this.modelFor('assetTypes').assetGroups;
        //
        // for (let assetGroupIndex = 0, numAssetGroups = assetGroups.length; assetGroupIndex < numAssetGroups; assetGroupIndex++) {
        //     if (assetGroups[assetGroupIndex].id === data.get('assetGroupId')) {
        //         assetType.set('group', assetGroups[assetGroupIndex]);
        //
        //         break;
        //     }
        // }
        return this.movieService.replaceOne('asset-types', data.assetType.id, {
          asset_group_id: data.data.assetGroupId,
          name: data.data.name,
          description: data.data.description,
          allow_many: data.data.allowMany,
          type: data.data.type
        }).then(replacedAssetType => {
          this.notificationService.show('Asset type saved', `${replacedAssetType.name} has been saved.`);
          data.assetType.setProperties({
            'name': replacedAssetType.get('name'),
            'description': replacedAssetType.get('description')
          }); // let assetTypeModels = this.modelFor('asset-types');
          //
          // let assetTypeToUpdate = assetTypeModels.assetTypes.find((element, index) => {
          //     return (element.id === assetType.id);
          // });
          //
          // assetTypeToUpdate.set('asset_group_id', replacedAssetType.get('asset_group_id'));
          // assetTypeToUpdate.set('name', replacedAssetType.get('name'));
          // assetTypeToUpdate.set('description', replacedAssetType.get('description'));
          // assetTypeToUpdate.set('allow_many', replacedAssetType.get('allow_many'));
          // assetTypeToUpdate.set('type', replacedAssetType.get('type'));
          //
          // let assetGroups = assetTypeModels.assetGroups;
          //
          // for (let assetGroupIndex = 0, numassetGroups = assetGroups.length; assetGroupIndex < numassetGroups; assetGroupIndex++) {
          //     if (assetGroups[assetGroupIndex].id === assetTypeToUpdate.group) {
          //         assetTypeToUpdate.set('groupName', assetGroups[assetGroupIndex].name);
          //
          //         break;
          //     }
          // }

          return replacedAssetType;
        });
      }

    }
  });

  _exports.default = _default;
});