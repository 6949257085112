define("universal-cms/components/dreamworks/asset-img", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagElement: 'div',
    assetId: undefined,
    height: 32,
    isLoading: false,
    loadError: null,

    // src: computed('assetId', function() {
    //     return htmlSafe(`${this.dreamworksService.getUrl()}/api/assets/${this.assetId}/render`);
    // }),
    async init() {
      this._super(...arguments);

      await this.loadAsset();
    },

    async loadAsset() {
      if (!this.assetId) {
        console.warn('No assetId provided to asset-img component');
        return;
      }

      try {
        this.set('isLoading', true);
        this.set('loadError', null);
        const loadResponse = await this.dreamworksService.getOne('assets', this.assetId);

        if (!loadResponse.id) {
          throw new Error();
        }

        this.set('src', loadResponse.path);
      } catch (loadError) {
        this.set('loadError', loadError);
      } finally {
        this.set('isLoading', false);
      }
    }

  });

  _exports.default = _default;
});