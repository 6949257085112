define("universal-cms/controllers/dashboard/user-groups/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      updateGroup(data) {
        return this.auth.replaceOne('groups', data.group.id, {
          name: data.data.name,
          services: data.data.services
        }).then(replacedGroup => {
          let newData = {
            name: replacedGroup.get('name')
          };
          data.group.setProperties(newData);
          let groupInList = this.get('model.groups.groups').find(group => {
            return group.get('id') === replacedGroup.get('id');
          });
          groupInList.setProperties(newData);
          this.notificationService.show('User group saved', `<strong>${replacedGroup.get('name')}</strong> was successfully saved.`);
          return replacedGroup;
        }, error => {
          this.notificationService.show('Error', 'Could not save user group.');
        });
      }

    }
  });

  _exports.default = _default;
});