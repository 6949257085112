define("universal-cms/controllers/dreamworks/events/edit", ["exports", "universal-cms/controllers/dreamworks/resource-edit"], function (_exports, _resourceEdit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resourceEdit.default.extend({
    resourceType: 'events',
    text: {
      singular: 'Event',
      plural: 'Events'
    }
  });

  _exports.default = _default;
});