define("universal-cms/components/pxl-youtube-video-form-field", ["exports", "universal-cms/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    label: null,
    value: '',
    isPlaylist: false,
    preUrl: Ember.computed('isPlaylist', function () {
      let url = 'https://www.youtube.com/';

      if (this.isPlaylist) {
        url += 'playlist?list=';
      } else {
        url += 'watch?v=';
      }

      return url;
    }),
    openButtonDisabled: Ember.computed('value', function () {
      return !this.value;
    }),

    didInsertElement() {
      const $inputField = (0, _jquery.default)(this.element.querySelector('input[type="text"]'));
      $inputField.on('paste', e => {
        const value = e.originalEvent.clipboardData.getData('Text');
        let regex;

        if (this.isPlaylist) {
          regex = new RegExp(/https:\/\/www\.youtube\.com\/playlist\?list=(.*)/);
        } else {
          regex = new RegExp(/https:\/\/www\.youtube\.com\/watch\?v=(.*)/);
        }

        if (regex.test(value)) {
          var matches = regex.exec(value);

          if (matches.length > 1) {
            e.preventDefault();
            const youtubeVideoId = matches[1];
            this.set('value', youtubeVideoId);
          }
        }
      });
    },

    actions: {
      openVideo() {
        window.open(`${this.preUrl}${this.value}`);
      },

      onChange(e) {
        const newValue = typeof e === 'object' ? e.target.value : e;
        this.set('value', newValue);
      }

    }
  });

  _exports.default = _default;
});