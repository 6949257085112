define("universal-cms/components/service-resource-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      updateResource(resource, showNotification) {
        // forward action to resource controller
        this.updateResource(resource, showNotification);
      },

      newResource(resource) {
        // forward action to resource controller
        this.newResource(resource);
      },

      deleteResource(resource) {
        if (!confirm('Are you sure you want to delete resource?')) {
          return;
        } // forward action to resource controller


        this.deleteResource(resource);
      }

    },
    fields: Ember.computed('options', function () {
      let resourceFields = this.get('options.resource_fields');
      let shouldEditInline = true;
      let fields = [];

      for (let field of resourceFields) {
        shouldEditInline = shouldEditInline && field.type !== "image" && field.type !== "relationship" && field.type !== "textarea";

        if (field.name === "position" || field.name === "title" || field.name === "name" || field.name === 'date') {
          fields.push(field);
        }
      }

      shouldEditInline = this.shouldEditInline !== undefined ? this.shouldEditInline : shouldEditInline;
      this.set("shouldEditInline", shouldEditInline);
      return shouldEditInline ? resourceFields : fields;
    })
  });

  _exports.default = _default;
});