define("universal-cms/routes/dreamworks/resource-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    async model(params, transition) {
      const controller = this.controllerFor(this.routeName);
      const type = controller.type || 'dreamworks';
      const resourceType = controller.resourceType;
      const pageType = controller.pageType;
      const isGabbys = transition.to.name.startsWith('gabbys-website');
      let selectedLanguage = null;
      let resourceOptions = null;

      if (isGabbys) {
        // TODO: Should technically do this for DreamWorks too but not necessary until it has multiple languages
        const preferredLanguageLocaleCode = localStorage.getItem('language_locale_code');
        const gabbysWebsiteOptions = await this.dreamworksService.getOptions('gabbys-website');
        resourceOptions = gabbysWebsiteOptions;

        if (gabbysWebsiteOptions.languages && gabbysWebsiteOptions.languages.length > 0) {
          const languages = gabbysWebsiteOptions.languages || [];

          if (this.auth.languageLocaleCode) {
            // Set selected language based on cookie
            selectedLanguage = languages.find(language => language.locale_code === this.auth.languageLocaleCode);
          } else if (preferredLanguageLocaleCode) {
            selectedLanguage = languages.find(language => language.locale_code === preferredLanguageLocaleCode);

            if (!selectedLanguage) {
              // Tried to get language from localStorage but it was not found, remove it from localSTorage
              localStorage.removeItem('language_locale_code');
            }
          }

          if (!selectedLanguage) {
            if (languages && languages.length > 0) {
              if (transition.to.queryParams.lang) {
                selectedLanguage = languages.find(language => language.locale_code === transition.to.queryParams.lang);
              }

              if (!selectedLanguage) {
                selectedLanguage = languages[0];
              }
            }
          }
        }
      }

      let queryParams = {};

      if (pageType) {
        queryParams.type = pageType;
      } else if (resourceType === 'pages') {
        queryParams.type = 'default';
      }

      if (selectedLanguage) {
        queryParams.language_id = selectedLanguage.id;
      }

      if (this.sortBy !== null) {
        queryParams['sort-by'] = this.sortBy;
      }

      if (this.sortOrder !== null) {
        queryParams['sort-order'] = this.sortOrder;
      }

      let resources = await this.dreamworksService.getMany(this.resourceUrl || resourceType, queryParams);

      if (resourceType === 'pages') {
        resources = resources.filter(resource => resource.slug !== '/style-guide');
      }

      return Ember.RSVP.hash({
        resourceOptions,
        selectedLanguage,
        resources: resources.map(resource => {
          if (resourceType === 'pages') {
            if (['/', '/movies', '/shows', '/search', '/terms-of-use', '/privacy-policy'].includes(resource.slug)) {
              resource.set('isNonDeletable', true);
            } // if (resource.slug === '/search') {
            //     resource.set('isNonEditable', true);
            // }

          }

          resource.set('edit_route', `${type}.${resourceType}.edit`);
          return resource;
        })
      });
    }

  });

  _exports.default = _default;
});