define("universal-cms/controllers/universal-studios/playlists/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    error: false,
    actions: {
      updatePlaylistsOrder(orderedPlaylists) {
        let newOrder = [];

        for (let playlist of orderedPlaylists) {
          newOrder.push(playlist.get('id'));
        }

        let saveData = {
          playlists: newOrder
        };
        this.universalStudiosService.updateOne(`playlists`, 'update-order', saveData).then(() => {
          this.notificationService.show('Saved', `Playlist has been moved.`);
        }).catch(() => {
          this.notificationService.show('Error', `Could not save playlists order.`);
        });
      },

      deletePlaylist(playlist) {
        playlist.set('isDeleting', true);
        return this.universalStudiosService.deleteOne('playlists', playlist.get('id')).then(() => {
          this.notificationService.show('Playlist deleted', `<strong>${playlist.get('title')}</strong> was successfully deleted.`);
          this.model.removeObject(playlist);
        }, () => {
          playlist.set('isDeleting', false);
          this.notificationService.show('Error', `Could not delete playlist <strong>${playlist.get('title')}</strong>.`);
        });
      }

    }
  });

  _exports.default = _default;
});