define("universal-cms/ember-quill/tests/templates/components/pxl-asset-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fy/imEPa",
    "block": "{\"symbols\":[],\"statements\":[[1,0,0,0,[27,[26,0,\"AppendSingleId\"],[]]],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"buttonText\"]}",
    "meta": {
      "moduleName": "universal-cms/ember-quill/tests/templates/components/pxl-asset-picker.hbs"
    }
  });

  _exports.default = _default;
});