define("universal-cms/controllers/dashboard/users/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      updateUser(data) {
        return this.auth.replaceOne('users', data.user.id, {
          username: data.data.username,
          password: data.data.password,
          changePassword: data.data.changePassword ? 1 : 0,
          group_id: data.data.group_id,
          language_ids: data.data.language_ids || []
        }).then(replacedUser => {
          let newData = {
            username: replacedUser.get('username'),
            password: replacedUser.get('password')
          };
          data.user.setProperties(newData);
          let userInList = this.get('model.users').find(user => {
            return user.get('id') === replacedUser.get('id');
          });
          userInList.setProperties(newData);
          this.notificationService.show('User saved', `<strong>${replacedUser.username}</strong> was successfully saved.`);
          return replacedUser;
        }, error => {
          this.notificationService.show('Error', 'Could not save username.');
        });
      }

    }
  });

  _exports.default = _default;
});