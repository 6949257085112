define("universal-cms/controllers/dashboard/user-groups/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      createGroup(data) {
        return this.auth.createOne('groups', {
          name: data.data.name
        }).then(createdGroup => {
          this.get('model.groups').pushObject(createdGroup);
          this.notificationService.show('User group added', `<strong>${createdGroup.get('name')}</strong> was successfully added.`);
          return createdGroup;
        }, error => {
          this.notificationService.show('Error', 'Could not add user group.');
        });
      },

      deleteGroup(group) {
        group.set('isDeleting', true);
        this.auth.deleteOne('groups', group.get('id')).then(() => {
          this.get('model.groups').removeObject(group);
          group.set('isDeleting', false);
          this.notificationService.show('User group deleted', `<strong>${group.get('name')}</strong> was successfully deleted.`);
        }, error => {
          this.notificationService.show('Error', 'Could not delete user group.');
        });
      }

    }
  });

  _exports.default = _default;
});