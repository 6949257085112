define("universal-cms/ember-quill/tests/templates/universal-studios/banner-headers/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KKDqVRz1",
    "block": "{\"symbols\":[],\"statements\":[[9,\"h1\",true],[12,\"class\",\"page-header\",null],[10],[1,1,0,0,\"\\n    Banner Headers\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[7,\"link-to\",[[23,\"class\",\"btn btn-xs btn-primary\",null]],[[\"@route\",\"@model\"],[\"universalStudios.bannerHeaders.edit\",\"add\"]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"Add Banner Header\"]],\"parameters\":[]}]]],[1,1,0,0,\"\\n\\n\"],[5,[27,[26,7,\"BlockHead\"],[]],[[27,[26,6,\"Expression\"],[]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"    \"],[7,\"models-table\",[],[[\"@data\",\"@columns\",\"@showColumnsDropdown\",\"@useFilteringByColumns\",\"@multipleColumnsSorting\",\"@filteringIgnoreCase\",\"@customClasses\",\"@deleteBannerHeader\",\"@columnComponents\"],[[27,[26,0,\"AppendSingleId\"],[]],[27,[26,1,\"AppendSingleId\"],[]],false,false,false,true,[27,[26,2,\"AppendSingleId\"],[]],[31,499,6,[27,[26,3,\"CallHead\"],[]],[[27,[24,0],[]],\"deleteBannerHeader\"],null],[31,558,4,[27,[26,5,\"CallHead\"],[]],null,[[\"manage\"],[[31,600,9,[27,[26,4,\"CallHead\"],[]],[\"models-table/universal-studios/banner-header-actions\"],[[\"deleteBannerHeader\"],[[31,701,6,[27,[26,3,\"CallHead\"],[]],[[27,[24,0],[]],\"deleteBannerHeader\"],null]]]]]]]]],null],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"    \"],[9,\"div\",true],[12,\"class\",\"alert alert-danger alert-table-load-error\",null],[10],[1,1,0,0,\"\\n        Could not load banner headers.\\n    \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"tableColumns\",\"tableClasses\",\"action\",\"component\",\"hash\",\"error\",\"unless\"]}",
    "meta": {
      "moduleName": "universal-cms/ember-quill/tests/templates/universal-studios/banner-headers/list.hbs"
    }
  });

  _exports.default = _default;
});