define("universal-cms/routes/dashboard/user-groups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return Ember.RSVP.hash({
        groups: this.auth.getAll('groups'),
        services: this.auth.getAll('services')
      });
    }

  });

  _exports.default = _default;
});