define("universal-cms/routes/movies/edit/options", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function (controller, model) {
      this._super(controller, model);

      controller.set('isLoading', true);
      this.movieService.getAll('movies/' + controller.movie.id + '/options').then(addedOptions => {
        this.movieService.getAll('option-groups').then(optionGroups => {
          let optionGroupPromises = [];
          optionGroups.forEach((optionGroup, optionGroupIndex) => {
            optionGroupPromises.push(new Ember.RSVP.Promise((resolve, reject) => {
              this.movieService.getAll('option-types', {
                'group': optionGroup.id
              }).then(optionTypes => {
                // Set type variable
                optionTypes.forEach((optionType, optionTypeIndex) => {
                  if (optionType.type === 'text') {
                    optionType.set('isText', true);
                  } else if (optionType.type === 'long_text') {
                    optionType.set('isLongText', true);
                  } else if (optionType.type === 'date') {
                    optionType.set('isDate', true);
                  } else if (optionType.type === 'numeric') {
                    optionType.set('isNumeric', true);
                  }
                }); // Assign movie option value to field

                addedOptions.forEach(option => {
                  optionTypes.forEach((optionType, optionTypeIndex) => {
                    if (optionType.id === option.option_type) {
                      // If date field; convert format UNIX timestamp
                      if (optionType.type === 'date') {
                        option.set('value', _moment.default.utc(option.value, 'X').format('YYYY-MM-DD'));
                      }

                      optionTypes[optionTypeIndex].set('optionId', option.id);
                      optionTypes[optionTypeIndex].set('value', option.value);
                    }
                  });
                }); // Set default selected tab

                optionGroup.set('tabSelected', optionGroupIndex === 0 ? 'active' : '');
                optionGroup.set('optionTypes', optionTypes);
                resolve(optionGroup);
              }, error => {
                reject(error);
              });
            }));
          });
          Ember.RSVP.all(optionGroupPromises).then(optionGroups => {
            controller.set('optionGroups', optionGroups);
            controller.set('isLoading', false);
          }).catch(error => {
            console.log(error);
          });
        });
      });
    }
  });

  _exports.default = _default;
});