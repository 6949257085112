define("universal-cms/initializers/auth", ["exports", "universal-cms/services/auth"], function (_exports, _auth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize(application) {
    application.inject('controller', 'auth', 'service:auth');
    application.inject('component', 'auth', 'service:auth');
    application.inject('route', 'auth', 'service:auth');
    application.inject('service:movie', 'auth', 'service:auth');
    application.inject('service:service-resource', 'auth', 'service:auth');
  }

  var _default = {
    name: 'auth',
    initialize: initialize
  };
  _exports.default = _default;
});