define("universal-cms/routes/universal-studios/playlists/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      const controller = this.controllerFor(this.routeName);
      const playlistId = params.playlist_id;
      const isNew = playlistId === 'add';
      controller.set('isNew', isNew);
      let rsvp = {};
      rsvp.playlist = isNew ? Ember.Object.create({
        isNew: true
      }) : this.universalStudiosService.getOne('playlists', playlistId).then(playlist => {
        return playlist;
      }, () => {
        controller.set('error', true);
      });
      return Ember.RSVP.hash(rsvp);
    }

  });

  _exports.default = _default;
});