define("universal-cms/routes/website/movie-promos/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      let parentModel = this.modelFor('website.movie-promos');
      this.set('resources', parentModel.options.location);
      return Ember.RSVP.hash({
        options: parentModel.options,
        resource: Ember.ArrayProxy.create(this.serviceResource.getResource('website', 'movie-promos', params.movie_promo_id)),
        spots: Ember.ArrayProxy.create(this.serviceResource.getResources('website', `movie-promos/${params.movie_promo_id}/spots`, null, 'movie_promo_spots')),
        spot_types: Ember.ArrayProxy.create(this.serviceResource.getResources('webiste', 'spot-types'))
      });
    },

    actions: {
      newResource(moviePromo, parentController) {
        this.controller.set('isAdding', true);
        parentController.set('isLoading', true);
        this.serviceResource.newResource('website', `movie-promos/${moviePromo.promo_id}/spots`, moviePromo, 'movie_promo_spots').then(moviePromos => {
          let model = this.modelFor('website.moviePromos.edit');
          this.controller.set('isAdding', false);
          parentController.set('isLoading', false);
          this.notificationService.show('Created', 'Movie promo spot was created successfully.');
          model.spots.pushObject(moviePromos[0]);
        });
      }

    }
  });

  _exports.default = _default;
});