define("universal-cms/components/dreamworks/page-component-modals/gabbys-website-standard-diptych-full", ["exports", "universal-cms/components/dreamworks/page-component-modals/page-component-modal"], function (_exports, _pageComponentModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _pageComponentModal.default.extend({
    classNames: 'gabbys-website-standard-diptych-full',

    init() {
      this._super(...arguments); // let resourceFormOptions = [];
      // resourceFormOptions.pushObject(EmberObject.create({
      //     column: 'tag',
      //     type: 'text',
      //     label: 'Tag'
      // }));
      // resourceFormOptions.pushObject(EmberObject.create({
      //     column: 'title',
      //     type: 'text',
      //     label: 'Title'
      // }));
      // resourceFormOptions.pushObject(EmberObject.create({
      //     column: 'description',
      //     type: 'text-block',
      //     label: 'Description'
      // }));
      // resourceFormOptions.pushObject(EmberObject.create({
      //     column: 'custom_cta',
      //     type: 'text',
      //     label: 'Custom CTA (optional)'
      // }));
      // resourceFormOptions.pushObject(EmberObject.create({
      //     column: 'external_link',
      //     type: 'text',
      //     label: 'Link (optional)'
      // }));
      // resourceFormOptions.pushObject(EmberObject.create({
      //     column: 'image',
      //     type: 'asset',
      //     label: 'Image - Required'
      // }));
      // this.set('resourceFormOptions', resourceFormOptions);
      // if (!this.get('pageComponent.data.items')) {
      //     this.set('pageComponent.data.items', A());
      // }

    }

  });

  _exports.default = _default;
});