define("universal-cms/templates/components/dreamworks/page-component-modals/featured-carousel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LluMdL5b",
    "block": "{\"symbols\":[],\"statements\":[[9,\"h2\",true],[10],[1,1,0,0,\"\\n    Featured Carousel\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[7,\"pxl-form-field\",[],[[\"@label\",\"@value\"],[\"Section Title\",[27,[26,0,\"AppendSingleId\"],[\"data\",\"sectionTitle\"]]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[1,1,0,0,\"\\n\\n\"],[9,\"div\",true],[12,\"class\",\"resources-container\",null],[10],[1,1,0,0,\"\\n    \"],[7,\"dreamworks/resource-list\",[],[[\"@pageType\",\"@resourceIds\",\"@selectedResourceTypeOption\",\"@canAdd\",\"@onChanged\",\"@sectionPropertyMode\",\"@languageId\"],[[27,[26,1,\"AppendSingleId\"],[\"type\"]],[27,[26,0,\"AppendSingleId\"],[\"data\",\"items\"]],[27,[26,2,\"AppendSingleId\"],[]],true,[31,392,6,[27,[26,3,\"CallHead\"],[]],[[27,[24,0],[]],\"onResourcesChanged\"],null],true,[27,[26,4,\"AppendSingleId\"],[]]]],null],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"pageComponent\",\"page\",\"selectedResourceTypeOption\",\"action\",\"languageId\"]}",
    "meta": {
      "moduleName": "universal-cms/templates/components/dreamworks/page-component-modals/featured-carousel.hbs"
    }
  });

  _exports.default = _default;
});