define("universal-cms/components/main-nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service('-routing'),
    tagName: 'nav',
    isOpen: false,
    mainRoute: null,
    routeParam: null,
    icon: 'fa-sitemap',
    actions: {
      itemAction(action) {
        this.get(action)();
      }

    }
  });

  _exports.default = _default;
});