define("universal-cms/routes/dashboard/user-groups/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      return Ember.RSVP.hash({
        group: this.auth.getOne('groups', params.user_group_id),
        groups: this.modelFor('dashboard/userGroups'),
        services: this.auth.getAll('services')
      });
    }

  });

  _exports.default = _default;
});