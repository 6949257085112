define("universal-cms/components/pxl-form-listbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    util: Ember.inject.service(),
    classNames: 'pxl-form-listbox',
    items: [],
    singularText: 'item',
    pluralText: 'items',
    preValue: '',
    sortable: false,
    newItem: '',
    addButtonDisabled: Ember.computed('newItem', function () {
      return this.newItem === '';
    }),
    formattedItems: Ember.computed('items.[]', 'preValue', function () {
      if (!this.items) {
        return [];
      }

      return this.items.map(item => {
        return Ember.Object.create({
          originalValue: item,
          displayValue: this.getDisplayValue(item)
        });
      });
    }),

    init() {
      this._super(...arguments);

      if (!this.items) {
        this.set('items', []);
      }
    },

    getDisplayValue(item) {
      if (this.preValue) {
        return `${this.preValue}${item}`;
      } else {
        return item;
      }
    },

    _onChange() {
      if (typeof this.onChange === 'function') {
        this.onChange(this.items);
      }
    },

    actions: {
      addItem() {
        let newItem = this.newItem;

        if (newItem.substring(0, 1) === '#') {
          newItem = newItem.substring(1);
        }

        if (!this.items) {
          this.set('items', []);
        } // Check if item already exists


        if (this.items.includes(newItem)) {
          alert(`${this.util.capitalizeFirstLetter(this.singularText)} ${this.getDisplayValue(newItem)} already exists.`);
          return;
        }

        this.items.pushObject(newItem);
        this.set('newItem', '');
        this.element.querySelector('.add-input').focus();

        this._onChange();
      },

      removeItem(itemToRemove) {
        if (!confirm(`Are you sure you want to remove ${this.singularText} ${itemToRemove.displayValue}?`)) {
          return;
        }

        this.items.removeObject(itemToRemove.originalValue);

        this._onChange();
      },

      onReordered() {
        const items = this.formattedItems.map(item => item.originalValue);
        this.set('items', items);

        this._onChange();
      }

    }
  });

  _exports.default = _default;
});