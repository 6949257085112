define("universal-cms/components/dreamworks/page-component-modals/media-carousel", ["exports", "universal-cms/components/dreamworks/page-component-modals/page-component-modal"], function (_exports, _pageComponentModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _pageComponentModal.default.extend({
    classNames: 'dreamworks-media-carousel',

    init() {
      this._super(...arguments);

      let defaultSectionProperty; // Set default section property on init

      if (this.pageComponent.data.sectionProperty != null) {
        defaultSectionProperty = this.pageComponent.data.sectionProperty;
      } else {
        defaultSectionProperty = 'Movie';
      }

      const selectedResourceTypeOption = this.dreamworksService.getResourceTypeOptionFromSectionProperty(defaultSectionProperty);
      this.set('selectedResourceTypeOption', selectedResourceTypeOption);
    },

    actions: {
      onResourcesChanged(resourceTypeOption) {
        this.set('pageComponent.data.sectionProperty', resourceTypeOption.sectionProperty);
      }

    }
  });

  _exports.default = _default;
});