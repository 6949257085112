define("universal-cms/routes/dreamworks/home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    async model() {
      const controller = this.controllerFor(this.routeName);
      controller.set('isRefreshingModel', true);
      const homePage = await this.dreamworksService.getFirst('pages', {
        'slug': '/'
      });
      const pageDataResponse = await this.dreamworksService.getFirst('page-data', {
        resource_id: homePage.id
      });
      const homePageData = pageDataResponse && typeof pageDataResponse === 'object' ? pageDataResponse : null;
      const homePageSaveData = homePageData ? homePageData.save_data : null;
      const mediaTheaterComponent = homePageSaveData ? homePageSaveData.components.find(component => component.key === 'media-theater') : null;
      return Ember.RSVP.hash({
        homePage: homePage,
        homePageData: homePageData,
        homePageSaveData: homePageSaveData,
        mediaTheaterComponent: mediaTheaterComponent
      });
    },

    afterModel() {
      this._super(...arguments);

      const controller = this.controllerFor(this.routeName);
      controller.set('isRefreshingModel', false);
    },

    actions: {
      refreshModel() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});