define("universal-cms/components/dreamworks/page-component-modals/media-grid", ["exports", "universal-cms/components/dreamworks/page-component-modals/page-component-modal"], function (_exports, _pageComponentModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _pageComponentModal.default.extend({
    classNames: 'dreamworks-media-grid',
    resourceFormOptions: undefined,
    title: null,

    init() {
      this._super(...arguments);

      let resourceFormOptions = [];

      if (this.pageComponent.data.sectionTitle === 'Upcoming Events' || this.pageComponent.data.sectionTitle === 'Previous Events') {
        resourceFormOptions.pushObject(Ember.Object.create({
          column: 'title',
          type: 'text',
          label: 'Title'
        }));
        resourceFormOptions.pushObject(Ember.Object.create({
          column: 'subhead',
          type: 'text',
          label: 'Sub Title'
        }));
        resourceFormOptions.pushObject(Ember.Object.create({
          column: 'key_art',
          type: 'asset',
          label: 'Key Art'
        }));
        resourceFormOptions.pushObject(Ember.Object.create({
          column: 'url',
          type: 'text',
          label: 'URL'
        }));
      } else if (this.pageComponent.data.sectionTitle === 'Celebrating 25 Years of DreamWorks') {
        resourceFormOptions.pushObject(Ember.Object.create({
          column: 'key_art',
          type: 'asset',
          label: 'Key Art'
        }));
        resourceFormOptions.pushObject(Ember.Object.create({
          column: 'url',
          type: 'text',
          label: 'URL'
        }));
      } else {
        resourceFormOptions.pushObject(Ember.Object.create({
          column: 'id',
          type: 'games',
          resourceType: this.dreamworksService.getResourceTypeOption(this.dreamworksService.convertResourceType(this.resourceType)),
          label: 'Resource'
        }));
      }

      this.set('resourceFormOptions', resourceFormOptions);
    }

  });

  _exports.default = _default;
});