define("universal-cms/ember-quill/tests/templates/components/models-table/live", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "erAdI/fc",
    "block": "{\"symbols\":[],\"statements\":[[5,[27,[26,3,\"BlockHead\"],[]],[[27,[26,2,\"Expression\"],[\"is_live\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"    \"],[9,\"span\",true],[12,\"class\",\"label label-live\",null],[12,\"data-toggle\",\"tooltip\",null],[12,\"data-placement\",\"top\",null],[13,\"title\",[27,[26,5,\"AppendSingleId\"],[]],null],[13,\"data-original-title\",[27,[26,5,\"AppendSingleId\"],[]],null],[10],[1,1,0,0,\"\\n        Live\\n    \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[5,[27,[26,3,\"BlockHead\"],[]],[[27,[26,2,\"Expression\"],[\"automatically_unpublished\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"    \"],[9,\"span\",true],[12,\"class\",\"label label-default\",null],[12,\"data-toggle\",\"tooltip\",null],[12,\"data-placement\",\"top\",null],[13,\"title\",[27,[26,4,\"AppendSingleId\"],[]],null],[13,\"data-original-title\",[27,[26,4,\"AppendSingleId\"],[]],null],[10],[1,1,0,0,\"\\n        Was automatically unpublished\\n    \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[5,[27,[26,3,\"BlockHead\"],[]],[[27,[26,2,\"Expression\"],[\"live_start\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"        \"],[9,\"span\",true],[12,\"class\",\"label label-other\",null],[10],[1,1,0,0,\"\\n            Scheduled to go live \"],[1,0,0,0,[31,579,15,[27,[26,1,\"CallHead\"],[]],[[27,[26,0,\"Expression\"],[]]],[[\"interval\"],[1000]]]],[1,1,0,0,\"\\n        \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"        \"],[9,\"span\",true],[12,\"class\",\"label label-default\",null],[10],[1,1,0,0,\"\\n            Not scheduled to go live\\n        \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"liveStartFormatted\",\"moment-from-now\",\"record\",\"if\",\"automaticallyUnpublishedText\",\"wentLiveText\"]}",
    "meta": {
      "moduleName": "universal-cms/ember-quill/tests/templates/components/models-table/live.hbs"
    }
  });

  _exports.default = _default;
});