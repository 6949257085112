define("universal-cms/routes/universal-studios/banners/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      const controller = this.controllerFor(this.routeName);
      const bannerId = params.banner_id;
      const isNew = bannerId === 'add';
      controller.set('isNew', isNew);
      let rsvp = {};
      rsvp.banner = isNew ? Ember.Object.create({
        isNew: true
      }) : this.universalStudiosService.getOne('banners', bannerId).then(banner => {
        if (banner.get('desktop_image')) {
          banner.set('desktop_image_url', this.auth.getUniversalStudiosUrl(`uploads/${banner.get('desktop_image')}`));
        }

        if (banner.get('mobile_image')) {
          banner.set('mobile_image_url', this.auth.getUniversalStudiosUrl(`uploads/${banner.get('mobile_image')}`));
        }

        return banner;
      }, () => {
        controller.set('error', true);
      });
      return Ember.RSVP.hash(rsvp);
    }

  });

  _exports.default = _default;
});