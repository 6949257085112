define("universal-cms/components/dreamworks/page-component-modals/gabbys-website-resources-grid", ["exports", "universal-cms/components/dreamworks/page-component-modals/page-component-modal"], function (_exports, _pageComponentModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _pageComponentModal.default.extend({
    classNames: 'gabbys-website-resources-grid',

    init() {
      this._super(...arguments);

      if (!this.get('pageComponent.data.type')) {
        this.set('pageComponent.data.type', 'recent');
      }

      if (!this.get('pageComponent.data.custom_resource_ids')) {
        this.set('pageComponent.data.custom_resource_ids', Ember.A());
      } // if (!this.get('pageComponent.data.num_resources')) {
      //     this.set('pageComponent.data.num_resources', 3);
      // }


      if (!this.get('pageComponent.data.resource_type')) {
        // const selectedResourceTypeOption = this.dreamworksService.getResourceTypeOptionFromSectionProperty('Page');
        this.set('pageComponent.data.resource_type', 'page');
      }

      if (!this.get('pageComponent.data.custom_resource_type')) {
        const selectedResourceTypeOption = this.dreamworksService.getResourceTypeOptionFromSectionProperty('Page');
        this.set('pageComponent.data.custom_resource_type', selectedResourceTypeOption);
      }
    }

  });

  _exports.default = _default;
});