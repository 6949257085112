define("universal-cms/controllers/movies/edit/assets", ["exports", "ember-uploader", "universal-cms/config/environment", "moment"], function (_exports, _emberUploader, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    editMovieController: Ember.inject.controller('movies/edit'),
    movie: Ember.computed.reads('editMovieController.model'),
    selectedAssetType: undefined,
    assetGroups: Ember.A(),
    isLoading: true,
    isUploading: false,
    uploadProgressPercent: 0,
    fileToUpload: null,
    selectedAssetGroup: null,
    formattedAssetGroups: Ember.computed('model.{assetGroups.[],assets.[],assetTypes.[]}', 'selectedAssetGroup', function () {
      let assetTypes = this.get('model.assetTypes');
      let assetGroups = this.get('model.assetGroups');
      let selectedAssetGroup = this.selectedAssetGroup;
      assetGroups = assetGroups.map((assetGroup, assetGroupIndex) => {
        if (selectedAssetGroup === null) {
          assetGroup.set('tabSelected', assetGroupIndex === 0 ? 'active' : '');
        } else {
          assetGroup.set('tabSelected', assetGroup.get('id') === selectedAssetGroup.get('id') ? 'active' : '');
        }

        assetGroup.set('assets', Ember.A());
        assetGroup.set('assetTypes', assetTypes.filter(assetType => {
          return assetType.get('asset_group_id') === assetGroup.get('id');
        }));
        return assetGroup;
      });
      let assets = this.get('model.assets').map(asset => {
        let assetAssetType = assetTypes.find(assetType => {
          return asset.get('asset_type') === assetType.get('id');
        });
        let assetAssetGroupIndex = assetGroups.findIndex(assetGroup => {
          return assetGroup.get('id') === assetAssetType.get('asset_group_id');
        });
        let liveStartFormatted;
        let wentLiveText;
        let automaticallyUnpublishedText;
        let dbLiveStart;
        let userLiveStart;

        if (asset.get('live_start')) {
          dbLiveStart = _moment.default.tz(asset.get('live_start'), _environment.default.databaseTimezone);
          userLiveStart = dbLiveStart.clone().tz(_moment.default.tz.guess());
          liveStartFormatted = userLiveStart.format('YYYY-MM-DD HH:mm:ss');
        }

        if (asset.get('is_live')) {
          let now = _moment.default.tz((0, _moment.default)(), _environment.default.databaseTimezone);

          wentLiveText = `Went live ${now.to(userLiveStart)}`;
        } else if (asset.get('automatically_unpublished')) {
          let dbLiveEnd = _moment.default.tz(asset.get('live_end'), _environment.default.databaseTimezone);

          let userLiveEnd = dbLiveEnd.clone().tz(_moment.default.tz.guess());

          let now = _moment.default.tz((0, _moment.default)(), _environment.default.databaseTimezone);

          automaticallyUnpublishedText = `Was unpublished ${now.to(userLiveEnd)}`;
        }

        asset.setProperties({
          'assetTypeName': assetAssetType.get('name'),
          'assetTypeDescription': assetAssetType.get('description'),
          'isAssetTypeImage': assetAssetType.get('type') === 'image',
          'isAssetTypeVideo': assetAssetType.get('type') === 'video',
          'liveStartFormatted': liveStartFormatted,
          'wentLiveText': wentLiveText,
          'automaticallyUnpublishedText': automaticallyUnpublishedText
        });
        assetGroups[assetAssetGroupIndex].get('assets').pushObject(asset);
        return asset;
      });
      assetGroups = assetGroups.map(assetGroup => {
        let numAssets = assetGroup.get('assets').length;

        if (numAssets > 0) {
          assetGroup.get('assets')[0].set('isFirst', true);
          assetGroup.get('assets')[numAssets - 1].set('isLast', true);
        }

        return assetGroup;
      });
      return assetGroups;
    }),
    actions: {
      createAsset(data) {
        let assetType = this.get('model.assetTypes').find(assetType => {
          return assetType.get('id') === data.assetTypeId;
        });
        let allowMany = assetType.get('allow_many') === true;
        this.movieService.createOne('assets', {
          asset_type_id: data.assetTypeId,
          movie_id: this.movie.id,
          value: data.value,
          live_start: null,
          live_end: null
        }).then(createdAsset => {
          //this.get('model.assets').pushObject(createdAsset);
          //this.set('fileToUpload', null);
          this.notificationService.show('Asset uploaded', 'Asset has been uploaded!');
          this.get('target._routerMicrolib').refresh();
        }, error => {
          this.notificationService.show('Something went wrong', 'Could not save asset.');
        }).catch(() => {
          this.notificationService.show('Something went wrong', 'Could not save asset.');
        }).then(() => {
          this.set('isUploading', false);
        });
      },

      updateAsset(assetToUpdate) {
        let alt_text = assetToUpdate.alt_text === '' ? null : assetToUpdate.alt_text;
        return this.movieService.replaceOne('assets', assetToUpdate.get('id'), {
          alt_text: alt_text,
          asset_type_id: assetToUpdate.asset_type,
          movie_id: assetToUpdate.movie,
          value: assetToUpdate.value,
          live_start: assetToUpdate.live_start,
          live_end: assetToUpdate.live_end,
          position: assetToUpdate.position
        }).then(replacedAsset => {
          this.notificationService.show('Saved', 'Alt tag was saved');
          this.get('target._routerMicrolib').refresh();
          return replacedAsset;
        }, error => {
          this.notificationService.show('Something went wrong', 'Could not save asset.');
        }).catch(() => {
          this.notificationService.show('Something went wrong', 'Could not save asset.');
        });
      },

      deleteAsset(assetToDelete) {
        if (!confirm('Are you sure you want to delete asset?')) {
          return;
        }

        assetToDelete.set('isDeleting', true);
        this.movieService.deleteOne('assets', assetToDelete.get('id')).then(() => {
          this.get('model.assets').removeObject(assetToDelete);
          this.notificationService.show('Asset deleted', `Asset was successfully deleted.`);
          this.get('target._routerMicrolib').refresh();
        }, error => {
          this.notificationService.show('Could not delete asset', `Could not delete asset.`);
        });
      },

      tabClick(selectedAssetGroup) {
        this.set('selectedAssetGroup', selectedAssetGroup);
      },

      changePosition(assetToMove, assetGroup, direction) {
        let assetToMoveIndex = assetGroup.assets.indexOf(assetToMove);
        let assetToMoveOldPosition = assetToMove.position;
        let assetToMoveNewPosition;
        let assetToSwitchWith;
        let assetToSwitchWithIndex;

        if (direction === 'up') {
          // Get asset above
          assetToSwitchWithIndex = assetToMoveIndex - 1;
          assetToSwitchWith = assetGroup.assets[assetToMoveIndex - 1];
          assetToMoveNewPosition = assetToMoveOldPosition - 1;
        } else {
          // Get asset below
          assetToSwitchWithIndex = assetToMoveIndex + 1;
          assetToSwitchWith = assetGroup.assets[assetToMoveIndex + 1];
          assetToMoveNewPosition = assetToMoveOldPosition + 1;
        }

        let assetToSwitchWithNewPosition = assetToMoveOldPosition;
        assetToMove.position = assetToMoveNewPosition;
        assetToSwitchWith.position = assetToSwitchWithNewPosition;
        Ember.RSVP.Promise.all([new Ember.RSVP.Promise((resolve, reject) => {
          this.movieService.replaceOne('assets', assetToMove.id, {
            asset_type_id: assetToMove.asset_type,
            movie_id: assetToMove.movie,
            value: assetToMove.value,
            position: assetToMove.position
          }).then(replacedAsset => {
            resolve(replacedAsset);
          });
        }), new Ember.RSVP.Promise((resolve, reject) => {
          this.movieService.replaceOne('assets', assetToSwitchWith.id, {
            asset_type_id: assetToSwitchWith.asset_type,
            movie_id: assetToSwitchWith.movie,
            value: assetToSwitchWith.value,
            position: assetToSwitchWith.position
          }).then(replacedAsset => {
            resolve(replacedAsset);
          });
        })]).then(result => {
          /*let assetGroups = this.get('assetGroups');
           // Switch assets in array
          for (let assetGroupIndex = 0, numAssetGroups = assetGroups.length; assetGroupIndex < numAssetGroups; assetGroupIndex++) {
              if (assetGroups[assetGroupIndex].id === assetGroup.id) {
                  let numAssets = assetGroups[assetGroupIndex].assets.length;
                   let tmpAssets = assetGroups[assetGroupIndex].assets;
                  let tmpAsset = tmpAssets[assetToSwitchWithIndex];
                   // Update isFirst/isLast
                  if (assetToMoveNewPosition === 0) {
                      assetToMove.set('isFirst', true);
                      assetToMove.set('isLast', false);
                  } else if (assetToMoveNewPosition === (numAssets - 1)) {
                      assetToMove.set('isLast', true);
                      assetToMove.set('isFirst', false);
                  } else {
                      assetToMove.set('isFirst', false);
                      assetToMove.set('isLast', false);
                  }
                   if (assetToSwitchWithNewPosition === 0) {
                      assetToSwitchWith.set('isFirst', true);
                      assetToSwitchWith.set('isLast', false);
                  } else if (assetToSwitchWithNewPosition === (numAssets - 1)) {
                      assetToSwitchWith.set('isLast', true);
                      assetToSwitchWith.set('isFirst', false);
                  } else {
                      assetToSwitchWith.set('isFirst', false);
                      assetToSwitchWith.set('isLast', false);
                  }
                   tmpAssets[assetToSwitchWithIndex] = tmpAssets[assetToMoveIndex];
                  tmpAssets[assetToMoveIndex] = tmpAsset;
                   assetGroups[assetGroupIndex].set('assets', tmpAssets);
                  assetGroups[assetGroupIndex].assets.arrayContentDidChange();
                   break;
              }
          }*/
          this.notificationService.show('Asset moved', 'Asset position changed.');
          this.get('target._routerMicrolib').refresh();
        });
      },

      upload() {
        let fileToUpload = this.fileToUpload;

        if (fileToUpload === null) {
          this.notificationService.show('Choose file', 'Choose file before uploading asset.');
          return;
        }

        let assetType = this.selectedAssetType;

        if (!assetType) {
          this.notificationService.show('Choose type', 'Choose type before uploading asset.');
          return;
        }

        let uploadUrl = this.movieService.getUrl('media');
        let auth = this.auth;

        let uploader = _emberUploader.default.Uploader.extend({
          ajaxSettings: function () {
            var settings = this._super.apply(this, arguments);

            settings.headers = {
              'X-Auth': 'Bearer ' + auth.jwt
            };
            return settings;
          }
        }).create({
          url: uploadUrl
        });

        let oldAjaxSettings = uploader.ajaxSettings.bind(uploader);

        uploader.ajaxSettings = (url, data, method) => {
          let response = oldAjaxSettings(url, data, method);
          response.headers = {
            'X-Auth': 'Bearer ' + this.get('auth.jwt')
          };
          return response;
        };

        this.set('isUploading', true);
        uploader.upload(fileToUpload).then(data => {
          if (data.status === 'fail') {
            this.set('isUploading', false);
            this.notificationService.show('Could not upload asset.', data.data[0]);
            return;
          }

          let url = this.movieService.getUrl('media/' + data.data.media.filename); // Save asset

          this.send('createAsset', {
            assetTypeId: assetType.id,
            value: url
          }); //this.get('notificationService').show('Asset uploaded', 'Asset has been uploaded!');
        }, uploadError => {
          console.log('uploadError');
          this.set('isUploading', false);
          this.notificationService.show('Something went wrong', 'Could not upload asset.');
        });
        uploader.on('progress', function (e) {
          let percent = e.percent;
          this.set('uploadProgressPercent', percent);
        });
      },

      onPublish(data) {
        return this.movieService.replaceOne('assets', data.resource.id, {
          asset_type_id: data.resource.asset_type,
          movie_id: data.resource.movie,
          value: data.resource.value,
          live_start: data.publishDate,
          live_end: data.unpublishDate,
          position: data.resource.position
        }).then(replacedAsset => {
          data.doneCallback(replacedAsset);
          this.notificationService.show('Published', 'Asset was published.');
          this.get('target._routerMicrolib').refresh();
          return replacedAsset;
        });
      },

      onUnpublish(data) {
        return this.movieService.replaceOne('assets', data.resource.id, {
          asset_type_id: data.resource.asset_type,
          movie_id: data.resource.movie,
          value: data.resource.value,
          live_start: null,
          live_end: null,
          position: data.resource.position
        }).then(replacedAsset => {
          data.doneCallback(replacedAsset);
          this.notificationService.show('Unublished', 'Asset was unpublished.');
          this.get('target._routerMicrolib').refresh();
          return replacedAsset;
        });
      }

    }
  });

  _exports.default = _default;
});