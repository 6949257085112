define("universal-cms/templates/option-types/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dXYZspsA",
    "block": "{\"symbols\":[],\"statements\":[[9,\"h1\",true],[12,\"class\",\"page-header\",null],[10],[1,1,0,0,\"\\n    \"],[1,0,0,0,[27,[26,0,\"Expression\"],[\"optionType\",\"name\"]]],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[7,\"option-type-form\",[],[[\"@mode\",\"@optionType\",\"@optionGroups\",\"@updateOptionType\"],[\"edit\",[27,[26,0,\"AppendSingleId\"],[\"optionType\"]],[27,[26,0,\"AppendSingleId\"],[\"optionGroups\"]],[31,181,6,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[]],\"updateOptionType\"],null]]],null],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"action\"]}",
    "meta": {
      "moduleName": "universal-cms/templates/option-types/edit.hbs"
    }
  });

  _exports.default = _default;
});