define("universal-cms/ember-quill/tests/templates/dreamworks/resource-items/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ebQ7EtaM",
    "block": "{\"symbols\":[],\"statements\":[[9,\"h1\",true],[12,\"class\",\"page-header less-bottom-margin\",null],[10],[1,1,0,0,\"\\n    \"],[1,0,0,0,[27,[26,0,\"AppendSingleId\"],[]]],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"title\"]}",
    "meta": {
      "moduleName": "universal-cms/ember-quill/tests/templates/dreamworks/resource-items/list.hbs"
    }
  });

  _exports.default = _default;
});