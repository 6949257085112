define("universal-cms/components/dreamworks/social-editor/posts-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'dreamworks-posts-editor',
    title: null,
    posts: [],
    formattedPosts: Ember.computed('posts.[]', 'disabledPosts.[]', function () {
      if (!this.posts) {
        return [];
      }

      return this.posts.map(post => {
        const isDisabled = this.disabledPosts && this.disabledPosts.find(postId => postId === post.id);
        post.set('isDisabled', isDisabled);
        post.set('htmlText', Ember.String.htmlSafe(post.text));
        return post;
      });
    }),
    disabledPosts: [],
    postsText: Ember.computed('formattedPosts.[]', function () {
      const numPosts = this.formattedPosts.length;
      return `Scraped ${numPosts} post${numPosts !== 1 ? 's' : ''}.`;
    }),
    actions: {
      disablePost(postToDisable) {
        if (!confirm('Are you sure you want to disable post?')) {
          return;
        }

        if (!this.disabledPosts) {
          this.set('disabledPosts', []);
        }

        this.disabledPosts.pushObject(postToDisable.id);
      },

      enablePost(postToEnable) {
        this.disabledPosts.removeObject(postToEnable.id);
      }

    }
  });

  _exports.default = _default;
});