define("universal-cms/templates/components/dreamworks/page-component-modals/search-results", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kawRbLJW",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[16,1,null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "universal-cms/templates/components/dreamworks/page-component-modals/search-results.hbs"
    }
  });

  _exports.default = _default;
});