define("universal-cms/initializers/uwatch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize(application) {
    application.inject('route', 'uwatchService', 'service:uwatch');
    application.inject('controller', 'uwatchService', 'service:uwatch');
    application.inject('component', 'uwatchService', 'service:uwatch');
  }

  var _default = {
    name: 'uwatch',
    initialize
  };
  _exports.default = _default;
});