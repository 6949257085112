define("universal-cms/components/dreamworks/business-partners-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'dreamworks-business-partners-editor',
    isSaving: false,
    newPartner: undefined,
    partners: undefined,
    editIndex: null,
    saveButtonText: Ember.computed('mode', function () {
      if (this.mode === 'edit') {
        return this.isSaving ? 'Saving...' : 'Save';
      } else {
        return this.isSaving ? 'Adding...' : 'Add';
      }
    }),

    init() {
      this._super(...arguments);

      if (!this.partners) {
        this.set('partners', []);
      }

      this.resetNewPartner();
    },

    resetNewPartner() {
      this.set('newPartner', Ember.Object.create({
        alt: '',
        link: '',
        image: null
      }));
    },

    _onChange() {
      if (typeof this.onChange === 'function') {
        this.onChange(this.partners);
      }
    },

    focusNameInput() {
      this.element.querySelector('.input-name').focus();
    },

    actions: {
      savePartner() {
        if (!this.newPartner.alt) {
          this.element.querySelector('.input-name').focus();
          return;
        }

        this.set('isSaving', true);

        if (!this.partners) {
          this.set('partners', []);
        }

        if (this.mode === 'edit') {
          this.partners[this.editIndex] = this.newPartner;
          this.partners.arrayContentDidChange();
          this.editIndex = null;
        } else {
          this.partners.pushObject(this.newPartner);
        }

        this.set('mode', 'add');
        this.resetNewPartner();
        Ember.run.scheduleOnce('afterRender', this, this.focusNameInput);

        this._onChange();

        this.set('isSaving', false);
      },

      removePartner(partnerToRemove) {
        if (!confirm(`Are you sure you want to remove "${partnerToRemove.alt}"?`)) {
          return;
        }

        this.partners.removeObject(partnerToRemove);

        this._onChange();
      },

      editPartner(partnerToEdit, index) {
        this.set('mode', 'edit');
        this.set('isSaving', false);
        this.set('editIndex', index);
        this.set('newPartner', Ember.Object.create({
          alt: partnerToEdit.alt,
          link: partnerToEdit.link,
          image: partnerToEdit.image
        }));
      }

    }
  });

  _exports.default = _default;
});