define("universal-cms/controllers/website/seo/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    tableColumns: null,

    init() {
      this._super(...arguments);

      this.set('tableColumns', [{
        'propertyName': 'title',
        'title': 'Page',
        'component': 'models-table/seo-page-title'
      }, {
        'title': 'Manage',
        'component': 'models-table/seo-page-actions'
      }]);
    },

    categories: Ember.computed('model.pages', function () {
      let categories = Ember.A();
      const prefrerredOrder = ['Landing Pages', 'Movie Pages', 'News & Press Pages', 'Executive Bios Pages'];

      for (let page of this.get('model.pages')) {
        let categoryInCategoriesIndex = categories.findIndex(checkCategory => {
          return checkCategory.name === page.category;
        });

        if (categoryInCategoriesIndex === -1) {
          categories.pushObject({
            activeClass: page.category === prefrerredOrder[0] ? 'active' : '',
            name: page.category,
            pages: Ember.A([page])
          });
        } else {
          categories[categoryInCategoriesIndex].pages.pushObject(page);
        }
      } // Sort


      let sortCategories = function (categories) {
        let sortedCategories = [];

        for (let i = 0, numPreferredOrderItems = prefrerredOrder.length; i < numPreferredOrderItems; i++) {
          for (let category of categories) {
            if (category.name === prefrerredOrder[i]) {
              sortedCategories.push(category);
              break;
            }
          }
        }

        return sortedCategories;
      };

      categories = sortCategories(categories);
      return categories;
    })
  });

  _exports.default = _default;
});