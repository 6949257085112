define("universal-cms/ember-quill/tests/templates/components/super-table-yield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C/+mG4kV",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[16,1,[[31,9,4,[27,[26,4,\"CallHead\"],[]],null,[[\"orderable\",\"deletable\",\"creatable\",\"setDraggingItem\",\"onDragEnter\",\"onDragEnd\"],[[27,[26,3,\"Expression\"],[]],[27,[26,2,\"Expression\"],[]],[27,[26,1,\"Expression\"],[]],[31,129,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[]],\"setDraggingItem\"],null],[31,177,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[]],\"onDragEnter\"],null],[31,221,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[]],\"onDragEnd\"],null]]]]]]],\"hasEval\":false,\"upvars\":[\"action\",\"creatable\",\"deletable\",\"orderable\",\"hash\"]}",
    "meta": {
      "moduleName": "universal-cms/ember-quill/tests/templates/components/super-table-yield.hbs"
    }
  });

  _exports.default = _default;
});