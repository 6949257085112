define("universal-cms/ember-quill/tests/templates/components/thumbnail-preview-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nu6JPztU",
    "block": "{\"symbols\":[],\"statements\":[[7,\"light-box\",[],[[\"@href\",\"@data-lightbox\",\"@class\"],[[27,[26,0,\"AppendSingleId\"],[]],[27,[26,1,\"AppendSingleId\"],[]],\"thumbnail-preview-image\"]],null],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"url\",\"elementId\"]}",
    "meta": {
      "moduleName": "universal-cms/ember-quill/tests/templates/components/thumbnail-preview-image.hbs"
    }
  });

  _exports.default = _default;
});